<template>
  <div>
    <a-form-item-rest>
      <template v-if="dataSource.length === 0 && fileList.length === 0">
        <div class="flex justify-center ">
          <a-upload-dragger
            :file-list="fileList"
            :max-count="1"
            accept=".xlsx, .xls"
            :multiple="false"
            :before-upload="() => false"
            @change="handleChange"
          >
            <p class="ant-upload-drag-icon">
              <basic-icon
                name="icon-upload_black_24dp"
                class="text-4xl"
              />
            </p>
            <p class="ant-upload-text">
              点击或拖拽文件到此上传
            </p>
            <p class="px-2 ant-upload-hint">
              可直接将文件拖拽到此处进行上传，支持格式: XLS、XLSX，大小不超过5M
            </p>
          </a-upload-dragger>
        </div>
        <Divider class="mt-10" />
        <div class="flex justify-between mb-2 text-red-500 ">
          上传前请确认： <span
            v-if="Boolean(props.downLoadUrl)"
            class="text-blue-500 cursor-pointer"
            @click="downloadFile(props.downLoadUrl, {}, props.excelName, 'xlsx')"
          >
            下载模板
          </span>
        </div>

        <div
          v-for="(tip, index) in props.warningTexts"
          :key="index"
          class="mb-1 text-xs text-gray-500"
        >
          {{ tip }}
        </div>
      </template>
      <div class="flex items-center">
        <div class="flex items-center text-red-500">
          已经开启实名认证校验
        </div>
        <a-progress
          v-if="excelInfo.isShowProgress"
          class="mx-auto w-4/5"
          :percent="excelInfo.realNameCheckPercent"
          status="active"
        />
      </div>

      <div v-if="realNameCheckFailTableInfo.dataSource.length">
        <div class="flex justify-between items-center">
          <div>
            检测结果：共导入{{ excelInfo.result.resultList.length }}条，<span class="text-green-500">{{ excelInfo.validatedNumber
            }}</span>条正常，<span class="text-red-500">{{ excelInfo.unValidateNumber }}</span>条异常
          </div>
          <div>
            <a-button
              type="primary"
              class="mr-3 "
              style="margin-top: 2px;"
              @click="toggle"
            >
              全屏预览
            </a-button>
            <a-button
              v-if="realNameCheckFailTableInfo.dataSource.length > 0"
              type="primary"
              class="ml-2 inline"
              style="margin-top: 2px;"
              @click="downloadError(realNameCheckFailTableInfo.dataSource)"
            >
              下载错误名单
            </a-button>
          </div>
        </div>
        <a-table
          ref="tableRef"
          :bordered="true"
          :scroll="{
            x: isFullscreen ? 'calc(100vh - 55px)' : tableWidth,
          }"
          :data-source="realNameCheckFailTableInfo.dataSource"
          :columns="realNameCheckFailTableInfo.columns"
          :pagination="{
            total: realNameCheckFailTableInfo.dataSource.length,
            pageSize: realNameCheckFailTableInfo.pageSize,
            showQuickJumper: true,
            showLessItems: true,
            onShowSizeChange: realNameCheckFailTableInfo.onShowSizeChange,
            showSizeChanger: true
          }"
        >
          <template #bodyCell="{ column, record }">
            <div :class="column.dataIndex === 'reason' ? 'text-red-500' : 'text-black'">
              {{ record[column.dataIndex] }}
            </div>
          </template>
        </a-table>
      </div>
      <div
        v-if=" excelInfo.isComplete && excelInfo.unValidateNumber===0"
        class="text-green-500"
      >
        所有人员已成功导入
      </div>
    </a-form-item-rest>
  </div>
</template>
<script lang="tsx" setup>
import { useMessage } from '@/hooks/message'
import { downloadFile } from '@/utils/file'
import { exportExcelDetail, getExcelOriginCell, getSheetHeaderAndData } from '@/utils/xlsx'
import { TableColumnsType, Divider, UploadFile } from 'ant-design-vue'
import { formatToDateTime, formatToDate, formatToMonth, dateFromNow } from '@/utils/date'
import { DateFormat, FilterDependedKeyAndString, realnamecheckExcelImportProps } from './props'
import { cloneDeep } from 'lodash-es'
import { Table } from 'ant-design-vue/es'
import { useAppStore } from '@/store/modules/app'
import { catchData } from '../excel-import/common/catchData'
import { IRealNameCheckFailTableInfo } from '../excel-import/excel'
import { workApi } from '@/api/worker'
import { toErrorPackage } from '../excel-import/common/toErrorPackage'
import { ICheckedExcelInfo } from './types'
const { getReferenceItemsLabelMap } = useAppStore()
type EmitEvents = {
  (e: 'update:value', params: ICheckedExcelInfo['result']): void,
  (e: 'complete'): void,
}
const realNameCheckFailTableInfo = ref<IRealNameCheckFailTableInfo>({
  dataSource: [],
  onShowSizeChange(current: number, pageSize: number) {
    realNameCheckFailTableInfo.value.pageSize = pageSize
  },
  pageSize: 15,
  columns: []
})
const excelInfo = ref<ICheckedExcelInfo>({
  result: {
    isAllImport: false,
    resultList: [], // 最后需要传递给form的数据
  },
  isShowProgress: false,
  unValidateNumber: 0,
  validatedNumber: 0,
  realNameCheckPercent: 0,
  isComplete: false // 校验是否完成
})
const fileList = ref<UploadFile[]>([])
const dataSource = ref<Recordable[]>([])
let errorList = ref<Recordable[]>([])
const columns = ref<TableColumnsType>([])
const tableRef = ref<InstanceType<typeof Table>>()
const tableWidth = ref(0)
const emits = defineEmits<EmitEvents>()
const props = defineProps(realnamecheckExcelImportProps)
const { toggle, isFullscreen } = useFullscreen(tableRef)
const downloadError = async dataList => {
  let textKeyMaps = Object.keys(props.textKeyMap).map(key => ({ [key]: props.textKeyMap[key] }))
  textKeyMaps.unshift({ 失败原因: 'reason' })
  await exportExcelDetail({
    textKeyMaps,
    dataList,
    excelName: `${props.excelName}信息有误数据表`
  })
}
const attrs = useAttrs()


const calibrator = (staffContractId, projectId) => {
  realNameCheckFailTableInfo.value.dataSource = [] // 重置校验错误数据，防止重复提交数据重复
  let count = 0
  if (excelInfo.value.result.resultList.length === 0) {
    useMessage.error('通过校验的数据为空,请重新上传表格')
    return
  }
  const tempResultList = excelInfo.value.result.resultList?.map(item => {
    // 附带上签约公司id
    // let tempObj: Recordable = { staffContractId } //后端要求去掉合同id
    let tempObj: Recordable = { }
    let fieldObj = {}
    for (let key in item) {
      if (!/\w/g.test(key)) {
        fieldObj[key] = item[key]
      } else {
        tempObj[key] = item[key]
      }
    }
    // 将学历string 转value
    tempObj.education = getReferenceItemsLabelMap('educationEnum')[item.education]?.value
    tempObj.feildList = fieldObj
    return tempObj
  })
  excelInfo.value.isShowProgress = true
  const promiseArr = tempResultList.map((item, index) =>
    workApi.batchImportWorker({
      projectId,
      data: [item],
      twoVerify: 1 // 开启两要素校验
    }).then(successRes => {
      if (successRes.data.httpCode !== 200) {
        excelInfo.value.unValidateNumber++

        /* httpCode
            201;//身份证验证不通过
            202;//已在其他地方入职
            203;//身份信息有误 */
        const dataSourceItem = Object.assign({ reason: toErrorPackage(successRes.data.data) }, excelInfo.value.result.resultList[index])

        // 不通过实名校验的数据搞出来
        realNameCheckFailTableInfo.value.dataSource.push(dataSourceItem)
      } else {
        excelInfo.value.validatedNumber++
      }
    })
      .catch(err => {
        useMessage.error(err)
      })
      .finally(() => {
        count++
        excelInfo.value.realNameCheckPercent = Math.min(Math.round(count / tempResultList.length * 100), 100)
      }))
  Promise.allSettled(promiseArr).then(() => {
    excelInfo.value.isComplete = true
    excelInfo.value.result.isAllImport = !excelInfo.value.unValidateNumber
    emits('complete')
    emits('update:value', unref(excelInfo).result)
    // if (!excelInfo.value.result.isAllImport) {
    //   await downloadError(realNameCheckFailTableInfo.value.dataSource)
    //   useMessage.info('有校验失败数据已自动下载')
    // }
  })
}

/** 解决百分比化小数精度问题 （百分比最多三位小数时） */
const formatter = value => {
  let v = (parseFloat(value) / 100).toString()
  let vArr = v.split('.')
  if (vArr.length == 2) {
    v = `${vArr[0]}.${vArr[1].substring(0, 5)}`
    v = `${parseFloat(v)}`
  } else if (vArr.length == 1) {
    return `${v}`
  }
  return v
}

// 重置表格数据
const resetTable = () => {
  fileList.value = []
  dataSource.value = []
  excelInfo.value.result.resultList = []
  emits('update:value', unref(excelInfo).result)
}
// 格式化日期
const formatDate = (needFormatDataKey: string[], format: DateFormat, cloneItem) => {
  const formatFunctionMap = {
    'YYYY-MM-DD HH:mm': formatToDateTime,
    'YYYY-MM-DD': formatToDate,
    'YYYY-MM': formatToMonth,
    NOW: dateFromNow
  }
  needFormatDataKey.forEach(key => {
    if (cloneItem[key]) {
      const formatItem = formatFunctionMap[format](cloneItem[key])
      if (formatItem !== 'Invalid Date') {
        cloneItem[key] = formatItem
      } else {
        cloneItem[key] = undefined

      }
    }
  })
  return cloneItem
}
// 去百分号
const removePercentSign = (cloneDataList, needRemovePercentKey: string[], cloneItem, cloneItemIndex) => {
  needRemovePercentKey.forEach(key => {
    cloneItem[key] = cloneItem[key] && formatter(cloneItem[key])
  })
  return cloneDataList
}

// 过滤数据
const dataFilter = (cloneDataList, filterDependedKeyAndString: FilterDependedKeyAndString) => cloneDataList.filter(item => item[filterDependedKeyAndString.key] === filterDependedKeyAndString.dependedString)
async function handleChange({ file }) {

  let unValidateNumber = [] as Recordable[]
  if (file.status === 'removed') {
    resetTable()
    return
  }
  // 单个sheet导入
  const singleSheet = sheetList => {
    let { headerColumns, dataList, dataSourceList } = getSheetHeaderAndData(sheetList[0], props.textKeyMap)
    const headerColumnsTextKey = headerColumns.map(i => i.title)
    const textKey = Object.keys(props.textKeyMap)

    let status = false
    // 单sheet导入通过判断配置的表头名字来验证是否选错导入表格(为了可以自定义表头导入，只要选择的表头包含了配置的表头既正确)
    const inequalityArr = textKey.filter(key => !headerColumnsTextKey.includes(key))
    if (inequalityArr.length === 0 && textKey.length === headerColumnsTextKey.length) {
      status = true
    }
    let errorAndOriginalDataList: Recordable[] = []
    let tempUnValidateNumber: number[] = []
    let cloneDataList = cloneDeep(dataList)
    cloneDataList.forEach((item, index) => {
      // 是否去掉百分号
      if (props.needRemovePercentKey.length) {
        dataList[index] = removePercentSign(cloneDataList, props.needRemovePercentKey, item, index)
      }
      // 是否格式化日期
      if (props.needFormatDataKey.length) {
        dataList[index] = formatDate(props.needFormatDataKey, props.format, item)
      }

    })
    unValidateNumber = Array.from(new Set(tempUnValidateNumber)) // 去除重复索引
    // 过滤出没通过校验的数据
    let unValiDateDataList = errorAndOriginalDataList.filter((item, index) => unValidateNumber.includes(index))
    //  过滤出通过校验的数据
    let valiDateDataList = cloneDataList.filter((item, index) => !unValidateNumber.includes(index))
    console.log(valiDateDataList, '通过校验的数据', unValiDateDataList, '错误的数据')

    // 是否过滤数据
    if (props.isFilter) {
      valiDateDataList = dataFilter(valiDateDataList, props.filterDependedKeyAndString!)
    }
    return {
      headerColumns,
      errorAndOriginalDataList,
      valiDateDataList,
      unValiDateDataList,
      status
    }
  }


  const { excelData: sheetList, sheetNames } = await getExcelOriginCell(file) // 获取 excel 表所有 sheet 所有单元格数据
  // 多sheet导入时计算所有sheet的数据总数，单sheet导入只计算第一个sheet的数据长度
  if (props.maxImportNum + 1 < sheetList[0].length) {
    useMessage.error(`最多导入${props.maxImportNum}条`)
    resetTable()
    return
  }

  const { headerColumns, unValiDateDataList, errorAndOriginalDataList, valiDateDataList, status } = singleSheet(sheetList)
  if (!status) {
    useMessage.error('模板错误，请选择正确模板')
    return
  }
  console.log('headerColumns', headerColumns) // table 表头结构
  console.log('errorAndOriginalDataList', errorAndOriginalDataList) // table 表格数据
  console.log('valiDateDataList', valiDateDataList) // 传递给后端的数据
  tableWidth.value = headerColumns.length * 110
  columns.value = headerColumns
  const temp = [...headerColumns]
  temp.unshift({
    dataIndex: 'reason',
    title: '失败原因'
  })
  realNameCheckFailTableInfo.value.columns = temp
  dataSource.value = errorAndOriginalDataList
  errorList.value = unValiDateDataList
  excelInfo.value.result.resultList = valiDateDataList

  catchData(excelInfo.value.result.resultList, () => {
    fileList.value = [file]
    calibrator(props.realNameCheckParams?.contractId, props.realNameCheckParams?.projectId)
    emits('update:value', unref(excelInfo).result)
  })
}
// onMounted(() => {

//   /* 挂载好提交一次默认值，防止直接提交控制台报错（在form中会失去必填校验效果） */
//   emits('update:value', unref(excelInfo).resultList)
// })


</script>
