<style lang="less" scoped>
.content:parent {
  padding: 12px 6px !important;
}

/* 单行省略 */
.f-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.view-more:hover {
  cursor: pointer;
}
</style>

<template>
  <div class="relative">
    <div class="z-10 flex items-center h-full">
      <a-dropdown
        placement="bottom"
        @click="toViewMore"
      >
        <div
          class="cursor-pointer w-[50px] h-[50px] flex justify-center items-center rounded text-gray-400 hover:text-blue-400"
        >
          <a-badge
            :count="notificaitons.unreadMessageCount"
            :overflow-count="99"
            size="small"
          >
            <BasicIcon
              name="icon-notifications_black_24dp"
              class="mt-2 text-2xl text-white cursor-pointer"
            />
          </a-badge>
        </div>

        <template #overlay>
          <div class="bg-white shadow-lg">
            <div
              v-if="messages.length>0"
              style="width: 320px;"
              class="content"
            >
              <div
                class="flex justify-between px-4 py-3 font-bold"
                style="border-bottom: solid 1px #EEEEEE;"
              >
                <div>
                  共 <span class="text-base text-red-600">{{ notificaitons.unreadMessageCount }}</span> 条未读通知
                </div>
                <div
                  class="cursor-pointer text-primaryColor-default"
                >
                  <!-- 一键已读 -->
                </div>
              </div>
              <div
                v-for="item in messages.slice(0, 5)"
                :key="item.id"
                class="relative px-4 py-2 cursor-pointer group hover:bg-[#F5F7F9]"
                style="border-bottom: solid 1px #EEEEEE;"
                @click="toMessagePage(item)"
              >
                <div class="flex items-center w-full">
                  <div class="flex-1 flex flex-col">
                    <div
                      class="flex items-center justify-between"
                      style="margin-bottom: 10px;"
                    >
                      <div
                        class="font-bold"
                        style="border-radius: 16px;padding: 2px 10px;color: #21209C;background-color: rgb(33 32 156 / 10%);"
                      >
                        {{ item.messageEventTypeDesc }}
                      </div>
                      <div class="text-gray-400">
                        {{ dateFromNow(item.createdAt) }}
                      </div>
                    </div>
                    <div class="flex justify-between items-center">
                      <div
                        class="text-xs text-gray-400 f-ellipsis"
                        style="width: 165px;"
                      >
                        {{ item.messageContent }}
                      </div>
                      <div
                        class="text-xs text-primaryColor-default"
                        style="text-decoration: underline;"
                        @click.stop="() => handleSingleRead(item.id)"
                      >
                        标记已读
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="py-2 text-center view-more"
                style="color: #21209C;"
                @click="toViewMore"
              >
                查看更多...
              </div>
            </div>
            <div
              v-else
              class="p-2 px-8 text-center content"
            >
              暂无未读消息
            </div>
          </div>
        </template>
      </a-dropdown>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { messageApi } from '@/api/system'
import { computed } from 'vue'
import { useAppStore } from '@/store/modules/app'
import { dateFromNow } from '@/utils/date'
import { useMessage } from '@/hooks/message'
const { getReferenceItemsValueMap } = useAppStore()
import { router } from '@/router'
import { Model } from '@/types/model'
const appStore = useAppStore()
const notificaitons = computed<Model.Notification>(() => appStore.notifications)
// 过滤出未读
const messages = computed<Model.MessageItem[]>(() => {
  let res = appStore.messages ? appStore.messages.filter(item => item.isRead === 0) : []
  res.forEach(item => {
    item.messageEventTypeDesc = getReferenceItemsValueMap('messageEventEnum')[item.messageEventType].label
  })
  return res
})

async function handleSingleRead(id: number) {
  await messageApi.singleMessageRead(id)
  useMessage.success('操作成功')
  appStore.refreshTimerData()
}

const toViewMore = () => {
  router.push({ name: 'notificationList' })
}

const toMessagePage = record => {
  useAppStore().clickMessageToDetail(record)
}

</script>
