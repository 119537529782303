<template>
  <custom-api-select
    :api="sendAddrManagementApi.invoiceSendAddrSelect"
    :api-params="apiParams"
    :immediate="true"
    value-field="value"
    label-field="label"
  />
</template>
<script lang="ts" setup>
import { sendAddrManagementApi } from '@/api/wage'
import { sendAddrSelectProps } from './props'
const props = defineProps(sendAddrSelectProps)
const { apiParams } = props
</script>
