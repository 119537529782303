
const toString = Object.prototype.toString

export function isMobileAgent() {
  const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  return flag
}

export function is(val: unknown, type: string) {
  return toString.call(val) === `[object ${type}]`
}

export function isDef<T = unknown>(val?: T): val is T {
  return typeof val !== 'undefined'
}

export function isUnDef<T = unknown>(val?: T): val is T {
  return !isDef(val)
}

export function isObject(val: any): val is Record<any, any> {
  return val !== null && is(val, 'Object')
}

export function isEmpty<T = unknown>(val: T): val is T {
  if (isArray(val) || isString(val)) {
    return val.length === 0
  }

  if (val instanceof Map || val instanceof Set) {
    return val.size === 0
  }

  if (isObject(val)) {
    return Object.keys(val).length === 0
  }

  return false
}

export function isDate(val: unknown): val is Date {
  return is(val, 'Date')
}

export function isNull(val: unknown): val is null {
  return val === null
}

export function isNullAndUnDef(val: unknown): val is null | undefined {
  return isUnDef(val) && isNull(val)
}

export function isNullOrUnDef(val: unknown): val is null | undefined {
  return isUnDef(val) || isNull(val)
}

export function isNumber(val: unknown): val is number {
  return is(val, 'Number')
}

export function isPromise<T = any>(val: unknown): val is Promise<T> {
  return is(val, 'Promise') && isObject(val) && isFunction(val.then) && isFunction(val.catch)
}

export function isString(val: unknown): val is string {
  return is(val, 'String')
}

export function isFunction(val: unknown): val is Function {
  return typeof val === 'function'
}

export function isBoolean(val: unknown): val is boolean {
  return is(val, 'Boolean')
}

export function isRegExp(val: unknown): val is RegExp {
  return is(val, 'RegExp')
}

export function isArray(val: any): val is Array<any> {
  return val && Array.isArray(val)
}

export function isWindow(val: any): val is Window {
  return typeof window !== 'undefined' && is(val, 'Window')
}

export function isElement(val: unknown): val is Element {
  return isObject(val) && !!val.tagName
}

export function isMap(val: unknown): val is Map<any, any> {
  return is(val, 'Map')
}

export const isServer = typeof window === 'undefined'

export const isClient = !isServer

export function isUrl(path: string): boolean {
  const reg
    = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/
  return reg.test(path)
}

export function isImg(val: string): boolean {
  if (isString(val)) {
    return /\.(jpg|jpeg|png|gif)$/i.test(val)
  }
  return false
}
export function isValidIDCard(idCard) {
  // 大陆身份证正则表达式
  let reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
  return reg.test(idCard)
}
export function isHongKongIDCard(idCard) {
  // 香港身份证正则表达式
  let reg = /^([A-Z]{1,2})([0-9]{6,10})([0-9A-Z])$/
  return reg.test(idCard)
}
export function isMacaoIDCard(idCard) {
  // 澳门身份证正则表达式
  let reg = /^[1|5|7][0-9]{6}([0-9A-Z])$/
  return reg.test(idCard)
}
export function isTaiwanIDCard(idCard) {
  // 台湾身份证正则表达式
  let reg = /^[a-zA-Z]{1}[0-9]{9}$/
  return reg.test(idCard)
}
export function isMobile(value) {
  const regex = /^1[3-9]\d{9}$/
  return regex.test(value)
}

export function isDateMonth(val) {
  let pattern = /^\d{4}-\d{2}$/
  return pattern.test(val)
}
export function isCompanyName(value) {
  const pattern = /^[\u4e00-\u9fa5\(\)（）\-a-zA-Z]+$/
  return pattern.test(value)
}
export function isChs(value) {
  const pattern = /^[\u4e00-\u9fa5]+$/
  return pattern.test(value)
}
