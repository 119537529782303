<template>
  <custom-api-select
    :api="invoice2Api.expressCompanyList"
    :api-params="apiParams"
    :immediate="true"
    value-field="name"
    label-field="name"
  />
</template>
<script lang="ts" setup>
import { invoice2Api } from '@/api/wage'
import { invoiceExpressCompanySelectProps } from './props'
const props = defineProps(invoiceExpressCompanySelectProps)
const { apiParams } = props
</script>
