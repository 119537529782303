import { ExtractPropTypes, PropType } from 'vue'
import type { UploadProps as AUploadProps } from 'ant-design-vue/es/upload'

export const uploadProps = {
  maxSize: {
    type: Number as PropType<number>,
    default: 20,
  },
  limitNum: {
    type: Number as PropType<number>,
    default: 0,
  },
  maxNum: {
    type: Number as PropType<number>,
    default: 5,
  },
  fieldName: {
    type: String as PropType<string>,
  },
  fileLists: {
    type: Array,
    default: [],
  },
  value: {
    type: Array as PropType<File[]>,
    default: () => [],
  },
  accept: {
    type: Array as PropType<string[]>,
  },
  acceptType: {
    type: String as PropType<'img' | 'file'>,
    default: 'file',
  },
  convert: {
    type: Boolean,
    default: true,
  }
}

export type socialUploadProps = Partial<ExtractPropTypes<typeof uploadProps>> & AUploadProps
