import { areaDataTree } from './data'
import { cloneDeep } from 'lodash-es'
const getAreaDataTree = (level?: number) => {
  const tree = cloneDeep(areaDataTree)
  if (level === 1) {
    tree.forEach((province:any) => {
      province.children = []
    })
    return tree
  }
  if (level === 2) {
    tree.forEach(province => {
      province.children.forEach((city:any) => {
        city.children = []
      })
    })
    return tree
  }
  return tree
}

export { getAreaDataTree }