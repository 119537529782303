<style lang='less' scoped>
.right-box {
  padding: 10px;
  flex: 1;
  min-width: 375px;
}
</style>
<template>
  <div>
    <a-image
      :preview="imgPre"
    />
    <div class="flex">
      <a-menu
        v-model:openKeys="openKeys"
        v-model:selectedKeys="selectedKeys"
        :style="menuStyle"
        mode="inline"
        @click="handleClick"
      >
        <a-menu-item
          v-for="helpItem in helpList"
          :key="helpItem.id"
        >
          {{ helpItem.title }}
        </a-menu-item>
      </a-menu>
      <div
        v-html="innerContent"
        class="right-box"
      />
    </div>
  </div>
</template>
<script lang='tsx' setup>
import { MenuProps } from 'ant-design-vue'
import { helpApi } from '@/api/system'
import { CSSProperties } from '@vue/runtime-dom'
import { defineEmits } from 'vue'
import { ImagePreviewType } from 'ant-design-vue/es/vc-image'
const emits = defineEmits<{(e: 'change', value: string): void }>()
const imgPre = ref<ImagePreviewType>()
interface HelpList {
  content:string,
  createdAt:string
  createdByAdminId:number
  id:number
  isValid:number
  title:string
  updatedAt:string
  updatedByAdminId:number
}
const menuStyle = computed((): CSSProperties => ({
  position: 'sticky',
  top: 0,
  height: 'calc(100vh - 156px)',
  minWidth: '200px',
  maxWidth: '200px',
}))
const bindPreFn = () => {
  const rightBox = document.querySelector('.right-box')
  const imgs = rightBox?.getElementsByTagName('img')

  if (imgs) {
    for (let i = 0; i < imgs?.length; i++) {
      const el = imgs[i]
      el.onclick = () => {
        imgPre.value = {
          visible: true,
          src: el.currentSrc,
          onVisibleChange(value, prevValue) {
            imgPre.value!.visible = value

          },
        }
      }
    }
  }
}
const helpList = ref<HelpList[]>([])
const innerContent = ref<string>('<div>暂无信息</div>')
const getHelpList = async () => {
  let res = await helpApi.getHelpArticleList({
    isPage: 1,
    page: 1,
    pageSize: 5000
  })
  helpList.value = res.items
  selectedKeys.value = [helpList.value[0].id]
  innerContent.value = helpList.value.filter((helpItem: HelpList) => helpItem.id === selectedKeys.value[0])[0].content
  nextTick(bindPreFn)

}
getHelpList()
const selectedKeys = ref<number[]>([])
const openKeys = ref<string[]>(['sub1'])

const handleClick: MenuProps['onClick'] = e => {
  const { content, updatedAt } = helpList.value.filter((helpItem:HelpList) => helpItem.id == e.key)[0]
  innerContent.value = content
  nextTick(bindPreFn)
  emits('change', updatedAt)
}

</script>