import { useSiteConfig } from '@/hooks/site-config'
import { computed, unref, ref, Ref, ComputedRef, h } from 'vue'
import {
  cloneDeep,
  isFunction,
  isBoolean,
  isString,
  omit,
  isUndefined,
  isNull,
  forEach,
  isArray,
} from 'lodash-es'
import RowAction from '../components/row-action.vue'
import { Tag, Image, Badge, Tooltip } from 'ant-design-vue'
import { PaginationProps } from 'ant-design-vue/es'
import { useAppStore, useSystemStore } from '@/store'
import { DragOutlined, InfoCircleOutlined } from '@ant-design/icons-vue'
import { useSortable } from '@/hooks/sortable'
import { transformObjToDotStrObj } from '@/utils/object'
import { router } from '@/router'
import { ColumnType } from 'ant-design-vue/es/table'
const { table } = useSiteConfig

export declare type GetColumnsParm = {
  ignoreIndex?: boolean;
  ignoreAction?: boolean;
  ignoreHidden?: boolean;
}

export function useColumn(
  tableRef,
  props: ComputedRef<IGridTable.Props>,
  paginationRef: ComputedRef<PaginationProps | boolean>,
  rawDataSourceRef: Ref<any[]>,
  actions: { handleEdit: Fn; handleDel: Fn }
) {
  const systemStore = useSystemStore()
  const scrollConfig = unref(props).scroll as {
    x?: string | number | true;
    y?: string | number;
  }
  const scrollRef = ref({
    x: scrollConfig?.x || 'max-content',
    y: scrollConfig?.y,
  } as { x?: string | number | true; y?: string | number })
  // 操作列宽度
  const actionWidth = ref(0)

  const rawColumnsRef = ref(unref(props).columnSchemas)
  const columnsRef = computed(() =>
    unref(allColumnsRef!)?.filter(column => !column.defaultHidden))
  const allColumnsRef = computed<IGridTable.ColumnProps[]>(() => {
    const {
      draggable,
      indexable,
      editable,
      deleteable,
      cardable,
      isFormTable,
      rowActionsWidth,
      rowActions: rawRowActions = [],
    } = unref(props)

    let columns = (
      cloneDeep(unref(rawColumnsRef)) as IGridTable.ColumnProps[]
    ).map((column, index) => {
      // 给单元格设置class标识
      column.customHeaderCell = (column: ColumnType) => ({
        class: `grid_table_th_${index}`,
      })
      column.customCell = (record, rowIndex, column: ColumnType) => ({
        class: `grid_table_td_${index}`,
      })

      if (
        column.dataIndex
        && isString(column.dataIndex)
        && column.dataIndex.indexOf('.') > 0
      ) {
        column.dataIndex = column.dataIndex.split('.')
      }
      if (column.enumSchemas) {
        column.customRender = function({ text, record }) {
          const enumItem = column.enumSchemas?.find(e => e.value?.toString() === text?.toString())
          let domEl: any
          if (enumItem?.color && enumItem?.bgColor) {
            domEl = h(Tag, { color: enumItem.bgColor }, () => [h('span', { style: { color: enumItem.color } }, enumItem.label)])
          } else if (enumItem?.color) {
            domEl = h(Badge, {
              color: enumItem?.color,
              text: enumItem?.label,
            })
          } else {
            domEl = enumItem?.label
          }
          if (
            column.failStatus
            && column.failStatus.statusList.findIndex(e => e.toString() === text?.toString()) !== -1
          ) {
            return h(
              Tooltip,
              {
                ...omit(column.failStatus, ['statusList', 'failMsg']),
                title: isFunction(column.failStatus.failMsg)
                  ? column.failStatus.failMsg(record)
                  : column.failStatus.failMsg,
                color: column.failStatus?.color ?? '#F53F3F',
              },
              () => [
                domEl,
                h(InfoCircleOutlined, {
                  class: 'ml-2 text-red-600',
                }),
              ]
            )
          } else {
            return domEl
          }
        }
      }

      if (column.toDetail) {
        const getPushParam = (
          toDetail: IGridTable.ColumnProps['toDetail'],
          id
        ) => {
          if (toDetail!.paramType === 'params') {
            return {
              name: toDetail!.routeName,
              params: { id },
            }
          }
          return toDetail!.routeName
            ? {
              name: toDetail!.routeName,
              query: { id },
            }
            : {
              path: toDetail!.routePath,
              query: { id },
            }
        }

        column.customRender = function({ text, record }) {
          if (isUndefined(text) || isNull(text) || text === '') {

            /* 兼容table空字段显示 ‘--’ 显示为空时直接返回空 */
            return ''
          }
          const id = column.toDetail!.idKey
            ? transformObjToDotStrObj(record)[column.toDetail!.idKey]
            : record.id
          return h(
            'span',
            {
              style: {},
              class: id
                ? 'cursor-pointer hover:text-primaryColor-default underline ...'
                : '',
              title: `去${text}详情`,
              onClick: () => {
                if (!id) {
                  return
                }
                router.push(getPushParam(column.toDetail, id))
              },
            },
            text
          )
        }
      }
      if (column.referenceKey) {
        const { reference } = useAppStore()
        column.customRender = function({ text }) {
          return reference?.[column.referenceKey as string]?.find(e => e.value === text)?.label
        }
      }
      if (column.thumbable) {
        column.customRender = function({ text }) {
          return h(Image, {
            src: text,
            width: 120,
          })
        }
      }
      if (column.isMoney) {
        column.customRender = function({ text }) {
          return `￥${text}`
          // return h('span', {
          //   title: `￥${text}`,
          // }, text)
        }
      }

      if (column.isEllipsisToTooltip) {
        column.customRender = function({ text, record }) {
          text = isFunction(column.isEllipsisToTooltip)
            ? column.isEllipsisToTooltip(record)
            : text
          let element
          let t = ''
          if (isArray(text) && text.length > 1) {
            element = (
              <div>
                {text.map((item, index) => (
                  <div
                    class="py-1"
                    style={
                      index === 0
                        ? {}
                        : { borderTop: '1px solid rgb(239, 239, 239)' }
                    }
                  >
                    {item}
                  </div>
                ))}
              </div>
            )
            t = text[0]
            t += '...'
          } else if (isArray(text) && text.length === 1) {
            t = text[0]
          } else if (isString(text) && text.length > 10) {
            element = text
            t = `${text.substring(0, 10)}...`
          } else {
            t = text
          }

          return element ? h(Tooltip, { title: element }, () => [t]) : t
        }
      }
      return column
    })
    if (!columns) {
      return []
    }

    // 拖拽列
    if (draggable) {
      columns.unshift({
        width: 60,
        title: '拖拽',
        align: 'center',
        flag: 'drag',
        customRender: () =>
          h(DragOutlined, {
            class: 'mr-2 cursor-move table-coulmn-drag-icon',
          }),
      })
      nextTick(() => {
        // Drag and drop sort
        const el = tableRef.value.$el.querySelector('.ant-table-tbody') as HTMLDivElement
        const { initSortable } = useSortable(el, {
          handle: '.table-coulmn-drag-icon',
          onEnd: evt => {
            const { oldIndex, newIndex } = evt
            nextTick(() => {
              const rawDataSource = unref(rawDataSourceRef)
              if (oldIndex > newIndex) {
                rawDataSource.splice(
                  newIndex - 1,
                  0,
                  rawDataSource[oldIndex - 1]
                )
                rawDataSource.splice(oldIndex, 1)
              } else {
                rawDataSource.splice(newIndex, 0, rawDataSource[oldIndex - 1])
                rawDataSource.splice(oldIndex - 1, 1)
              }
            })
          },
        })
        initSortable()
      })
    }
    // 索引列
    if (indexable) {
      columns.unshift({
        width: 70,
        title: '序号',
        align: 'center',
        flag: 'index',
        customRender: ({ index }) => {
          const pagination = unref(paginationRef)
          if (isBoolean(pagination) && !pagination) {
            return index + 1
          }
          const { current = 1, pageSize = table.defaultPageSize }
            = pagination as PaginationProps
          return ((current < 1 ? 1 : current) - 1) * pageSize + index + 1
        },
      })
    }

    const rowActions = cloneDeep(rawRowActions)
    if ((isFunction(deleteable) && deleteable()) || deleteable) {
      rowActions.push({
        label: '删除',
        confirm: '确定删除吗？',
        click: actions.handleDel,
      })
    }
    if ((isFunction(editable) && editable()) || editable) {
      rowActions.push({
        label: '编辑',
        click: actions.handleEdit
      })
    }

    if (!systemStore.isPc && cardable) {
      rowActions.unshift({
        label: '显示',
        click: record => {
          // 通过data-key属性找到元素
          const element = document.querySelector(`[data-row-key="${record?.id}"]`)
          // 打印元素内容
          if (element) {
            if (element.classList.contains('mobile-table-row-hidden')) {
              const actionBtn = element
                ?.querySelector('button[label="显示"]')
                ?.querySelector('span')
              if (actionBtn) {
                actionBtn.innerHTML = '隐藏'
              }
              element.classList.remove('mobile-table-row-hidden')
              element.classList.add('mobile-table-row-showall')
            } else {
              const actionBtn = element
                ?.querySelector('[label="显示"]')
                ?.querySelector('span')

              if (actionBtn) {
                actionBtn.innerHTML = '显示'
              }
              element.classList.remove('mobile-table-row-showall')
              element.classList.add('mobile-table-row-hidden')
            }
          }
        },
      })
    }

    // 操作列
    if (rowActions.length > 0 || isFormTable) {
      columns.push({
        width: unref(actionWidth),
        title: '操作',
        align: 'center',
        fixed: 'right',
        dataIndex: 'action',
        flag: 'action',
        customRender: ({ index }) => {
          const rawRecord = rawDataSourceRef.value[index]
          return h(RowAction, {
            actions: rowActions,
            record: rawRecord,
          })
        },
      })
    }

    if (!systemStore.isPc) {
      columns.forEach((item, index) => {
        // 移动设备全部去掉fixed
        item.fixed = undefined
        // item.width = index === 0 ? '300px' : 0
        // 开启移动卡片形式
        if (cardable) {
          item.align = 'right'
        }
      })
    }

    return columns
  })

  // 自动宽度
  function autoColumnWidth() {
    actionWidth.value = 0
    return new Promise(async resolve => {
      await nextTick()
      const el = tableRef.value.$el as HTMLElement
      actionWidth.value
        = getWidth(el, actionWidth.value, 'grid-table-actions') + 20
      if (
        systemStore.isPc
        && (unref(props).autoWidth || unref(props).autoHeight || scrollConfig?.y)
      ) {
        scrollRef.value.y = undefined
        unref(props).columnSchemas?.forEach((item: IGridTable.ColumnProps) => {
          if (!item.width) {
            let column = arrFind(
              rawColumnsRef.value as Array<IGridTable.ColumnProps>,
              'dataIndex',
              item.dataIndex
            )
            if (column.width) {
              delete column.width
            }
          }
        })
        h
        await nextTick()
        setTimeout(async () => {
          const tableElement = el.querySelector('.ant-table-content') as HTMLElement
          const columnLength
            = rawColumnsRef.value!.length
            - (tableElement?.scrollWidth > tableElement?.clientWidth ? 0 : 1)
          rawColumnsRef.value!.forEach((column: IGridTable.ColumnProps, index) => {
            let width = 0
            if (!column.width && index < columnLength) {
              width = getWidth(el, width, `grid_table_th_${index}`)
              width = getWidth(el, width, `grid_table_td_${index}`)
              column.width = width + 1
            }
          })
          scrollRef.value.y = scrollConfig?.y
          autoHeight()
          await nextTick()
          resolve(null)
        })
      } else {
        scrollRef.value.x = '100%'
      }
    })
  }
  function autoHeight() {
    if (!systemStore.isPc || systemStore.miniScreen) {
      scrollRef.value.y = undefined
      return
    }
    const el = tableRef.value.$el.querySelector('tbody')
    let height = window.innerHeight - offsetTop(el) - 90
    if (height > 200) {
      scrollRef.value.y = height
    }
  }

  function offsetTop(el: HTMLElement, filterClass: Array<string> = []): any {
    for (let key in filterClass) {
      if (el.className.indexOf(filterClass[key]) > -1) {
        return 0
      }
    }
    if (el.offsetParent) {
      return (
        offsetTop(el.offsetParent as HTMLElement, filterClass) + el.offsetTop
      )
    }

    return el.offsetTop
  }

  function getWidth(el: HTMLElement, width: number, dataIndex: string) {
    forEach(el.getElementsByClassName(dataIndex), (item: Element) => {
      let offsetWidth = (item as HTMLElement).offsetWidth
      if (width < offsetWidth) {
        width = offsetWidth
      }
    })
    return width
  }

  // 递归查找数组
  function arrFind(
    arr: any,
    field: string,
    value: any,
    children = 'children'
  ): any {
    for (let key in arr) {
      if (Object(arr[key])[field] == value) {
        return arr[key]
      }
      if (Object(arr[key])[children]) {
        let find = arrFind(Object(arr[key])[children], field, value, children)
        if (find) {
          return find
        }
      }
    }
    return null
  }

  function setColumns(columns: IGridTable.ColumnProps[]) {
    const newColumns = cloneDeep(columns)
    rawColumnsRef.value = newColumns
  }

  function getColumns(opt: IGridTable.GetColumnParam) {
    let columns = unref(allColumnsRef)
    if (opt?.ignoreHidden) {
      columns = columns.filter(column => !column.defaultHidden)
    }
    if (opt?.ignoreDrag) {
      columns = columns.filter(column => column.flag !== 'drag')
    }
    if (opt?.ignoreIndex) {
      columns = columns.filter(column => column.flag !== 'index')
    }
    if (opt?.ignoreAction) {
      columns = columns.filter(column => column.flag !== 'action')
    }
    return columns
  }

  return {
    columnsRef,
    allColumnsRef,
    scrollRef,
    setColumns,
    getColumns,
    autoColumnWidth,
    autoHeight,
  }
}
