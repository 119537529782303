import { ExtractPropTypes, PropType } from 'vue'
import type { TreeSelectProps } from 'ant-design-vue/es/tree-select'

export const apiTreeSelectProps = {
  value: {
    type: [Number, String, Array] as PropType<number | string | (number | string)[]>,
    default: undefined,
  },
  api: {
    type: Function,
    required: true,
  },
  apiParams: {
    type: Object,
    default: () => ({})
  },
  valueToString: {
    type: Boolean,
    default: false
  },
  zeroToUndefined: {
    type: Boolean,
    default: true
  }
}

export type ApiTreeSelectProps = Partial<ExtractPropTypes<typeof apiTreeSelectProps>> & TreeSelectProps