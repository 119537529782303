/* eslint-disable max-lines */
/* 接口/Area/area/getAreaData */
const areaDataTree = [
  {
    id: 110000,
    pid: 0,
    level: 1,
    createdAt: null,
    updatedAt: null,
    deletedAt: null,
    isValid: 1,
    label: '北京市',
    children: [
      {
        id: 110100,
        pid: 110000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '市辖区',
        children: [
          {
            id: 110101,
            pid: 110100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东城区',
            children: []
          },
          {
            id: 110102,
            pid: 110100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '西城区',
            children: []
          },
          {
            id: 110105,
            pid: 110100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '朝阳区',
            children: []
          },
          {
            id: 110106,
            pid: 110100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '丰台区',
            children: []
          },
          {
            id: 110107,
            pid: 110100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '石景山区',
            children: []
          },
          {
            id: 110108,
            pid: 110100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '海淀区',
            children: []
          },
          {
            id: 110109,
            pid: 110100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '门头沟区',
            children: []
          },
          {
            id: 110111,
            pid: 110100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '房山区',
            children: []
          },
          {
            id: 110112,
            pid: 110100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '通州区',
            children: []
          },
          {
            id: 110113,
            pid: 110100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '顺义区',
            children: []
          },
          {
            id: 110114,
            pid: 110100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '昌平区',
            children: []
          },
          {
            id: 110115,
            pid: 110100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大兴区',
            children: []
          },
          {
            id: 110116,
            pid: 110100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '怀柔区',
            children: []
          },
          {
            id: 110117,
            pid: 110100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '平谷区',
            children: []
          },
          {
            id: 110118,
            pid: 110100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '密云区',
            children: []
          },
          {
            id: 110119,
            pid: 110100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '延庆区',
            children: []
          }
        ]
      }
    ]
  },
  {
    id: 120000,
    pid: 0,
    level: 1,
    createdAt: null,
    updatedAt: null,
    deletedAt: null,
    isValid: 1,
    label: '天津市',
    children: [
      {
        id: 120100,
        pid: 120000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '市辖区',
        children: [
          {
            id: 120101,
            pid: 120100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '和平区',
            children: []
          },
          {
            id: 120102,
            pid: 120100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '河东区',
            children: []
          },
          {
            id: 120103,
            pid: 120100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '河西区',
            children: []
          },
          {
            id: 120104,
            pid: 120100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南开区',
            children: []
          },
          {
            id: 120105,
            pid: 120100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '河北区',
            children: []
          },
          {
            id: 120106,
            pid: 120100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '红桥区',
            children: []
          },
          {
            id: 120110,
            pid: 120100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东丽区',
            children: []
          },
          {
            id: 120111,
            pid: 120100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '西青区',
            children: []
          },
          {
            id: 120112,
            pid: 120100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '津南区',
            children: []
          },
          {
            id: 120113,
            pid: 120100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '北辰区',
            children: []
          },
          {
            id: 120114,
            pid: 120100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '武清区',
            children: []
          },
          {
            id: 120115,
            pid: 120100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宝坻区',
            children: []
          },
          {
            id: 120116,
            pid: 120100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '滨海新区',
            children: []
          },
          {
            id: 120117,
            pid: 120100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宁河区',
            children: []
          },
          {
            id: 120118,
            pid: 120100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '静海区',
            children: []
          },
          {
            id: 120119,
            pid: 120100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '蓟州区',
            children: []
          }
        ]
      }
    ]
  },
  {
    id: 130000,
    pid: 0,
    level: 1,
    createdAt: null,
    updatedAt: null,
    deletedAt: null,
    isValid: 1,
    label: '河北省',
    children: [
      {
        id: 130100,
        pid: 130000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '石家庄市',
        children: [
          {
            id: 130102,
            pid: 130100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '长安区',
            children: []
          },
          {
            id: 130104,
            pid: 130100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '桥西区',
            children: []
          },
          {
            id: 130105,
            pid: 130100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新华区',
            children: []
          },
          {
            id: 130107,
            pid: 130100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '井陉矿区',
            children: []
          },
          {
            id: 130108,
            pid: 130100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '裕华区',
            children: []
          },
          {
            id: 130109,
            pid: 130100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '藁城区',
            children: []
          },
          {
            id: 130110,
            pid: 130100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '鹿泉区',
            children: []
          },
          {
            id: 130111,
            pid: 130100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '栾城区',
            children: []
          },
          {
            id: 130121,
            pid: 130100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '井陉县',
            children: []
          },
          {
            id: 130123,
            pid: 130100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '正定县',
            children: []
          },
          {
            id: 130125,
            pid: 130100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '行唐县',
            children: []
          },
          {
            id: 130126,
            pid: 130100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '灵寿县',
            children: []
          },
          {
            id: 130127,
            pid: 130100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '高邑县',
            children: []
          },
          {
            id: 130128,
            pid: 130100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '深泽县',
            children: []
          },
          {
            id: 130129,
            pid: 130100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '赞皇县',
            children: []
          },
          {
            id: 130130,
            pid: 130100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '无极县',
            children: []
          },
          {
            id: 130131,
            pid: 130100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '平山县',
            children: []
          },
          {
            id: 130132,
            pid: 130100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '元氏县',
            children: []
          },
          {
            id: 130133,
            pid: 130100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '赵县',
            children: []
          },
          {
            id: 130171,
            pid: 130100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '石家庄高新技术产业开发区',
            children: []
          },
          {
            id: 130172,
            pid: 130100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '石家庄循环化工园区',
            children: []
          },
          {
            id: 130181,
            pid: 130100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '辛集市',
            children: []
          },
          {
            id: 130183,
            pid: 130100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '晋州市',
            children: []
          },
          {
            id: 130184,
            pid: 130100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新乐市',
            children: []
          }
        ]
      },
      {
        id: 130200,
        pid: 130000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '唐山市',
        children: [
          {
            id: 130202,
            pid: 130200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '路南区',
            children: []
          },
          {
            id: 130203,
            pid: 130200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '路北区',
            children: []
          },
          {
            id: 130204,
            pid: 130200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '古冶区',
            children: []
          },
          {
            id: 130205,
            pid: 130200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '开平区',
            children: []
          },
          {
            id: 130207,
            pid: 130200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '丰南区',
            children: []
          },
          {
            id: 130208,
            pid: 130200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '丰润区',
            children: []
          },
          {
            id: 130209,
            pid: 130200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '曹妃甸区',
            children: []
          },
          {
            id: 130224,
            pid: 130200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '滦南县',
            children: []
          },
          {
            id: 130225,
            pid: 130200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '乐亭县',
            children: []
          },
          {
            id: 130227,
            pid: 130200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '迁西县',
            children: []
          },
          {
            id: 130229,
            pid: 130200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '玉田县',
            children: []
          },
          {
            id: 130271,
            pid: 130200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '河北唐山芦台经济开发区',
            children: []
          },
          {
            id: 130272,
            pid: 130200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '唐山市汉沽管理区',
            children: []
          },
          {
            id: 130273,
            pid: 130200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '唐山高新技术产业开发区',
            children: []
          },
          {
            id: 130274,
            pid: 130200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '河北唐山海港经济开发区',
            children: []
          },
          {
            id: 130281,
            pid: 130200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '遵化市',
            children: []
          },
          {
            id: 130283,
            pid: 130200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '迁安市',
            children: []
          },
          {
            id: 130284,
            pid: 130200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '滦州市',
            children: []
          }
        ]
      },
      {
        id: 130300,
        pid: 130000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '秦皇岛市',
        children: [
          {
            id: 130302,
            pid: 130300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '海港区',
            children: []
          },
          {
            id: 130303,
            pid: 130300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '山海关区',
            children: []
          },
          {
            id: 130304,
            pid: 130300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '北戴河区',
            children: []
          },
          {
            id: 130306,
            pid: 130300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '抚宁区',
            children: []
          },
          {
            id: 130321,
            pid: 130300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '青龙满族自治县',
            children: []
          },
          {
            id: 130322,
            pid: 130300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '昌黎县',
            children: []
          },
          {
            id: 130324,
            pid: 130300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '卢龙县',
            children: []
          },
          {
            id: 130371,
            pid: 130300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '秦皇岛市经济技术开发区',
            children: []
          },
          {
            id: 130372,
            pid: 130300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '北戴河新区',
            children: []
          }
        ]
      },
      {
        id: 130400,
        pid: 130000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '邯郸市',
        children: [
          {
            id: 130402,
            pid: 130400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '邯山区',
            children: []
          },
          {
            id: 130403,
            pid: 130400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '丛台区',
            children: []
          },
          {
            id: 130404,
            pid: 130400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '复兴区',
            children: []
          },
          {
            id: 130406,
            pid: 130400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '峰峰矿区',
            children: []
          },
          {
            id: 130407,
            pid: 130400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '肥乡区',
            children: []
          },
          {
            id: 130408,
            pid: 130400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '永年区',
            children: []
          },
          {
            id: 130423,
            pid: 130400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '临漳县',
            children: []
          },
          {
            id: 130424,
            pid: 130400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '成安县',
            children: []
          },
          {
            id: 130425,
            pid: 130400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大名县',
            children: []
          },
          {
            id: 130426,
            pid: 130400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '涉县',
            children: []
          },
          {
            id: 130427,
            pid: 130400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '磁县',
            children: []
          },
          {
            id: 130430,
            pid: 130400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '邱县',
            children: []
          },
          {
            id: 130431,
            pid: 130400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '鸡泽县',
            children: []
          },
          {
            id: 130432,
            pid: 130400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '广平县',
            children: []
          },
          {
            id: 130433,
            pid: 130400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '馆陶县',
            children: []
          },
          {
            id: 130434,
            pid: 130400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '魏县',
            children: []
          },
          {
            id: 130435,
            pid: 130400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '曲周县',
            children: []
          },
          {
            id: 130471,
            pid: 130400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '邯郸经济技术开发区',
            children: []
          },
          {
            id: 130473,
            pid: 130400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '邯郸冀南新区',
            children: []
          },
          {
            id: 130481,
            pid: 130400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '武安市',
            children: []
          }
        ]
      },
      {
        id: 130500,
        pid: 130000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '邢台市',
        children: [
          {
            id: 130502,
            pid: 130500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '襄都区',
            children: []
          },
          {
            id: 130503,
            pid: 130500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '信都区',
            children: []
          },
          {
            id: 130505,
            pid: 130500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '任泽区',
            children: []
          },
          {
            id: 130506,
            pid: 130500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南和区',
            children: []
          },
          {
            id: 130522,
            pid: 130500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '临城县',
            children: []
          },
          {
            id: 130523,
            pid: 130500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '内丘县',
            children: []
          },
          {
            id: 130524,
            pid: 130500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '柏乡县',
            children: []
          },
          {
            id: 130525,
            pid: 130500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '隆尧县',
            children: []
          },
          {
            id: 130528,
            pid: 130500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宁晋县',
            children: []
          },
          {
            id: 130529,
            pid: 130500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '巨鹿县',
            children: []
          },
          {
            id: 130530,
            pid: 130500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新河县',
            children: []
          },
          {
            id: 130531,
            pid: 130500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '广宗县',
            children: []
          },
          {
            id: 130532,
            pid: 130500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '平乡县',
            children: []
          },
          {
            id: 130533,
            pid: 130500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '威县',
            children: []
          },
          {
            id: 130534,
            pid: 130500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '清河县',
            children: []
          },
          {
            id: 130535,
            pid: 130500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '临西县',
            children: []
          },
          {
            id: 130571,
            pid: 130500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '河北邢台经济开发区',
            children: []
          },
          {
            id: 130581,
            pid: 130500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南宫市',
            children: []
          },
          {
            id: 130582,
            pid: 130500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '沙河市',
            children: []
          }
        ]
      },
      {
        id: 130600,
        pid: 130000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '保定市',
        children: [
          {
            id: 130602,
            pid: 130600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '竞秀区',
            children: []
          },
          {
            id: 130606,
            pid: 130600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '莲池区',
            children: []
          },
          {
            id: 130607,
            pid: 130600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '满城区',
            children: []
          },
          {
            id: 130608,
            pid: 130600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '清苑区',
            children: []
          },
          {
            id: 130609,
            pid: 130600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '徐水区',
            children: []
          },
          {
            id: 130623,
            pid: 130600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '涞水县',
            children: []
          },
          {
            id: 130624,
            pid: 130600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '阜平县',
            children: []
          },
          {
            id: 130626,
            pid: 130600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '定兴县',
            children: []
          },
          {
            id: 130627,
            pid: 130600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '唐县',
            children: []
          },
          {
            id: 130628,
            pid: 130600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '高阳县',
            children: []
          },
          {
            id: 130629,
            pid: 130600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '容城县',
            children: []
          },
          {
            id: 130630,
            pid: 130600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '涞源县',
            children: []
          },
          {
            id: 130631,
            pid: 130600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '望都县',
            children: []
          },
          {
            id: 130632,
            pid: 130600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '安新县',
            children: []
          },
          {
            id: 130633,
            pid: 130600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '易县',
            children: []
          },
          {
            id: 130634,
            pid: 130600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '曲阳县',
            children: []
          },
          {
            id: 130635,
            pid: 130600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '蠡县',
            children: []
          },
          {
            id: 130636,
            pid: 130600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '顺平县',
            children: []
          },
          {
            id: 130637,
            pid: 130600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '博野县',
            children: []
          },
          {
            id: 130638,
            pid: 130600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '雄县',
            children: []
          },
          {
            id: 130671,
            pid: 130600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '保定高新技术产业开发区',
            children: []
          },
          {
            id: 130672,
            pid: 130600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '保定白沟新城',
            children: []
          },
          {
            id: 130681,
            pid: 130600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '涿州市',
            children: []
          },
          {
            id: 130682,
            pid: 130600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '定州市',
            children: []
          },
          {
            id: 130683,
            pid: 130600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '安国市',
            children: []
          },
          {
            id: 130684,
            pid: 130600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '高碑店市',
            children: []
          }
        ]
      },
      {
        id: 130700,
        pid: 130000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '张家口市',
        children: [
          {
            id: 130702,
            pid: 130700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '桥东区',
            children: []
          },
          {
            id: 130703,
            pid: 130700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '桥西区',
            children: []
          },
          {
            id: 130705,
            pid: 130700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宣化区',
            children: []
          },
          {
            id: 130706,
            pid: 130700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '下花园区',
            children: []
          },
          {
            id: 130708,
            pid: 130700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '万全区',
            children: []
          },
          {
            id: 130709,
            pid: 130700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '崇礼区',
            children: []
          },
          {
            id: 130722,
            pid: 130700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '张北县',
            children: []
          },
          {
            id: 130723,
            pid: 130700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '康保县',
            children: []
          },
          {
            id: 130724,
            pid: 130700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '沽源县',
            children: []
          },
          {
            id: 130725,
            pid: 130700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '尚义县',
            children: []
          },
          {
            id: 130726,
            pid: 130700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '蔚县',
            children: []
          },
          {
            id: 130727,
            pid: 130700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '阳原县',
            children: []
          },
          {
            id: 130728,
            pid: 130700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '怀安县',
            children: []
          },
          {
            id: 130730,
            pid: 130700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '怀来县',
            children: []
          },
          {
            id: 130731,
            pid: 130700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '涿鹿县',
            children: []
          },
          {
            id: 130732,
            pid: 130700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '赤城县',
            children: []
          },
          {
            id: 130771,
            pid: 130700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '张家口经济开发区',
            children: []
          },
          {
            id: 130772,
            pid: 130700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '张家口市察北管理区',
            children: []
          },
          {
            id: 130773,
            pid: 130700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '张家口市塞北管理区',
            children: []
          }
        ]
      },
      {
        id: 130800,
        pid: 130000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '承德市',
        children: [
          {
            id: 130802,
            pid: 130800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '双桥区',
            children: []
          },
          {
            id: 130803,
            pid: 130800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '双滦区',
            children: []
          },
          {
            id: 130804,
            pid: 130800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '鹰手营子矿区',
            children: []
          },
          {
            id: 130821,
            pid: 130800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '承德县',
            children: []
          },
          {
            id: 130822,
            pid: 130800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '兴隆县',
            children: []
          },
          {
            id: 130824,
            pid: 130800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '滦平县',
            children: []
          },
          {
            id: 130825,
            pid: 130800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '隆化县',
            children: []
          },
          {
            id: 130826,
            pid: 130800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '丰宁满族自治县',
            children: []
          },
          {
            id: 130827,
            pid: 130800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宽城满族自治县',
            children: []
          },
          {
            id: 130828,
            pid: 130800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '围场满族蒙古族自治县',
            children: []
          },
          {
            id: 130871,
            pid: 130800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '承德高新技术产业开发区',
            children: []
          },
          {
            id: 130881,
            pid: 130800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '平泉市',
            children: []
          }
        ]
      },
      {
        id: 130900,
        pid: 130000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '沧州市',
        children: [
          {
            id: 130902,
            pid: 130900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新华区',
            children: []
          },
          {
            id: 130903,
            pid: 130900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '运河区',
            children: []
          },
          {
            id: 130921,
            pid: 130900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '沧县',
            children: []
          },
          {
            id: 130922,
            pid: 130900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '青县',
            children: []
          },
          {
            id: 130923,
            pid: 130900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东光县',
            children: []
          },
          {
            id: 130924,
            pid: 130900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '海兴县',
            children: []
          },
          {
            id: 130925,
            pid: 130900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '盐山县',
            children: []
          },
          {
            id: 130926,
            pid: 130900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '肃宁县',
            children: []
          },
          {
            id: 130927,
            pid: 130900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南皮县',
            children: []
          },
          {
            id: 130928,
            pid: 130900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '吴桥县',
            children: []
          },
          {
            id: 130929,
            pid: 130900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '献县',
            children: []
          },
          {
            id: 130930,
            pid: 130900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '孟村回族自治县',
            children: []
          },
          {
            id: 130971,
            pid: 130900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '河北沧州经济开发区',
            children: []
          },
          {
            id: 130972,
            pid: 130900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '沧州高新技术产业开发区',
            children: []
          },
          {
            id: 130973,
            pid: 130900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '沧州渤海新区',
            children: []
          },
          {
            id: 130981,
            pid: 130900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '泊头市',
            children: []
          },
          {
            id: 130982,
            pid: 130900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '任丘市',
            children: []
          },
          {
            id: 130983,
            pid: 130900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '黄骅市',
            children: []
          },
          {
            id: 130984,
            pid: 130900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '河间市',
            children: []
          }
        ]
      },
      {
        id: 131000,
        pid: 130000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '廊坊市',
        children: [
          {
            id: 131002,
            pid: 131000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '安次区',
            children: []
          },
          {
            id: 131003,
            pid: 131000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '广阳区',
            children: []
          },
          {
            id: 131022,
            pid: 131000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '固安县',
            children: []
          },
          {
            id: 131023,
            pid: 131000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '永清县',
            children: []
          },
          {
            id: 131024,
            pid: 131000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '香河县',
            children: []
          },
          {
            id: 131025,
            pid: 131000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大城县',
            children: []
          },
          {
            id: 131026,
            pid: 131000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '文安县',
            children: []
          },
          {
            id: 131028,
            pid: 131000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大厂回族自治县',
            children: []
          },
          {
            id: 131071,
            pid: 131000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '廊坊经济技术开发区',
            children: []
          },
          {
            id: 131081,
            pid: 131000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '霸州市',
            children: []
          },
          {
            id: 131082,
            pid: 131000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '三河市',
            children: []
          }
        ]
      },
      {
        id: 131100,
        pid: 130000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '衡水市',
        children: [
          {
            id: 131102,
            pid: 131100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '桃城区',
            children: []
          },
          {
            id: 131103,
            pid: 131100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '冀州区',
            children: []
          },
          {
            id: 131121,
            pid: 131100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '枣强县',
            children: []
          },
          {
            id: 131122,
            pid: 131100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '武邑县',
            children: []
          },
          {
            id: 131123,
            pid: 131100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '武强县',
            children: []
          },
          {
            id: 131124,
            pid: 131100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '饶阳县',
            children: []
          },
          {
            id: 131125,
            pid: 131100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '安平县',
            children: []
          },
          {
            id: 131126,
            pid: 131100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '故城县',
            children: []
          },
          {
            id: 131127,
            pid: 131100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '景县',
            children: []
          },
          {
            id: 131128,
            pid: 131100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '阜城县',
            children: []
          },
          {
            id: 131171,
            pid: 131100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '河北衡水高新技术产业开发区',
            children: []
          },
          {
            id: 131172,
            pid: 131100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '衡水滨湖新区',
            children: []
          },
          {
            id: 131182,
            pid: 131100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '深州市',
            children: []
          }
        ]
      }
    ]
  },
  {
    id: 140000,
    pid: 0,
    level: 1,
    createdAt: null,
    updatedAt: null,
    deletedAt: null,
    isValid: 1,
    label: '山西省',
    children: [
      {
        id: 140100,
        pid: 140000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '太原市',
        children: [
          {
            id: 140105,
            pid: 140100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '小店区',
            children: []
          },
          {
            id: 140106,
            pid: 140100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '迎泽区',
            children: []
          },
          {
            id: 140107,
            pid: 140100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '杏花岭区',
            children: []
          },
          {
            id: 140108,
            pid: 140100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '尖草坪区',
            children: []
          },
          {
            id: 140109,
            pid: 140100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '万柏林区',
            children: []
          },
          {
            id: 140110,
            pid: 140100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '晋源区',
            children: []
          },
          {
            id: 140121,
            pid: 140100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '清徐县',
            children: []
          },
          {
            id: 140122,
            pid: 140100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '阳曲县',
            children: []
          },
          {
            id: 140123,
            pid: 140100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '娄烦县',
            children: []
          },
          {
            id: 140171,
            pid: 140100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '山西转型综合改革示范区',
            children: []
          },
          {
            id: 140181,
            pid: 140100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '古交市',
            children: []
          }
        ]
      },
      {
        id: 140200,
        pid: 140000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '大同市',
        children: [
          {
            id: 140212,
            pid: 140200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新荣区',
            children: []
          },
          {
            id: 140213,
            pid: 140200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '平城区',
            children: []
          },
          {
            id: 140214,
            pid: 140200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '云冈区',
            children: []
          },
          {
            id: 140215,
            pid: 140200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '云州区',
            children: []
          },
          {
            id: 140221,
            pid: 140200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '阳高县',
            children: []
          },
          {
            id: 140222,
            pid: 140200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '天镇县',
            children: []
          },
          {
            id: 140223,
            pid: 140200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '广灵县',
            children: []
          },
          {
            id: 140224,
            pid: 140200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '灵丘县',
            children: []
          },
          {
            id: 140225,
            pid: 140200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '浑源县',
            children: []
          },
          {
            id: 140226,
            pid: 140200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '左云县',
            children: []
          },
          {
            id: 140271,
            pid: 140200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '山西大同经济开发区',
            children: []
          }
        ]
      },
      {
        id: 140300,
        pid: 140000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '阳泉市',
        children: [
          {
            id: 140302,
            pid: 140300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '城区',
            children: []
          },
          {
            id: 140303,
            pid: 140300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '矿区',
            children: []
          },
          {
            id: 140311,
            pid: 140300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '郊区',
            children: []
          },
          {
            id: 140321,
            pid: 140300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '平定县',
            children: []
          },
          {
            id: 140322,
            pid: 140300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '盂县',
            children: []
          }
        ]
      },
      {
        id: 140400,
        pid: 140000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '长治市',
        children: [
          {
            id: 140403,
            pid: 140400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '潞州区',
            children: []
          },
          {
            id: 140404,
            pid: 140400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '上党区',
            children: []
          },
          {
            id: 140405,
            pid: 140400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '屯留区',
            children: []
          },
          {
            id: 140406,
            pid: 140400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '潞城区',
            children: []
          },
          {
            id: 140423,
            pid: 140400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '襄垣县',
            children: []
          },
          {
            id: 140425,
            pid: 140400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '平顺县',
            children: []
          },
          {
            id: 140426,
            pid: 140400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '黎城县',
            children: []
          },
          {
            id: 140427,
            pid: 140400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '壶关县',
            children: []
          },
          {
            id: 140428,
            pid: 140400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '长子县',
            children: []
          },
          {
            id: 140429,
            pid: 140400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '武乡县',
            children: []
          },
          {
            id: 140430,
            pid: 140400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '沁县',
            children: []
          },
          {
            id: 140431,
            pid: 140400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '沁源县',
            children: []
          },
          {
            id: 140471,
            pid: 140400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '山西长治高新技术产业园区',
            children: []
          }
        ]
      },
      {
        id: 140500,
        pid: 140000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '晋城市',
        children: [
          {
            id: 140502,
            pid: 140500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '城区',
            children: []
          },
          {
            id: 140521,
            pid: 140500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '沁水县',
            children: []
          },
          {
            id: 140522,
            pid: 140500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '阳城县',
            children: []
          },
          {
            id: 140524,
            pid: 140500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '陵川县',
            children: []
          },
          {
            id: 140525,
            pid: 140500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '泽州县',
            children: []
          },
          {
            id: 140581,
            pid: 140500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '高平市',
            children: []
          }
        ]
      },
      {
        id: 140600,
        pid: 140000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '朔州市',
        children: [
          {
            id: 140602,
            pid: 140600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '朔城区',
            children: []
          },
          {
            id: 140603,
            pid: 140600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '平鲁区',
            children: []
          },
          {
            id: 140621,
            pid: 140600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '山阴县',
            children: []
          },
          {
            id: 140622,
            pid: 140600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '应县',
            children: []
          },
          {
            id: 140623,
            pid: 140600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '右玉县',
            children: []
          },
          {
            id: 140671,
            pid: 140600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '山西朔州经济开发区',
            children: []
          },
          {
            id: 140681,
            pid: 140600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '怀仁市',
            children: []
          }
        ]
      },
      {
        id: 140700,
        pid: 140000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '晋中市',
        children: [
          {
            id: 140702,
            pid: 140700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '榆次区',
            children: []
          },
          {
            id: 140703,
            pid: 140700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '太谷区',
            children: []
          },
          {
            id: 140721,
            pid: 140700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '榆社县',
            children: []
          },
          {
            id: 140722,
            pid: 140700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '左权县',
            children: []
          },
          {
            id: 140723,
            pid: 140700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '和顺县',
            children: []
          },
          {
            id: 140724,
            pid: 140700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '昔阳县',
            children: []
          },
          {
            id: 140725,
            pid: 140700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '寿阳县',
            children: []
          },
          {
            id: 140727,
            pid: 140700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '祁县',
            children: []
          },
          {
            id: 140728,
            pid: 140700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '平遥县',
            children: []
          },
          {
            id: 140729,
            pid: 140700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '灵石县',
            children: []
          },
          {
            id: 140781,
            pid: 140700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '介休市',
            children: []
          }
        ]
      },
      {
        id: 140800,
        pid: 140000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '运城市',
        children: [
          {
            id: 140802,
            pid: 140800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '盐湖区',
            children: []
          },
          {
            id: 140821,
            pid: 140800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '临猗县',
            children: []
          },
          {
            id: 140822,
            pid: 140800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '万荣县',
            children: []
          },
          {
            id: 140823,
            pid: 140800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '闻喜县',
            children: []
          },
          {
            id: 140824,
            pid: 140800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '稷山县',
            children: []
          },
          {
            id: 140825,
            pid: 140800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新绛县',
            children: []
          },
          {
            id: 140826,
            pid: 140800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '绛县',
            children: []
          },
          {
            id: 140827,
            pid: 140800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '垣曲县',
            children: []
          },
          {
            id: 140828,
            pid: 140800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '夏县',
            children: []
          },
          {
            id: 140829,
            pid: 140800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '平陆县',
            children: []
          },
          {
            id: 140830,
            pid: 140800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '芮城县',
            children: []
          },
          {
            id: 140881,
            pid: 140800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '永济市',
            children: []
          },
          {
            id: 140882,
            pid: 140800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '河津市',
            children: []
          }
        ]
      },
      {
        id: 140900,
        pid: 140000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '忻州市',
        children: [
          {
            id: 140902,
            pid: 140900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '忻府区',
            children: []
          },
          {
            id: 140921,
            pid: 140900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '定襄县',
            children: []
          },
          {
            id: 140922,
            pid: 140900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '五台县',
            children: []
          },
          {
            id: 140923,
            pid: 140900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '代县',
            children: []
          },
          {
            id: 140924,
            pid: 140900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '繁峙县',
            children: []
          },
          {
            id: 140925,
            pid: 140900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宁武县',
            children: []
          },
          {
            id: 140926,
            pid: 140900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '静乐县',
            children: []
          },
          {
            id: 140927,
            pid: 140900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '神池县',
            children: []
          },
          {
            id: 140928,
            pid: 140900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '五寨县',
            children: []
          },
          {
            id: 140929,
            pid: 140900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '岢岚县',
            children: []
          },
          {
            id: 140930,
            pid: 140900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '河曲县',
            children: []
          },
          {
            id: 140931,
            pid: 140900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '保德县',
            children: []
          },
          {
            id: 140932,
            pid: 140900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '偏关县',
            children: []
          },
          {
            id: 140971,
            pid: 140900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '五台山风景名胜区',
            children: []
          },
          {
            id: 140981,
            pid: 140900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '原平市',
            children: []
          }
        ]
      },
      {
        id: 141000,
        pid: 140000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '临汾市',
        children: [
          {
            id: 141002,
            pid: 141000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '尧都区',
            children: []
          },
          {
            id: 141021,
            pid: 141000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '曲沃县',
            children: []
          },
          {
            id: 141022,
            pid: 141000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '翼城县',
            children: []
          },
          {
            id: 141023,
            pid: 141000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '襄汾县',
            children: []
          },
          {
            id: 141024,
            pid: 141000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '洪洞县',
            children: []
          },
          {
            id: 141025,
            pid: 141000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '古县',
            children: []
          },
          {
            id: 141026,
            pid: 141000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '安泽县',
            children: []
          },
          {
            id: 141027,
            pid: 141000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '浮山县',
            children: []
          },
          {
            id: 141028,
            pid: 141000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '吉县',
            children: []
          },
          {
            id: 141029,
            pid: 141000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '乡宁县',
            children: []
          },
          {
            id: 141030,
            pid: 141000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大宁县',
            children: []
          },
          {
            id: 141031,
            pid: 141000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '隰县',
            children: []
          },
          {
            id: 141032,
            pid: 141000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '永和县',
            children: []
          },
          {
            id: 141033,
            pid: 141000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '蒲县',
            children: []
          },
          {
            id: 141034,
            pid: 141000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '汾西县',
            children: []
          },
          {
            id: 141081,
            pid: 141000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '侯马市',
            children: []
          },
          {
            id: 141082,
            pid: 141000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '霍州市',
            children: []
          }
        ]
      },
      {
        id: 141100,
        pid: 140000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '吕梁市',
        children: [
          {
            id: 141102,
            pid: 141100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '离石区',
            children: []
          },
          {
            id: 141121,
            pid: 141100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '文水县',
            children: []
          },
          {
            id: 141122,
            pid: 141100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '交城县',
            children: []
          },
          {
            id: 141123,
            pid: 141100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '兴县',
            children: []
          },
          {
            id: 141124,
            pid: 141100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '临县',
            children: []
          },
          {
            id: 141125,
            pid: 141100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '柳林县',
            children: []
          },
          {
            id: 141126,
            pid: 141100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '石楼县',
            children: []
          },
          {
            id: 141127,
            pid: 141100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '岚县',
            children: []
          },
          {
            id: 141128,
            pid: 141100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '方山县',
            children: []
          },
          {
            id: 141129,
            pid: 141100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '中阳县',
            children: []
          },
          {
            id: 141130,
            pid: 141100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '交口县',
            children: []
          },
          {
            id: 141181,
            pid: 141100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '孝义市',
            children: []
          },
          {
            id: 141182,
            pid: 141100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '汾阳市',
            children: []
          }
        ]
      }
    ]
  },
  {
    id: 150000,
    pid: 0,
    level: 1,
    createdAt: null,
    updatedAt: null,
    deletedAt: null,
    isValid: 1,
    label: '内蒙古自治区',
    children: [
      {
        id: 150100,
        pid: 150000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '呼和浩特市',
        children: [
          {
            id: 150102,
            pid: 150100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新城区',
            children: []
          },
          {
            id: 150103,
            pid: 150100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '回民区',
            children: []
          },
          {
            id: 150104,
            pid: 150100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '玉泉区',
            children: []
          },
          {
            id: 150105,
            pid: 150100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '赛罕区',
            children: []
          },
          {
            id: 150121,
            pid: 150100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '土默特左旗',
            children: []
          },
          {
            id: 150122,
            pid: 150100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '托克托县',
            children: []
          },
          {
            id: 150123,
            pid: 150100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '和林格尔县',
            children: []
          },
          {
            id: 150124,
            pid: 150100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '清水河县',
            children: []
          },
          {
            id: 150125,
            pid: 150100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '武川县',
            children: []
          },
          {
            id: 150172,
            pid: 150100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '呼和浩特经济技术开发区',
            children: []
          }
        ]
      },
      {
        id: 150200,
        pid: 150000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '包头市',
        children: [
          {
            id: 150202,
            pid: 150200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东河区',
            children: []
          },
          {
            id: 150203,
            pid: 150200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '昆都仑区',
            children: []
          },
          {
            id: 150204,
            pid: 150200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '青山区',
            children: []
          },
          {
            id: 150205,
            pid: 150200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '石拐区',
            children: []
          },
          {
            id: 150206,
            pid: 150200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '白云鄂博矿区',
            children: []
          },
          {
            id: 150207,
            pid: 150200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '九原区',
            children: []
          },
          {
            id: 150221,
            pid: 150200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '土默特右旗',
            children: []
          },
          {
            id: 150222,
            pid: 150200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '固阳县',
            children: []
          },
          {
            id: 150223,
            pid: 150200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '达尔罕茂明安联合旗',
            children: []
          },
          {
            id: 150271,
            pid: 150200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '包头稀土高新技术产业开发区',
            children: []
          }
        ]
      },
      {
        id: 150300,
        pid: 150000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '乌海市',
        children: [
          {
            id: 150302,
            pid: 150300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '海勃湾区',
            children: []
          },
          {
            id: 150303,
            pid: 150300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '海南区',
            children: []
          },
          {
            id: 150304,
            pid: 150300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '乌达区',
            children: []
          }
        ]
      },
      {
        id: 150400,
        pid: 150000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '赤峰市',
        children: [
          {
            id: 150402,
            pid: 150400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '红山区',
            children: []
          },
          {
            id: 150403,
            pid: 150400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '元宝山区',
            children: []
          },
          {
            id: 150404,
            pid: 150400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '松山区',
            children: []
          },
          {
            id: 150421,
            pid: 150400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '阿鲁科尔沁旗',
            children: []
          },
          {
            id: 150422,
            pid: 150400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '巴林左旗',
            children: []
          },
          {
            id: 150423,
            pid: 150400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '巴林右旗',
            children: []
          },
          {
            id: 150424,
            pid: 150400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '林西县',
            children: []
          },
          {
            id: 150425,
            pid: 150400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '克什克腾旗',
            children: []
          },
          {
            id: 150426,
            pid: 150400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '翁牛特旗',
            children: []
          },
          {
            id: 150428,
            pid: 150400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '喀喇沁旗',
            children: []
          },
          {
            id: 150429,
            pid: 150400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宁城县',
            children: []
          },
          {
            id: 150430,
            pid: 150400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '敖汉旗',
            children: []
          }
        ]
      },
      {
        id: 150500,
        pid: 150000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '通辽市',
        children: [
          {
            id: 150502,
            pid: 150500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '科尔沁区',
            children: []
          },
          {
            id: 150521,
            pid: 150500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '科尔沁左翼中旗',
            children: []
          },
          {
            id: 150522,
            pid: 150500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '科尔沁左翼后旗',
            children: []
          },
          {
            id: 150523,
            pid: 150500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '开鲁县',
            children: []
          },
          {
            id: 150524,
            pid: 150500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '库伦旗',
            children: []
          },
          {
            id: 150525,
            pid: 150500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '奈曼旗',
            children: []
          },
          {
            id: 150526,
            pid: 150500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '扎鲁特旗',
            children: []
          },
          {
            id: 150571,
            pid: 150500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '通辽经济技术开发区',
            children: []
          },
          {
            id: 150581,
            pid: 150500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '霍林郭勒市',
            children: []
          }
        ]
      },
      {
        id: 150600,
        pid: 150000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '鄂尔多斯市',
        children: [
          {
            id: 150602,
            pid: 150600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东胜区',
            children: []
          },
          {
            id: 150603,
            pid: 150600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '康巴什区',
            children: []
          },
          {
            id: 150621,
            pid: 150600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '达拉特旗',
            children: []
          },
          {
            id: 150622,
            pid: 150600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '准格尔旗',
            children: []
          },
          {
            id: 150623,
            pid: 150600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '鄂托克前旗',
            children: []
          },
          {
            id: 150624,
            pid: 150600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '鄂托克旗',
            children: []
          },
          {
            id: 150625,
            pid: 150600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '杭锦旗',
            children: []
          },
          {
            id: 150626,
            pid: 150600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '乌审旗',
            children: []
          },
          {
            id: 150627,
            pid: 150600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '伊金霍洛旗',
            children: []
          }
        ]
      },
      {
        id: 150700,
        pid: 150000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '呼伦贝尔市',
        children: [
          {
            id: 150702,
            pid: 150700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '海拉尔区',
            children: []
          },
          {
            id: 150703,
            pid: 150700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '扎赉诺尔区',
            children: []
          },
          {
            id: 150721,
            pid: 150700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '阿荣旗',
            children: []
          },
          {
            id: 150722,
            pid: 150700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '莫力达瓦达斡尔族自治旗',
            children: []
          },
          {
            id: 150723,
            pid: 150700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '鄂伦春自治旗',
            children: []
          },
          {
            id: 150724,
            pid: 150700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '鄂温克族自治旗',
            children: []
          },
          {
            id: 150725,
            pid: 150700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '陈巴尔虎旗',
            children: []
          },
          {
            id: 150726,
            pid: 150700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新巴尔虎左旗',
            children: []
          },
          {
            id: 150727,
            pid: 150700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新巴尔虎右旗',
            children: []
          },
          {
            id: 150781,
            pid: 150700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '满洲里市',
            children: []
          },
          {
            id: 150782,
            pid: 150700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '牙克石市',
            children: []
          },
          {
            id: 150783,
            pid: 150700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '扎兰屯市',
            children: []
          },
          {
            id: 150784,
            pid: 150700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '额尔古纳市',
            children: []
          },
          {
            id: 150785,
            pid: 150700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '根河市',
            children: []
          }
        ]
      },
      {
        id: 150800,
        pid: 150000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '巴彦淖尔市',
        children: [
          {
            id: 150802,
            pid: 150800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '临河区',
            children: []
          },
          {
            id: 150821,
            pid: 150800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '五原县',
            children: []
          },
          {
            id: 150822,
            pid: 150800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '磴口县',
            children: []
          },
          {
            id: 150823,
            pid: 150800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '乌拉特前旗',
            children: []
          },
          {
            id: 150824,
            pid: 150800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '乌拉特中旗',
            children: []
          },
          {
            id: 150825,
            pid: 150800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '乌拉特后旗',
            children: []
          },
          {
            id: 150826,
            pid: 150800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '杭锦后旗',
            children: []
          }
        ]
      },
      {
        id: 150900,
        pid: 150000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '乌兰察布市',
        children: [
          {
            id: 150902,
            pid: 150900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '集宁区',
            children: []
          },
          {
            id: 150921,
            pid: 150900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '卓资县',
            children: []
          },
          {
            id: 150922,
            pid: 150900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '化德县',
            children: []
          },
          {
            id: 150923,
            pid: 150900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '商都县',
            children: []
          },
          {
            id: 150924,
            pid: 150900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '兴和县',
            children: []
          },
          {
            id: 150925,
            pid: 150900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '凉城县',
            children: []
          },
          {
            id: 150926,
            pid: 150900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '察哈尔右翼前旗',
            children: []
          },
          {
            id: 150927,
            pid: 150900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '察哈尔右翼中旗',
            children: []
          },
          {
            id: 150928,
            pid: 150900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '察哈尔右翼后旗',
            children: []
          },
          {
            id: 150929,
            pid: 150900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '四子王旗',
            children: []
          },
          {
            id: 150981,
            pid: 150900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '丰镇市',
            children: []
          }
        ]
      },
      {
        id: 152200,
        pid: 150000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '兴安盟',
        children: [
          {
            id: 152201,
            pid: 152200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '乌兰浩特市',
            children: []
          },
          {
            id: 152202,
            pid: 152200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '阿尔山市',
            children: []
          },
          {
            id: 152221,
            pid: 152200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '科尔沁右翼前旗',
            children: []
          },
          {
            id: 152222,
            pid: 152200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '科尔沁右翼中旗',
            children: []
          },
          {
            id: 152223,
            pid: 152200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '扎赉特旗',
            children: []
          },
          {
            id: 152224,
            pid: 152200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '突泉县',
            children: []
          }
        ]
      },
      {
        id: 152500,
        pid: 150000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '锡林郭勒盟',
        children: [
          {
            id: 152501,
            pid: 152500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '二连浩特市',
            children: []
          },
          {
            id: 152502,
            pid: 152500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '锡林浩特市',
            children: []
          },
          {
            id: 152522,
            pid: 152500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '阿巴嘎旗',
            children: []
          },
          {
            id: 152523,
            pid: 152500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '苏尼特左旗',
            children: []
          },
          {
            id: 152524,
            pid: 152500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '苏尼特右旗',
            children: []
          },
          {
            id: 152525,
            pid: 152500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东乌珠穆沁旗',
            children: []
          },
          {
            id: 152526,
            pid: 152500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '西乌珠穆沁旗',
            children: []
          },
          {
            id: 152527,
            pid: 152500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '太仆寺旗',
            children: []
          },
          {
            id: 152528,
            pid: 152500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '镶黄旗',
            children: []
          },
          {
            id: 152529,
            pid: 152500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '正镶白旗',
            children: []
          },
          {
            id: 152530,
            pid: 152500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '正蓝旗',
            children: []
          },
          {
            id: 152531,
            pid: 152500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '多伦县',
            children: []
          },
          {
            id: 152571,
            pid: 152500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '乌拉盖管委会',
            children: []
          }
        ]
      },
      {
        id: 152900,
        pid: 150000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '阿拉善盟',
        children: [
          {
            id: 152921,
            pid: 152900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '阿拉善左旗',
            children: []
          },
          {
            id: 152922,
            pid: 152900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '阿拉善右旗',
            children: []
          },
          {
            id: 152923,
            pid: 152900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '额济纳旗',
            children: []
          },
          {
            id: 152971,
            pid: 152900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '内蒙古阿拉善经济开发区',
            children: []
          }
        ]
      }
    ]
  },
  {
    id: 210000,
    pid: 0,
    level: 1,
    createdAt: null,
    updatedAt: null,
    deletedAt: null,
    isValid: 1,
    label: '辽宁省',
    children: [
      {
        id: 210100,
        pid: 210000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '沈阳市',
        children: [
          {
            id: 210102,
            pid: 210100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '和平区',
            children: []
          },
          {
            id: 210103,
            pid: 210100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '沈河区',
            children: []
          },
          {
            id: 210104,
            pid: 210100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大东区',
            children: []
          },
          {
            id: 210105,
            pid: 210100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '皇姑区',
            children: []
          },
          {
            id: 210106,
            pid: 210100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '铁西区',
            children: []
          },
          {
            id: 210111,
            pid: 210100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '苏家屯区',
            children: []
          },
          {
            id: 210112,
            pid: 210100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '浑南区',
            children: []
          },
          {
            id: 210113,
            pid: 210100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '沈北新区',
            children: []
          },
          {
            id: 210114,
            pid: 210100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '于洪区',
            children: []
          },
          {
            id: 210115,
            pid: 210100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '辽中区',
            children: []
          },
          {
            id: 210123,
            pid: 210100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '康平县',
            children: []
          },
          {
            id: 210124,
            pid: 210100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '法库县',
            children: []
          },
          {
            id: 210181,
            pid: 210100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新民市',
            children: []
          }
        ]
      },
      {
        id: 210200,
        pid: 210000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '大连市',
        children: [
          {
            id: 210202,
            pid: 210200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '中山区',
            children: []
          },
          {
            id: 210203,
            pid: 210200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '西岗区',
            children: []
          },
          {
            id: 210204,
            pid: 210200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '沙河口区',
            children: []
          },
          {
            id: 210211,
            pid: 210200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '甘井子区',
            children: []
          },
          {
            id: 210212,
            pid: 210200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '旅顺口区',
            children: []
          },
          {
            id: 210213,
            pid: 210200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '金州区',
            children: []
          },
          {
            id: 210214,
            pid: 210200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '普兰店区',
            children: []
          },
          {
            id: 210224,
            pid: 210200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '长海县',
            children: []
          },
          {
            id: 210281,
            pid: 210200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '瓦房店市',
            children: []
          },
          {
            id: 210283,
            pid: 210200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '庄河市',
            children: []
          }
        ]
      },
      {
        id: 210300,
        pid: 210000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '鞍山市',
        children: [
          {
            id: 210302,
            pid: 210300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '铁东区',
            children: []
          },
          {
            id: 210303,
            pid: 210300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '铁西区',
            children: []
          },
          {
            id: 210304,
            pid: 210300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '立山区',
            children: []
          },
          {
            id: 210311,
            pid: 210300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '千山区',
            children: []
          },
          {
            id: 210321,
            pid: 210300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '台安县',
            children: []
          },
          {
            id: 210323,
            pid: 210300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '岫岩满族自治县',
            children: []
          },
          {
            id: 210381,
            pid: 210300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '海城市',
            children: []
          }
        ]
      },
      {
        id: 210400,
        pid: 210000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '抚顺市',
        children: [
          {
            id: 210402,
            pid: 210400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新抚区',
            children: []
          },
          {
            id: 210403,
            pid: 210400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东洲区',
            children: []
          },
          {
            id: 210404,
            pid: 210400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '望花区',
            children: []
          },
          {
            id: 210411,
            pid: 210400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '顺城区',
            children: []
          },
          {
            id: 210421,
            pid: 210400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '抚顺县',
            children: []
          },
          {
            id: 210422,
            pid: 210400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新宾满族自治县',
            children: []
          },
          {
            id: 210423,
            pid: 210400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '清原满族自治县',
            children: []
          }
        ]
      },
      {
        id: 210500,
        pid: 210000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '本溪市',
        children: [
          {
            id: 210502,
            pid: 210500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '平山区',
            children: []
          },
          {
            id: 210503,
            pid: 210500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '溪湖区',
            children: []
          },
          {
            id: 210504,
            pid: 210500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '明山区',
            children: []
          },
          {
            id: 210505,
            pid: 210500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南芬区',
            children: []
          },
          {
            id: 210521,
            pid: 210500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '本溪满族自治县',
            children: []
          },
          {
            id: 210522,
            pid: 210500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '桓仁满族自治县',
            children: []
          }
        ]
      },
      {
        id: 210600,
        pid: 210000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '丹东市',
        children: [
          {
            id: 210602,
            pid: 210600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '元宝区',
            children: []
          },
          {
            id: 210603,
            pid: 210600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '振兴区',
            children: []
          },
          {
            id: 210604,
            pid: 210600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '振安区',
            children: []
          },
          {
            id: 210624,
            pid: 210600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宽甸满族自治县',
            children: []
          },
          {
            id: 210681,
            pid: 210600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东港市',
            children: []
          },
          {
            id: 210682,
            pid: 210600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '凤城市',
            children: []
          }
        ]
      },
      {
        id: 210700,
        pid: 210000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '锦州市',
        children: [
          {
            id: 210702,
            pid: 210700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '古塔区',
            children: []
          },
          {
            id: 210703,
            pid: 210700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '凌河区',
            children: []
          },
          {
            id: 210711,
            pid: 210700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '太和区',
            children: []
          },
          {
            id: 210726,
            pid: 210700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '黑山县',
            children: []
          },
          {
            id: 210727,
            pid: 210700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '义县',
            children: []
          },
          {
            id: 210781,
            pid: 210700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '凌海市',
            children: []
          },
          {
            id: 210782,
            pid: 210700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '北镇市',
            children: []
          }
        ]
      },
      {
        id: 210800,
        pid: 210000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '营口市',
        children: [
          {
            id: 210802,
            pid: 210800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '站前区',
            children: []
          },
          {
            id: 210803,
            pid: 210800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '西市区',
            children: []
          },
          {
            id: 210804,
            pid: 210800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '鲅鱼圈区',
            children: []
          },
          {
            id: 210811,
            pid: 210800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '老边区',
            children: []
          },
          {
            id: 210881,
            pid: 210800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '盖州市',
            children: []
          },
          {
            id: 210882,
            pid: 210800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大石桥市',
            children: []
          }
        ]
      },
      {
        id: 210900,
        pid: 210000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '阜新市',
        children: [
          {
            id: 210902,
            pid: 210900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '海州区',
            children: []
          },
          {
            id: 210903,
            pid: 210900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新邱区',
            children: []
          },
          {
            id: 210904,
            pid: 210900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '太平区',
            children: []
          },
          {
            id: 210905,
            pid: 210900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '清河门区',
            children: []
          },
          {
            id: 210911,
            pid: 210900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '细河区',
            children: []
          },
          {
            id: 210921,
            pid: 210900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '阜新蒙古族自治县',
            children: []
          },
          {
            id: 210922,
            pid: 210900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '彰武县',
            children: []
          }
        ]
      },
      {
        id: 211000,
        pid: 210000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '辽阳市',
        children: [
          {
            id: 211002,
            pid: 211000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '白塔区',
            children: []
          },
          {
            id: 211003,
            pid: 211000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '文圣区',
            children: []
          },
          {
            id: 211004,
            pid: 211000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宏伟区',
            children: []
          },
          {
            id: 211005,
            pid: 211000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '弓长岭区',
            children: []
          },
          {
            id: 211011,
            pid: 211000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '太子河区',
            children: []
          },
          {
            id: 211021,
            pid: 211000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '辽阳县',
            children: []
          },
          {
            id: 211081,
            pid: 211000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '灯塔市',
            children: []
          }
        ]
      },
      {
        id: 211100,
        pid: 210000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '盘锦市',
        children: [
          {
            id: 211102,
            pid: 211100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '双台子区',
            children: []
          },
          {
            id: 211103,
            pid: 211100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '兴隆台区',
            children: []
          },
          {
            id: 211104,
            pid: 211100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大洼区',
            children: []
          },
          {
            id: 211122,
            pid: 211100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '盘山县',
            children: []
          }
        ]
      },
      {
        id: 211200,
        pid: 210000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '铁岭市',
        children: [
          {
            id: 211202,
            pid: 211200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '银州区',
            children: []
          },
          {
            id: 211204,
            pid: 211200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '清河区',
            children: []
          },
          {
            id: 211221,
            pid: 211200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '铁岭县',
            children: []
          },
          {
            id: 211223,
            pid: 211200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '西丰县',
            children: []
          },
          {
            id: 211224,
            pid: 211200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '昌图县',
            children: []
          },
          {
            id: 211281,
            pid: 211200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '调兵山市',
            children: []
          },
          {
            id: 211282,
            pid: 211200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '开原市',
            children: []
          }
        ]
      },
      {
        id: 211300,
        pid: 210000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '朝阳市',
        children: [
          {
            id: 211302,
            pid: 211300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '双塔区',
            children: []
          },
          {
            id: 211303,
            pid: 211300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '龙城区',
            children: []
          },
          {
            id: 211321,
            pid: 211300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '朝阳县',
            children: []
          },
          {
            id: 211322,
            pid: 211300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '建平县',
            children: []
          },
          {
            id: 211324,
            pid: 211300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '喀喇沁左翼蒙古族自治县',
            children: []
          },
          {
            id: 211381,
            pid: 211300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '北票市',
            children: []
          },
          {
            id: 211382,
            pid: 211300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '凌源市',
            children: []
          }
        ]
      },
      {
        id: 211400,
        pid: 210000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '葫芦岛市',
        children: [
          {
            id: 211402,
            pid: 211400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '连山区',
            children: []
          },
          {
            id: 211403,
            pid: 211400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '龙港区',
            children: []
          },
          {
            id: 211404,
            pid: 211400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南票区',
            children: []
          },
          {
            id: 211421,
            pid: 211400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '绥中县',
            children: []
          },
          {
            id: 211422,
            pid: 211400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '建昌县',
            children: []
          },
          {
            id: 211481,
            pid: 211400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '兴城市',
            children: []
          }
        ]
      }
    ]
  },
  {
    id: 220000,
    pid: 0,
    level: 1,
    createdAt: null,
    updatedAt: null,
    deletedAt: null,
    isValid: 1,
    label: '吉林省',
    children: [
      {
        id: 220100,
        pid: 220000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '长春市',
        children: [
          {
            id: 220102,
            pid: 220100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南关区',
            children: []
          },
          {
            id: 220103,
            pid: 220100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宽城区',
            children: []
          },
          {
            id: 220104,
            pid: 220100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '朝阳区',
            children: []
          },
          {
            id: 220105,
            pid: 220100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '二道区',
            children: []
          },
          {
            id: 220106,
            pid: 220100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '绿园区',
            children: []
          },
          {
            id: 220112,
            pid: 220100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '双阳区',
            children: []
          },
          {
            id: 220113,
            pid: 220100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '九台区',
            children: []
          },
          {
            id: 220122,
            pid: 220100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '农安县',
            children: []
          },
          {
            id: 220171,
            pid: 220100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '长春经济技术开发区',
            children: []
          },
          {
            id: 220172,
            pid: 220100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '长春净月高新技术产业开发区',
            children: []
          },
          {
            id: 220173,
            pid: 220100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '长春高新技术产业开发区',
            children: []
          },
          {
            id: 220174,
            pid: 220100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '长春汽车经济技术开发区',
            children: []
          },
          {
            id: 220182,
            pid: 220100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '榆树市',
            children: []
          },
          {
            id: 220183,
            pid: 220100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '德惠市',
            children: []
          },
          {
            id: 220184,
            pid: 220100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '公主岭市',
            children: []
          }
        ]
      },
      {
        id: 220200,
        pid: 220000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '吉林市',
        children: [
          {
            id: 220202,
            pid: 220200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '昌邑区',
            children: []
          },
          {
            id: 220203,
            pid: 220200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '龙潭区',
            children: []
          },
          {
            id: 220204,
            pid: 220200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '船营区',
            children: []
          },
          {
            id: 220211,
            pid: 220200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '丰满区',
            children: []
          },
          {
            id: 220221,
            pid: 220200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '永吉县',
            children: []
          },
          {
            id: 220271,
            pid: 220200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '吉林经济开发区',
            children: []
          },
          {
            id: 220272,
            pid: 220200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '吉林高新技术产业开发区',
            children: []
          },
          {
            id: 220273,
            pid: 220200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '吉林中国新加坡食品区',
            children: []
          },
          {
            id: 220281,
            pid: 220200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '蛟河市',
            children: []
          },
          {
            id: 220282,
            pid: 220200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '桦甸市',
            children: []
          },
          {
            id: 220283,
            pid: 220200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '舒兰市',
            children: []
          },
          {
            id: 220284,
            pid: 220200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '磐石市',
            children: []
          }
        ]
      },
      {
        id: 220300,
        pid: 220000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '四平市',
        children: [
          {
            id: 220302,
            pid: 220300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '铁西区',
            children: []
          },
          {
            id: 220303,
            pid: 220300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '铁东区',
            children: []
          },
          {
            id: 220322,
            pid: 220300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '梨树县',
            children: []
          },
          {
            id: 220323,
            pid: 220300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '伊通满族自治县',
            children: []
          },
          {
            id: 220382,
            pid: 220300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '双辽市',
            children: []
          }
        ]
      },
      {
        id: 220400,
        pid: 220000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '辽源市',
        children: [
          {
            id: 220402,
            pid: 220400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '龙山区',
            children: []
          },
          {
            id: 220403,
            pid: 220400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '西安区',
            children: []
          },
          {
            id: 220421,
            pid: 220400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东丰县',
            children: []
          },
          {
            id: 220422,
            pid: 220400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东辽县',
            children: []
          }
        ]
      },
      {
        id: 220500,
        pid: 220000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '通化市',
        children: [
          {
            id: 220502,
            pid: 220500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东昌区',
            children: []
          },
          {
            id: 220503,
            pid: 220500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '二道江区',
            children: []
          },
          {
            id: 220521,
            pid: 220500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '通化县',
            children: []
          },
          {
            id: 220523,
            pid: 220500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '辉南县',
            children: []
          },
          {
            id: 220524,
            pid: 220500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '柳河县',
            children: []
          },
          {
            id: 220581,
            pid: 220500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '梅河口市',
            children: []
          },
          {
            id: 220582,
            pid: 220500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '集安市',
            children: []
          }
        ]
      },
      {
        id: 220600,
        pid: 220000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '白山市',
        children: [
          {
            id: 220602,
            pid: 220600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '浑江区',
            children: []
          },
          {
            id: 220605,
            pid: 220600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '江源区',
            children: []
          },
          {
            id: 220621,
            pid: 220600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '抚松县',
            children: []
          },
          {
            id: 220622,
            pid: 220600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '靖宇县',
            children: []
          },
          {
            id: 220623,
            pid: 220600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '长白朝鲜族自治县',
            children: []
          },
          {
            id: 220681,
            pid: 220600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '临江市',
            children: []
          }
        ]
      },
      {
        id: 220700,
        pid: 220000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '松原市',
        children: [
          {
            id: 220702,
            pid: 220700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宁江区',
            children: []
          },
          {
            id: 220721,
            pid: 220700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '前郭尔罗斯蒙古族自治县',
            children: []
          },
          {
            id: 220722,
            pid: 220700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '长岭县',
            children: []
          },
          {
            id: 220723,
            pid: 220700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '乾安县',
            children: []
          },
          {
            id: 220771,
            pid: 220700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '吉林松原经济开发区',
            children: []
          },
          {
            id: 220781,
            pid: 220700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '扶余市',
            children: []
          }
        ]
      },
      {
        id: 220800,
        pid: 220000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '白城市',
        children: [
          {
            id: 220802,
            pid: 220800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '洮北区',
            children: []
          },
          {
            id: 220821,
            pid: 220800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '镇赉县',
            children: []
          },
          {
            id: 220822,
            pid: 220800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '通榆县',
            children: []
          },
          {
            id: 220871,
            pid: 220800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '吉林白城经济开发区',
            children: []
          },
          {
            id: 220881,
            pid: 220800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '洮南市',
            children: []
          },
          {
            id: 220882,
            pid: 220800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大安市',
            children: []
          }
        ]
      },
      {
        id: 222400,
        pid: 220000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '延边朝鲜族自治州',
        children: [
          {
            id: 222401,
            pid: 222400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '延吉市',
            children: []
          },
          {
            id: 222402,
            pid: 222400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '图们市',
            children: []
          },
          {
            id: 222403,
            pid: 222400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '敦化市',
            children: []
          },
          {
            id: 222404,
            pid: 222400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '珲春市',
            children: []
          },
          {
            id: 222405,
            pid: 222400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '龙井市',
            children: []
          },
          {
            id: 222406,
            pid: 222400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '和龙市',
            children: []
          },
          {
            id: 222424,
            pid: 222400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '汪清县',
            children: []
          },
          {
            id: 222426,
            pid: 222400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '安图县',
            children: []
          }
        ]
      }
    ]
  },
  {
    id: 230000,
    pid: 0,
    level: 1,
    createdAt: null,
    updatedAt: null,
    deletedAt: null,
    isValid: 1,
    label: '黑龙江省',
    children: [
      {
        id: 230100,
        pid: 230000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '哈尔滨市',
        children: [
          {
            id: 230102,
            pid: 230100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '道里区',
            children: []
          },
          {
            id: 230103,
            pid: 230100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南岗区',
            children: []
          },
          {
            id: 230104,
            pid: 230100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '道外区',
            children: []
          },
          {
            id: 230108,
            pid: 230100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '平房区',
            children: []
          },
          {
            id: 230109,
            pid: 230100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '松北区',
            children: []
          },
          {
            id: 230110,
            pid: 230100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '香坊区',
            children: []
          },
          {
            id: 230111,
            pid: 230100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '呼兰区',
            children: []
          },
          {
            id: 230112,
            pid: 230100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '阿城区',
            children: []
          },
          {
            id: 230113,
            pid: 230100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '双城区',
            children: []
          },
          {
            id: 230123,
            pid: 230100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '依兰县',
            children: []
          },
          {
            id: 230124,
            pid: 230100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '方正县',
            children: []
          },
          {
            id: 230125,
            pid: 230100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宾县',
            children: []
          },
          {
            id: 230126,
            pid: 230100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '巴彦县',
            children: []
          },
          {
            id: 230127,
            pid: 230100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '木兰县',
            children: []
          },
          {
            id: 230128,
            pid: 230100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '通河县',
            children: []
          },
          {
            id: 230129,
            pid: 230100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '延寿县',
            children: []
          },
          {
            id: 230183,
            pid: 230100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '尚志市',
            children: []
          },
          {
            id: 230184,
            pid: 230100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '五常市',
            children: []
          }
        ]
      },
      {
        id: 230200,
        pid: 230000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '齐齐哈尔市',
        children: [
          {
            id: 230202,
            pid: 230200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '龙沙区',
            children: []
          },
          {
            id: 230203,
            pid: 230200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '建华区',
            children: []
          },
          {
            id: 230204,
            pid: 230200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '铁锋区',
            children: []
          },
          {
            id: 230205,
            pid: 230200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '昂昂溪区',
            children: []
          },
          {
            id: 230206,
            pid: 230200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '富拉尔基区',
            children: []
          },
          {
            id: 230207,
            pid: 230200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '碾子山区',
            children: []
          },
          {
            id: 230208,
            pid: 230200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '梅里斯达斡尔族区',
            children: []
          },
          {
            id: 230221,
            pid: 230200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '龙江县',
            children: []
          },
          {
            id: 230223,
            pid: 230200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '依安县',
            children: []
          },
          {
            id: 230224,
            pid: 230200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '泰来县',
            children: []
          },
          {
            id: 230225,
            pid: 230200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '甘南县',
            children: []
          },
          {
            id: 230227,
            pid: 230200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '富裕县',
            children: []
          },
          {
            id: 230229,
            pid: 230200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '克山县',
            children: []
          },
          {
            id: 230230,
            pid: 230200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '克东县',
            children: []
          },
          {
            id: 230231,
            pid: 230200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '拜泉县',
            children: []
          },
          {
            id: 230281,
            pid: 230200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '讷河市',
            children: []
          }
        ]
      },
      {
        id: 230300,
        pid: 230000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '鸡西市',
        children: [
          {
            id: 230302,
            pid: 230300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '鸡冠区',
            children: []
          },
          {
            id: 230303,
            pid: 230300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '恒山区',
            children: []
          },
          {
            id: 230304,
            pid: 230300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '滴道区',
            children: []
          },
          {
            id: 230305,
            pid: 230300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '梨树区',
            children: []
          },
          {
            id: 230306,
            pid: 230300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '城子河区',
            children: []
          },
          {
            id: 230307,
            pid: 230300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '麻山区',
            children: []
          },
          {
            id: 230321,
            pid: 230300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '鸡东县',
            children: []
          },
          {
            id: 230381,
            pid: 230300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '虎林市',
            children: []
          },
          {
            id: 230382,
            pid: 230300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '密山市',
            children: []
          }
        ]
      },
      {
        id: 230400,
        pid: 230000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '鹤岗市',
        children: [
          {
            id: 230402,
            pid: 230400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '向阳区',
            children: []
          },
          {
            id: 230403,
            pid: 230400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '工农区',
            children: []
          },
          {
            id: 230404,
            pid: 230400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南山区',
            children: []
          },
          {
            id: 230405,
            pid: 230400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '兴安区',
            children: []
          },
          {
            id: 230406,
            pid: 230400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东山区',
            children: []
          },
          {
            id: 230407,
            pid: 230400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '兴山区',
            children: []
          },
          {
            id: 230421,
            pid: 230400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '萝北县',
            children: []
          },
          {
            id: 230422,
            pid: 230400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '绥滨县',
            children: []
          }
        ]
      },
      {
        id: 230500,
        pid: 230000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '双鸭山市',
        children: [
          {
            id: 230502,
            pid: 230500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '尖山区',
            children: []
          },
          {
            id: 230503,
            pid: 230500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '岭东区',
            children: []
          },
          {
            id: 230505,
            pid: 230500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '四方台区',
            children: []
          },
          {
            id: 230506,
            pid: 230500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宝山区',
            children: []
          },
          {
            id: 230521,
            pid: 230500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '集贤县',
            children: []
          },
          {
            id: 230522,
            pid: 230500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '友谊县',
            children: []
          },
          {
            id: 230523,
            pid: 230500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宝清县',
            children: []
          },
          {
            id: 230524,
            pid: 230500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '饶河县',
            children: []
          }
        ]
      },
      {
        id: 230600,
        pid: 230000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '大庆市',
        children: [
          {
            id: 230602,
            pid: 230600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '萨尔图区',
            children: []
          },
          {
            id: 230603,
            pid: 230600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '龙凤区',
            children: []
          },
          {
            id: 230604,
            pid: 230600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '让胡路区',
            children: []
          },
          {
            id: 230605,
            pid: 230600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '红岗区',
            children: []
          },
          {
            id: 230606,
            pid: 230600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大同区',
            children: []
          },
          {
            id: 230621,
            pid: 230600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '肇州县',
            children: []
          },
          {
            id: 230622,
            pid: 230600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '肇源县',
            children: []
          },
          {
            id: 230623,
            pid: 230600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '林甸县',
            children: []
          },
          {
            id: 230624,
            pid: 230600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '杜尔伯特蒙古族自治县',
            children: []
          },
          {
            id: 230671,
            pid: 230600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大庆高新技术产业开发区',
            children: []
          }
        ]
      },
      {
        id: 230700,
        pid: 230000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '伊春市',
        children: [
          {
            id: 230717,
            pid: 230700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '伊美区',
            children: []
          },
          {
            id: 230718,
            pid: 230700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '乌翠区',
            children: []
          },
          {
            id: 230719,
            pid: 230700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '友好区',
            children: []
          },
          {
            id: 230722,
            pid: 230700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '嘉荫县',
            children: []
          },
          {
            id: 230723,
            pid: 230700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '汤旺县',
            children: []
          },
          {
            id: 230724,
            pid: 230700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '丰林县',
            children: []
          },
          {
            id: 230725,
            pid: 230700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大箐山县',
            children: []
          },
          {
            id: 230726,
            pid: 230700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南岔县',
            children: []
          },
          {
            id: 230751,
            pid: 230700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '金林区',
            children: []
          },
          {
            id: 230781,
            pid: 230700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '铁力市',
            children: []
          }
        ]
      },
      {
        id: 230800,
        pid: 230000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '佳木斯市',
        children: [
          {
            id: 230803,
            pid: 230800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '向阳区',
            children: []
          },
          {
            id: 230804,
            pid: 230800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '前进区',
            children: []
          },
          {
            id: 230805,
            pid: 230800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东风区',
            children: []
          },
          {
            id: 230811,
            pid: 230800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '郊区',
            children: []
          },
          {
            id: 230822,
            pid: 230800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '桦南县',
            children: []
          },
          {
            id: 230826,
            pid: 230800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '桦川县',
            children: []
          },
          {
            id: 230828,
            pid: 230800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '汤原县',
            children: []
          },
          {
            id: 230881,
            pid: 230800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '同江市',
            children: []
          },
          {
            id: 230882,
            pid: 230800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '富锦市',
            children: []
          },
          {
            id: 230883,
            pid: 230800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '抚远市',
            children: []
          }
        ]
      },
      {
        id: 230900,
        pid: 230000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '七台河市',
        children: [
          {
            id: 230902,
            pid: 230900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新兴区',
            children: []
          },
          {
            id: 230903,
            pid: 230900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '桃山区',
            children: []
          },
          {
            id: 230904,
            pid: 230900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '茄子河区',
            children: []
          },
          {
            id: 230921,
            pid: 230900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '勃利县',
            children: []
          }
        ]
      },
      {
        id: 231000,
        pid: 230000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '牡丹江市',
        children: [
          {
            id: 231002,
            pid: 231000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东安区',
            children: []
          },
          {
            id: 231003,
            pid: 231000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '阳明区',
            children: []
          },
          {
            id: 231004,
            pid: 231000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '爱民区',
            children: []
          },
          {
            id: 231005,
            pid: 231000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '西安区',
            children: []
          },
          {
            id: 231025,
            pid: 231000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '林口县',
            children: []
          },
          {
            id: 231071,
            pid: 231000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '牡丹江经济技术开发区',
            children: []
          },
          {
            id: 231081,
            pid: 231000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '绥芬河市',
            children: []
          },
          {
            id: 231083,
            pid: 231000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '海林市',
            children: []
          },
          {
            id: 231084,
            pid: 231000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宁安市',
            children: []
          },
          {
            id: 231085,
            pid: 231000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '穆棱市',
            children: []
          },
          {
            id: 231086,
            pid: 231000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东宁市',
            children: []
          }
        ]
      },
      {
        id: 231100,
        pid: 230000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '黑河市',
        children: [
          {
            id: 231102,
            pid: 231100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '爱辉区',
            children: []
          },
          {
            id: 231123,
            pid: 231100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '逊克县',
            children: []
          },
          {
            id: 231124,
            pid: 231100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '孙吴县',
            children: []
          },
          {
            id: 231181,
            pid: 231100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '北安市',
            children: []
          },
          {
            id: 231182,
            pid: 231100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '五大连池市',
            children: []
          },
          {
            id: 231183,
            pid: 231100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '嫩江市',
            children: []
          }
        ]
      },
      {
        id: 231200,
        pid: 230000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '绥化市',
        children: [
          {
            id: 231202,
            pid: 231200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '北林区',
            children: []
          },
          {
            id: 231221,
            pid: 231200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '望奎县',
            children: []
          },
          {
            id: 231222,
            pid: 231200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '兰西县',
            children: []
          },
          {
            id: 231223,
            pid: 231200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '青冈县',
            children: []
          },
          {
            id: 231224,
            pid: 231200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '庆安县',
            children: []
          },
          {
            id: 231225,
            pid: 231200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '明水县',
            children: []
          },
          {
            id: 231226,
            pid: 231200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '绥棱县',
            children: []
          },
          {
            id: 231281,
            pid: 231200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '安达市',
            children: []
          },
          {
            id: 231282,
            pid: 231200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '肇东市',
            children: []
          },
          {
            id: 231283,
            pid: 231200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '海伦市',
            children: []
          }
        ]
      },
      {
        id: 232700,
        pid: 230000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '大兴安岭地区',
        children: [
          {
            id: 232701,
            pid: 232700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '漠河市',
            children: []
          },
          {
            id: 232721,
            pid: 232700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '呼玛县',
            children: []
          },
          {
            id: 232722,
            pid: 232700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '塔河县',
            children: []
          },
          {
            id: 232761,
            pid: 232700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '加格达奇区',
            children: []
          },
          {
            id: 232762,
            pid: 232700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '松岭区',
            children: []
          },
          {
            id: 232763,
            pid: 232700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新林区',
            children: []
          },
          {
            id: 232764,
            pid: 232700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '呼中区',
            children: []
          }
        ]
      }
    ]
  },
  {
    id: 310000,
    pid: 0,
    level: 1,
    createdAt: null,
    updatedAt: null,
    deletedAt: null,
    isValid: 1,
    label: '上海市',
    children: [
      {
        id: 310100,
        pid: 310000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '市辖区',
        children: [
          {
            id: 310101,
            pid: 310100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '黄浦区',
            children: []
          },
          {
            id: 310104,
            pid: 310100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '徐汇区',
            children: []
          },
          {
            id: 310105,
            pid: 310100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '长宁区',
            children: []
          },
          {
            id: 310106,
            pid: 310100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '静安区',
            children: []
          },
          {
            id: 310107,
            pid: 310100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '普陀区',
            children: []
          },
          {
            id: 310109,
            pid: 310100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '虹口区',
            children: []
          },
          {
            id: 310110,
            pid: 310100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '杨浦区',
            children: []
          },
          {
            id: 310112,
            pid: 310100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '闵行区',
            children: []
          },
          {
            id: 310113,
            pid: 310100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宝山区',
            children: []
          },
          {
            id: 310114,
            pid: 310100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '嘉定区',
            children: []
          },
          {
            id: 310115,
            pid: 310100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '浦东新区',
            children: []
          },
          {
            id: 310116,
            pid: 310100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '金山区',
            children: []
          },
          {
            id: 310117,
            pid: 310100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '松江区',
            children: []
          },
          {
            id: 310118,
            pid: 310100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '青浦区',
            children: []
          },
          {
            id: 310120,
            pid: 310100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '奉贤区',
            children: []
          },
          {
            id: 310151,
            pid: 310100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '崇明区',
            children: []
          }
        ]
      }
    ]
  },
  {
    id: 320000,
    pid: 0,
    level: 1,
    createdAt: null,
    updatedAt: null,
    deletedAt: null,
    isValid: 1,
    label: '江苏省',
    children: [
      {
        id: 320100,
        pid: 320000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '南京市',
        children: [
          {
            id: 320102,
            pid: 320100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '玄武区',
            children: []
          },
          {
            id: 320104,
            pid: 320100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '秦淮区',
            children: []
          },
          {
            id: 320105,
            pid: 320100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '建邺区',
            children: []
          },
          {
            id: 320106,
            pid: 320100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '鼓楼区',
            children: []
          },
          {
            id: 320111,
            pid: 320100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '浦口区',
            children: []
          },
          {
            id: 320113,
            pid: 320100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '栖霞区',
            children: []
          },
          {
            id: 320114,
            pid: 320100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '雨花台区',
            children: []
          },
          {
            id: 320115,
            pid: 320100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '江宁区',
            children: []
          },
          {
            id: 320116,
            pid: 320100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '六合区',
            children: []
          },
          {
            id: 320117,
            pid: 320100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '溧水区',
            children: []
          },
          {
            id: 320118,
            pid: 320100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '高淳区',
            children: []
          }
        ]
      },
      {
        id: 320200,
        pid: 320000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '无锡市',
        children: [
          {
            id: 320205,
            pid: 320200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '锡山区',
            children: []
          },
          {
            id: 320206,
            pid: 320200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '惠山区',
            children: []
          },
          {
            id: 320211,
            pid: 320200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '滨湖区',
            children: []
          },
          {
            id: 320213,
            pid: 320200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '梁溪区',
            children: []
          },
          {
            id: 320214,
            pid: 320200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新吴区',
            children: []
          },
          {
            id: 320281,
            pid: 320200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '江阴市',
            children: []
          },
          {
            id: 320282,
            pid: 320200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宜兴市',
            children: []
          }
        ]
      },
      {
        id: 320300,
        pid: 320000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '徐州市',
        children: [
          {
            id: 320302,
            pid: 320300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '鼓楼区',
            children: []
          },
          {
            id: 320303,
            pid: 320300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '云龙区',
            children: []
          },
          {
            id: 320305,
            pid: 320300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '贾汪区',
            children: []
          },
          {
            id: 320311,
            pid: 320300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '泉山区',
            children: []
          },
          {
            id: 320312,
            pid: 320300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '铜山区',
            children: []
          },
          {
            id: 320321,
            pid: 320300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '丰县',
            children: []
          },
          {
            id: 320322,
            pid: 320300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '沛县',
            children: []
          },
          {
            id: 320324,
            pid: 320300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '睢宁县',
            children: []
          },
          {
            id: 320371,
            pid: 320300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '徐州经济技术开发区',
            children: []
          },
          {
            id: 320381,
            pid: 320300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新沂市',
            children: []
          },
          {
            id: 320382,
            pid: 320300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '邳州市',
            children: []
          }
        ]
      },
      {
        id: 320400,
        pid: 320000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '常州市',
        children: [
          {
            id: 320402,
            pid: 320400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '天宁区',
            children: []
          },
          {
            id: 320404,
            pid: 320400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '钟楼区',
            children: []
          },
          {
            id: 320411,
            pid: 320400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新北区',
            children: []
          },
          {
            id: 320412,
            pid: 320400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '武进区',
            children: []
          },
          {
            id: 320413,
            pid: 320400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '金坛区',
            children: []
          },
          {
            id: 320481,
            pid: 320400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '溧阳市',
            children: []
          }
        ]
      },
      {
        id: 320500,
        pid: 320000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '苏州市',
        children: [
          {
            id: 320505,
            pid: 320500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '虎丘区',
            children: []
          },
          {
            id: 320506,
            pid: 320500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '吴中区',
            children: []
          },
          {
            id: 320507,
            pid: 320500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '相城区',
            children: []
          },
          {
            id: 320508,
            pid: 320500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '姑苏区',
            children: []
          },
          {
            id: 320509,
            pid: 320500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '吴江区',
            children: []
          },
          {
            id: 320571,
            pid: 320500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '苏州工业园区',
            children: []
          },
          {
            id: 320581,
            pid: 320500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '常熟市',
            children: []
          },
          {
            id: 320582,
            pid: 320500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '张家港市',
            children: []
          },
          {
            id: 320583,
            pid: 320500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '昆山市',
            children: []
          },
          {
            id: 320585,
            pid: 320500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '太仓市',
            children: []
          }
        ]
      },
      {
        id: 320600,
        pid: 320000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '南通市',
        children: [
          {
            id: 320602,
            pid: 320600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '崇川区',
            children: []
          },
          {
            id: 320611,
            pid: 320600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '港闸区',
            children: []
          },
          {
            id: 320612,
            pid: 320600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '通州区',
            children: []
          },
          {
            id: 320623,
            pid: 320600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '如东县',
            children: []
          },
          {
            id: 320671,
            pid: 320600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南通经济技术开发区',
            children: []
          },
          {
            id: 320681,
            pid: 320600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '启东市',
            children: []
          },
          {
            id: 320682,
            pid: 320600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '如皋市',
            children: []
          },
          {
            id: 320684,
            pid: 320600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '海门市',
            children: []
          },
          {
            id: 320685,
            pid: 320600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '海安市',
            children: []
          }
        ]
      },
      {
        id: 320700,
        pid: 320000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '连云港市',
        children: [
          {
            id: 320703,
            pid: 320700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '连云区',
            children: []
          },
          {
            id: 320706,
            pid: 320700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '海州区',
            children: []
          },
          {
            id: 320707,
            pid: 320700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '赣榆区',
            children: []
          },
          {
            id: 320722,
            pid: 320700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东海县',
            children: []
          },
          {
            id: 320723,
            pid: 320700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '灌云县',
            children: []
          },
          {
            id: 320724,
            pid: 320700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '灌南县',
            children: []
          },
          {
            id: 320771,
            pid: 320700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '连云港经济技术开发区',
            children: []
          },
          {
            id: 320772,
            pid: 320700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '连云港高新技术产业开发区',
            children: []
          }
        ]
      },
      {
        id: 320800,
        pid: 320000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '淮安市',
        children: [
          {
            id: 320803,
            pid: 320800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '淮安区',
            children: []
          },
          {
            id: 320804,
            pid: 320800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '淮阴区',
            children: []
          },
          {
            id: 320812,
            pid: 320800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '清江浦区',
            children: []
          },
          {
            id: 320813,
            pid: 320800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '洪泽区',
            children: []
          },
          {
            id: 320826,
            pid: 320800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '涟水县',
            children: []
          },
          {
            id: 320830,
            pid: 320800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '盱眙县',
            children: []
          },
          {
            id: 320831,
            pid: 320800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '金湖县',
            children: []
          },
          {
            id: 320871,
            pid: 320800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '淮安经济技术开发区',
            children: []
          }
        ]
      },
      {
        id: 320900,
        pid: 320000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '盐城市',
        children: [
          {
            id: 320902,
            pid: 320900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '亭湖区',
            children: []
          },
          {
            id: 320903,
            pid: 320900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '盐都区',
            children: []
          },
          {
            id: 320904,
            pid: 320900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大丰区',
            children: []
          },
          {
            id: 320921,
            pid: 320900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '响水县',
            children: []
          },
          {
            id: 320922,
            pid: 320900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '滨海县',
            children: []
          },
          {
            id: 320923,
            pid: 320900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '阜宁县',
            children: []
          },
          {
            id: 320924,
            pid: 320900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '射阳县',
            children: []
          },
          {
            id: 320925,
            pid: 320900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '建湖县',
            children: []
          },
          {
            id: 320971,
            pid: 320900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '盐城经济技术开发区',
            children: []
          },
          {
            id: 320981,
            pid: 320900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东台市',
            children: []
          }
        ]
      },
      {
        id: 321000,
        pid: 320000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '扬州市',
        children: [
          {
            id: 321002,
            pid: 321000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '广陵区',
            children: []
          },
          {
            id: 321003,
            pid: 321000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '邗江区',
            children: []
          },
          {
            id: 321012,
            pid: 321000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '江都区',
            children: []
          },
          {
            id: 321023,
            pid: 321000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宝应县',
            children: []
          },
          {
            id: 321071,
            pid: 321000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '扬州经济技术开发区',
            children: []
          },
          {
            id: 321081,
            pid: 321000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '仪征市',
            children: []
          },
          {
            id: 321084,
            pid: 321000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '高邮市',
            children: []
          }
        ]
      },
      {
        id: 321100,
        pid: 320000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '镇江市',
        children: [
          {
            id: 321102,
            pid: 321100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '京口区',
            children: []
          },
          {
            id: 321111,
            pid: 321100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '润州区',
            children: []
          },
          {
            id: 321112,
            pid: 321100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '丹徒区',
            children: []
          },
          {
            id: 321171,
            pid: 321100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '镇江新区',
            children: []
          },
          {
            id: 321181,
            pid: 321100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '丹阳市',
            children: []
          },
          {
            id: 321182,
            pid: 321100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '扬中市',
            children: []
          },
          {
            id: 321183,
            pid: 321100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '句容市',
            children: []
          }
        ]
      },
      {
        id: 321200,
        pid: 320000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '泰州市',
        children: [
          {
            id: 321202,
            pid: 321200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '海陵区',
            children: []
          },
          {
            id: 321203,
            pid: 321200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '高港区',
            children: []
          },
          {
            id: 321204,
            pid: 321200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '姜堰区',
            children: []
          },
          {
            id: 321271,
            pid: 321200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '泰州医药高新技术产业开发区',
            children: []
          },
          {
            id: 321281,
            pid: 321200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '兴化市',
            children: []
          },
          {
            id: 321282,
            pid: 321200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '靖江市',
            children: []
          },
          {
            id: 321283,
            pid: 321200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '泰兴市',
            children: []
          }
        ]
      },
      {
        id: 321300,
        pid: 320000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '宿迁市',
        children: [
          {
            id: 321302,
            pid: 321300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宿城区',
            children: []
          },
          {
            id: 321311,
            pid: 321300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宿豫区',
            children: []
          },
          {
            id: 321322,
            pid: 321300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '沭阳县',
            children: []
          },
          {
            id: 321323,
            pid: 321300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '泗阳县',
            children: []
          },
          {
            id: 321324,
            pid: 321300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '泗洪县',
            children: []
          },
          {
            id: 321371,
            pid: 321300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宿迁经济技术开发区',
            children: []
          }
        ]
      }
    ]
  },
  {
    id: 330000,
    pid: 0,
    level: 1,
    createdAt: null,
    updatedAt: null,
    deletedAt: null,
    isValid: 1,
    label: '浙江省',
    children: [
      {
        id: 330100,
        pid: 330000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '杭州市',
        children: [
          {
            id: 330102,
            pid: 330100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '上城区',
            children: []
          },
          {
            id: 330103,
            pid: 330100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '下城区',
            children: []
          },
          {
            id: 330104,
            pid: 330100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '江干区',
            children: []
          },
          {
            id: 330105,
            pid: 330100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '拱墅区',
            children: []
          },
          {
            id: 330106,
            pid: 330100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '西湖区',
            children: []
          },
          {
            id: 330108,
            pid: 330100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '滨江区',
            children: []
          },
          {
            id: 330109,
            pid: 330100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '萧山区',
            children: []
          },
          {
            id: 330110,
            pid: 330100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '余杭区',
            children: []
          },
          {
            id: 330111,
            pid: 330100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '富阳区',
            children: []
          },
          {
            id: 330112,
            pid: 330100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '临安区',
            children: []
          },
          {
            id: 330122,
            pid: 330100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '桐庐县',
            children: []
          },
          {
            id: 330127,
            pid: 330100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '淳安县',
            children: []
          },
          {
            id: 330182,
            pid: 330100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '建德市',
            children: []
          }
        ]
      },
      {
        id: 330200,
        pid: 330000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '宁波市',
        children: [
          {
            id: 330203,
            pid: 330200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '海曙区',
            children: []
          },
          {
            id: 330205,
            pid: 330200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '江北区',
            children: []
          },
          {
            id: 330206,
            pid: 330200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '北仑区',
            children: []
          },
          {
            id: 330211,
            pid: 330200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '镇海区',
            children: []
          },
          {
            id: 330212,
            pid: 330200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '鄞州区',
            children: []
          },
          {
            id: 330213,
            pid: 330200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '奉化区',
            children: []
          },
          {
            id: 330225,
            pid: 330200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '象山县',
            children: []
          },
          {
            id: 330226,
            pid: 330200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宁海县',
            children: []
          },
          {
            id: 330281,
            pid: 330200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '余姚市',
            children: []
          },
          {
            id: 330282,
            pid: 330200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '慈溪市',
            children: []
          }
        ]
      },
      {
        id: 330300,
        pid: 330000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '温州市',
        children: [
          {
            id: 330302,
            pid: 330300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '鹿城区',
            children: []
          },
          {
            id: 330303,
            pid: 330300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '龙湾区',
            children: []
          },
          {
            id: 330304,
            pid: 330300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '瓯海区',
            children: []
          },
          {
            id: 330305,
            pid: 330300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '洞头区',
            children: []
          },
          {
            id: 330324,
            pid: 330300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '永嘉县',
            children: []
          },
          {
            id: 330326,
            pid: 330300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '平阳县',
            children: []
          },
          {
            id: 330327,
            pid: 330300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '苍南县',
            children: []
          },
          {
            id: 330328,
            pid: 330300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '文成县',
            children: []
          },
          {
            id: 330329,
            pid: 330300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '泰顺县',
            children: []
          },
          {
            id: 330371,
            pid: 330300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '温州经济技术开发区',
            children: []
          },
          {
            id: 330381,
            pid: 330300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '瑞安市',
            children: []
          },
          {
            id: 330382,
            pid: 330300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '乐清市',
            children: []
          },
          {
            id: 330383,
            pid: 330300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '龙港市',
            children: []
          }
        ]
      },
      {
        id: 330400,
        pid: 330000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '嘉兴市',
        children: [
          {
            id: 330402,
            pid: 330400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南湖区',
            children: []
          },
          {
            id: 330411,
            pid: 330400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '秀洲区',
            children: []
          },
          {
            id: 330421,
            pid: 330400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '嘉善县',
            children: []
          },
          {
            id: 330424,
            pid: 330400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '海盐县',
            children: []
          },
          {
            id: 330481,
            pid: 330400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '海宁市',
            children: []
          },
          {
            id: 330482,
            pid: 330400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '平湖市',
            children: []
          },
          {
            id: 330483,
            pid: 330400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '桐乡市',
            children: []
          }
        ]
      },
      {
        id: 330500,
        pid: 330000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '湖州市',
        children: [
          {
            id: 330502,
            pid: 330500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '吴兴区',
            children: []
          },
          {
            id: 330503,
            pid: 330500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南浔区',
            children: []
          },
          {
            id: 330521,
            pid: 330500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '德清县',
            children: []
          },
          {
            id: 330522,
            pid: 330500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '长兴县',
            children: []
          },
          {
            id: 330523,
            pid: 330500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '安吉县',
            children: []
          }
        ]
      },
      {
        id: 330600,
        pid: 330000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '绍兴市',
        children: [
          {
            id: 330602,
            pid: 330600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '越城区',
            children: []
          },
          {
            id: 330603,
            pid: 330600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '柯桥区',
            children: []
          },
          {
            id: 330604,
            pid: 330600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '上虞区',
            children: []
          },
          {
            id: 330624,
            pid: 330600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新昌县',
            children: []
          },
          {
            id: 330681,
            pid: 330600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '诸暨市',
            children: []
          },
          {
            id: 330683,
            pid: 330600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '嵊州市',
            children: []
          }
        ]
      },
      {
        id: 330700,
        pid: 330000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '金华市',
        children: [
          {
            id: 330702,
            pid: 330700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '婺城区',
            children: []
          },
          {
            id: 330703,
            pid: 330700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '金东区',
            children: []
          },
          {
            id: 330723,
            pid: 330700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '武义县',
            children: []
          },
          {
            id: 330726,
            pid: 330700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '浦江县',
            children: []
          },
          {
            id: 330727,
            pid: 330700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '磐安县',
            children: []
          },
          {
            id: 330781,
            pid: 330700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '兰溪市',
            children: []
          },
          {
            id: 330782,
            pid: 330700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '义乌市',
            children: []
          },
          {
            id: 330783,
            pid: 330700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东阳市',
            children: []
          },
          {
            id: 330784,
            pid: 330700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '永康市',
            children: []
          }
        ]
      },
      {
        id: 330800,
        pid: 330000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '衢州市',
        children: [
          {
            id: 330802,
            pid: 330800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '柯城区',
            children: []
          },
          {
            id: 330803,
            pid: 330800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '衢江区',
            children: []
          },
          {
            id: 330822,
            pid: 330800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '常山县',
            children: []
          },
          {
            id: 330824,
            pid: 330800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '开化县',
            children: []
          },
          {
            id: 330825,
            pid: 330800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '龙游县',
            children: []
          },
          {
            id: 330881,
            pid: 330800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '江山市',
            children: []
          }
        ]
      },
      {
        id: 330900,
        pid: 330000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '舟山市',
        children: [
          {
            id: 330902,
            pid: 330900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '定海区',
            children: []
          },
          {
            id: 330903,
            pid: 330900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '普陀区',
            children: []
          },
          {
            id: 330921,
            pid: 330900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '岱山县',
            children: []
          },
          {
            id: 330922,
            pid: 330900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '嵊泗县',
            children: []
          }
        ]
      },
      {
        id: 331000,
        pid: 330000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '台州市',
        children: [
          {
            id: 331002,
            pid: 331000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '椒江区',
            children: []
          },
          {
            id: 331003,
            pid: 331000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '黄岩区',
            children: []
          },
          {
            id: 331004,
            pid: 331000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '路桥区',
            children: []
          },
          {
            id: 331022,
            pid: 331000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '三门县',
            children: []
          },
          {
            id: 331023,
            pid: 331000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '天台县',
            children: []
          },
          {
            id: 331024,
            pid: 331000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '仙居县',
            children: []
          },
          {
            id: 331081,
            pid: 331000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '温岭市',
            children: []
          },
          {
            id: 331082,
            pid: 331000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '临海市',
            children: []
          },
          {
            id: 331083,
            pid: 331000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '玉环市',
            children: []
          }
        ]
      },
      {
        id: 331100,
        pid: 330000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '丽水市',
        children: [
          {
            id: 331102,
            pid: 331100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '莲都区',
            children: []
          },
          {
            id: 331121,
            pid: 331100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '青田县',
            children: []
          },
          {
            id: 331122,
            pid: 331100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '缙云县',
            children: []
          },
          {
            id: 331123,
            pid: 331100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '遂昌县',
            children: []
          },
          {
            id: 331124,
            pid: 331100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '松阳县',
            children: []
          },
          {
            id: 331125,
            pid: 331100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '云和县',
            children: []
          },
          {
            id: 331126,
            pid: 331100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '庆元县',
            children: []
          },
          {
            id: 331127,
            pid: 331100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '景宁畲族自治县',
            children: []
          },
          {
            id: 331181,
            pid: 331100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '龙泉市',
            children: []
          }
        ]
      }
    ]
  },
  {
    id: 340000,
    pid: 0,
    level: 1,
    createdAt: null,
    updatedAt: null,
    deletedAt: null,
    isValid: 1,
    label: '安徽省',
    children: [
      {
        id: 340100,
        pid: 340000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '合肥市',
        children: [
          {
            id: 340102,
            pid: 340100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '瑶海区',
            children: []
          },
          {
            id: 340103,
            pid: 340100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '庐阳区',
            children: []
          },
          {
            id: 340104,
            pid: 340100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '蜀山区',
            children: []
          },
          {
            id: 340111,
            pid: 340100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '包河区',
            children: []
          },
          {
            id: 340121,
            pid: 340100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '长丰县',
            children: []
          },
          {
            id: 340122,
            pid: 340100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '肥东县',
            children: []
          },
          {
            id: 340123,
            pid: 340100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '肥西县',
            children: []
          },
          {
            id: 340124,
            pid: 340100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '庐江县',
            children: []
          },
          {
            id: 340171,
            pid: 340100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '合肥高新技术产业开发区',
            children: []
          },
          {
            id: 340172,
            pid: 340100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '合肥经济技术开发区',
            children: []
          },
          {
            id: 340173,
            pid: 340100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '合肥新站高新技术产业开发区',
            children: []
          },
          {
            id: 340181,
            pid: 340100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '巢湖市',
            children: []
          }
        ]
      },
      {
        id: 340200,
        pid: 340000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '芜湖市',
        children: [
          {
            id: 340202,
            pid: 340200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '镜湖区',
            children: []
          },
          {
            id: 340203,
            pid: 340200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '弋江区',
            children: []
          },
          {
            id: 340207,
            pid: 340200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '鸠江区',
            children: []
          },
          {
            id: 340208,
            pid: 340200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '三山区',
            children: []
          },
          {
            id: 340221,
            pid: 340200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '芜湖县',
            children: []
          },
          {
            id: 340222,
            pid: 340200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '繁昌县',
            children: []
          },
          {
            id: 340223,
            pid: 340200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南陵县',
            children: []
          },
          {
            id: 340271,
            pid: 340200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '芜湖经济技术开发区',
            children: []
          },
          {
            id: 340272,
            pid: 340200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '安徽芜湖长江大桥经济开发区',
            children: []
          },
          {
            id: 340281,
            pid: 340200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '无为市',
            children: []
          }
        ]
      },
      {
        id: 340300,
        pid: 340000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '蚌埠市',
        children: [
          {
            id: 340302,
            pid: 340300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '龙子湖区',
            children: []
          },
          {
            id: 340303,
            pid: 340300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '蚌山区',
            children: []
          },
          {
            id: 340304,
            pid: 340300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '禹会区',
            children: []
          },
          {
            id: 340311,
            pid: 340300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '淮上区',
            children: []
          },
          {
            id: 340321,
            pid: 340300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '怀远县',
            children: []
          },
          {
            id: 340322,
            pid: 340300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '五河县',
            children: []
          },
          {
            id: 340323,
            pid: 340300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '固镇县',
            children: []
          },
          {
            id: 340371,
            pid: 340300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '蚌埠市高新技术开发区',
            children: []
          },
          {
            id: 340372,
            pid: 340300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '蚌埠市经济开发区',
            children: []
          }
        ]
      },
      {
        id: 340400,
        pid: 340000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '淮南市',
        children: [
          {
            id: 340402,
            pid: 340400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大通区',
            children: []
          },
          {
            id: 340403,
            pid: 340400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '田家庵区',
            children: []
          },
          {
            id: 340404,
            pid: 340400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '谢家集区',
            children: []
          },
          {
            id: 340405,
            pid: 340400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '八公山区',
            children: []
          },
          {
            id: 340406,
            pid: 340400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '潘集区',
            children: []
          },
          {
            id: 340421,
            pid: 340400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '凤台县',
            children: []
          },
          {
            id: 340422,
            pid: 340400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '寿县',
            children: []
          }
        ]
      },
      {
        id: 340500,
        pid: 340000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '马鞍山市',
        children: [
          {
            id: 340503,
            pid: 340500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '花山区',
            children: []
          },
          {
            id: 340504,
            pid: 340500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '雨山区',
            children: []
          },
          {
            id: 340506,
            pid: 340500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '博望区',
            children: []
          },
          {
            id: 340521,
            pid: 340500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '当涂县',
            children: []
          },
          {
            id: 340522,
            pid: 340500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '含山县',
            children: []
          },
          {
            id: 340523,
            pid: 340500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '和县',
            children: []
          }
        ]
      },
      {
        id: 340600,
        pid: 340000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '淮北市',
        children: [
          {
            id: 340602,
            pid: 340600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '杜集区',
            children: []
          },
          {
            id: 340603,
            pid: 340600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '相山区',
            children: []
          },
          {
            id: 340604,
            pid: 340600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '烈山区',
            children: []
          },
          {
            id: 340621,
            pid: 340600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '濉溪县',
            children: []
          }
        ]
      },
      {
        id: 340700,
        pid: 340000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '铜陵市',
        children: [
          {
            id: 340705,
            pid: 340700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '铜官区',
            children: []
          },
          {
            id: 340706,
            pid: 340700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '义安区',
            children: []
          },
          {
            id: 340711,
            pid: 340700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '郊区',
            children: []
          },
          {
            id: 340722,
            pid: 340700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '枞阳县',
            children: []
          }
        ]
      },
      {
        id: 340800,
        pid: 340000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '安庆市',
        children: [
          {
            id: 340802,
            pid: 340800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '迎江区',
            children: []
          },
          {
            id: 340803,
            pid: 340800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大观区',
            children: []
          },
          {
            id: 340811,
            pid: 340800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宜秀区',
            children: []
          },
          {
            id: 340822,
            pid: 340800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '怀宁县',
            children: []
          },
          {
            id: 340825,
            pid: 340800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '太湖县',
            children: []
          },
          {
            id: 340826,
            pid: 340800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宿松县',
            children: []
          },
          {
            id: 340827,
            pid: 340800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '望江县',
            children: []
          },
          {
            id: 340828,
            pid: 340800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '岳西县',
            children: []
          },
          {
            id: 340871,
            pid: 340800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '安徽安庆经济开发区',
            children: []
          },
          {
            id: 340881,
            pid: 340800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '桐城市',
            children: []
          },
          {
            id: 340882,
            pid: 340800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '潜山市',
            children: []
          }
        ]
      },
      {
        id: 341000,
        pid: 340000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '黄山市',
        children: [
          {
            id: 341002,
            pid: 341000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '屯溪区',
            children: []
          },
          {
            id: 341003,
            pid: 341000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '黄山区',
            children: []
          },
          {
            id: 341004,
            pid: 341000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '徽州区',
            children: []
          },
          {
            id: 341021,
            pid: 341000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '歙县',
            children: []
          },
          {
            id: 341022,
            pid: 341000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '休宁县',
            children: []
          },
          {
            id: 341023,
            pid: 341000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '黟县',
            children: []
          },
          {
            id: 341024,
            pid: 341000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '祁门县',
            children: []
          }
        ]
      },
      {
        id: 341100,
        pid: 340000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '滁州市',
        children: [
          {
            id: 341102,
            pid: 341100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '琅琊区',
            children: []
          },
          {
            id: 341103,
            pid: 341100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南谯区',
            children: []
          },
          {
            id: 341122,
            pid: 341100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '来安县',
            children: []
          },
          {
            id: 341124,
            pid: 341100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '全椒县',
            children: []
          },
          {
            id: 341125,
            pid: 341100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '定远县',
            children: []
          },
          {
            id: 341126,
            pid: 341100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '凤阳县',
            children: []
          },
          {
            id: 341171,
            pid: 341100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '苏滁现代产业园',
            children: []
          },
          {
            id: 341172,
            pid: 341100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '滁州经济技术开发区',
            children: []
          },
          {
            id: 341181,
            pid: 341100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '天长市',
            children: []
          },
          {
            id: 341182,
            pid: 341100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '明光市',
            children: []
          }
        ]
      },
      {
        id: 341200,
        pid: 340000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '阜阳市',
        children: [
          {
            id: 341202,
            pid: 341200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '颍州区',
            children: []
          },
          {
            id: 341203,
            pid: 341200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '颍东区',
            children: []
          },
          {
            id: 341204,
            pid: 341200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '颍泉区',
            children: []
          },
          {
            id: 341221,
            pid: 341200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '临泉县',
            children: []
          },
          {
            id: 341222,
            pid: 341200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '太和县',
            children: []
          },
          {
            id: 341225,
            pid: 341200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '阜南县',
            children: []
          },
          {
            id: 341226,
            pid: 341200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '颍上县',
            children: []
          },
          {
            id: 341271,
            pid: 341200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '阜阳合肥现代产业园区',
            children: []
          },
          {
            id: 341272,
            pid: 341200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '阜阳经济技术开发区',
            children: []
          },
          {
            id: 341282,
            pid: 341200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '界首市',
            children: []
          }
        ]
      },
      {
        id: 341300,
        pid: 340000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '宿州市',
        children: [
          {
            id: 341302,
            pid: 341300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '埇桥区',
            children: []
          },
          {
            id: 341321,
            pid: 341300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '砀山县',
            children: []
          },
          {
            id: 341322,
            pid: 341300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '萧县',
            children: []
          },
          {
            id: 341323,
            pid: 341300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '灵璧县',
            children: []
          },
          {
            id: 341324,
            pid: 341300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '泗县',
            children: []
          },
          {
            id: 341371,
            pid: 341300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宿州马鞍山现代产业园区',
            children: []
          },
          {
            id: 341372,
            pid: 341300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宿州经济技术开发区',
            children: []
          }
        ]
      },
      {
        id: 341500,
        pid: 340000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '六安市',
        children: [
          {
            id: 341502,
            pid: 341500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '金安区',
            children: []
          },
          {
            id: 341503,
            pid: 341500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '裕安区',
            children: []
          },
          {
            id: 341504,
            pid: 341500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '叶集区',
            children: []
          },
          {
            id: 341522,
            pid: 341500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '霍邱县',
            children: []
          },
          {
            id: 341523,
            pid: 341500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '舒城县',
            children: []
          },
          {
            id: 341524,
            pid: 341500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '金寨县',
            children: []
          },
          {
            id: 341525,
            pid: 341500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '霍山县',
            children: []
          }
        ]
      },
      {
        id: 341600,
        pid: 340000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '亳州市',
        children: [
          {
            id: 341602,
            pid: 341600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '谯城区',
            children: []
          },
          {
            id: 341621,
            pid: 341600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '涡阳县',
            children: []
          },
          {
            id: 341622,
            pid: 341600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '蒙城县',
            children: []
          },
          {
            id: 341623,
            pid: 341600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '利辛县',
            children: []
          }
        ]
      },
      {
        id: 341700,
        pid: 340000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '池州市',
        children: [
          {
            id: 341702,
            pid: 341700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '贵池区',
            children: []
          },
          {
            id: 341721,
            pid: 341700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东至县',
            children: []
          },
          {
            id: 341722,
            pid: 341700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '石台县',
            children: []
          },
          {
            id: 341723,
            pid: 341700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '青阳县',
            children: []
          }
        ]
      },
      {
        id: 341800,
        pid: 340000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '宣城市',
        children: [
          {
            id: 341802,
            pid: 341800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宣州区',
            children: []
          },
          {
            id: 341821,
            pid: 341800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '郎溪县',
            children: []
          },
          {
            id: 341823,
            pid: 341800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '泾县',
            children: []
          },
          {
            id: 341824,
            pid: 341800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '绩溪县',
            children: []
          },
          {
            id: 341825,
            pid: 341800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '旌德县',
            children: []
          },
          {
            id: 341871,
            pid: 341800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宣城市经济开发区',
            children: []
          },
          {
            id: 341881,
            pid: 341800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宁国市',
            children: []
          },
          {
            id: 341882,
            pid: 341800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '广德市',
            children: []
          }
        ]
      }
    ]
  },
  {
    id: 350000,
    pid: 0,
    level: 1,
    createdAt: null,
    updatedAt: null,
    deletedAt: null,
    isValid: 1,
    label: '福建省',
    children: [
      {
        id: 350100,
        pid: 350000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '福州市',
        children: [
          {
            id: 350102,
            pid: 350100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '鼓楼区',
            children: []
          },
          {
            id: 350103,
            pid: 350100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '台江区',
            children: []
          },
          {
            id: 350104,
            pid: 350100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '仓山区',
            children: []
          },
          {
            id: 350105,
            pid: 350100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '马尾区',
            children: []
          },
          {
            id: 350111,
            pid: 350100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '晋安区',
            children: []
          },
          {
            id: 350112,
            pid: 350100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '长乐区',
            children: []
          },
          {
            id: 350121,
            pid: 350100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '闽侯县',
            children: []
          },
          {
            id: 350122,
            pid: 350100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '连江县',
            children: []
          },
          {
            id: 350123,
            pid: 350100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '罗源县',
            children: []
          },
          {
            id: 350124,
            pid: 350100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '闽清县',
            children: []
          },
          {
            id: 350125,
            pid: 350100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '永泰县',
            children: []
          },
          {
            id: 350128,
            pid: 350100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '平潭县',
            children: []
          },
          {
            id: 350181,
            pid: 350100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '福清市',
            children: []
          }
        ]
      },
      {
        id: 350200,
        pid: 350000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '厦门市',
        children: [
          {
            id: 350203,
            pid: 350200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '思明区',
            children: []
          },
          {
            id: 350205,
            pid: 350200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '海沧区',
            children: []
          },
          {
            id: 350206,
            pid: 350200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '湖里区',
            children: []
          },
          {
            id: 350211,
            pid: 350200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '集美区',
            children: []
          },
          {
            id: 350212,
            pid: 350200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '同安区',
            children: []
          },
          {
            id: 350213,
            pid: 350200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '翔安区',
            children: []
          }
        ]
      },
      {
        id: 350300,
        pid: 350000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '莆田市',
        children: [
          {
            id: 350302,
            pid: 350300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '城厢区',
            children: []
          },
          {
            id: 350303,
            pid: 350300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '涵江区',
            children: []
          },
          {
            id: 350304,
            pid: 350300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '荔城区',
            children: []
          },
          {
            id: 350305,
            pid: 350300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '秀屿区',
            children: []
          },
          {
            id: 350322,
            pid: 350300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '仙游县',
            children: []
          }
        ]
      },
      {
        id: 350400,
        pid: 350000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '三明市',
        children: [
          {
            id: 350402,
            pid: 350400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '梅列区',
            children: []
          },
          {
            id: 350403,
            pid: 350400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '三元区',
            children: []
          },
          {
            id: 350421,
            pid: 350400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '明溪县',
            children: []
          },
          {
            id: 350423,
            pid: 350400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '清流县',
            children: []
          },
          {
            id: 350424,
            pid: 350400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宁化县',
            children: []
          },
          {
            id: 350425,
            pid: 350400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大田县',
            children: []
          },
          {
            id: 350426,
            pid: 350400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '尤溪县',
            children: []
          },
          {
            id: 350427,
            pid: 350400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '沙县',
            children: []
          },
          {
            id: 350428,
            pid: 350400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '将乐县',
            children: []
          },
          {
            id: 350429,
            pid: 350400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '泰宁县',
            children: []
          },
          {
            id: 350430,
            pid: 350400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '建宁县',
            children: []
          },
          {
            id: 350481,
            pid: 350400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '永安市',
            children: []
          }
        ]
      },
      {
        id: 350500,
        pid: 350000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '泉州市',
        children: [
          {
            id: 350502,
            pid: 350500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '鲤城区',
            children: []
          },
          {
            id: 350503,
            pid: 350500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '丰泽区',
            children: []
          },
          {
            id: 350504,
            pid: 350500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '洛江区',
            children: []
          },
          {
            id: 350505,
            pid: 350500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '泉港区',
            children: []
          },
          {
            id: 350521,
            pid: 350500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '惠安县',
            children: []
          },
          {
            id: 350524,
            pid: 350500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '安溪县',
            children: []
          },
          {
            id: 350525,
            pid: 350500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '永春县',
            children: []
          },
          {
            id: 350526,
            pid: 350500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '德化县',
            children: []
          },
          {
            id: 350581,
            pid: 350500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '石狮市',
            children: []
          },
          {
            id: 350582,
            pid: 350500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '晋江市',
            children: []
          },
          {
            id: 350583,
            pid: 350500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南安市',
            children: []
          }
        ]
      },
      {
        id: 350600,
        pid: 350000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '漳州市',
        children: [
          {
            id: 350602,
            pid: 350600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '芗城区',
            children: []
          },
          {
            id: 350603,
            pid: 350600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '龙文区',
            children: []
          },
          {
            id: 350622,
            pid: 350600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '云霄县',
            children: []
          },
          {
            id: 350623,
            pid: 350600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '漳浦县',
            children: []
          },
          {
            id: 350624,
            pid: 350600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '诏安县',
            children: []
          },
          {
            id: 350625,
            pid: 350600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '长泰县',
            children: []
          },
          {
            id: 350626,
            pid: 350600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东山县',
            children: []
          },
          {
            id: 350627,
            pid: 350600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南靖县',
            children: []
          },
          {
            id: 350628,
            pid: 350600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '平和县',
            children: []
          },
          {
            id: 350629,
            pid: 350600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '华安县',
            children: []
          },
          {
            id: 350681,
            pid: 350600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '龙海市',
            children: []
          }
        ]
      },
      {
        id: 350700,
        pid: 350000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '南平市',
        children: [
          {
            id: 350702,
            pid: 350700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '延平区',
            children: []
          },
          {
            id: 350703,
            pid: 350700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '建阳区',
            children: []
          },
          {
            id: 350721,
            pid: 350700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '顺昌县',
            children: []
          },
          {
            id: 350722,
            pid: 350700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '浦城县',
            children: []
          },
          {
            id: 350723,
            pid: 350700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '光泽县',
            children: []
          },
          {
            id: 350724,
            pid: 350700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '松溪县',
            children: []
          },
          {
            id: 350725,
            pid: 350700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '政和县',
            children: []
          },
          {
            id: 350781,
            pid: 350700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '邵武市',
            children: []
          },
          {
            id: 350782,
            pid: 350700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '武夷山市',
            children: []
          },
          {
            id: 350783,
            pid: 350700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '建瓯市',
            children: []
          }
        ]
      },
      {
        id: 350800,
        pid: 350000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '龙岩市',
        children: [
          {
            id: 350802,
            pid: 350800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新罗区',
            children: []
          },
          {
            id: 350803,
            pid: 350800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '永定区',
            children: []
          },
          {
            id: 350821,
            pid: 350800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '长汀县',
            children: []
          },
          {
            id: 350823,
            pid: 350800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '上杭县',
            children: []
          },
          {
            id: 350824,
            pid: 350800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '武平县',
            children: []
          },
          {
            id: 350825,
            pid: 350800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '连城县',
            children: []
          },
          {
            id: 350881,
            pid: 350800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '漳平市',
            children: []
          }
        ]
      },
      {
        id: 350900,
        pid: 350000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '宁德市',
        children: [
          {
            id: 350902,
            pid: 350900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '蕉城区',
            children: []
          },
          {
            id: 350921,
            pid: 350900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '霞浦县',
            children: []
          },
          {
            id: 350922,
            pid: 350900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '古田县',
            children: []
          },
          {
            id: 350923,
            pid: 350900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '屏南县',
            children: []
          },
          {
            id: 350924,
            pid: 350900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '寿宁县',
            children: []
          },
          {
            id: 350925,
            pid: 350900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '周宁县',
            children: []
          },
          {
            id: 350926,
            pid: 350900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '柘荣县',
            children: []
          },
          {
            id: 350981,
            pid: 350900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '福安市',
            children: []
          },
          {
            id: 350982,
            pid: 350900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '福鼎市',
            children: []
          }
        ]
      }
    ]
  },
  {
    id: 360000,
    pid: 0,
    level: 1,
    createdAt: null,
    updatedAt: null,
    deletedAt: null,
    isValid: 1,
    label: '江西省',
    children: [
      {
        id: 360100,
        pid: 360000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '南昌市',
        children: [
          {
            id: 360102,
            pid: 360100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东湖区',
            children: []
          },
          {
            id: 360103,
            pid: 360100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '西湖区',
            children: []
          },
          {
            id: 360104,
            pid: 360100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '青云谱区',
            children: []
          },
          {
            id: 360111,
            pid: 360100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '青山湖区',
            children: []
          },
          {
            id: 360112,
            pid: 360100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新建区',
            children: []
          },
          {
            id: 360113,
            pid: 360100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '红谷滩区',
            children: []
          },
          {
            id: 360121,
            pid: 360100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南昌县',
            children: []
          },
          {
            id: 360123,
            pid: 360100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '安义县',
            children: []
          },
          {
            id: 360124,
            pid: 360100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '进贤县',
            children: []
          }
        ]
      },
      {
        id: 360200,
        pid: 360000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '景德镇市',
        children: [
          {
            id: 360202,
            pid: 360200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '昌江区',
            children: []
          },
          {
            id: 360203,
            pid: 360200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '珠山区',
            children: []
          },
          {
            id: 360222,
            pid: 360200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '浮梁县',
            children: []
          },
          {
            id: 360281,
            pid: 360200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '乐平市',
            children: []
          }
        ]
      },
      {
        id: 360300,
        pid: 360000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '萍乡市',
        children: [
          {
            id: 360302,
            pid: 360300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '安源区',
            children: []
          },
          {
            id: 360313,
            pid: 360300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '湘东区',
            children: []
          },
          {
            id: 360321,
            pid: 360300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '莲花县',
            children: []
          },
          {
            id: 360322,
            pid: 360300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '上栗县',
            children: []
          },
          {
            id: 360323,
            pid: 360300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '芦溪县',
            children: []
          }
        ]
      },
      {
        id: 360400,
        pid: 360000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '九江市',
        children: [
          {
            id: 360402,
            pid: 360400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '濂溪区',
            children: []
          },
          {
            id: 360403,
            pid: 360400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '浔阳区',
            children: []
          },
          {
            id: 360404,
            pid: 360400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '柴桑区',
            children: []
          },
          {
            id: 360423,
            pid: 360400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '武宁县',
            children: []
          },
          {
            id: 360424,
            pid: 360400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '修水县',
            children: []
          },
          {
            id: 360425,
            pid: 360400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '永修县',
            children: []
          },
          {
            id: 360426,
            pid: 360400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '德安县',
            children: []
          },
          {
            id: 360428,
            pid: 360400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '都昌县',
            children: []
          },
          {
            id: 360429,
            pid: 360400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '湖口县',
            children: []
          },
          {
            id: 360430,
            pid: 360400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '彭泽县',
            children: []
          },
          {
            id: 360481,
            pid: 360400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '瑞昌市',
            children: []
          },
          {
            id: 360482,
            pid: 360400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '共青城市',
            children: []
          },
          {
            id: 360483,
            pid: 360400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '庐山市',
            children: []
          }
        ]
      },
      {
        id: 360500,
        pid: 360000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '新余市',
        children: [
          {
            id: 360502,
            pid: 360500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '渝水区',
            children: []
          },
          {
            id: 360521,
            pid: 360500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '分宜县',
            children: []
          }
        ]
      },
      {
        id: 360600,
        pid: 360000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '鹰潭市',
        children: [
          {
            id: 360602,
            pid: 360600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '月湖区',
            children: []
          },
          {
            id: 360603,
            pid: 360600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '余江区',
            children: []
          },
          {
            id: 360681,
            pid: 360600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '贵溪市',
            children: []
          }
        ]
      },
      {
        id: 360700,
        pid: 360000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '赣州市',
        children: [
          {
            id: 360702,
            pid: 360700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '章贡区',
            children: []
          },
          {
            id: 360703,
            pid: 360700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南康区',
            children: []
          },
          {
            id: 360704,
            pid: 360700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '赣县区',
            children: []
          },
          {
            id: 360722,
            pid: 360700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '信丰县',
            children: []
          },
          {
            id: 360723,
            pid: 360700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大余县',
            children: []
          },
          {
            id: 360724,
            pid: 360700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '上犹县',
            children: []
          },
          {
            id: 360725,
            pid: 360700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '崇义县',
            children: []
          },
          {
            id: 360726,
            pid: 360700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '安远县',
            children: []
          },
          {
            id: 360728,
            pid: 360700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '定南县',
            children: []
          },
          {
            id: 360729,
            pid: 360700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '全南县',
            children: []
          },
          {
            id: 360730,
            pid: 360700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宁都县',
            children: []
          },
          {
            id: 360731,
            pid: 360700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '于都县',
            children: []
          },
          {
            id: 360732,
            pid: 360700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '兴国县',
            children: []
          },
          {
            id: 360733,
            pid: 360700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '会昌县',
            children: []
          },
          {
            id: 360734,
            pid: 360700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '寻乌县',
            children: []
          },
          {
            id: 360735,
            pid: 360700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '石城县',
            children: []
          },
          {
            id: 360781,
            pid: 360700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '瑞金市',
            children: []
          },
          {
            id: 360783,
            pid: 360700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '龙南市',
            children: []
          }
        ]
      },
      {
        id: 360800,
        pid: 360000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '吉安市',
        children: [
          {
            id: 360802,
            pid: 360800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '吉州区',
            children: []
          },
          {
            id: 360803,
            pid: 360800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '青原区',
            children: []
          },
          {
            id: 360821,
            pid: 360800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '吉安县',
            children: []
          },
          {
            id: 360822,
            pid: 360800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '吉水县',
            children: []
          },
          {
            id: 360823,
            pid: 360800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '峡江县',
            children: []
          },
          {
            id: 360824,
            pid: 360800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新干县',
            children: []
          },
          {
            id: 360825,
            pid: 360800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '永丰县',
            children: []
          },
          {
            id: 360826,
            pid: 360800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '泰和县',
            children: []
          },
          {
            id: 360827,
            pid: 360800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '遂川县',
            children: []
          },
          {
            id: 360828,
            pid: 360800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '万安县',
            children: []
          },
          {
            id: 360829,
            pid: 360800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '安福县',
            children: []
          },
          {
            id: 360830,
            pid: 360800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '永新县',
            children: []
          },
          {
            id: 360881,
            pid: 360800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '井冈山市',
            children: []
          }
        ]
      },
      {
        id: 360900,
        pid: 360000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '宜春市',
        children: [
          {
            id: 360902,
            pid: 360900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '袁州区',
            children: []
          },
          {
            id: 360921,
            pid: 360900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '奉新县',
            children: []
          },
          {
            id: 360922,
            pid: 360900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '万载县',
            children: []
          },
          {
            id: 360923,
            pid: 360900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '上高县',
            children: []
          },
          {
            id: 360924,
            pid: 360900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宜丰县',
            children: []
          },
          {
            id: 360925,
            pid: 360900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '靖安县',
            children: []
          },
          {
            id: 360926,
            pid: 360900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '铜鼓县',
            children: []
          },
          {
            id: 360981,
            pid: 360900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '丰城市',
            children: []
          },
          {
            id: 360982,
            pid: 360900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '樟树市',
            children: []
          },
          {
            id: 360983,
            pid: 360900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '高安市',
            children: []
          }
        ]
      },
      {
        id: 361000,
        pid: 360000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '抚州市',
        children: [
          {
            id: 361002,
            pid: 361000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '临川区',
            children: []
          },
          {
            id: 361003,
            pid: 361000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东乡区',
            children: []
          },
          {
            id: 361021,
            pid: 361000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南城县',
            children: []
          },
          {
            id: 361022,
            pid: 361000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '黎川县',
            children: []
          },
          {
            id: 361023,
            pid: 361000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南丰县',
            children: []
          },
          {
            id: 361024,
            pid: 361000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '崇仁县',
            children: []
          },
          {
            id: 361025,
            pid: 361000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '乐安县',
            children: []
          },
          {
            id: 361026,
            pid: 361000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宜黄县',
            children: []
          },
          {
            id: 361027,
            pid: 361000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '金溪县',
            children: []
          },
          {
            id: 361028,
            pid: 361000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '资溪县',
            children: []
          },
          {
            id: 361030,
            pid: 361000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '广昌县',
            children: []
          }
        ]
      },
      {
        id: 361100,
        pid: 360000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '上饶市',
        children: [
          {
            id: 361102,
            pid: 361100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '信州区',
            children: []
          },
          {
            id: 361103,
            pid: 361100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '广丰区',
            children: []
          },
          {
            id: 361104,
            pid: 361100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '广信区',
            children: []
          },
          {
            id: 361123,
            pid: 361100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '玉山县',
            children: []
          },
          {
            id: 361124,
            pid: 361100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '铅山县',
            children: []
          },
          {
            id: 361125,
            pid: 361100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '横峰县',
            children: []
          },
          {
            id: 361126,
            pid: 361100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '弋阳县',
            children: []
          },
          {
            id: 361127,
            pid: 361100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '余干县',
            children: []
          },
          {
            id: 361128,
            pid: 361100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '鄱阳县',
            children: []
          },
          {
            id: 361129,
            pid: 361100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '万年县',
            children: []
          },
          {
            id: 361130,
            pid: 361100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '婺源县',
            children: []
          },
          {
            id: 361181,
            pid: 361100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '德兴市',
            children: []
          }
        ]
      }
    ]
  },
  {
    id: 370000,
    pid: 0,
    level: 1,
    createdAt: null,
    updatedAt: null,
    deletedAt: null,
    isValid: 1,
    label: '山东省',
    children: [
      {
        id: 370100,
        pid: 370000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '济南市',
        children: [
          {
            id: 370102,
            pid: 370100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '历下区',
            children: []
          },
          {
            id: 370103,
            pid: 370100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '市中区',
            children: []
          },
          {
            id: 370104,
            pid: 370100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '槐荫区',
            children: []
          },
          {
            id: 370105,
            pid: 370100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '天桥区',
            children: []
          },
          {
            id: 370112,
            pid: 370100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '历城区',
            children: []
          },
          {
            id: 370113,
            pid: 370100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '长清区',
            children: []
          },
          {
            id: 370114,
            pid: 370100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '章丘区',
            children: []
          },
          {
            id: 370115,
            pid: 370100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '济阳区',
            children: []
          },
          {
            id: 370116,
            pid: 370100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '莱芜区',
            children: []
          },
          {
            id: 370117,
            pid: 370100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '钢城区',
            children: []
          },
          {
            id: 370124,
            pid: 370100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '平阴县',
            children: []
          },
          {
            id: 370126,
            pid: 370100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '商河县',
            children: []
          },
          {
            id: 370171,
            pid: 370100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '济南高新技术产业开发区',
            children: []
          }
        ]
      },
      {
        id: 370200,
        pid: 370000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '青岛市',
        children: [
          {
            id: 370202,
            pid: 370200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '市南区',
            children: []
          },
          {
            id: 370203,
            pid: 370200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '市北区',
            children: []
          },
          {
            id: 370211,
            pid: 370200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '黄岛区',
            children: []
          },
          {
            id: 370212,
            pid: 370200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '崂山区',
            children: []
          },
          {
            id: 370213,
            pid: 370200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '李沧区',
            children: []
          },
          {
            id: 370214,
            pid: 370200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '城阳区',
            children: []
          },
          {
            id: 370215,
            pid: 370200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '即墨区',
            children: []
          },
          {
            id: 370271,
            pid: 370200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '青岛高新技术产业开发区',
            children: []
          },
          {
            id: 370281,
            pid: 370200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '胶州市',
            children: []
          },
          {
            id: 370283,
            pid: 370200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '平度市',
            children: []
          },
          {
            id: 370285,
            pid: 370200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '莱西市',
            children: []
          }
        ]
      },
      {
        id: 370300,
        pid: 370000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '淄博市',
        children: [
          {
            id: 370302,
            pid: 370300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '淄川区',
            children: []
          },
          {
            id: 370303,
            pid: 370300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '张店区',
            children: []
          },
          {
            id: 370304,
            pid: 370300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '博山区',
            children: []
          },
          {
            id: 370305,
            pid: 370300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '临淄区',
            children: []
          },
          {
            id: 370306,
            pid: 370300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '周村区',
            children: []
          },
          {
            id: 370321,
            pid: 370300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '桓台县',
            children: []
          },
          {
            id: 370322,
            pid: 370300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '高青县',
            children: []
          },
          {
            id: 370323,
            pid: 370300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '沂源县',
            children: []
          }
        ]
      },
      {
        id: 370400,
        pid: 370000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '枣庄市',
        children: [
          {
            id: 370402,
            pid: 370400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '市中区',
            children: []
          },
          {
            id: 370403,
            pid: 370400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '薛城区',
            children: []
          },
          {
            id: 370404,
            pid: 370400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '峄城区',
            children: []
          },
          {
            id: 370405,
            pid: 370400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '台儿庄区',
            children: []
          },
          {
            id: 370406,
            pid: 370400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '山亭区',
            children: []
          },
          {
            id: 370481,
            pid: 370400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '滕州市',
            children: []
          }
        ]
      },
      {
        id: 370500,
        pid: 370000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '东营市',
        children: [
          {
            id: 370502,
            pid: 370500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东营区',
            children: []
          },
          {
            id: 370503,
            pid: 370500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '河口区',
            children: []
          },
          {
            id: 370505,
            pid: 370500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '垦利区',
            children: []
          },
          {
            id: 370522,
            pid: 370500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '利津县',
            children: []
          },
          {
            id: 370523,
            pid: 370500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '广饶县',
            children: []
          },
          {
            id: 370571,
            pid: 370500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东营经济技术开发区',
            children: []
          },
          {
            id: 370572,
            pid: 370500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东营港经济开发区',
            children: []
          }
        ]
      },
      {
        id: 370600,
        pid: 370000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '烟台市',
        children: [
          {
            id: 370602,
            pid: 370600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '芝罘区',
            children: []
          },
          {
            id: 370611,
            pid: 370600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '福山区',
            children: []
          },
          {
            id: 370612,
            pid: 370600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '牟平区',
            children: []
          },
          {
            id: 370613,
            pid: 370600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '莱山区',
            children: []
          },
          {
            id: 370614,
            pid: 370600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '蓬莱区',
            children: []
          },
          {
            id: 370671,
            pid: 370600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '烟台高新技术产业开发区',
            children: []
          },
          {
            id: 370672,
            pid: 370600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '烟台经济技术开发区',
            children: []
          },
          {
            id: 370681,
            pid: 370600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '龙口市',
            children: []
          },
          {
            id: 370682,
            pid: 370600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '莱阳市',
            children: []
          },
          {
            id: 370683,
            pid: 370600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '莱州市',
            children: []
          },
          {
            id: 370685,
            pid: 370600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '招远市',
            children: []
          },
          {
            id: 370686,
            pid: 370600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '栖霞市',
            children: []
          },
          {
            id: 370687,
            pid: 370600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '海阳市',
            children: []
          }
        ]
      },
      {
        id: 370700,
        pid: 370000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '潍坊市',
        children: [
          {
            id: 370702,
            pid: 370700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '潍城区',
            children: []
          },
          {
            id: 370703,
            pid: 370700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '寒亭区',
            children: []
          },
          {
            id: 370704,
            pid: 370700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '坊子区',
            children: []
          },
          {
            id: 370705,
            pid: 370700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '奎文区',
            children: []
          },
          {
            id: 370724,
            pid: 370700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '临朐县',
            children: []
          },
          {
            id: 370725,
            pid: 370700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '昌乐县',
            children: []
          },
          {
            id: 370772,
            pid: 370700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '潍坊滨海经济技术开发区',
            children: []
          },
          {
            id: 370781,
            pid: 370700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '青州市',
            children: []
          },
          {
            id: 370782,
            pid: 370700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '诸城市',
            children: []
          },
          {
            id: 370783,
            pid: 370700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '寿光市',
            children: []
          },
          {
            id: 370784,
            pid: 370700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '安丘市',
            children: []
          },
          {
            id: 370785,
            pid: 370700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '高密市',
            children: []
          },
          {
            id: 370786,
            pid: 370700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '昌邑市',
            children: []
          }
        ]
      },
      {
        id: 370800,
        pid: 370000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '济宁市',
        children: [
          {
            id: 370811,
            pid: 370800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '任城区',
            children: []
          },
          {
            id: 370812,
            pid: 370800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '兖州区',
            children: []
          },
          {
            id: 370826,
            pid: 370800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '微山县',
            children: []
          },
          {
            id: 370827,
            pid: 370800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '鱼台县',
            children: []
          },
          {
            id: 370828,
            pid: 370800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '金乡县',
            children: []
          },
          {
            id: 370829,
            pid: 370800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '嘉祥县',
            children: []
          },
          {
            id: 370830,
            pid: 370800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '汶上县',
            children: []
          },
          {
            id: 370831,
            pid: 370800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '泗水县',
            children: []
          },
          {
            id: 370832,
            pid: 370800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '梁山县',
            children: []
          },
          {
            id: 370871,
            pid: 370800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '济宁高新技术产业开发区',
            children: []
          },
          {
            id: 370881,
            pid: 370800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '曲阜市',
            children: []
          },
          {
            id: 370883,
            pid: 370800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '邹城市',
            children: []
          }
        ]
      },
      {
        id: 370900,
        pid: 370000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '泰安市',
        children: [
          {
            id: 370902,
            pid: 370900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '泰山区',
            children: []
          },
          {
            id: 370911,
            pid: 370900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '岱岳区',
            children: []
          },
          {
            id: 370921,
            pid: 370900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宁阳县',
            children: []
          },
          {
            id: 370923,
            pid: 370900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东平县',
            children: []
          },
          {
            id: 370982,
            pid: 370900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新泰市',
            children: []
          },
          {
            id: 370983,
            pid: 370900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '肥城市',
            children: []
          }
        ]
      },
      {
        id: 371000,
        pid: 370000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '威海市',
        children: [
          {
            id: 371002,
            pid: 371000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '环翠区',
            children: []
          },
          {
            id: 371003,
            pid: 371000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '文登区',
            children: []
          },
          {
            id: 371071,
            pid: 371000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '威海火炬高技术产业开发区',
            children: []
          },
          {
            id: 371072,
            pid: 371000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '威海经济技术开发区',
            children: []
          },
          {
            id: 371073,
            pid: 371000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '威海临港经济技术开发区',
            children: []
          },
          {
            id: 371082,
            pid: 371000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '荣成市',
            children: []
          },
          {
            id: 371083,
            pid: 371000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '乳山市',
            children: []
          }
        ]
      },
      {
        id: 371100,
        pid: 370000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '日照市',
        children: [
          {
            id: 371102,
            pid: 371100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东港区',
            children: []
          },
          {
            id: 371103,
            pid: 371100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '岚山区',
            children: []
          },
          {
            id: 371121,
            pid: 371100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '五莲县',
            children: []
          },
          {
            id: 371122,
            pid: 371100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '莒县',
            children: []
          },
          {
            id: 371171,
            pid: 371100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '日照经济技术开发区',
            children: []
          }
        ]
      },
      {
        id: 371300,
        pid: 370000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '临沂市',
        children: [
          {
            id: 371302,
            pid: 371300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '兰山区',
            children: []
          },
          {
            id: 371311,
            pid: 371300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '罗庄区',
            children: []
          },
          {
            id: 371312,
            pid: 371300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '河东区',
            children: []
          },
          {
            id: 371321,
            pid: 371300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '沂南县',
            children: []
          },
          {
            id: 371322,
            pid: 371300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '郯城县',
            children: []
          },
          {
            id: 371323,
            pid: 371300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '沂水县',
            children: []
          },
          {
            id: 371324,
            pid: 371300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '兰陵县',
            children: []
          },
          {
            id: 371325,
            pid: 371300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '费县',
            children: []
          },
          {
            id: 371326,
            pid: 371300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '平邑县',
            children: []
          },
          {
            id: 371327,
            pid: 371300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '莒南县',
            children: []
          },
          {
            id: 371328,
            pid: 371300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '蒙阴县',
            children: []
          },
          {
            id: 371329,
            pid: 371300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '临沭县',
            children: []
          },
          {
            id: 371371,
            pid: 371300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '临沂高新技术产业开发区',
            children: []
          }
        ]
      },
      {
        id: 371400,
        pid: 370000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '德州市',
        children: [
          {
            id: 371402,
            pid: 371400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '德城区',
            children: []
          },
          {
            id: 371403,
            pid: 371400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '陵城区',
            children: []
          },
          {
            id: 371422,
            pid: 371400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宁津县',
            children: []
          },
          {
            id: 371423,
            pid: 371400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '庆云县',
            children: []
          },
          {
            id: 371424,
            pid: 371400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '临邑县',
            children: []
          },
          {
            id: 371425,
            pid: 371400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '齐河县',
            children: []
          },
          {
            id: 371426,
            pid: 371400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '平原县',
            children: []
          },
          {
            id: 371427,
            pid: 371400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '夏津县',
            children: []
          },
          {
            id: 371428,
            pid: 371400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '武城县',
            children: []
          },
          {
            id: 371471,
            pid: 371400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '德州经济技术开发区',
            children: []
          },
          {
            id: 371472,
            pid: 371400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '德州运河经济开发区',
            children: []
          },
          {
            id: 371481,
            pid: 371400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '乐陵市',
            children: []
          },
          {
            id: 371482,
            pid: 371400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '禹城市',
            children: []
          }
        ]
      },
      {
        id: 371500,
        pid: 370000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '聊城市',
        children: [
          {
            id: 371502,
            pid: 371500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东昌府区',
            children: []
          },
          {
            id: 371503,
            pid: 371500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '茌平区',
            children: []
          },
          {
            id: 371521,
            pid: 371500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '阳谷县',
            children: []
          },
          {
            id: 371522,
            pid: 371500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '莘县',
            children: []
          },
          {
            id: 371524,
            pid: 371500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东阿县',
            children: []
          },
          {
            id: 371525,
            pid: 371500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '冠县',
            children: []
          },
          {
            id: 371526,
            pid: 371500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '高唐县',
            children: []
          },
          {
            id: 371581,
            pid: 371500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '临清市',
            children: []
          }
        ]
      },
      {
        id: 371600,
        pid: 370000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '滨州市',
        children: [
          {
            id: 371602,
            pid: 371600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '滨城区',
            children: []
          },
          {
            id: 371603,
            pid: 371600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '沾化区',
            children: []
          },
          {
            id: 371621,
            pid: 371600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '惠民县',
            children: []
          },
          {
            id: 371622,
            pid: 371600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '阳信县',
            children: []
          },
          {
            id: 371623,
            pid: 371600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '无棣县',
            children: []
          },
          {
            id: 371625,
            pid: 371600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '博兴县',
            children: []
          },
          {
            id: 371681,
            pid: 371600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '邹平市',
            children: []
          }
        ]
      },
      {
        id: 371700,
        pid: 370000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '菏泽市',
        children: [
          {
            id: 371702,
            pid: 371700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '牡丹区',
            children: []
          },
          {
            id: 371703,
            pid: 371700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '定陶区',
            children: []
          },
          {
            id: 371721,
            pid: 371700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '曹县',
            children: []
          },
          {
            id: 371722,
            pid: 371700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '单县',
            children: []
          },
          {
            id: 371723,
            pid: 371700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '成武县',
            children: []
          },
          {
            id: 371724,
            pid: 371700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '巨野县',
            children: []
          },
          {
            id: 371725,
            pid: 371700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '郓城县',
            children: []
          },
          {
            id: 371726,
            pid: 371700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '鄄城县',
            children: []
          },
          {
            id: 371728,
            pid: 371700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东明县',
            children: []
          },
          {
            id: 371771,
            pid: 371700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '菏泽经济技术开发区',
            children: []
          },
          {
            id: 371772,
            pid: 371700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '菏泽高新技术开发区',
            children: []
          }
        ]
      }
    ]
  },
  {
    id: 410000,
    pid: 0,
    level: 1,
    createdAt: null,
    updatedAt: null,
    deletedAt: null,
    isValid: 1,
    label: '河南省',
    children: [
      {
        id: 410100,
        pid: 410000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '郑州市',
        children: [
          {
            id: 410102,
            pid: 410100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '中原区',
            children: []
          },
          {
            id: 410103,
            pid: 410100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '二七区',
            children: []
          },
          {
            id: 410104,
            pid: 410100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '管城回族区',
            children: []
          },
          {
            id: 410105,
            pid: 410100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '金水区',
            children: []
          },
          {
            id: 410106,
            pid: 410100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '上街区',
            children: []
          },
          {
            id: 410108,
            pid: 410100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '惠济区',
            children: []
          },
          {
            id: 410122,
            pid: 410100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '中牟县',
            children: []
          },
          {
            id: 410171,
            pid: 410100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '郑州经济技术开发区',
            children: []
          },
          {
            id: 410172,
            pid: 410100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '郑州高新技术产业开发区',
            children: []
          },
          {
            id: 410173,
            pid: 410100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '郑州航空港经济综合实验区',
            children: []
          },
          {
            id: 410181,
            pid: 410100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '巩义市',
            children: []
          },
          {
            id: 410182,
            pid: 410100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '荥阳市',
            children: []
          },
          {
            id: 410183,
            pid: 410100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新密市',
            children: []
          },
          {
            id: 410184,
            pid: 410100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新郑市',
            children: []
          },
          {
            id: 410185,
            pid: 410100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '登封市',
            children: []
          }
        ]
      },
      {
        id: 410200,
        pid: 410000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '开封市',
        children: [
          {
            id: 410202,
            pid: 410200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '龙亭区',
            children: []
          },
          {
            id: 410203,
            pid: 410200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '顺河回族区',
            children: []
          },
          {
            id: 410204,
            pid: 410200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '鼓楼区',
            children: []
          },
          {
            id: 410205,
            pid: 410200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '禹王台区',
            children: []
          },
          {
            id: 410212,
            pid: 410200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '祥符区',
            children: []
          },
          {
            id: 410221,
            pid: 410200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '杞县',
            children: []
          },
          {
            id: 410222,
            pid: 410200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '通许县',
            children: []
          },
          {
            id: 410223,
            pid: 410200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '尉氏县',
            children: []
          },
          {
            id: 410225,
            pid: 410200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '兰考县',
            children: []
          }
        ]
      },
      {
        id: 410300,
        pid: 410000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '洛阳市',
        children: [
          {
            id: 410302,
            pid: 410300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '老城区',
            children: []
          },
          {
            id: 410303,
            pid: 410300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '西工区',
            children: []
          },
          {
            id: 410304,
            pid: 410300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '瀍河回族区',
            children: []
          },
          {
            id: 410305,
            pid: 410300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '涧西区',
            children: []
          },
          {
            id: 410306,
            pid: 410300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '吉利区',
            children: []
          },
          {
            id: 410311,
            pid: 410300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '洛龙区',
            children: []
          },
          {
            id: 410322,
            pid: 410300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '孟津县',
            children: []
          },
          {
            id: 410323,
            pid: 410300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新安县',
            children: []
          },
          {
            id: 410324,
            pid: 410300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '栾川县',
            children: []
          },
          {
            id: 410325,
            pid: 410300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '嵩县',
            children: []
          },
          {
            id: 410326,
            pid: 410300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '汝阳县',
            children: []
          },
          {
            id: 410327,
            pid: 410300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宜阳县',
            children: []
          },
          {
            id: 410328,
            pid: 410300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '洛宁县',
            children: []
          },
          {
            id: 410329,
            pid: 410300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '伊川县',
            children: []
          },
          {
            id: 410371,
            pid: 410300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '洛阳高新技术产业开发区',
            children: []
          },
          {
            id: 410381,
            pid: 410300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '偃师市',
            children: []
          }
        ]
      },
      {
        id: 410400,
        pid: 410000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '平顶山市',
        children: [
          {
            id: 410402,
            pid: 410400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新华区',
            children: []
          },
          {
            id: 410403,
            pid: 410400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '卫东区',
            children: []
          },
          {
            id: 410404,
            pid: 410400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '石龙区',
            children: []
          },
          {
            id: 410411,
            pid: 410400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '湛河区',
            children: []
          },
          {
            id: 410421,
            pid: 410400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宝丰县',
            children: []
          },
          {
            id: 410422,
            pid: 410400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '叶县',
            children: []
          },
          {
            id: 410423,
            pid: 410400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '鲁山县',
            children: []
          },
          {
            id: 410425,
            pid: 410400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '郏县',
            children: []
          },
          {
            id: 410471,
            pid: 410400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '平顶山高新技术产业开发区',
            children: []
          },
          {
            id: 410472,
            pid: 410400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '平顶山市城乡一体化示范区',
            children: []
          },
          {
            id: 410481,
            pid: 410400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '舞钢市',
            children: []
          },
          {
            id: 410482,
            pid: 410400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '汝州市',
            children: []
          }
        ]
      },
      {
        id: 410500,
        pid: 410000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '安阳市',
        children: [
          {
            id: 410502,
            pid: 410500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '文峰区',
            children: []
          },
          {
            id: 410503,
            pid: 410500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '北关区',
            children: []
          },
          {
            id: 410505,
            pid: 410500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '殷都区',
            children: []
          },
          {
            id: 410506,
            pid: 410500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '龙安区',
            children: []
          },
          {
            id: 410522,
            pid: 410500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '安阳县',
            children: []
          },
          {
            id: 410523,
            pid: 410500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '汤阴县',
            children: []
          },
          {
            id: 410526,
            pid: 410500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '滑县',
            children: []
          },
          {
            id: 410527,
            pid: 410500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '内黄县',
            children: []
          },
          {
            id: 410571,
            pid: 410500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '安阳高新技术产业开发区',
            children: []
          },
          {
            id: 410581,
            pid: 410500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '林州市',
            children: []
          }
        ]
      },
      {
        id: 410600,
        pid: 410000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '鹤壁市',
        children: [
          {
            id: 410602,
            pid: 410600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '鹤山区',
            children: []
          },
          {
            id: 410603,
            pid: 410600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '山城区',
            children: []
          },
          {
            id: 410611,
            pid: 410600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '淇滨区',
            children: []
          },
          {
            id: 410621,
            pid: 410600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '浚县',
            children: []
          },
          {
            id: 410622,
            pid: 410600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '淇县',
            children: []
          },
          {
            id: 410671,
            pid: 410600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '鹤壁经济技术开发区',
            children: []
          }
        ]
      },
      {
        id: 410700,
        pid: 410000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '新乡市',
        children: [
          {
            id: 410702,
            pid: 410700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '红旗区',
            children: []
          },
          {
            id: 410703,
            pid: 410700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '卫滨区',
            children: []
          },
          {
            id: 410704,
            pid: 410700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '凤泉区',
            children: []
          },
          {
            id: 410711,
            pid: 410700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '牧野区',
            children: []
          },
          {
            id: 410721,
            pid: 410700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新乡县',
            children: []
          },
          {
            id: 410724,
            pid: 410700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '获嘉县',
            children: []
          },
          {
            id: 410725,
            pid: 410700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '原阳县',
            children: []
          },
          {
            id: 410726,
            pid: 410700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '延津县',
            children: []
          },
          {
            id: 410727,
            pid: 410700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '封丘县',
            children: []
          },
          {
            id: 410771,
            pid: 410700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新乡高新技术产业开发区',
            children: []
          },
          {
            id: 410772,
            pid: 410700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新乡经济技术开发区',
            children: []
          },
          {
            id: 410773,
            pid: 410700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新乡市平原城乡一体化示范区',
            children: []
          },
          {
            id: 410781,
            pid: 410700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '卫辉市',
            children: []
          },
          {
            id: 410782,
            pid: 410700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '辉县市',
            children: []
          },
          {
            id: 410783,
            pid: 410700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '长垣市',
            children: []
          }
        ]
      },
      {
        id: 410800,
        pid: 410000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '焦作市',
        children: [
          {
            id: 410802,
            pid: 410800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '解放区',
            children: []
          },
          {
            id: 410803,
            pid: 410800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '中站区',
            children: []
          },
          {
            id: 410804,
            pid: 410800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '马村区',
            children: []
          },
          {
            id: 410811,
            pid: 410800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '山阳区',
            children: []
          },
          {
            id: 410821,
            pid: 410800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '修武县',
            children: []
          },
          {
            id: 410822,
            pid: 410800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '博爱县',
            children: []
          },
          {
            id: 410823,
            pid: 410800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '武陟县',
            children: []
          },
          {
            id: 410825,
            pid: 410800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '温县',
            children: []
          },
          {
            id: 410871,
            pid: 410800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '焦作城乡一体化示范区',
            children: []
          },
          {
            id: 410882,
            pid: 410800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '沁阳市',
            children: []
          },
          {
            id: 410883,
            pid: 410800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '孟州市',
            children: []
          }
        ]
      },
      {
        id: 410900,
        pid: 410000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '濮阳市',
        children: [
          {
            id: 410902,
            pid: 410900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '华龙区',
            children: []
          },
          {
            id: 410922,
            pid: 410900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '清丰县',
            children: []
          },
          {
            id: 410923,
            pid: 410900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南乐县',
            children: []
          },
          {
            id: 410926,
            pid: 410900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '范县',
            children: []
          },
          {
            id: 410927,
            pid: 410900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '台前县',
            children: []
          },
          {
            id: 410928,
            pid: 410900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '濮阳县',
            children: []
          },
          {
            id: 410971,
            pid: 410900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '河南濮阳工业园区',
            children: []
          },
          {
            id: 410972,
            pid: 410900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '濮阳经济技术开发区',
            children: []
          }
        ]
      },
      {
        id: 411000,
        pid: 410000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '许昌市',
        children: [
          {
            id: 411002,
            pid: 411000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '魏都区',
            children: []
          },
          {
            id: 411003,
            pid: 411000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '建安区',
            children: []
          },
          {
            id: 411024,
            pid: 411000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '鄢陵县',
            children: []
          },
          {
            id: 411025,
            pid: 411000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '襄城县',
            children: []
          },
          {
            id: 411071,
            pid: 411000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '许昌经济技术开发区',
            children: []
          },
          {
            id: 411081,
            pid: 411000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '禹州市',
            children: []
          },
          {
            id: 411082,
            pid: 411000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '长葛市',
            children: []
          }
        ]
      },
      {
        id: 411100,
        pid: 410000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '漯河市',
        children: [
          {
            id: 411102,
            pid: 411100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '源汇区',
            children: []
          },
          {
            id: 411103,
            pid: 411100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '郾城区',
            children: []
          },
          {
            id: 411104,
            pid: 411100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '召陵区',
            children: []
          },
          {
            id: 411121,
            pid: 411100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '舞阳县',
            children: []
          },
          {
            id: 411122,
            pid: 411100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '临颍县',
            children: []
          },
          {
            id: 411171,
            pid: 411100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '漯河经济技术开发区',
            children: []
          }
        ]
      },
      {
        id: 411200,
        pid: 410000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '三门峡市',
        children: [
          {
            id: 411202,
            pid: 411200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '湖滨区',
            children: []
          },
          {
            id: 411203,
            pid: 411200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '陕州区',
            children: []
          },
          {
            id: 411221,
            pid: 411200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '渑池县',
            children: []
          },
          {
            id: 411224,
            pid: 411200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '卢氏县',
            children: []
          },
          {
            id: 411271,
            pid: 411200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '河南三门峡经济开发区',
            children: []
          },
          {
            id: 411281,
            pid: 411200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '义马市',
            children: []
          },
          {
            id: 411282,
            pid: 411200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '灵宝市',
            children: []
          }
        ]
      },
      {
        id: 411300,
        pid: 410000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '南阳市',
        children: [
          {
            id: 411302,
            pid: 411300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宛城区',
            children: []
          },
          {
            id: 411303,
            pid: 411300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '卧龙区',
            children: []
          },
          {
            id: 411321,
            pid: 411300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南召县',
            children: []
          },
          {
            id: 411322,
            pid: 411300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '方城县',
            children: []
          },
          {
            id: 411323,
            pid: 411300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '西峡县',
            children: []
          },
          {
            id: 411324,
            pid: 411300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '镇平县',
            children: []
          },
          {
            id: 411325,
            pid: 411300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '内乡县',
            children: []
          },
          {
            id: 411326,
            pid: 411300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '淅川县',
            children: []
          },
          {
            id: 411327,
            pid: 411300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '社旗县',
            children: []
          },
          {
            id: 411328,
            pid: 411300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '唐河县',
            children: []
          },
          {
            id: 411329,
            pid: 411300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新野县',
            children: []
          },
          {
            id: 411330,
            pid: 411300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '桐柏县',
            children: []
          },
          {
            id: 411371,
            pid: 411300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南阳高新技术产业开发区',
            children: []
          },
          {
            id: 411372,
            pid: 411300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南阳市城乡一体化示范区',
            children: []
          },
          {
            id: 411381,
            pid: 411300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '邓州市',
            children: []
          }
        ]
      },
      {
        id: 411400,
        pid: 410000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '商丘市',
        children: [
          {
            id: 411402,
            pid: 411400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '梁园区',
            children: []
          },
          {
            id: 411403,
            pid: 411400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '睢阳区',
            children: []
          },
          {
            id: 411421,
            pid: 411400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '民权县',
            children: []
          },
          {
            id: 411422,
            pid: 411400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '睢县',
            children: []
          },
          {
            id: 411423,
            pid: 411400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宁陵县',
            children: []
          },
          {
            id: 411424,
            pid: 411400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '柘城县',
            children: []
          },
          {
            id: 411425,
            pid: 411400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '虞城县',
            children: []
          },
          {
            id: 411426,
            pid: 411400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '夏邑县',
            children: []
          },
          {
            id: 411471,
            pid: 411400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '豫东综合物流产业聚集区',
            children: []
          },
          {
            id: 411472,
            pid: 411400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '河南商丘经济开发区',
            children: []
          },
          {
            id: 411481,
            pid: 411400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '永城市',
            children: []
          }
        ]
      },
      {
        id: 411500,
        pid: 410000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '信阳市',
        children: [
          {
            id: 411502,
            pid: 411500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '浉河区',
            children: []
          },
          {
            id: 411503,
            pid: 411500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '平桥区',
            children: []
          },
          {
            id: 411521,
            pid: 411500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '罗山县',
            children: []
          },
          {
            id: 411522,
            pid: 411500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '光山县',
            children: []
          },
          {
            id: 411523,
            pid: 411500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新县',
            children: []
          },
          {
            id: 411524,
            pid: 411500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '商城县',
            children: []
          },
          {
            id: 411525,
            pid: 411500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '固始县',
            children: []
          },
          {
            id: 411526,
            pid: 411500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '潢川县',
            children: []
          },
          {
            id: 411527,
            pid: 411500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '淮滨县',
            children: []
          },
          {
            id: 411528,
            pid: 411500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '息县',
            children: []
          },
          {
            id: 411571,
            pid: 411500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '信阳高新技术产业开发区',
            children: []
          }
        ]
      },
      {
        id: 411600,
        pid: 410000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '周口市',
        children: [
          {
            id: 411602,
            pid: 411600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '川汇区',
            children: []
          },
          {
            id: 411603,
            pid: 411600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '淮阳区',
            children: []
          },
          {
            id: 411621,
            pid: 411600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '扶沟县',
            children: []
          },
          {
            id: 411622,
            pid: 411600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '西华县',
            children: []
          },
          {
            id: 411623,
            pid: 411600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '商水县',
            children: []
          },
          {
            id: 411624,
            pid: 411600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '沈丘县',
            children: []
          },
          {
            id: 411625,
            pid: 411600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '郸城县',
            children: []
          },
          {
            id: 411627,
            pid: 411600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '太康县',
            children: []
          },
          {
            id: 411628,
            pid: 411600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '鹿邑县',
            children: []
          },
          {
            id: 411671,
            pid: 411600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '河南周口经济开发区',
            children: []
          },
          {
            id: 411681,
            pid: 411600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '项城市',
            children: []
          }
        ]
      },
      {
        id: 411700,
        pid: 410000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '驻马店市',
        children: [
          {
            id: 411702,
            pid: 411700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '驿城区',
            children: []
          },
          {
            id: 411721,
            pid: 411700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '西平县',
            children: []
          },
          {
            id: 411722,
            pid: 411700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '上蔡县',
            children: []
          },
          {
            id: 411723,
            pid: 411700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '平舆县',
            children: []
          },
          {
            id: 411724,
            pid: 411700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '正阳县',
            children: []
          },
          {
            id: 411725,
            pid: 411700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '确山县',
            children: []
          },
          {
            id: 411726,
            pid: 411700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '泌阳县',
            children: []
          },
          {
            id: 411727,
            pid: 411700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '汝南县',
            children: []
          },
          {
            id: 411728,
            pid: 411700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '遂平县',
            children: []
          },
          {
            id: 411729,
            pid: 411700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新蔡县',
            children: []
          },
          {
            id: 411771,
            pid: 411700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '河南驻马店经济开发区',
            children: []
          }
        ]
      },
      {
        id: 419000,
        pid: 410000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '省直辖县级行政区划',
        children: [
          {
            id: 419001,
            pid: 419000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '济源市',
            children: []
          }
        ]
      }
    ]
  },
  {
    id: 420000,
    pid: 0,
    level: 1,
    createdAt: null,
    updatedAt: null,
    deletedAt: null,
    isValid: 1,
    label: '湖北省',
    children: [
      {
        id: 420100,
        pid: 420000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '武汉市',
        children: [
          {
            id: 420102,
            pid: 420100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '江岸区',
            children: []
          },
          {
            id: 420103,
            pid: 420100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '江汉区',
            children: []
          },
          {
            id: 420104,
            pid: 420100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '硚口区',
            children: []
          },
          {
            id: 420105,
            pid: 420100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '汉阳区',
            children: []
          },
          {
            id: 420106,
            pid: 420100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '武昌区',
            children: []
          },
          {
            id: 420107,
            pid: 420100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '青山区',
            children: []
          },
          {
            id: 420111,
            pid: 420100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '洪山区',
            children: []
          },
          {
            id: 420112,
            pid: 420100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东西湖区',
            children: []
          },
          {
            id: 420113,
            pid: 420100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '汉南区',
            children: []
          },
          {
            id: 420114,
            pid: 420100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '蔡甸区',
            children: []
          },
          {
            id: 420115,
            pid: 420100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '江夏区',
            children: []
          },
          {
            id: 420116,
            pid: 420100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '黄陂区',
            children: []
          },
          {
            id: 420117,
            pid: 420100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新洲区',
            children: []
          }
        ]
      },
      {
        id: 420200,
        pid: 420000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '黄石市',
        children: [
          {
            id: 420202,
            pid: 420200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '黄石港区',
            children: []
          },
          {
            id: 420203,
            pid: 420200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '西塞山区',
            children: []
          },
          {
            id: 420204,
            pid: 420200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '下陆区',
            children: []
          },
          {
            id: 420205,
            pid: 420200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '铁山区',
            children: []
          },
          {
            id: 420222,
            pid: 420200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '阳新县',
            children: []
          },
          {
            id: 420281,
            pid: 420200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大冶市',
            children: []
          }
        ]
      },
      {
        id: 420300,
        pid: 420000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '十堰市',
        children: [
          {
            id: 420302,
            pid: 420300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '茅箭区',
            children: []
          },
          {
            id: 420303,
            pid: 420300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '张湾区',
            children: []
          },
          {
            id: 420304,
            pid: 420300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '郧阳区',
            children: []
          },
          {
            id: 420322,
            pid: 420300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '郧西县',
            children: []
          },
          {
            id: 420323,
            pid: 420300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '竹山县',
            children: []
          },
          {
            id: 420324,
            pid: 420300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '竹溪县',
            children: []
          },
          {
            id: 420325,
            pid: 420300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '房县',
            children: []
          },
          {
            id: 420381,
            pid: 420300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '丹江口市',
            children: []
          }
        ]
      },
      {
        id: 420500,
        pid: 420000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '宜昌市',
        children: [
          {
            id: 420502,
            pid: 420500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '西陵区',
            children: []
          },
          {
            id: 420503,
            pid: 420500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '伍家岗区',
            children: []
          },
          {
            id: 420504,
            pid: 420500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '点军区',
            children: []
          },
          {
            id: 420505,
            pid: 420500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '猇亭区',
            children: []
          },
          {
            id: 420506,
            pid: 420500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '夷陵区',
            children: []
          },
          {
            id: 420525,
            pid: 420500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '远安县',
            children: []
          },
          {
            id: 420526,
            pid: 420500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '兴山县',
            children: []
          },
          {
            id: 420527,
            pid: 420500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '秭归县',
            children: []
          },
          {
            id: 420528,
            pid: 420500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '长阳土家族自治县',
            children: []
          },
          {
            id: 420529,
            pid: 420500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '五峰土家族自治县',
            children: []
          },
          {
            id: 420581,
            pid: 420500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宜都市',
            children: []
          },
          {
            id: 420582,
            pid: 420500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '当阳市',
            children: []
          },
          {
            id: 420583,
            pid: 420500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '枝江市',
            children: []
          }
        ]
      },
      {
        id: 420600,
        pid: 420000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '襄阳市',
        children: [
          {
            id: 420602,
            pid: 420600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '襄城区',
            children: []
          },
          {
            id: 420606,
            pid: 420600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '樊城区',
            children: []
          },
          {
            id: 420607,
            pid: 420600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '襄州区',
            children: []
          },
          {
            id: 420624,
            pid: 420600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南漳县',
            children: []
          },
          {
            id: 420625,
            pid: 420600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '谷城县',
            children: []
          },
          {
            id: 420626,
            pid: 420600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '保康县',
            children: []
          },
          {
            id: 420682,
            pid: 420600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '老河口市',
            children: []
          },
          {
            id: 420683,
            pid: 420600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '枣阳市',
            children: []
          },
          {
            id: 420684,
            pid: 420600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宜城市',
            children: []
          }
        ]
      },
      {
        id: 420700,
        pid: 420000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '鄂州市',
        children: [
          {
            id: 420702,
            pid: 420700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '梁子湖区',
            children: []
          },
          {
            id: 420703,
            pid: 420700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '华容区',
            children: []
          },
          {
            id: 420704,
            pid: 420700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '鄂城区',
            children: []
          }
        ]
      },
      {
        id: 420800,
        pid: 420000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '荆门市',
        children: [
          {
            id: 420802,
            pid: 420800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东宝区',
            children: []
          },
          {
            id: 420804,
            pid: 420800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '掇刀区',
            children: []
          },
          {
            id: 420822,
            pid: 420800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '沙洋县',
            children: []
          },
          {
            id: 420881,
            pid: 420800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '钟祥市',
            children: []
          },
          {
            id: 420882,
            pid: 420800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '京山市',
            children: []
          }
        ]
      },
      {
        id: 420900,
        pid: 420000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '孝感市',
        children: [
          {
            id: 420902,
            pid: 420900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '孝南区',
            children: []
          },
          {
            id: 420921,
            pid: 420900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '孝昌县',
            children: []
          },
          {
            id: 420922,
            pid: 420900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大悟县',
            children: []
          },
          {
            id: 420923,
            pid: 420900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '云梦县',
            children: []
          },
          {
            id: 420981,
            pid: 420900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '应城市',
            children: []
          },
          {
            id: 420982,
            pid: 420900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '安陆市',
            children: []
          },
          {
            id: 420984,
            pid: 420900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '汉川市',
            children: []
          }
        ]
      },
      {
        id: 421000,
        pid: 420000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '荆州市',
        children: [
          {
            id: 421002,
            pid: 421000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '沙市区',
            children: []
          },
          {
            id: 421003,
            pid: 421000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '荆州区',
            children: []
          },
          {
            id: 421022,
            pid: 421000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '公安县',
            children: []
          },
          {
            id: 421023,
            pid: 421000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '监利县',
            children: []
          },
          {
            id: 421024,
            pid: 421000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '江陵县',
            children: []
          },
          {
            id: 421071,
            pid: 421000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '荆州经济技术开发区',
            children: []
          },
          {
            id: 421081,
            pid: 421000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '石首市',
            children: []
          },
          {
            id: 421083,
            pid: 421000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '洪湖市',
            children: []
          },
          {
            id: 421087,
            pid: 421000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '松滋市',
            children: []
          }
        ]
      },
      {
        id: 421100,
        pid: 420000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '黄冈市',
        children: [
          {
            id: 421102,
            pid: 421100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '黄州区',
            children: []
          },
          {
            id: 421121,
            pid: 421100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '团风县',
            children: []
          },
          {
            id: 421122,
            pid: 421100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '红安县',
            children: []
          },
          {
            id: 421123,
            pid: 421100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '罗田县',
            children: []
          },
          {
            id: 421124,
            pid: 421100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '英山县',
            children: []
          },
          {
            id: 421125,
            pid: 421100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '浠水县',
            children: []
          },
          {
            id: 421126,
            pid: 421100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '蕲春县',
            children: []
          },
          {
            id: 421127,
            pid: 421100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '黄梅县',
            children: []
          },
          {
            id: 421171,
            pid: 421100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '龙感湖管理区',
            children: []
          },
          {
            id: 421181,
            pid: 421100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '麻城市',
            children: []
          },
          {
            id: 421182,
            pid: 421100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '武穴市',
            children: []
          }
        ]
      },
      {
        id: 421200,
        pid: 420000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '咸宁市',
        children: [
          {
            id: 421202,
            pid: 421200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '咸安区',
            children: []
          },
          {
            id: 421221,
            pid: 421200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '嘉鱼县',
            children: []
          },
          {
            id: 421222,
            pid: 421200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '通城县',
            children: []
          },
          {
            id: 421223,
            pid: 421200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '崇阳县',
            children: []
          },
          {
            id: 421224,
            pid: 421200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '通山县',
            children: []
          },
          {
            id: 421281,
            pid: 421200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '赤壁市',
            children: []
          }
        ]
      },
      {
        id: 421300,
        pid: 420000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '随州市',
        children: [
          {
            id: 421303,
            pid: 421300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '曾都区',
            children: []
          },
          {
            id: 421321,
            pid: 421300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '随县',
            children: []
          },
          {
            id: 421381,
            pid: 421300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '广水市',
            children: []
          }
        ]
      },
      {
        id: 422800,
        pid: 420000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '恩施土家族苗族自治州',
        children: [
          {
            id: 422801,
            pid: 422800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '恩施市',
            children: []
          },
          {
            id: 422802,
            pid: 422800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '利川市',
            children: []
          },
          {
            id: 422822,
            pid: 422800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '建始县',
            children: []
          },
          {
            id: 422823,
            pid: 422800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '巴东县',
            children: []
          },
          {
            id: 422825,
            pid: 422800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宣恩县',
            children: []
          },
          {
            id: 422826,
            pid: 422800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '咸丰县',
            children: []
          },
          {
            id: 422827,
            pid: 422800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '来凤县',
            children: []
          },
          {
            id: 422828,
            pid: 422800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '鹤峰县',
            children: []
          }
        ]
      },
      {
        id: 429000,
        pid: 420000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '省直辖县级行政区划',
        children: [
          {
            id: 429004,
            pid: 429000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '仙桃市',
            children: []
          },
          {
            id: 429005,
            pid: 429000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '潜江市',
            children: []
          },
          {
            id: 429006,
            pid: 429000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '天门市',
            children: []
          },
          {
            id: 429021,
            pid: 429000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '神农架林区',
            children: []
          }
        ]
      }
    ]
  },
  {
    id: 430000,
    pid: 0,
    level: 1,
    createdAt: null,
    updatedAt: null,
    deletedAt: null,
    isValid: 1,
    label: '湖南省',
    children: [
      {
        id: 430100,
        pid: 430000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '长沙市',
        children: [
          {
            id: 430102,
            pid: 430100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '芙蓉区',
            children: []
          },
          {
            id: 430103,
            pid: 430100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '天心区',
            children: []
          },
          {
            id: 430104,
            pid: 430100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '岳麓区',
            children: []
          },
          {
            id: 430105,
            pid: 430100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '开福区',
            children: []
          },
          {
            id: 430111,
            pid: 430100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '雨花区',
            children: []
          },
          {
            id: 430112,
            pid: 430100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '望城区',
            children: []
          },
          {
            id: 430121,
            pid: 430100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '长沙县',
            children: []
          },
          {
            id: 430181,
            pid: 430100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '浏阳市',
            children: []
          },
          {
            id: 430182,
            pid: 430100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宁乡市',
            children: []
          }
        ]
      },
      {
        id: 430200,
        pid: 430000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '株洲市',
        children: [
          {
            id: 430202,
            pid: 430200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '荷塘区',
            children: []
          },
          {
            id: 430203,
            pid: 430200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '芦淞区',
            children: []
          },
          {
            id: 430204,
            pid: 430200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '石峰区',
            children: []
          },
          {
            id: 430211,
            pid: 430200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '天元区',
            children: []
          },
          {
            id: 430212,
            pid: 430200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '渌口区',
            children: []
          },
          {
            id: 430223,
            pid: 430200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '攸县',
            children: []
          },
          {
            id: 430224,
            pid: 430200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '茶陵县',
            children: []
          },
          {
            id: 430225,
            pid: 430200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '炎陵县',
            children: []
          },
          {
            id: 430271,
            pid: 430200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '云龙示范区',
            children: []
          },
          {
            id: 430281,
            pid: 430200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '醴陵市',
            children: []
          }
        ]
      },
      {
        id: 430300,
        pid: 430000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '湘潭市',
        children: [
          {
            id: 430302,
            pid: 430300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '雨湖区',
            children: []
          },
          {
            id: 430304,
            pid: 430300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '岳塘区',
            children: []
          },
          {
            id: 430321,
            pid: 430300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '湘潭县',
            children: []
          },
          {
            id: 430371,
            pid: 430300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '湖南湘潭高新技术产业园区',
            children: []
          },
          {
            id: 430372,
            pid: 430300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '湘潭昭山示范区',
            children: []
          },
          {
            id: 430373,
            pid: 430300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '湘潭九华示范区',
            children: []
          },
          {
            id: 430381,
            pid: 430300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '湘乡市',
            children: []
          },
          {
            id: 430382,
            pid: 430300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '韶山市',
            children: []
          }
        ]
      },
      {
        id: 430400,
        pid: 430000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '衡阳市',
        children: [
          {
            id: 430405,
            pid: 430400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '珠晖区',
            children: []
          },
          {
            id: 430406,
            pid: 430400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '雁峰区',
            children: []
          },
          {
            id: 430407,
            pid: 430400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '石鼓区',
            children: []
          },
          {
            id: 430408,
            pid: 430400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '蒸湘区',
            children: []
          },
          {
            id: 430412,
            pid: 430400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南岳区',
            children: []
          },
          {
            id: 430421,
            pid: 430400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '衡阳县',
            children: []
          },
          {
            id: 430422,
            pid: 430400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '衡南县',
            children: []
          },
          {
            id: 430423,
            pid: 430400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '衡山县',
            children: []
          },
          {
            id: 430424,
            pid: 430400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '衡东县',
            children: []
          },
          {
            id: 430426,
            pid: 430400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '祁东县',
            children: []
          },
          {
            id: 430471,
            pid: 430400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '衡阳综合保税区',
            children: []
          },
          {
            id: 430472,
            pid: 430400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '湖南衡阳高新技术产业园区',
            children: []
          },
          {
            id: 430473,
            pid: 430400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '湖南衡阳松木经济开发区',
            children: []
          },
          {
            id: 430481,
            pid: 430400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '耒阳市',
            children: []
          },
          {
            id: 430482,
            pid: 430400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '常宁市',
            children: []
          }
        ]
      },
      {
        id: 430500,
        pid: 430000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '邵阳市',
        children: [
          {
            id: 430502,
            pid: 430500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '双清区',
            children: []
          },
          {
            id: 430503,
            pid: 430500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大祥区',
            children: []
          },
          {
            id: 430511,
            pid: 430500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '北塔区',
            children: []
          },
          {
            id: 430522,
            pid: 430500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新邵县',
            children: []
          },
          {
            id: 430523,
            pid: 430500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '邵阳县',
            children: []
          },
          {
            id: 430524,
            pid: 430500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '隆回县',
            children: []
          },
          {
            id: 430525,
            pid: 430500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '洞口县',
            children: []
          },
          {
            id: 430527,
            pid: 430500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '绥宁县',
            children: []
          },
          {
            id: 430528,
            pid: 430500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新宁县',
            children: []
          },
          {
            id: 430529,
            pid: 430500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '城步苗族自治县',
            children: []
          },
          {
            id: 430581,
            pid: 430500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '武冈市',
            children: []
          },
          {
            id: 430582,
            pid: 430500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '邵东市',
            children: []
          }
        ]
      },
      {
        id: 430600,
        pid: 430000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '岳阳市',
        children: [
          {
            id: 430602,
            pid: 430600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '岳阳楼区',
            children: []
          },
          {
            id: 430603,
            pid: 430600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '云溪区',
            children: []
          },
          {
            id: 430611,
            pid: 430600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '君山区',
            children: []
          },
          {
            id: 430621,
            pid: 430600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '岳阳县',
            children: []
          },
          {
            id: 430623,
            pid: 430600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '华容县',
            children: []
          },
          {
            id: 430624,
            pid: 430600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '湘阴县',
            children: []
          },
          {
            id: 430626,
            pid: 430600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '平江县',
            children: []
          },
          {
            id: 430671,
            pid: 430600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '岳阳市屈原管理区',
            children: []
          },
          {
            id: 430681,
            pid: 430600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '汨罗市',
            children: []
          },
          {
            id: 430682,
            pid: 430600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '临湘市',
            children: []
          }
        ]
      },
      {
        id: 430700,
        pid: 430000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '常德市',
        children: [
          {
            id: 430702,
            pid: 430700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '武陵区',
            children: []
          },
          {
            id: 430703,
            pid: 430700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '鼎城区',
            children: []
          },
          {
            id: 430721,
            pid: 430700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '安乡县',
            children: []
          },
          {
            id: 430722,
            pid: 430700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '汉寿县',
            children: []
          },
          {
            id: 430723,
            pid: 430700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '澧县',
            children: []
          },
          {
            id: 430724,
            pid: 430700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '临澧县',
            children: []
          },
          {
            id: 430725,
            pid: 430700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '桃源县',
            children: []
          },
          {
            id: 430726,
            pid: 430700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '石门县',
            children: []
          },
          {
            id: 430771,
            pid: 430700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '常德市西洞庭管理区',
            children: []
          },
          {
            id: 430781,
            pid: 430700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '津市市',
            children: []
          }
        ]
      },
      {
        id: 430800,
        pid: 430000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '张家界市',
        children: [
          {
            id: 430802,
            pid: 430800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '永定区',
            children: []
          },
          {
            id: 430811,
            pid: 430800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '武陵源区',
            children: []
          },
          {
            id: 430821,
            pid: 430800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '慈利县',
            children: []
          },
          {
            id: 430822,
            pid: 430800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '桑植县',
            children: []
          }
        ]
      },
      {
        id: 430900,
        pid: 430000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '益阳市',
        children: [
          {
            id: 430902,
            pid: 430900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '资阳区',
            children: []
          },
          {
            id: 430903,
            pid: 430900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '赫山区',
            children: []
          },
          {
            id: 430921,
            pid: 430900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南县',
            children: []
          },
          {
            id: 430922,
            pid: 430900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '桃江县',
            children: []
          },
          {
            id: 430923,
            pid: 430900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '安化县',
            children: []
          },
          {
            id: 430971,
            pid: 430900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '益阳市大通湖管理区',
            children: []
          },
          {
            id: 430972,
            pid: 430900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '湖南益阳高新技术产业园区',
            children: []
          },
          {
            id: 430981,
            pid: 430900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '沅江市',
            children: []
          }
        ]
      },
      {
        id: 431000,
        pid: 430000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '郴州市',
        children: [
          {
            id: 431002,
            pid: 431000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '北湖区',
            children: []
          },
          {
            id: 431003,
            pid: 431000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '苏仙区',
            children: []
          },
          {
            id: 431021,
            pid: 431000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '桂阳县',
            children: []
          },
          {
            id: 431022,
            pid: 431000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宜章县',
            children: []
          },
          {
            id: 431023,
            pid: 431000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '永兴县',
            children: []
          },
          {
            id: 431024,
            pid: 431000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '嘉禾县',
            children: []
          },
          {
            id: 431025,
            pid: 431000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '临武县',
            children: []
          },
          {
            id: 431026,
            pid: 431000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '汝城县',
            children: []
          },
          {
            id: 431027,
            pid: 431000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '桂东县',
            children: []
          },
          {
            id: 431028,
            pid: 431000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '安仁县',
            children: []
          },
          {
            id: 431081,
            pid: 431000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '资兴市',
            children: []
          }
        ]
      },
      {
        id: 431100,
        pid: 430000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '永州市',
        children: [
          {
            id: 431102,
            pid: 431100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '零陵区',
            children: []
          },
          {
            id: 431103,
            pid: 431100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '冷水滩区',
            children: []
          },
          {
            id: 431121,
            pid: 431100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '祁阳县',
            children: []
          },
          {
            id: 431122,
            pid: 431100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东安县',
            children: []
          },
          {
            id: 431123,
            pid: 431100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '双牌县',
            children: []
          },
          {
            id: 431124,
            pid: 431100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '道县',
            children: []
          },
          {
            id: 431125,
            pid: 431100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '江永县',
            children: []
          },
          {
            id: 431126,
            pid: 431100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宁远县',
            children: []
          },
          {
            id: 431127,
            pid: 431100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '蓝山县',
            children: []
          },
          {
            id: 431128,
            pid: 431100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新田县',
            children: []
          },
          {
            id: 431129,
            pid: 431100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '江华瑶族自治县',
            children: []
          },
          {
            id: 431171,
            pid: 431100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '永州经济技术开发区',
            children: []
          },
          {
            id: 431172,
            pid: 431100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '永州市金洞管理区',
            children: []
          },
          {
            id: 431173,
            pid: 431100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '永州市回龙圩管理区',
            children: []
          }
        ]
      },
      {
        id: 431200,
        pid: 430000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '怀化市',
        children: [
          {
            id: 431202,
            pid: 431200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '鹤城区',
            children: []
          },
          {
            id: 431221,
            pid: 431200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '中方县',
            children: []
          },
          {
            id: 431222,
            pid: 431200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '沅陵县',
            children: []
          },
          {
            id: 431223,
            pid: 431200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '辰溪县',
            children: []
          },
          {
            id: 431224,
            pid: 431200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '溆浦县',
            children: []
          },
          {
            id: 431225,
            pid: 431200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '会同县',
            children: []
          },
          {
            id: 431226,
            pid: 431200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '麻阳苗族自治县',
            children: []
          },
          {
            id: 431227,
            pid: 431200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新晃侗族自治县',
            children: []
          },
          {
            id: 431228,
            pid: 431200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '芷江侗族自治县',
            children: []
          },
          {
            id: 431229,
            pid: 431200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '靖州苗族侗族自治县',
            children: []
          },
          {
            id: 431230,
            pid: 431200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '通道侗族自治县',
            children: []
          },
          {
            id: 431271,
            pid: 431200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '怀化市洪江管理区',
            children: []
          },
          {
            id: 431281,
            pid: 431200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '洪江市',
            children: []
          }
        ]
      },
      {
        id: 431300,
        pid: 430000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '娄底市',
        children: [
          {
            id: 431302,
            pid: 431300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '娄星区',
            children: []
          },
          {
            id: 431321,
            pid: 431300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '双峰县',
            children: []
          },
          {
            id: 431322,
            pid: 431300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新化县',
            children: []
          },
          {
            id: 431381,
            pid: 431300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '冷水江市',
            children: []
          },
          {
            id: 431382,
            pid: 431300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '涟源市',
            children: []
          }
        ]
      },
      {
        id: 433100,
        pid: 430000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '湘西土家族苗族自治州',
        children: [
          {
            id: 433101,
            pid: 433100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '吉首市',
            children: []
          },
          {
            id: 433122,
            pid: 433100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '泸溪县',
            children: []
          },
          {
            id: 433123,
            pid: 433100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '凤凰县',
            children: []
          },
          {
            id: 433124,
            pid: 433100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '花垣县',
            children: []
          },
          {
            id: 433125,
            pid: 433100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '保靖县',
            children: []
          },
          {
            id: 433126,
            pid: 433100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '古丈县',
            children: []
          },
          {
            id: 433127,
            pid: 433100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '永顺县',
            children: []
          },
          {
            id: 433130,
            pid: 433100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '龙山县',
            children: []
          }
        ]
      }
    ]
  },
  {
    id: 440000,
    pid: 0,
    level: 1,
    createdAt: null,
    updatedAt: null,
    deletedAt: null,
    isValid: 1,
    label: '广东省',
    children: [
      {
        id: 440100,
        pid: 440000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '广州市',
        children: [
          {
            id: 440103,
            pid: 440100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '荔湾区',
            children: []
          },
          {
            id: 440104,
            pid: 440100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '越秀区',
            children: []
          },
          {
            id: 440105,
            pid: 440100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '海珠区',
            children: []
          },
          {
            id: 440106,
            pid: 440100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '天河区',
            children: []
          },
          {
            id: 440111,
            pid: 440100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '白云区',
            children: []
          },
          {
            id: 440112,
            pid: 440100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '黄埔区',
            children: []
          },
          {
            id: 440113,
            pid: 440100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '番禺区',
            children: []
          },
          {
            id: 440114,
            pid: 440100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '花都区',
            children: []
          },
          {
            id: 440115,
            pid: 440100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南沙区',
            children: []
          },
          {
            id: 440117,
            pid: 440100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '从化区',
            children: []
          },
          {
            id: 440118,
            pid: 440100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '增城区',
            children: []
          }
        ]
      },
      {
        id: 440200,
        pid: 440000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '韶关市',
        children: [
          {
            id: 440203,
            pid: 440200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '武江区',
            children: []
          },
          {
            id: 440204,
            pid: 440200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '浈江区',
            children: []
          },
          {
            id: 440205,
            pid: 440200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '曲江区',
            children: []
          },
          {
            id: 440222,
            pid: 440200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '始兴县',
            children: []
          },
          {
            id: 440224,
            pid: 440200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '仁化县',
            children: []
          },
          {
            id: 440229,
            pid: 440200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '翁源县',
            children: []
          },
          {
            id: 440232,
            pid: 440200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '乳源瑶族自治县',
            children: []
          },
          {
            id: 440233,
            pid: 440200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新丰县',
            children: []
          },
          {
            id: 440281,
            pid: 440200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '乐昌市',
            children: []
          },
          {
            id: 440282,
            pid: 440200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南雄市',
            children: []
          }
        ]
      },
      {
        id: 440300,
        pid: 440000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '深圳市',
        children: [
          {
            id: 440303,
            pid: 440300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '罗湖区',
            children: []
          },
          {
            id: 440304,
            pid: 440300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '福田区',
            children: []
          },
          {
            id: 440305,
            pid: 440300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南山区',
            children: []
          },
          {
            id: 440306,
            pid: 440300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宝安区',
            children: []
          },
          {
            id: 440307,
            pid: 440300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '龙岗区',
            children: []
          },
          {
            id: 440308,
            pid: 440300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '盐田区',
            children: []
          },
          {
            id: 440309,
            pid: 440300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '龙华区',
            children: []
          },
          {
            id: 440310,
            pid: 440300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '坪山区',
            children: []
          },
          {
            id: 440311,
            pid: 440300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '光明区',
            children: []
          }
        ]
      },
      {
        id: 440400,
        pid: 440000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '珠海市',
        children: [
          {
            id: 440402,
            pid: 440400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '香洲区',
            children: []
          },
          {
            id: 440403,
            pid: 440400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '斗门区',
            children: []
          },
          {
            id: 440404,
            pid: 440400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '金湾区',
            children: []
          }
        ]
      },
      {
        id: 440500,
        pid: 440000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '汕头市',
        children: [
          {
            id: 440507,
            pid: 440500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '龙湖区',
            children: []
          },
          {
            id: 440511,
            pid: 440500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '金平区',
            children: []
          },
          {
            id: 440512,
            pid: 440500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '濠江区',
            children: []
          },
          {
            id: 440513,
            pid: 440500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '潮阳区',
            children: []
          },
          {
            id: 440514,
            pid: 440500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '潮南区',
            children: []
          },
          {
            id: 440515,
            pid: 440500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '澄海区',
            children: []
          },
          {
            id: 440523,
            pid: 440500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南澳县',
            children: []
          }
        ]
      },
      {
        id: 440600,
        pid: 440000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '佛山市',
        children: [
          {
            id: 440604,
            pid: 440600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '禅城区',
            children: []
          },
          {
            id: 440605,
            pid: 440600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南海区',
            children: []
          },
          {
            id: 440606,
            pid: 440600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '顺德区',
            children: []
          },
          {
            id: 440607,
            pid: 440600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '三水区',
            children: []
          },
          {
            id: 440608,
            pid: 440600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '高明区',
            children: []
          }
        ]
      },
      {
        id: 440700,
        pid: 440000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '江门市',
        children: [
          {
            id: 440703,
            pid: 440700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '蓬江区',
            children: []
          },
          {
            id: 440704,
            pid: 440700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '江海区',
            children: []
          },
          {
            id: 440705,
            pid: 440700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新会区',
            children: []
          },
          {
            id: 440781,
            pid: 440700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '台山市',
            children: []
          },
          {
            id: 440783,
            pid: 440700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '开平市',
            children: []
          },
          {
            id: 440784,
            pid: 440700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '鹤山市',
            children: []
          },
          {
            id: 440785,
            pid: 440700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '恩平市',
            children: []
          }
        ]
      },
      {
        id: 440800,
        pid: 440000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '湛江市',
        children: [
          {
            id: 440802,
            pid: 440800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '赤坎区',
            children: []
          },
          {
            id: 440803,
            pid: 440800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '霞山区',
            children: []
          },
          {
            id: 440804,
            pid: 440800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '坡头区',
            children: []
          },
          {
            id: 440811,
            pid: 440800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '麻章区',
            children: []
          },
          {
            id: 440823,
            pid: 440800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '遂溪县',
            children: []
          },
          {
            id: 440825,
            pid: 440800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '徐闻县',
            children: []
          },
          {
            id: 440881,
            pid: 440800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '廉江市',
            children: []
          },
          {
            id: 440882,
            pid: 440800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '雷州市',
            children: []
          },
          {
            id: 440883,
            pid: 440800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '吴川市',
            children: []
          }
        ]
      },
      {
        id: 440900,
        pid: 440000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '茂名市',
        children: [
          {
            id: 440902,
            pid: 440900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '茂南区',
            children: []
          },
          {
            id: 440904,
            pid: 440900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '电白区',
            children: []
          },
          {
            id: 440981,
            pid: 440900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '高州市',
            children: []
          },
          {
            id: 440982,
            pid: 440900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '化州市',
            children: []
          },
          {
            id: 440983,
            pid: 440900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '信宜市',
            children: []
          }
        ]
      },
      {
        id: 441200,
        pid: 440000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '肇庆市',
        children: [
          {
            id: 441202,
            pid: 441200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '端州区',
            children: []
          },
          {
            id: 441203,
            pid: 441200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '鼎湖区',
            children: []
          },
          {
            id: 441204,
            pid: 441200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '高要区',
            children: []
          },
          {
            id: 441223,
            pid: 441200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '广宁县',
            children: []
          },
          {
            id: 441224,
            pid: 441200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '怀集县',
            children: []
          },
          {
            id: 441225,
            pid: 441200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '封开县',
            children: []
          },
          {
            id: 441226,
            pid: 441200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '德庆县',
            children: []
          },
          {
            id: 441284,
            pid: 441200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '四会市',
            children: []
          }
        ]
      },
      {
        id: 441300,
        pid: 440000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '惠州市',
        children: [
          {
            id: 441302,
            pid: 441300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '惠城区',
            children: []
          },
          {
            id: 441303,
            pid: 441300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '惠阳区',
            children: []
          },
          {
            id: 441322,
            pid: 441300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '博罗县',
            children: []
          },
          {
            id: 441323,
            pid: 441300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '惠东县',
            children: []
          },
          {
            id: 441324,
            pid: 441300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '龙门县',
            children: []
          }
        ]
      },
      {
        id: 441400,
        pid: 440000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '梅州市',
        children: [
          {
            id: 441402,
            pid: 441400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '梅江区',
            children: []
          },
          {
            id: 441403,
            pid: 441400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '梅县区',
            children: []
          },
          {
            id: 441422,
            pid: 441400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大埔县',
            children: []
          },
          {
            id: 441423,
            pid: 441400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '丰顺县',
            children: []
          },
          {
            id: 441424,
            pid: 441400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '五华县',
            children: []
          },
          {
            id: 441426,
            pid: 441400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '平远县',
            children: []
          },
          {
            id: 441427,
            pid: 441400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '蕉岭县',
            children: []
          },
          {
            id: 441481,
            pid: 441400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '兴宁市',
            children: []
          }
        ]
      },
      {
        id: 441500,
        pid: 440000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '汕尾市',
        children: [
          {
            id: 441502,
            pid: 441500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '城区',
            children: []
          },
          {
            id: 441521,
            pid: 441500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '海丰县',
            children: []
          },
          {
            id: 441523,
            pid: 441500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '陆河县',
            children: []
          },
          {
            id: 441581,
            pid: 441500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '陆丰市',
            children: []
          }
        ]
      },
      {
        id: 441600,
        pid: 440000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '河源市',
        children: [
          {
            id: 441602,
            pid: 441600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '源城区',
            children: []
          },
          {
            id: 441621,
            pid: 441600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '紫金县',
            children: []
          },
          {
            id: 441622,
            pid: 441600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '龙川县',
            children: []
          },
          {
            id: 441623,
            pid: 441600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '连平县',
            children: []
          },
          {
            id: 441624,
            pid: 441600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '和平县',
            children: []
          },
          {
            id: 441625,
            pid: 441600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东源县',
            children: []
          }
        ]
      },
      {
        id: 441700,
        pid: 440000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '阳江市',
        children: [
          {
            id: 441702,
            pid: 441700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '江城区',
            children: []
          },
          {
            id: 441704,
            pid: 441700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '阳东区',
            children: []
          },
          {
            id: 441721,
            pid: 441700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '阳西县',
            children: []
          },
          {
            id: 441781,
            pid: 441700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '阳春市',
            children: []
          }
        ]
      },
      {
        id: 441800,
        pid: 440000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '清远市',
        children: [
          {
            id: 441802,
            pid: 441800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '清城区',
            children: []
          },
          {
            id: 441803,
            pid: 441800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '清新区',
            children: []
          },
          {
            id: 441821,
            pid: 441800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '佛冈县',
            children: []
          },
          {
            id: 441823,
            pid: 441800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '阳山县',
            children: []
          },
          {
            id: 441825,
            pid: 441800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '连山壮族瑶族自治县',
            children: []
          },
          {
            id: 441826,
            pid: 441800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '连南瑶族自治县',
            children: []
          },
          {
            id: 441881,
            pid: 441800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '英德市',
            children: []
          },
          {
            id: 441882,
            pid: 441800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '连州市',
            children: []
          }
        ]
      },
      {
        id: 441900,
        pid: 440000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '东莞市',
        children: [
          {
            id: 44190011,
            pid: 441900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '常平镇',
            children: []
          },
          {
            id: 441900003,
            pid: 441900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东城街道',
            children: []
          },
          {
            id: 441900004,
            pid: 441900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南城街道',
            children: []
          },
          {
            id: 441900005,
            pid: 441900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '万江街道',
            children: []
          },
          {
            id: 441900006,
            pid: 441900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '莞城街道',
            children: []
          },
          {
            id: 441900101,
            pid: 441900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '石碣镇',
            children: []
          },
          {
            id: 441900102,
            pid: 441900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '石龙镇',
            children: []
          },
          {
            id: 441900103,
            pid: 441900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '茶山镇',
            children: []
          },
          {
            id: 441900104,
            pid: 441900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '石排镇',
            children: []
          },
          {
            id: 441900105,
            pid: 441900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '企石镇',
            children: []
          },
          {
            id: 441900106,
            pid: 441900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '横沥镇',
            children: []
          },
          {
            id: 441900107,
            pid: 441900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '桥头镇',
            children: []
          },
          {
            id: 441900108,
            pid: 441900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '谢岗镇',
            children: []
          },
          {
            id: 441900109,
            pid: 441900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东坑镇',
            children: []
          },
          {
            id: 441900111,
            pid: 441900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '寮步镇',
            children: []
          },
          {
            id: 441900112,
            pid: 441900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '樟木头镇',
            children: []
          },
          {
            id: 441900113,
            pid: 441900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大朗镇',
            children: []
          },
          {
            id: 441900114,
            pid: 441900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '黄江镇',
            children: []
          },
          {
            id: 441900115,
            pid: 441900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '清溪镇',
            children: []
          },
          {
            id: 441900116,
            pid: 441900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '塘厦镇',
            children: []
          },
          {
            id: 441900117,
            pid: 441900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '凤岗镇',
            children: []
          },
          {
            id: 441900118,
            pid: 441900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大岭山镇',
            children: []
          },
          {
            id: 441900119,
            pid: 441900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '长安镇',
            children: []
          },
          {
            id: 441900121,
            pid: 441900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '虎门镇',
            children: []
          },
          {
            id: 441900122,
            pid: 441900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '厚街镇',
            children: []
          },
          {
            id: 441900123,
            pid: 441900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '沙田镇',
            children: []
          },
          {
            id: 441900124,
            pid: 441900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '道滘镇',
            children: []
          },
          {
            id: 441900125,
            pid: 441900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '洪梅镇',
            children: []
          },
          {
            id: 441900126,
            pid: 441900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '麻涌镇',
            children: []
          },
          {
            id: 441900127,
            pid: 441900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '望牛墩镇',
            children: []
          },
          {
            id: 441900128,
            pid: 441900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '中堂镇',
            children: []
          },
          {
            id: 441900129,
            pid: 441900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '高埗镇',
            children: []
          },
          {
            id: 441900401,
            pid: 441900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '松山湖',
            children: []
          },
          {
            id: 441900402,
            pid: 441900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东莞港',
            children: []
          },
          {
            id: 441900403,
            pid: 441900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东莞生态园',
            children: []
          }
        ]
      },
      {
        id: 442000,
        pid: 440000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '中山市',
        children: [
          {
            id: 4420001,
            pid: 442000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '小榄镇',
            children: []
          },
          {
            id: 44200011,
            pid: 442000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '横栏镇',
            children: []
          },
          {
            id: 442000001,
            pid: 442000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '石岐街道',
            children: []
          },
          {
            id: 442000002,
            pid: 442000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东区街道',
            children: []
          },
          {
            id: 442000003,
            pid: 442000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '中山港街道',
            children: []
          },
          {
            id: 442000004,
            pid: 442000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '西区街道',
            children: []
          },
          {
            id: 442000005,
            pid: 442000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南区街道',
            children: []
          },
          {
            id: 442000006,
            pid: 442000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '五桂山街道',
            children: []
          },
          {
            id: 442000101,
            pid: 442000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '黄圃镇',
            children: []
          },
          {
            id: 442000102,
            pid: 442000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '民众镇',
            children: []
          },
          {
            id: 442000103,
            pid: 442000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东凤镇',
            children: []
          },
          {
            id: 442000104,
            pid: 442000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东升镇',
            children: []
          },
          {
            id: 442000105,
            pid: 442000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '古镇镇',
            children: []
          },
          {
            id: 442000106,
            pid: 442000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '沙溪镇',
            children: []
          },
          {
            id: 442000107,
            pid: 442000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '坦洲镇',
            children: []
          },
          {
            id: 442000108,
            pid: 442000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '港口镇',
            children: []
          },
          {
            id: 442000109,
            pid: 442000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '三角镇',
            children: []
          },
          {
            id: 442000111,
            pid: 442000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南头镇',
            children: []
          },
          {
            id: 442000112,
            pid: 442000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '阜沙镇',
            children: []
          },
          {
            id: 442000113,
            pid: 442000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南朗镇',
            children: []
          },
          {
            id: 442000114,
            pid: 442000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '三乡镇',
            children: []
          },
          {
            id: 442000115,
            pid: 442000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '板芙镇',
            children: []
          },
          {
            id: 442000116,
            pid: 442000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大涌镇',
            children: []
          },
          {
            id: 442000117,
            pid: 442000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '神湾镇',
            children: []
          }
        ]
      },
      {
        id: 445100,
        pid: 440000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '潮州市',
        children: [
          {
            id: 445102,
            pid: 445100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '湘桥区',
            children: []
          },
          {
            id: 445103,
            pid: 445100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '潮安区',
            children: []
          },
          {
            id: 445122,
            pid: 445100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '饶平县',
            children: []
          }
        ]
      },
      {
        id: 445200,
        pid: 440000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '揭阳市',
        children: [
          {
            id: 445202,
            pid: 445200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '榕城区',
            children: []
          },
          {
            id: 445203,
            pid: 445200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '揭东区',
            children: []
          },
          {
            id: 445222,
            pid: 445200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '揭西县',
            children: []
          },
          {
            id: 445224,
            pid: 445200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '惠来县',
            children: []
          },
          {
            id: 445281,
            pid: 445200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '普宁市',
            children: []
          }
        ]
      },
      {
        id: 445300,
        pid: 440000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '云浮市',
        children: [
          {
            id: 445302,
            pid: 445300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '云城区',
            children: []
          },
          {
            id: 445303,
            pid: 445300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '云安区',
            children: []
          },
          {
            id: 445321,
            pid: 445300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新兴县',
            children: []
          },
          {
            id: 445322,
            pid: 445300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '郁南县',
            children: []
          },
          {
            id: 445381,
            pid: 445300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '罗定市',
            children: []
          }
        ]
      }
    ]
  },
  {
    id: 450000,
    pid: 0,
    level: 1,
    createdAt: null,
    updatedAt: null,
    deletedAt: null,
    isValid: 1,
    label: '广西壮族自治区',
    children: [
      {
        id: 450100,
        pid: 450000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '南宁市',
        children: [
          {
            id: 450102,
            pid: 450100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '兴宁区',
            children: []
          },
          {
            id: 450103,
            pid: 450100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '青秀区',
            children: []
          },
          {
            id: 450105,
            pid: 450100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '江南区',
            children: []
          },
          {
            id: 450107,
            pid: 450100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '西乡塘区',
            children: []
          },
          {
            id: 450108,
            pid: 450100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '良庆区',
            children: []
          },
          {
            id: 450109,
            pid: 450100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '邕宁区',
            children: []
          },
          {
            id: 450110,
            pid: 450100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '武鸣区',
            children: []
          },
          {
            id: 450123,
            pid: 450100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '隆安县',
            children: []
          },
          {
            id: 450124,
            pid: 450100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '马山县',
            children: []
          },
          {
            id: 450125,
            pid: 450100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '上林县',
            children: []
          },
          {
            id: 450126,
            pid: 450100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宾阳县',
            children: []
          },
          {
            id: 450127,
            pid: 450100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '横县',
            children: []
          }
        ]
      },
      {
        id: 450200,
        pid: 450000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '柳州市',
        children: [
          {
            id: 450202,
            pid: 450200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '城中区',
            children: []
          },
          {
            id: 450203,
            pid: 450200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '鱼峰区',
            children: []
          },
          {
            id: 450204,
            pid: 450200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '柳南区',
            children: []
          },
          {
            id: 450205,
            pid: 450200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '柳北区',
            children: []
          },
          {
            id: 450206,
            pid: 450200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '柳江区',
            children: []
          },
          {
            id: 450222,
            pid: 450200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '柳城县',
            children: []
          },
          {
            id: 450223,
            pid: 450200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '鹿寨县',
            children: []
          },
          {
            id: 450224,
            pid: 450200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '融安县',
            children: []
          },
          {
            id: 450225,
            pid: 450200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '融水苗族自治县',
            children: []
          },
          {
            id: 450226,
            pid: 450200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '三江侗族自治县',
            children: []
          }
        ]
      },
      {
        id: 450300,
        pid: 450000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '桂林市',
        children: [
          {
            id: 450302,
            pid: 450300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '秀峰区',
            children: []
          },
          {
            id: 450303,
            pid: 450300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '叠彩区',
            children: []
          },
          {
            id: 450304,
            pid: 450300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '象山区',
            children: []
          },
          {
            id: 450305,
            pid: 450300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '七星区',
            children: []
          },
          {
            id: 450311,
            pid: 450300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '雁山区',
            children: []
          },
          {
            id: 450312,
            pid: 450300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '临桂区',
            children: []
          },
          {
            id: 450321,
            pid: 450300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '阳朔县',
            children: []
          },
          {
            id: 450323,
            pid: 450300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '灵川县',
            children: []
          },
          {
            id: 450324,
            pid: 450300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '全州县',
            children: []
          },
          {
            id: 450325,
            pid: 450300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '兴安县',
            children: []
          },
          {
            id: 450326,
            pid: 450300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '永福县',
            children: []
          },
          {
            id: 450327,
            pid: 450300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '灌阳县',
            children: []
          },
          {
            id: 450328,
            pid: 450300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '龙胜各族自治县',
            children: []
          },
          {
            id: 450329,
            pid: 450300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '资源县',
            children: []
          },
          {
            id: 450330,
            pid: 450300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '平乐县',
            children: []
          },
          {
            id: 450332,
            pid: 450300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '恭城瑶族自治县',
            children: []
          },
          {
            id: 450381,
            pid: 450300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '荔浦市',
            children: []
          }
        ]
      },
      {
        id: 450400,
        pid: 450000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '梧州市',
        children: [
          {
            id: 450403,
            pid: 450400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '万秀区',
            children: []
          },
          {
            id: 450405,
            pid: 450400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '长洲区',
            children: []
          },
          {
            id: 450406,
            pid: 450400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '龙圩区',
            children: []
          },
          {
            id: 450421,
            pid: 450400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '苍梧县',
            children: []
          },
          {
            id: 450422,
            pid: 450400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '藤县',
            children: []
          },
          {
            id: 450423,
            pid: 450400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '蒙山县',
            children: []
          },
          {
            id: 450481,
            pid: 450400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '岑溪市',
            children: []
          }
        ]
      },
      {
        id: 450500,
        pid: 450000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '北海市',
        children: [
          {
            id: 450502,
            pid: 450500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '海城区',
            children: []
          },
          {
            id: 450503,
            pid: 450500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '银海区',
            children: []
          },
          {
            id: 450512,
            pid: 450500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '铁山港区',
            children: []
          },
          {
            id: 450521,
            pid: 450500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '合浦县',
            children: []
          }
        ]
      },
      {
        id: 450600,
        pid: 450000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '防城港市',
        children: [
          {
            id: 450602,
            pid: 450600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '港口区',
            children: []
          },
          {
            id: 450603,
            pid: 450600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '防城区',
            children: []
          },
          {
            id: 450621,
            pid: 450600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '上思县',
            children: []
          },
          {
            id: 450681,
            pid: 450600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东兴市',
            children: []
          }
        ]
      },
      {
        id: 450700,
        pid: 450000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '钦州市',
        children: [
          {
            id: 450702,
            pid: 450700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '钦南区',
            children: []
          },
          {
            id: 450703,
            pid: 450700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '钦北区',
            children: []
          },
          {
            id: 450721,
            pid: 450700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '灵山县',
            children: []
          },
          {
            id: 450722,
            pid: 450700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '浦北县',
            children: []
          }
        ]
      },
      {
        id: 450800,
        pid: 450000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '贵港市',
        children: [
          {
            id: 450802,
            pid: 450800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '港北区',
            children: []
          },
          {
            id: 450803,
            pid: 450800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '港南区',
            children: []
          },
          {
            id: 450804,
            pid: 450800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '覃塘区',
            children: []
          },
          {
            id: 450821,
            pid: 450800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '平南县',
            children: []
          },
          {
            id: 450881,
            pid: 450800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '桂平市',
            children: []
          }
        ]
      },
      {
        id: 450900,
        pid: 450000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '玉林市',
        children: [
          {
            id: 450902,
            pid: 450900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '玉州区',
            children: []
          },
          {
            id: 450903,
            pid: 450900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '福绵区',
            children: []
          },
          {
            id: 450921,
            pid: 450900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '容县',
            children: []
          },
          {
            id: 450922,
            pid: 450900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '陆川县',
            children: []
          },
          {
            id: 450923,
            pid: 450900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '博白县',
            children: []
          },
          {
            id: 450924,
            pid: 450900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '兴业县',
            children: []
          },
          {
            id: 450981,
            pid: 450900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '北流市',
            children: []
          }
        ]
      },
      {
        id: 451000,
        pid: 450000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '百色市',
        children: [
          {
            id: 451002,
            pid: 451000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '右江区',
            children: []
          },
          {
            id: 451003,
            pid: 451000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '田阳区',
            children: []
          },
          {
            id: 451022,
            pid: 451000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '田东县',
            children: []
          },
          {
            id: 451024,
            pid: 451000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '德保县',
            children: []
          },
          {
            id: 451026,
            pid: 451000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '那坡县',
            children: []
          },
          {
            id: 451027,
            pid: 451000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '凌云县',
            children: []
          },
          {
            id: 451028,
            pid: 451000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '乐业县',
            children: []
          },
          {
            id: 451029,
            pid: 451000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '田林县',
            children: []
          },
          {
            id: 451030,
            pid: 451000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '西林县',
            children: []
          },
          {
            id: 451031,
            pid: 451000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '隆林各族自治县',
            children: []
          },
          {
            id: 451081,
            pid: 451000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '靖西市',
            children: []
          },
          {
            id: 451082,
            pid: 451000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '平果市',
            children: []
          }
        ]
      },
      {
        id: 451100,
        pid: 450000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '贺州市',
        children: [
          {
            id: 451102,
            pid: 451100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '八步区',
            children: []
          },
          {
            id: 451103,
            pid: 451100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '平桂区',
            children: []
          },
          {
            id: 451121,
            pid: 451100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '昭平县',
            children: []
          },
          {
            id: 451122,
            pid: 451100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '钟山县',
            children: []
          },
          {
            id: 451123,
            pid: 451100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '富川瑶族自治县',
            children: []
          }
        ]
      },
      {
        id: 451200,
        pid: 450000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '河池市',
        children: [
          {
            id: 451202,
            pid: 451200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '金城江区',
            children: []
          },
          {
            id: 451203,
            pid: 451200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宜州区',
            children: []
          },
          {
            id: 451221,
            pid: 451200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南丹县',
            children: []
          },
          {
            id: 451222,
            pid: 451200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '天峨县',
            children: []
          },
          {
            id: 451223,
            pid: 451200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '凤山县',
            children: []
          },
          {
            id: 451224,
            pid: 451200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东兰县',
            children: []
          },
          {
            id: 451225,
            pid: 451200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '罗城仫佬族自治县',
            children: []
          },
          {
            id: 451226,
            pid: 451200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '环江毛南族自治县',
            children: []
          },
          {
            id: 451227,
            pid: 451200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '巴马瑶族自治县',
            children: []
          },
          {
            id: 451228,
            pid: 451200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '都安瑶族自治县',
            children: []
          },
          {
            id: 451229,
            pid: 451200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大化瑶族自治县',
            children: []
          }
        ]
      },
      {
        id: 451300,
        pid: 450000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '来宾市',
        children: [
          {
            id: 451302,
            pid: 451300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '兴宾区',
            children: []
          },
          {
            id: 451321,
            pid: 451300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '忻城县',
            children: []
          },
          {
            id: 451322,
            pid: 451300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '象州县',
            children: []
          },
          {
            id: 451323,
            pid: 451300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '武宣县',
            children: []
          },
          {
            id: 451324,
            pid: 451300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '金秀瑶族自治县',
            children: []
          },
          {
            id: 451381,
            pid: 451300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '合山市',
            children: []
          }
        ]
      },
      {
        id: 451400,
        pid: 450000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '崇左市',
        children: [
          {
            id: 451402,
            pid: 451400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '江州区',
            children: []
          },
          {
            id: 451421,
            pid: 451400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '扶绥县',
            children: []
          },
          {
            id: 451422,
            pid: 451400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宁明县',
            children: []
          },
          {
            id: 451423,
            pid: 451400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '龙州县',
            children: []
          },
          {
            id: 451424,
            pid: 451400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大新县',
            children: []
          },
          {
            id: 451425,
            pid: 451400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '天等县',
            children: []
          },
          {
            id: 451481,
            pid: 451400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '凭祥市',
            children: []
          }
        ]
      }
    ]
  },
  {
    id: 460000,
    pid: 0,
    level: 1,
    createdAt: null,
    updatedAt: null,
    deletedAt: null,
    isValid: 1,
    label: '海南省',
    children: [
      {
        id: 460100,
        pid: 460000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '海口市',
        children: [
          {
            id: 460105,
            pid: 460100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '秀英区',
            children: []
          },
          {
            id: 460106,
            pid: 460100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '龙华区',
            children: []
          },
          {
            id: 460107,
            pid: 460100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '琼山区',
            children: []
          },
          {
            id: 460108,
            pid: 460100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '美兰区',
            children: []
          }
        ]
      },
      {
        id: 460200,
        pid: 460000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '三亚市',
        children: [
          {
            id: 460202,
            pid: 460200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '海棠区',
            children: []
          },
          {
            id: 460203,
            pid: 460200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '吉阳区',
            children: []
          },
          {
            id: 460204,
            pid: 460200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '天涯区',
            children: []
          },
          {
            id: 460205,
            pid: 460200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '崖州区',
            children: []
          }
        ]
      },
      {
        id: 460300,
        pid: 460000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '三沙市',
        children: [
          {
            id: 460321,
            pid: 460300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '西沙群岛',
            children: []
          },
          {
            id: 460322,
            pid: 460300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南沙群岛',
            children: []
          },
          {
            id: 460323,
            pid: 460300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '中沙群岛的岛礁及其海域',
            children: []
          }
        ]
      },
      {
        id: 460400,
        pid: 460000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '儋州市',
        children: [
          {
            id: 4604001,
            pid: 460400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '那大镇',
            children: []
          },
          {
            id: 4604005,
            pid: 460400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '华南热作学院',
            children: []
          },
          {
            id: 460400101,
            pid: 460400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '和庆镇',
            children: []
          },
          {
            id: 460400102,
            pid: 460400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南丰镇',
            children: []
          },
          {
            id: 460400103,
            pid: 460400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大成镇',
            children: []
          },
          {
            id: 460400104,
            pid: 460400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '雅星镇',
            children: []
          },
          {
            id: 460400105,
            pid: 460400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '兰洋镇',
            children: []
          },
          {
            id: 460400106,
            pid: 460400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '光村镇',
            children: []
          },
          {
            id: 460400107,
            pid: 460400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '木棠镇',
            children: []
          },
          {
            id: 460400108,
            pid: 460400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '海头镇',
            children: []
          },
          {
            id: 460400109,
            pid: 460400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '峨蔓镇',
            children: []
          },
          {
            id: 460400111,
            pid: 460400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '王五镇',
            children: []
          },
          {
            id: 460400112,
            pid: 460400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '白马井镇',
            children: []
          },
          {
            id: 460400113,
            pid: 460400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '中和镇',
            children: []
          },
          {
            id: 460400114,
            pid: 460400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '排浦镇',
            children: []
          },
          {
            id: 460400115,
            pid: 460400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东成镇',
            children: []
          },
          {
            id: 460400116,
            pid: 460400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新州镇',
            children: []
          },
          {
            id: 460400499,
            pid: 460400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '洋浦经济开发区',
            children: []
          }
        ]
      },
      {
        id: 469000,
        pid: 460000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '省直辖县级行政区划',
        children: [
          {
            id: 469001,
            pid: 469000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '五指山市',
            children: []
          },
          {
            id: 469002,
            pid: 469000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '琼海市',
            children: []
          },
          {
            id: 469005,
            pid: 469000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '文昌市',
            children: []
          },
          {
            id: 469006,
            pid: 469000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '万宁市',
            children: []
          },
          {
            id: 469007,
            pid: 469000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东方市',
            children: []
          },
          {
            id: 469021,
            pid: 469000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '定安县',
            children: []
          },
          {
            id: 469022,
            pid: 469000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '屯昌县',
            children: []
          },
          {
            id: 469023,
            pid: 469000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '澄迈县',
            children: []
          },
          {
            id: 469024,
            pid: 469000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '临高县',
            children: []
          },
          {
            id: 469025,
            pid: 469000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '白沙黎族自治县',
            children: []
          },
          {
            id: 469026,
            pid: 469000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '昌江黎族自治县',
            children: []
          },
          {
            id: 469027,
            pid: 469000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '乐东黎族自治县',
            children: []
          },
          {
            id: 469028,
            pid: 469000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '陵水黎族自治县',
            children: []
          },
          {
            id: 469029,
            pid: 469000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '保亭黎族苗族自治县',
            children: []
          },
          {
            id: 469030,
            pid: 469000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '琼中黎族苗族自治县',
            children: []
          }
        ]
      }
    ]
  },
  {
    id: 500000,
    pid: 0,
    level: 1,
    createdAt: null,
    updatedAt: null,
    deletedAt: null,
    isValid: 1,
    label: '重庆市',
    children: [
      {
        id: 500100,
        pid: 500000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '市辖区',
        children: [
          {
            id: 500101,
            pid: 500100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '万州区',
            children: []
          },
          {
            id: 500102,
            pid: 500100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '涪陵区',
            children: []
          },
          {
            id: 500103,
            pid: 500100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '渝中区',
            children: []
          },
          {
            id: 500104,
            pid: 500100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大渡口区',
            children: []
          },
          {
            id: 500105,
            pid: 500100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '江北区',
            children: []
          },
          {
            id: 500106,
            pid: 500100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '沙坪坝区',
            children: []
          },
          {
            id: 500107,
            pid: 500100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '九龙坡区',
            children: []
          },
          {
            id: 500108,
            pid: 500100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南岸区',
            children: []
          },
          {
            id: 500109,
            pid: 500100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '北碚区',
            children: []
          },
          {
            id: 500110,
            pid: 500100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '綦江区',
            children: []
          },
          {
            id: 500111,
            pid: 500100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大足区',
            children: []
          },
          {
            id: 500112,
            pid: 500100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '渝北区',
            children: []
          },
          {
            id: 500113,
            pid: 500100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '巴南区',
            children: []
          },
          {
            id: 500114,
            pid: 500100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '黔江区',
            children: []
          },
          {
            id: 500115,
            pid: 500100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '长寿区',
            children: []
          },
          {
            id: 500116,
            pid: 500100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '江津区',
            children: []
          },
          {
            id: 500117,
            pid: 500100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '合川区',
            children: []
          },
          {
            id: 500118,
            pid: 500100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '永川区',
            children: []
          },
          {
            id: 500119,
            pid: 500100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南川区',
            children: []
          },
          {
            id: 500120,
            pid: 500100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '璧山区',
            children: []
          },
          {
            id: 500151,
            pid: 500100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '铜梁区',
            children: []
          },
          {
            id: 500152,
            pid: 500100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '潼南区',
            children: []
          },
          {
            id: 500153,
            pid: 500100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '荣昌区',
            children: []
          },
          {
            id: 500154,
            pid: 500100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '开州区',
            children: []
          },
          {
            id: 500155,
            pid: 500100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '梁平区',
            children: []
          },
          {
            id: 500156,
            pid: 500100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '武隆区',
            children: []
          }
        ]
      },
      {
        id: 500200,
        pid: 500000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '县',
        children: [
          {
            id: 500229,
            pid: 500200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '城口县',
            children: []
          },
          {
            id: 500230,
            pid: 500200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '丰都县',
            children: []
          },
          {
            id: 500231,
            pid: 500200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '垫江县',
            children: []
          },
          {
            id: 500233,
            pid: 500200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '忠县',
            children: []
          },
          {
            id: 500235,
            pid: 500200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '云阳县',
            children: []
          },
          {
            id: 500236,
            pid: 500200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '奉节县',
            children: []
          },
          {
            id: 500237,
            pid: 500200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '巫山县',
            children: []
          },
          {
            id: 500238,
            pid: 500200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '巫溪县',
            children: []
          },
          {
            id: 500240,
            pid: 500200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '石柱土家族自治县',
            children: []
          },
          {
            id: 500241,
            pid: 500200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '秀山土家族苗族自治县',
            children: []
          },
          {
            id: 500242,
            pid: 500200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '酉阳土家族苗族自治县',
            children: []
          },
          {
            id: 500243,
            pid: 500200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '彭水苗族土家族自治县',
            children: []
          }
        ]
      }
    ]
  },
  {
    id: 510000,
    pid: 0,
    level: 1,
    createdAt: null,
    updatedAt: null,
    deletedAt: null,
    isValid: 1,
    label: '四川省',
    children: [
      {
        id: 510100,
        pid: 510000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '成都市',
        children: [
          {
            id: 510104,
            pid: 510100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '锦江区',
            children: []
          },
          {
            id: 510105,
            pid: 510100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '青羊区',
            children: []
          },
          {
            id: 510106,
            pid: 510100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '金牛区',
            children: []
          },
          {
            id: 510107,
            pid: 510100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '武侯区',
            children: []
          },
          {
            id: 510108,
            pid: 510100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '成华区',
            children: []
          },
          {
            id: 510112,
            pid: 510100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '龙泉驿区',
            children: []
          },
          {
            id: 510113,
            pid: 510100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '青白江区',
            children: []
          },
          {
            id: 510114,
            pid: 510100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新都区',
            children: []
          },
          {
            id: 510115,
            pid: 510100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '温江区',
            children: []
          },
          {
            id: 510116,
            pid: 510100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '双流区',
            children: []
          },
          {
            id: 510117,
            pid: 510100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '郫都区',
            children: []
          },
          {
            id: 510118,
            pid: 510100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新津区',
            children: []
          },
          {
            id: 510121,
            pid: 510100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '金堂县',
            children: []
          },
          {
            id: 510129,
            pid: 510100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大邑县',
            children: []
          },
          {
            id: 510131,
            pid: 510100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '蒲江县',
            children: []
          },
          {
            id: 510181,
            pid: 510100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '都江堰市',
            children: []
          },
          {
            id: 510182,
            pid: 510100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '彭州市',
            children: []
          },
          {
            id: 510183,
            pid: 510100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '邛崃市',
            children: []
          },
          {
            id: 510184,
            pid: 510100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '崇州市',
            children: []
          },
          {
            id: 510185,
            pid: 510100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '简阳市',
            children: []
          }
        ]
      },
      {
        id: 510300,
        pid: 510000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '自贡市',
        children: [
          {
            id: 510302,
            pid: 510300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '自流井区',
            children: []
          },
          {
            id: 510303,
            pid: 510300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '贡井区',
            children: []
          },
          {
            id: 510304,
            pid: 510300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大安区',
            children: []
          },
          {
            id: 510311,
            pid: 510300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '沿滩区',
            children: []
          },
          {
            id: 510321,
            pid: 510300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '荣县',
            children: []
          },
          {
            id: 510322,
            pid: 510300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '富顺县',
            children: []
          }
        ]
      },
      {
        id: 510400,
        pid: 510000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '攀枝花市',
        children: [
          {
            id: 510402,
            pid: 510400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东区',
            children: []
          },
          {
            id: 510403,
            pid: 510400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '西区',
            children: []
          },
          {
            id: 510411,
            pid: 510400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '仁和区',
            children: []
          },
          {
            id: 510421,
            pid: 510400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '米易县',
            children: []
          },
          {
            id: 510422,
            pid: 510400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '盐边县',
            children: []
          }
        ]
      },
      {
        id: 510500,
        pid: 510000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '泸州市',
        children: [
          {
            id: 510502,
            pid: 510500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '江阳区',
            children: []
          },
          {
            id: 510503,
            pid: 510500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '纳溪区',
            children: []
          },
          {
            id: 510504,
            pid: 510500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '龙马潭区',
            children: []
          },
          {
            id: 510521,
            pid: 510500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '泸县',
            children: []
          },
          {
            id: 510522,
            pid: 510500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '合江县',
            children: []
          },
          {
            id: 510524,
            pid: 510500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '叙永县',
            children: []
          },
          {
            id: 510525,
            pid: 510500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '古蔺县',
            children: []
          }
        ]
      },
      {
        id: 510600,
        pid: 510000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '德阳市',
        children: [
          {
            id: 510603,
            pid: 510600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '旌阳区',
            children: []
          },
          {
            id: 510604,
            pid: 510600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '罗江区',
            children: []
          },
          {
            id: 510623,
            pid: 510600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '中江县',
            children: []
          },
          {
            id: 510681,
            pid: 510600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '广汉市',
            children: []
          },
          {
            id: 510682,
            pid: 510600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '什邡市',
            children: []
          },
          {
            id: 510683,
            pid: 510600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '绵竹市',
            children: []
          }
        ]
      },
      {
        id: 510700,
        pid: 510000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '绵阳市',
        children: [
          {
            id: 510703,
            pid: 510700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '涪城区',
            children: []
          },
          {
            id: 510704,
            pid: 510700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '游仙区',
            children: []
          },
          {
            id: 510705,
            pid: 510700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '安州区',
            children: []
          },
          {
            id: 510722,
            pid: 510700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '三台县',
            children: []
          },
          {
            id: 510723,
            pid: 510700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '盐亭县',
            children: []
          },
          {
            id: 510725,
            pid: 510700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '梓潼县',
            children: []
          },
          {
            id: 510726,
            pid: 510700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '北川羌族自治县',
            children: []
          },
          {
            id: 510727,
            pid: 510700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '平武县',
            children: []
          },
          {
            id: 510781,
            pid: 510700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '江油市',
            children: []
          }
        ]
      },
      {
        id: 510800,
        pid: 510000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '广元市',
        children: [
          {
            id: 510802,
            pid: 510800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '利州区',
            children: []
          },
          {
            id: 510811,
            pid: 510800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '昭化区',
            children: []
          },
          {
            id: 510812,
            pid: 510800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '朝天区',
            children: []
          },
          {
            id: 510821,
            pid: 510800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '旺苍县',
            children: []
          },
          {
            id: 510822,
            pid: 510800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '青川县',
            children: []
          },
          {
            id: 510823,
            pid: 510800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '剑阁县',
            children: []
          },
          {
            id: 510824,
            pid: 510800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '苍溪县',
            children: []
          }
        ]
      },
      {
        id: 510900,
        pid: 510000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '遂宁市',
        children: [
          {
            id: 510903,
            pid: 510900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '船山区',
            children: []
          },
          {
            id: 510904,
            pid: 510900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '安居区',
            children: []
          },
          {
            id: 510921,
            pid: 510900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '蓬溪县',
            children: []
          },
          {
            id: 510923,
            pid: 510900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大英县',
            children: []
          },
          {
            id: 510981,
            pid: 510900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '射洪市',
            children: []
          }
        ]
      },
      {
        id: 511000,
        pid: 510000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '内江市',
        children: [
          {
            id: 511002,
            pid: 511000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '市中区',
            children: []
          },
          {
            id: 511011,
            pid: 511000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东兴区',
            children: []
          },
          {
            id: 511024,
            pid: 511000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '威远县',
            children: []
          },
          {
            id: 511025,
            pid: 511000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '资中县',
            children: []
          },
          {
            id: 511071,
            pid: 511000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '内江经济开发区',
            children: []
          },
          {
            id: 511083,
            pid: 511000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '隆昌市',
            children: []
          }
        ]
      },
      {
        id: 511100,
        pid: 510000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '乐山市',
        children: [
          {
            id: 511102,
            pid: 511100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '市中区',
            children: []
          },
          {
            id: 511111,
            pid: 511100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '沙湾区',
            children: []
          },
          {
            id: 511112,
            pid: 511100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '五通桥区',
            children: []
          },
          {
            id: 511113,
            pid: 511100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '金口河区',
            children: []
          },
          {
            id: 511123,
            pid: 511100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '犍为县',
            children: []
          },
          {
            id: 511124,
            pid: 511100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '井研县',
            children: []
          },
          {
            id: 511126,
            pid: 511100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '夹江县',
            children: []
          },
          {
            id: 511129,
            pid: 511100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '沐川县',
            children: []
          },
          {
            id: 511132,
            pid: 511100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '峨边彝族自治县',
            children: []
          },
          {
            id: 511133,
            pid: 511100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '马边彝族自治县',
            children: []
          },
          {
            id: 511181,
            pid: 511100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '峨眉山市',
            children: []
          }
        ]
      },
      {
        id: 511300,
        pid: 510000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '南充市',
        children: [
          {
            id: 511302,
            pid: 511300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '顺庆区',
            children: []
          },
          {
            id: 511303,
            pid: 511300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '高坪区',
            children: []
          },
          {
            id: 511304,
            pid: 511300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '嘉陵区',
            children: []
          },
          {
            id: 511321,
            pid: 511300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南部县',
            children: []
          },
          {
            id: 511322,
            pid: 511300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '营山县',
            children: []
          },
          {
            id: 511323,
            pid: 511300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '蓬安县',
            children: []
          },
          {
            id: 511324,
            pid: 511300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '仪陇县',
            children: []
          },
          {
            id: 511325,
            pid: 511300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '西充县',
            children: []
          },
          {
            id: 511381,
            pid: 511300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '阆中市',
            children: []
          }
        ]
      },
      {
        id: 511400,
        pid: 510000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '眉山市',
        children: [
          {
            id: 511402,
            pid: 511400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东坡区',
            children: []
          },
          {
            id: 511403,
            pid: 511400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '彭山区',
            children: []
          },
          {
            id: 511421,
            pid: 511400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '仁寿县',
            children: []
          },
          {
            id: 511423,
            pid: 511400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '洪雅县',
            children: []
          },
          {
            id: 511424,
            pid: 511400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '丹棱县',
            children: []
          },
          {
            id: 511425,
            pid: 511400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '青神县',
            children: []
          }
        ]
      },
      {
        id: 511500,
        pid: 510000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '宜宾市',
        children: [
          {
            id: 511502,
            pid: 511500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '翠屏区',
            children: []
          },
          {
            id: 511503,
            pid: 511500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南溪区',
            children: []
          },
          {
            id: 511504,
            pid: 511500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '叙州区',
            children: []
          },
          {
            id: 511523,
            pid: 511500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '江安县',
            children: []
          },
          {
            id: 511524,
            pid: 511500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '长宁县',
            children: []
          },
          {
            id: 511525,
            pid: 511500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '高县',
            children: []
          },
          {
            id: 511526,
            pid: 511500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '珙县',
            children: []
          },
          {
            id: 511527,
            pid: 511500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '筠连县',
            children: []
          },
          {
            id: 511528,
            pid: 511500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '兴文县',
            children: []
          },
          {
            id: 511529,
            pid: 511500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '屏山县',
            children: []
          }
        ]
      },
      {
        id: 511600,
        pid: 510000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '广安市',
        children: [
          {
            id: 511602,
            pid: 511600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '广安区',
            children: []
          },
          {
            id: 511603,
            pid: 511600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '前锋区',
            children: []
          },
          {
            id: 511621,
            pid: 511600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '岳池县',
            children: []
          },
          {
            id: 511622,
            pid: 511600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '武胜县',
            children: []
          },
          {
            id: 511623,
            pid: 511600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '邻水县',
            children: []
          },
          {
            id: 511681,
            pid: 511600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '华蓥市',
            children: []
          }
        ]
      },
      {
        id: 511700,
        pid: 510000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '达州市',
        children: [
          {
            id: 511702,
            pid: 511700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '通川区',
            children: []
          },
          {
            id: 511703,
            pid: 511700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '达川区',
            children: []
          },
          {
            id: 511722,
            pid: 511700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宣汉县',
            children: []
          },
          {
            id: 511723,
            pid: 511700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '开江县',
            children: []
          },
          {
            id: 511724,
            pid: 511700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大竹县',
            children: []
          },
          {
            id: 511725,
            pid: 511700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '渠县',
            children: []
          },
          {
            id: 511771,
            pid: 511700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '达州经济开发区',
            children: []
          },
          {
            id: 511781,
            pid: 511700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '万源市',
            children: []
          }
        ]
      },
      {
        id: 511800,
        pid: 510000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '雅安市',
        children: [
          {
            id: 511802,
            pid: 511800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '雨城区',
            children: []
          },
          {
            id: 511803,
            pid: 511800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '名山区',
            children: []
          },
          {
            id: 511822,
            pid: 511800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '荥经县',
            children: []
          },
          {
            id: 511823,
            pid: 511800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '汉源县',
            children: []
          },
          {
            id: 511824,
            pid: 511800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '石棉县',
            children: []
          },
          {
            id: 511825,
            pid: 511800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '天全县',
            children: []
          },
          {
            id: 511826,
            pid: 511800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '芦山县',
            children: []
          },
          {
            id: 511827,
            pid: 511800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宝兴县',
            children: []
          }
        ]
      },
      {
        id: 511900,
        pid: 510000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '巴中市',
        children: [
          {
            id: 511902,
            pid: 511900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '巴州区',
            children: []
          },
          {
            id: 511903,
            pid: 511900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '恩阳区',
            children: []
          },
          {
            id: 511921,
            pid: 511900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '通江县',
            children: []
          },
          {
            id: 511922,
            pid: 511900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南江县',
            children: []
          },
          {
            id: 511923,
            pid: 511900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '平昌县',
            children: []
          },
          {
            id: 511971,
            pid: 511900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '巴中经济开发区',
            children: []
          }
        ]
      },
      {
        id: 512000,
        pid: 510000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '资阳市',
        children: [
          {
            id: 512002,
            pid: 512000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '雁江区',
            children: []
          },
          {
            id: 512021,
            pid: 512000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '安岳县',
            children: []
          },
          {
            id: 512022,
            pid: 512000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '乐至县',
            children: []
          }
        ]
      },
      {
        id: 513200,
        pid: 510000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '阿坝藏族羌族自治州',
        children: [
          {
            id: 513201,
            pid: 513200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '马尔康市',
            children: []
          },
          {
            id: 513221,
            pid: 513200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '汶川县',
            children: []
          },
          {
            id: 513222,
            pid: 513200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '理县',
            children: []
          },
          {
            id: 513223,
            pid: 513200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '茂县',
            children: []
          },
          {
            id: 513224,
            pid: 513200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '松潘县',
            children: []
          },
          {
            id: 513225,
            pid: 513200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '九寨沟县',
            children: []
          },
          {
            id: 513226,
            pid: 513200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '金川县',
            children: []
          },
          {
            id: 513227,
            pid: 513200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '小金县',
            children: []
          },
          {
            id: 513228,
            pid: 513200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '黑水县',
            children: []
          },
          {
            id: 513230,
            pid: 513200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '壤塘县',
            children: []
          },
          {
            id: 513231,
            pid: 513200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '阿坝县',
            children: []
          },
          {
            id: 513232,
            pid: 513200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '若尔盖县',
            children: []
          },
          {
            id: 513233,
            pid: 513200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '红原县',
            children: []
          }
        ]
      },
      {
        id: 513300,
        pid: 510000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '甘孜藏族自治州',
        children: [
          {
            id: 513301,
            pid: 513300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '康定市',
            children: []
          },
          {
            id: 513322,
            pid: 513300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '泸定县',
            children: []
          },
          {
            id: 513323,
            pid: 513300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '丹巴县',
            children: []
          },
          {
            id: 513324,
            pid: 513300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '九龙县',
            children: []
          },
          {
            id: 513325,
            pid: 513300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '雅江县',
            children: []
          },
          {
            id: 513326,
            pid: 513300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '道孚县',
            children: []
          },
          {
            id: 513327,
            pid: 513300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '炉霍县',
            children: []
          },
          {
            id: 513328,
            pid: 513300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '甘孜县',
            children: []
          },
          {
            id: 513329,
            pid: 513300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新龙县',
            children: []
          },
          {
            id: 513330,
            pid: 513300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '德格县',
            children: []
          },
          {
            id: 513331,
            pid: 513300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '白玉县',
            children: []
          },
          {
            id: 513332,
            pid: 513300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '石渠县',
            children: []
          },
          {
            id: 513333,
            pid: 513300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '色达县',
            children: []
          },
          {
            id: 513334,
            pid: 513300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '理塘县',
            children: []
          },
          {
            id: 513335,
            pid: 513300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '巴塘县',
            children: []
          },
          {
            id: 513336,
            pid: 513300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '乡城县',
            children: []
          },
          {
            id: 513337,
            pid: 513300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '稻城县',
            children: []
          },
          {
            id: 513338,
            pid: 513300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '得荣县',
            children: []
          }
        ]
      },
      {
        id: 513400,
        pid: 510000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '凉山彝族自治州',
        children: [
          {
            id: 513401,
            pid: 513400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '西昌市',
            children: []
          },
          {
            id: 513422,
            pid: 513400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '木里藏族自治县',
            children: []
          },
          {
            id: 513423,
            pid: 513400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '盐源县',
            children: []
          },
          {
            id: 513424,
            pid: 513400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '德昌县',
            children: []
          },
          {
            id: 513425,
            pid: 513400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '会理县',
            children: []
          },
          {
            id: 513426,
            pid: 513400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '会东县',
            children: []
          },
          {
            id: 513427,
            pid: 513400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宁南县',
            children: []
          },
          {
            id: 513428,
            pid: 513400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '普格县',
            children: []
          },
          {
            id: 513429,
            pid: 513400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '布拖县',
            children: []
          },
          {
            id: 513430,
            pid: 513400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '金阳县',
            children: []
          },
          {
            id: 513431,
            pid: 513400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '昭觉县',
            children: []
          },
          {
            id: 513432,
            pid: 513400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '喜德县',
            children: []
          },
          {
            id: 513433,
            pid: 513400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '冕宁县',
            children: []
          },
          {
            id: 513434,
            pid: 513400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '越西县',
            children: []
          },
          {
            id: 513435,
            pid: 513400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '甘洛县',
            children: []
          },
          {
            id: 513436,
            pid: 513400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '美姑县',
            children: []
          },
          {
            id: 513437,
            pid: 513400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '雷波县',
            children: []
          }
        ]
      }
    ]
  },
  {
    id: 520000,
    pid: 0,
    level: 1,
    createdAt: null,
    updatedAt: null,
    deletedAt: null,
    isValid: 1,
    label: '贵州省',
    children: [
      {
        id: 520100,
        pid: 520000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '贵阳市',
        children: [
          {
            id: 520102,
            pid: 520100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南明区',
            children: []
          },
          {
            id: 520103,
            pid: 520100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '云岩区',
            children: []
          },
          {
            id: 520111,
            pid: 520100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '花溪区',
            children: []
          },
          {
            id: 520112,
            pid: 520100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '乌当区',
            children: []
          },
          {
            id: 520113,
            pid: 520100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '白云区',
            children: []
          },
          {
            id: 520115,
            pid: 520100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '观山湖区',
            children: []
          },
          {
            id: 520121,
            pid: 520100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '开阳县',
            children: []
          },
          {
            id: 520122,
            pid: 520100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '息烽县',
            children: []
          },
          {
            id: 520123,
            pid: 520100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '修文县',
            children: []
          },
          {
            id: 520181,
            pid: 520100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '清镇市',
            children: []
          }
        ]
      },
      {
        id: 520200,
        pid: 520000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '六盘水市',
        children: [
          {
            id: 520201,
            pid: 520200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '钟山区',
            children: []
          },
          {
            id: 520203,
            pid: 520200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '六枝特区',
            children: []
          },
          {
            id: 520221,
            pid: 520200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '水城县',
            children: []
          },
          {
            id: 520281,
            pid: 520200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '盘州市',
            children: []
          }
        ]
      },
      {
        id: 520300,
        pid: 520000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '遵义市',
        children: [
          {
            id: 520302,
            pid: 520300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '红花岗区',
            children: []
          },
          {
            id: 520303,
            pid: 520300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '汇川区',
            children: []
          },
          {
            id: 520304,
            pid: 520300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '播州区',
            children: []
          },
          {
            id: 520322,
            pid: 520300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '桐梓县',
            children: []
          },
          {
            id: 520323,
            pid: 520300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '绥阳县',
            children: []
          },
          {
            id: 520324,
            pid: 520300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '正安县',
            children: []
          },
          {
            id: 520325,
            pid: 520300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '道真仡佬族苗族自治县',
            children: []
          },
          {
            id: 520326,
            pid: 520300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '务川仡佬族苗族自治县',
            children: []
          },
          {
            id: 520327,
            pid: 520300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '凤冈县',
            children: []
          },
          {
            id: 520328,
            pid: 520300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '湄潭县',
            children: []
          },
          {
            id: 520329,
            pid: 520300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '余庆县',
            children: []
          },
          {
            id: 520330,
            pid: 520300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '习水县',
            children: []
          },
          {
            id: 520381,
            pid: 520300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '赤水市',
            children: []
          },
          {
            id: 520382,
            pid: 520300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '仁怀市',
            children: []
          }
        ]
      },
      {
        id: 520400,
        pid: 520000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '安顺市',
        children: [
          {
            id: 520402,
            pid: 520400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '西秀区',
            children: []
          },
          {
            id: 520403,
            pid: 520400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '平坝区',
            children: []
          },
          {
            id: 520422,
            pid: 520400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '普定县',
            children: []
          },
          {
            id: 520423,
            pid: 520400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '镇宁布依族苗族自治县',
            children: []
          },
          {
            id: 520424,
            pid: 520400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '关岭布依族苗族自治县',
            children: []
          },
          {
            id: 520425,
            pid: 520400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '紫云苗族布依族自治县',
            children: []
          }
        ]
      },
      {
        id: 520500,
        pid: 520000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '毕节市',
        children: [
          {
            id: 520502,
            pid: 520500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '七星关区',
            children: []
          },
          {
            id: 520521,
            pid: 520500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大方县',
            children: []
          },
          {
            id: 520522,
            pid: 520500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '黔西县',
            children: []
          },
          {
            id: 520523,
            pid: 520500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '金沙县',
            children: []
          },
          {
            id: 520524,
            pid: 520500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '织金县',
            children: []
          },
          {
            id: 520525,
            pid: 520500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '纳雍县',
            children: []
          },
          {
            id: 520526,
            pid: 520500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '威宁彝族回族苗族自治县',
            children: []
          },
          {
            id: 520527,
            pid: 520500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '赫章县',
            children: []
          }
        ]
      },
      {
        id: 520600,
        pid: 520000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '铜仁市',
        children: [
          {
            id: 520602,
            pid: 520600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '碧江区',
            children: []
          },
          {
            id: 520603,
            pid: 520600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '万山区',
            children: []
          },
          {
            id: 520621,
            pid: 520600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '江口县',
            children: []
          },
          {
            id: 520622,
            pid: 520600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '玉屏侗族自治县',
            children: []
          },
          {
            id: 520623,
            pid: 520600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '石阡县',
            children: []
          },
          {
            id: 520624,
            pid: 520600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '思南县',
            children: []
          },
          {
            id: 520625,
            pid: 520600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '印江土家族苗族自治县',
            children: []
          },
          {
            id: 520626,
            pid: 520600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '德江县',
            children: []
          },
          {
            id: 520627,
            pid: 520600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '沿河土家族自治县',
            children: []
          },
          {
            id: 520628,
            pid: 520600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '松桃苗族自治县',
            children: []
          }
        ]
      },
      {
        id: 522300,
        pid: 520000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '黔西南布依族苗族自治州',
        children: [
          {
            id: 522301,
            pid: 522300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '兴义市',
            children: []
          },
          {
            id: 522302,
            pid: 522300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '兴仁市',
            children: []
          },
          {
            id: 522323,
            pid: 522300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '普安县',
            children: []
          },
          {
            id: 522324,
            pid: 522300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '晴隆县',
            children: []
          },
          {
            id: 522325,
            pid: 522300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '贞丰县',
            children: []
          },
          {
            id: 522326,
            pid: 522300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '望谟县',
            children: []
          },
          {
            id: 522327,
            pid: 522300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '册亨县',
            children: []
          },
          {
            id: 522328,
            pid: 522300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '安龙县',
            children: []
          }
        ]
      },
      {
        id: 522600,
        pid: 520000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '黔东南苗族侗族自治州',
        children: [
          {
            id: 522601,
            pid: 522600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '凯里市',
            children: []
          },
          {
            id: 522622,
            pid: 522600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '黄平县',
            children: []
          },
          {
            id: 522623,
            pid: 522600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '施秉县',
            children: []
          },
          {
            id: 522624,
            pid: 522600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '三穗县',
            children: []
          },
          {
            id: 522625,
            pid: 522600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '镇远县',
            children: []
          },
          {
            id: 522626,
            pid: 522600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '岑巩县',
            children: []
          },
          {
            id: 522627,
            pid: 522600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '天柱县',
            children: []
          },
          {
            id: 522628,
            pid: 522600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '锦屏县',
            children: []
          },
          {
            id: 522629,
            pid: 522600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '剑河县',
            children: []
          },
          {
            id: 522630,
            pid: 522600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '台江县',
            children: []
          },
          {
            id: 522631,
            pid: 522600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '黎平县',
            children: []
          },
          {
            id: 522632,
            pid: 522600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '榕江县',
            children: []
          },
          {
            id: 522633,
            pid: 522600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '从江县',
            children: []
          },
          {
            id: 522634,
            pid: 522600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '雷山县',
            children: []
          },
          {
            id: 522635,
            pid: 522600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '麻江县',
            children: []
          },
          {
            id: 522636,
            pid: 522600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '丹寨县',
            children: []
          }
        ]
      },
      {
        id: 522700,
        pid: 520000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '黔南布依族苗族自治州',
        children: [
          {
            id: 522701,
            pid: 522700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '都匀市',
            children: []
          },
          {
            id: 522702,
            pid: 522700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '福泉市',
            children: []
          },
          {
            id: 522722,
            pid: 522700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '荔波县',
            children: []
          },
          {
            id: 522723,
            pid: 522700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '贵定县',
            children: []
          },
          {
            id: 522725,
            pid: 522700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '瓮安县',
            children: []
          },
          {
            id: 522726,
            pid: 522700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '独山县',
            children: []
          },
          {
            id: 522727,
            pid: 522700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '平塘县',
            children: []
          },
          {
            id: 522728,
            pid: 522700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '罗甸县',
            children: []
          },
          {
            id: 522729,
            pid: 522700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '长顺县',
            children: []
          },
          {
            id: 522730,
            pid: 522700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '龙里县',
            children: []
          },
          {
            id: 522731,
            pid: 522700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '惠水县',
            children: []
          },
          {
            id: 522732,
            pid: 522700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '三都水族自治县',
            children: []
          }
        ]
      }
    ]
  },
  {
    id: 530000,
    pid: 0,
    level: 1,
    createdAt: null,
    updatedAt: null,
    deletedAt: null,
    isValid: 1,
    label: '云南省',
    children: [
      {
        id: 530100,
        pid: 530000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '昆明市',
        children: [
          {
            id: 530102,
            pid: 530100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '五华区',
            children: []
          },
          {
            id: 530103,
            pid: 530100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '盘龙区',
            children: []
          },
          {
            id: 530111,
            pid: 530100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '官渡区',
            children: []
          },
          {
            id: 530112,
            pid: 530100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '西山区',
            children: []
          },
          {
            id: 530113,
            pid: 530100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东川区',
            children: []
          },
          {
            id: 530114,
            pid: 530100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '呈贡区',
            children: []
          },
          {
            id: 530115,
            pid: 530100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '晋宁区',
            children: []
          },
          {
            id: 530124,
            pid: 530100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '富民县',
            children: []
          },
          {
            id: 530125,
            pid: 530100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宜良县',
            children: []
          },
          {
            id: 530126,
            pid: 530100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '石林彝族自治县',
            children: []
          },
          {
            id: 530127,
            pid: 530100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '嵩明县',
            children: []
          },
          {
            id: 530128,
            pid: 530100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '禄劝彝族苗族自治县',
            children: []
          },
          {
            id: 530129,
            pid: 530100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '寻甸回族彝族自治县',
            children: []
          },
          {
            id: 530181,
            pid: 530100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '安宁市',
            children: []
          }
        ]
      },
      {
        id: 530300,
        pid: 530000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '曲靖市',
        children: [
          {
            id: 530302,
            pid: 530300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '麒麟区',
            children: []
          },
          {
            id: 530303,
            pid: 530300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '沾益区',
            children: []
          },
          {
            id: 530304,
            pid: 530300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '马龙区',
            children: []
          },
          {
            id: 530322,
            pid: 530300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '陆良县',
            children: []
          },
          {
            id: 530323,
            pid: 530300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '师宗县',
            children: []
          },
          {
            id: 530324,
            pid: 530300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '罗平县',
            children: []
          },
          {
            id: 530325,
            pid: 530300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '富源县',
            children: []
          },
          {
            id: 530326,
            pid: 530300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '会泽县',
            children: []
          },
          {
            id: 530381,
            pid: 530300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宣威市',
            children: []
          }
        ]
      },
      {
        id: 530400,
        pid: 530000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '玉溪市',
        children: [
          {
            id: 530402,
            pid: 530400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '红塔区',
            children: []
          },
          {
            id: 530403,
            pid: 530400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '江川区',
            children: []
          },
          {
            id: 530423,
            pid: 530400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '通海县',
            children: []
          },
          {
            id: 530424,
            pid: 530400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '华宁县',
            children: []
          },
          {
            id: 530425,
            pid: 530400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '易门县',
            children: []
          },
          {
            id: 530426,
            pid: 530400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '峨山彝族自治县',
            children: []
          },
          {
            id: 530427,
            pid: 530400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新平彝族傣族自治县',
            children: []
          },
          {
            id: 530428,
            pid: 530400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '元江哈尼族彝族傣族自治县',
            children: []
          },
          {
            id: 530481,
            pid: 530400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '澄江市',
            children: []
          }
        ]
      },
      {
        id: 530500,
        pid: 530000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '保山市',
        children: [
          {
            id: 530502,
            pid: 530500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '隆阳区',
            children: []
          },
          {
            id: 530521,
            pid: 530500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '施甸县',
            children: []
          },
          {
            id: 530523,
            pid: 530500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '龙陵县',
            children: []
          },
          {
            id: 530524,
            pid: 530500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '昌宁县',
            children: []
          },
          {
            id: 530581,
            pid: 530500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '腾冲市',
            children: []
          }
        ]
      },
      {
        id: 530600,
        pid: 530000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '昭通市',
        children: [
          {
            id: 530602,
            pid: 530600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '昭阳区',
            children: []
          },
          {
            id: 530621,
            pid: 530600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '鲁甸县',
            children: []
          },
          {
            id: 530622,
            pid: 530600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '巧家县',
            children: []
          },
          {
            id: 530623,
            pid: 530600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '盐津县',
            children: []
          },
          {
            id: 530624,
            pid: 530600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大关县',
            children: []
          },
          {
            id: 530625,
            pid: 530600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '永善县',
            children: []
          },
          {
            id: 530626,
            pid: 530600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '绥江县',
            children: []
          },
          {
            id: 530627,
            pid: 530600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '镇雄县',
            children: []
          },
          {
            id: 530628,
            pid: 530600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '彝良县',
            children: []
          },
          {
            id: 530629,
            pid: 530600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '威信县',
            children: []
          },
          {
            id: 530681,
            pid: 530600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '水富市',
            children: []
          }
        ]
      },
      {
        id: 530700,
        pid: 530000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '丽江市',
        children: [
          {
            id: 530702,
            pid: 530700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '古城区',
            children: []
          },
          {
            id: 530721,
            pid: 530700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '玉龙纳西族自治县',
            children: []
          },
          {
            id: 530722,
            pid: 530700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '永胜县',
            children: []
          },
          {
            id: 530723,
            pid: 530700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '华坪县',
            children: []
          },
          {
            id: 530724,
            pid: 530700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宁蒗彝族自治县',
            children: []
          }
        ]
      },
      {
        id: 530800,
        pid: 530000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '普洱市',
        children: [
          {
            id: 530802,
            pid: 530800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '思茅区',
            children: []
          },
          {
            id: 530821,
            pid: 530800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宁洱哈尼族彝族自治县',
            children: []
          },
          {
            id: 530822,
            pid: 530800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '墨江哈尼族自治县',
            children: []
          },
          {
            id: 530823,
            pid: 530800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '景东彝族自治县',
            children: []
          },
          {
            id: 530824,
            pid: 530800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '景谷傣族彝族自治县',
            children: []
          },
          {
            id: 530825,
            pid: 530800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '镇沅彝族哈尼族拉祜族自治县',
            children: []
          },
          {
            id: 530826,
            pid: 530800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '江城哈尼族彝族自治县',
            children: []
          },
          {
            id: 530827,
            pid: 530800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '孟连傣族拉祜族佤族自治县',
            children: []
          },
          {
            id: 530828,
            pid: 530800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '澜沧拉祜族自治县',
            children: []
          },
          {
            id: 530829,
            pid: 530800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '西盟佤族自治县',
            children: []
          }
        ]
      },
      {
        id: 530900,
        pid: 530000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '临沧市',
        children: [
          {
            id: 530902,
            pid: 530900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '临翔区',
            children: []
          },
          {
            id: 530921,
            pid: 530900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '凤庆县',
            children: []
          },
          {
            id: 530922,
            pid: 530900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '云县',
            children: []
          },
          {
            id: 530923,
            pid: 530900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '永德县',
            children: []
          },
          {
            id: 530924,
            pid: 530900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '镇康县',
            children: []
          },
          {
            id: 530925,
            pid: 530900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '双江拉祜族佤族布朗族傣族自治县',
            children: []
          },
          {
            id: 530926,
            pid: 530900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '耿马傣族佤族自治县',
            children: []
          },
          {
            id: 530927,
            pid: 530900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '沧源佤族自治县',
            children: []
          }
        ]
      },
      {
        id: 532300,
        pid: 530000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '楚雄彝族自治州',
        children: [
          {
            id: 532301,
            pid: 532300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '楚雄市',
            children: []
          },
          {
            id: 532322,
            pid: 532300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '双柏县',
            children: []
          },
          {
            id: 532323,
            pid: 532300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '牟定县',
            children: []
          },
          {
            id: 532324,
            pid: 532300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南华县',
            children: []
          },
          {
            id: 532325,
            pid: 532300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '姚安县',
            children: []
          },
          {
            id: 532326,
            pid: 532300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大姚县',
            children: []
          },
          {
            id: 532327,
            pid: 532300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '永仁县',
            children: []
          },
          {
            id: 532328,
            pid: 532300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '元谋县',
            children: []
          },
          {
            id: 532329,
            pid: 532300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '武定县',
            children: []
          },
          {
            id: 532331,
            pid: 532300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '禄丰县',
            children: []
          }
        ]
      },
      {
        id: 532500,
        pid: 530000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '红河哈尼族彝族自治州',
        children: [
          {
            id: 532501,
            pid: 532500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '个旧市',
            children: []
          },
          {
            id: 532502,
            pid: 532500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '开远市',
            children: []
          },
          {
            id: 532503,
            pid: 532500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '蒙自市',
            children: []
          },
          {
            id: 532504,
            pid: 532500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '弥勒市',
            children: []
          },
          {
            id: 532523,
            pid: 532500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '屏边苗族自治县',
            children: []
          },
          {
            id: 532524,
            pid: 532500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '建水县',
            children: []
          },
          {
            id: 532525,
            pid: 532500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '石屏县',
            children: []
          },
          {
            id: 532527,
            pid: 532500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '泸西县',
            children: []
          },
          {
            id: 532528,
            pid: 532500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '元阳县',
            children: []
          },
          {
            id: 532529,
            pid: 532500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '红河县',
            children: []
          },
          {
            id: 532530,
            pid: 532500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '金平苗族瑶族傣族自治县',
            children: []
          },
          {
            id: 532531,
            pid: 532500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '绿春县',
            children: []
          },
          {
            id: 532532,
            pid: 532500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '河口瑶族自治县',
            children: []
          }
        ]
      },
      {
        id: 532600,
        pid: 530000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '文山壮族苗族自治州',
        children: [
          {
            id: 532601,
            pid: 532600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '文山市',
            children: []
          },
          {
            id: 532622,
            pid: 532600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '砚山县',
            children: []
          },
          {
            id: 532623,
            pid: 532600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '西畴县',
            children: []
          },
          {
            id: 532624,
            pid: 532600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '麻栗坡县',
            children: []
          },
          {
            id: 532625,
            pid: 532600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '马关县',
            children: []
          },
          {
            id: 532626,
            pid: 532600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '丘北县',
            children: []
          },
          {
            id: 532627,
            pid: 532600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '广南县',
            children: []
          },
          {
            id: 532628,
            pid: 532600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '富宁县',
            children: []
          }
        ]
      },
      {
        id: 532800,
        pid: 530000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '西双版纳傣族自治州',
        children: [
          {
            id: 532801,
            pid: 532800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '景洪市',
            children: []
          },
          {
            id: 532822,
            pid: 532800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '勐海县',
            children: []
          },
          {
            id: 532823,
            pid: 532800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '勐腊县',
            children: []
          }
        ]
      },
      {
        id: 532900,
        pid: 530000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '大理白族自治州',
        children: [
          {
            id: 532901,
            pid: 532900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大理市',
            children: []
          },
          {
            id: 532922,
            pid: 532900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '漾濞彝族自治县',
            children: []
          },
          {
            id: 532923,
            pid: 532900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '祥云县',
            children: []
          },
          {
            id: 532924,
            pid: 532900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宾川县',
            children: []
          },
          {
            id: 532925,
            pid: 532900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '弥渡县',
            children: []
          },
          {
            id: 532926,
            pid: 532900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南涧彝族自治县',
            children: []
          },
          {
            id: 532927,
            pid: 532900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '巍山彝族回族自治县',
            children: []
          },
          {
            id: 532928,
            pid: 532900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '永平县',
            children: []
          },
          {
            id: 532929,
            pid: 532900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '云龙县',
            children: []
          },
          {
            id: 532930,
            pid: 532900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '洱源县',
            children: []
          },
          {
            id: 532931,
            pid: 532900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '剑川县',
            children: []
          },
          {
            id: 532932,
            pid: 532900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '鹤庆县',
            children: []
          }
        ]
      },
      {
        id: 533100,
        pid: 530000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '德宏傣族景颇族自治州',
        children: [
          {
            id: 533102,
            pid: 533100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '瑞丽市',
            children: []
          },
          {
            id: 533103,
            pid: 533100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '芒市',
            children: []
          },
          {
            id: 533122,
            pid: 533100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '梁河县',
            children: []
          },
          {
            id: 533123,
            pid: 533100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '盈江县',
            children: []
          },
          {
            id: 533124,
            pid: 533100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '陇川县',
            children: []
          }
        ]
      },
      {
        id: 533300,
        pid: 530000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '怒江傈僳族自治州',
        children: [
          {
            id: 533301,
            pid: 533300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '泸水市',
            children: []
          },
          {
            id: 533323,
            pid: 533300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '福贡县',
            children: []
          },
          {
            id: 533324,
            pid: 533300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '贡山独龙族怒族自治县',
            children: []
          },
          {
            id: 533325,
            pid: 533300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '兰坪白族普米族自治县',
            children: []
          }
        ]
      },
      {
        id: 533400,
        pid: 530000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '迪庆藏族自治州',
        children: [
          {
            id: 533401,
            pid: 533400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '香格里拉市',
            children: []
          },
          {
            id: 533422,
            pid: 533400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '德钦县',
            children: []
          },
          {
            id: 533423,
            pid: 533400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '维西傈僳族自治县',
            children: []
          }
        ]
      }
    ]
  },
  {
    id: 540000,
    pid: 0,
    level: 1,
    createdAt: null,
    updatedAt: null,
    deletedAt: null,
    isValid: 1,
    label: '西藏自治区',
    children: [
      {
        id: 540100,
        pid: 540000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '拉萨市',
        children: [
          {
            id: 540102,
            pid: 540100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '城关区',
            children: []
          },
          {
            id: 540103,
            pid: 540100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '堆龙德庆区',
            children: []
          },
          {
            id: 540104,
            pid: 540100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '达孜区',
            children: []
          },
          {
            id: 540121,
            pid: 540100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '林周县',
            children: []
          },
          {
            id: 540122,
            pid: 540100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '当雄县',
            children: []
          },
          {
            id: 540123,
            pid: 540100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '尼木县',
            children: []
          },
          {
            id: 540124,
            pid: 540100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '曲水县',
            children: []
          },
          {
            id: 540127,
            pid: 540100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '墨竹工卡县',
            children: []
          },
          {
            id: 540171,
            pid: 540100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '格尔木藏青工业园区',
            children: []
          },
          {
            id: 540172,
            pid: 540100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '拉萨经济技术开发区',
            children: []
          },
          {
            id: 540173,
            pid: 540100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '西藏文化旅游创意园区',
            children: []
          },
          {
            id: 540174,
            pid: 540100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '达孜工业园区',
            children: []
          }
        ]
      },
      {
        id: 540200,
        pid: 540000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '日喀则市',
        children: [
          {
            id: 540202,
            pid: 540200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '桑珠孜区',
            children: []
          },
          {
            id: 540221,
            pid: 540200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南木林县',
            children: []
          },
          {
            id: 540222,
            pid: 540200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '江孜县',
            children: []
          },
          {
            id: 540223,
            pid: 540200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '定日县',
            children: []
          },
          {
            id: 540224,
            pid: 540200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '萨迦县',
            children: []
          },
          {
            id: 540225,
            pid: 540200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '拉孜县',
            children: []
          },
          {
            id: 540226,
            pid: 540200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '昂仁县',
            children: []
          },
          {
            id: 540227,
            pid: 540200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '谢通门县',
            children: []
          },
          {
            id: 540228,
            pid: 540200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '白朗县',
            children: []
          },
          {
            id: 540229,
            pid: 540200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '仁布县',
            children: []
          },
          {
            id: 540230,
            pid: 540200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '康马县',
            children: []
          },
          {
            id: 540231,
            pid: 540200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '定结县',
            children: []
          },
          {
            id: 540232,
            pid: 540200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '仲巴县',
            children: []
          },
          {
            id: 540233,
            pid: 540200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '亚东县',
            children: []
          },
          {
            id: 540234,
            pid: 540200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '吉隆县',
            children: []
          },
          {
            id: 540235,
            pid: 540200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '聂拉木县',
            children: []
          },
          {
            id: 540236,
            pid: 540200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '萨嘎县',
            children: []
          },
          {
            id: 540237,
            pid: 540200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '岗巴县',
            children: []
          }
        ]
      },
      {
        id: 540300,
        pid: 540000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '昌都市',
        children: [
          {
            id: 540302,
            pid: 540300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '卡若区',
            children: []
          },
          {
            id: 540321,
            pid: 540300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '江达县',
            children: []
          },
          {
            id: 540322,
            pid: 540300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '贡觉县',
            children: []
          },
          {
            id: 540323,
            pid: 540300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '类乌齐县',
            children: []
          },
          {
            id: 540324,
            pid: 540300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '丁青县',
            children: []
          },
          {
            id: 540325,
            pid: 540300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '察雅县',
            children: []
          },
          {
            id: 540326,
            pid: 540300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '八宿县',
            children: []
          },
          {
            id: 540327,
            pid: 540300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '左贡县',
            children: []
          },
          {
            id: 540328,
            pid: 540300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '芒康县',
            children: []
          },
          {
            id: 540329,
            pid: 540300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '洛隆县',
            children: []
          },
          {
            id: 540330,
            pid: 540300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '边坝县',
            children: []
          }
        ]
      },
      {
        id: 540400,
        pid: 540000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '林芝市',
        children: [
          {
            id: 540402,
            pid: 540400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '巴宜区',
            children: []
          },
          {
            id: 540421,
            pid: 540400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '工布江达县',
            children: []
          },
          {
            id: 540422,
            pid: 540400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '米林县',
            children: []
          },
          {
            id: 540423,
            pid: 540400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '墨脱县',
            children: []
          },
          {
            id: 540424,
            pid: 540400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '波密县',
            children: []
          },
          {
            id: 540425,
            pid: 540400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '察隅县',
            children: []
          },
          {
            id: 540426,
            pid: 540400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '朗县',
            children: []
          }
        ]
      },
      {
        id: 540500,
        pid: 540000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '山南市',
        children: [
          {
            id: 540502,
            pid: 540500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '乃东区',
            children: []
          },
          {
            id: 540521,
            pid: 540500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '扎囊县',
            children: []
          },
          {
            id: 540522,
            pid: 540500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '贡嘎县',
            children: []
          },
          {
            id: 540523,
            pid: 540500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '桑日县',
            children: []
          },
          {
            id: 540524,
            pid: 540500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '琼结县',
            children: []
          },
          {
            id: 540525,
            pid: 540500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '曲松县',
            children: []
          },
          {
            id: 540526,
            pid: 540500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '措美县',
            children: []
          },
          {
            id: 540527,
            pid: 540500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '洛扎县',
            children: []
          },
          {
            id: 540528,
            pid: 540500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '加查县',
            children: []
          },
          {
            id: 540529,
            pid: 540500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '隆子县',
            children: []
          },
          {
            id: 540530,
            pid: 540500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '错那县',
            children: []
          },
          {
            id: 540531,
            pid: 540500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '浪卡子县',
            children: []
          }
        ]
      },
      {
        id: 540600,
        pid: 540000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '那曲市',
        children: [
          {
            id: 540602,
            pid: 540600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '色尼区',
            children: []
          },
          {
            id: 540621,
            pid: 540600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '嘉黎县',
            children: []
          },
          {
            id: 540622,
            pid: 540600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '比如县',
            children: []
          },
          {
            id: 540623,
            pid: 540600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '聂荣县',
            children: []
          },
          {
            id: 540624,
            pid: 540600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '安多县',
            children: []
          },
          {
            id: 540625,
            pid: 540600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '申扎县',
            children: []
          },
          {
            id: 540626,
            pid: 540600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '索县',
            children: []
          },
          {
            id: 540627,
            pid: 540600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '班戈县',
            children: []
          },
          {
            id: 540628,
            pid: 540600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '巴青县',
            children: []
          },
          {
            id: 540629,
            pid: 540600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '尼玛县',
            children: []
          },
          {
            id: 540630,
            pid: 540600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '双湖县',
            children: []
          }
        ]
      },
      {
        id: 542500,
        pid: 540000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '阿里地区',
        children: [
          {
            id: 542521,
            pid: 542500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '普兰县',
            children: []
          },
          {
            id: 542522,
            pid: 542500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '札达县',
            children: []
          },
          {
            id: 542523,
            pid: 542500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '噶尔县',
            children: []
          },
          {
            id: 542524,
            pid: 542500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '日土县',
            children: []
          },
          {
            id: 542525,
            pid: 542500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '革吉县',
            children: []
          },
          {
            id: 542526,
            pid: 542500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '改则县',
            children: []
          },
          {
            id: 542527,
            pid: 542500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '措勤县',
            children: []
          }
        ]
      }
    ]
  },
  {
    id: 610000,
    pid: 0,
    level: 1,
    createdAt: null,
    updatedAt: null,
    deletedAt: null,
    isValid: 1,
    label: '陕西省',
    children: [
      {
        id: 610100,
        pid: 610000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '西安市',
        children: [
          {
            id: 610102,
            pid: 610100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新城区',
            children: []
          },
          {
            id: 610103,
            pid: 610100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '碑林区',
            children: []
          },
          {
            id: 610104,
            pid: 610100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '莲湖区',
            children: []
          },
          {
            id: 610111,
            pid: 610100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '灞桥区',
            children: []
          },
          {
            id: 610112,
            pid: 610100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '未央区',
            children: []
          },
          {
            id: 610113,
            pid: 610100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '雁塔区',
            children: []
          },
          {
            id: 610114,
            pid: 610100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '阎良区',
            children: []
          },
          {
            id: 610115,
            pid: 610100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '临潼区',
            children: []
          },
          {
            id: 610116,
            pid: 610100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '长安区',
            children: []
          },
          {
            id: 610117,
            pid: 610100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '高陵区',
            children: []
          },
          {
            id: 610118,
            pid: 610100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '鄠邑区',
            children: []
          },
          {
            id: 610122,
            pid: 610100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '蓝田县',
            children: []
          },
          {
            id: 610124,
            pid: 610100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '周至县',
            children: []
          }
        ]
      },
      {
        id: 610200,
        pid: 610000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '铜川市',
        children: [
          {
            id: 610202,
            pid: 610200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '王益区',
            children: []
          },
          {
            id: 610203,
            pid: 610200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '印台区',
            children: []
          },
          {
            id: 610204,
            pid: 610200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '耀州区',
            children: []
          },
          {
            id: 610222,
            pid: 610200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宜君县',
            children: []
          }
        ]
      },
      {
        id: 610300,
        pid: 610000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '宝鸡市',
        children: [
          {
            id: 610302,
            pid: 610300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '渭滨区',
            children: []
          },
          {
            id: 610303,
            pid: 610300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '金台区',
            children: []
          },
          {
            id: 610304,
            pid: 610300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '陈仓区',
            children: []
          },
          {
            id: 610322,
            pid: 610300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '凤翔县',
            children: []
          },
          {
            id: 610323,
            pid: 610300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '岐山县',
            children: []
          },
          {
            id: 610324,
            pid: 610300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '扶风县',
            children: []
          },
          {
            id: 610326,
            pid: 610300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '眉县',
            children: []
          },
          {
            id: 610327,
            pid: 610300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '陇县',
            children: []
          },
          {
            id: 610328,
            pid: 610300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '千阳县',
            children: []
          },
          {
            id: 610329,
            pid: 610300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '麟游县',
            children: []
          },
          {
            id: 610330,
            pid: 610300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '凤县',
            children: []
          },
          {
            id: 610331,
            pid: 610300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '太白县',
            children: []
          }
        ]
      },
      {
        id: 610400,
        pid: 610000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '咸阳市',
        children: [
          {
            id: 610402,
            pid: 610400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '秦都区',
            children: []
          },
          {
            id: 610403,
            pid: 610400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '杨陵区',
            children: []
          },
          {
            id: 610404,
            pid: 610400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '渭城区',
            children: []
          },
          {
            id: 610422,
            pid: 610400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '三原县',
            children: []
          },
          {
            id: 610423,
            pid: 610400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '泾阳县',
            children: []
          },
          {
            id: 610424,
            pid: 610400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '乾县',
            children: []
          },
          {
            id: 610425,
            pid: 610400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '礼泉县',
            children: []
          },
          {
            id: 610426,
            pid: 610400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '永寿县',
            children: []
          },
          {
            id: 610428,
            pid: 610400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '长武县',
            children: []
          },
          {
            id: 610429,
            pid: 610400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '旬邑县',
            children: []
          },
          {
            id: 610430,
            pid: 610400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '淳化县',
            children: []
          },
          {
            id: 610431,
            pid: 610400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '武功县',
            children: []
          },
          {
            id: 610481,
            pid: 610400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '兴平市',
            children: []
          },
          {
            id: 610482,
            pid: 610400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '彬州市',
            children: []
          }
        ]
      },
      {
        id: 610500,
        pid: 610000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '渭南市',
        children: [
          {
            id: 610502,
            pid: 610500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '临渭区',
            children: []
          },
          {
            id: 610503,
            pid: 610500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '华州区',
            children: []
          },
          {
            id: 610522,
            pid: 610500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '潼关县',
            children: []
          },
          {
            id: 610523,
            pid: 610500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大荔县',
            children: []
          },
          {
            id: 610524,
            pid: 610500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '合阳县',
            children: []
          },
          {
            id: 610525,
            pid: 610500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '澄城县',
            children: []
          },
          {
            id: 610526,
            pid: 610500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '蒲城县',
            children: []
          },
          {
            id: 610527,
            pid: 610500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '白水县',
            children: []
          },
          {
            id: 610528,
            pid: 610500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '富平县',
            children: []
          },
          {
            id: 610581,
            pid: 610500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '韩城市',
            children: []
          },
          {
            id: 610582,
            pid: 610500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '华阴市',
            children: []
          }
        ]
      },
      {
        id: 610600,
        pid: 610000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '延安市',
        children: [
          {
            id: 610602,
            pid: 610600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宝塔区',
            children: []
          },
          {
            id: 610603,
            pid: 610600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '安塞区',
            children: []
          },
          {
            id: 610621,
            pid: 610600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '延长县',
            children: []
          },
          {
            id: 610622,
            pid: 610600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '延川县',
            children: []
          },
          {
            id: 610625,
            pid: 610600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '志丹县',
            children: []
          },
          {
            id: 610626,
            pid: 610600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '吴起县',
            children: []
          },
          {
            id: 610627,
            pid: 610600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '甘泉县',
            children: []
          },
          {
            id: 610628,
            pid: 610600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '富县',
            children: []
          },
          {
            id: 610629,
            pid: 610600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '洛川县',
            children: []
          },
          {
            id: 610630,
            pid: 610600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宜川县',
            children: []
          },
          {
            id: 610631,
            pid: 610600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '黄龙县',
            children: []
          },
          {
            id: 610632,
            pid: 610600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '黄陵县',
            children: []
          },
          {
            id: 610681,
            pid: 610600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '子长市',
            children: []
          }
        ]
      },
      {
        id: 610700,
        pid: 610000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '汉中市',
        children: [
          {
            id: 610702,
            pid: 610700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '汉台区',
            children: []
          },
          {
            id: 610703,
            pid: 610700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南郑区',
            children: []
          },
          {
            id: 610722,
            pid: 610700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '城固县',
            children: []
          },
          {
            id: 610723,
            pid: 610700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '洋县',
            children: []
          },
          {
            id: 610724,
            pid: 610700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '西乡县',
            children: []
          },
          {
            id: 610725,
            pid: 610700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '勉县',
            children: []
          },
          {
            id: 610726,
            pid: 610700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宁强县',
            children: []
          },
          {
            id: 610727,
            pid: 610700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '略阳县',
            children: []
          },
          {
            id: 610728,
            pid: 610700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '镇巴县',
            children: []
          },
          {
            id: 610729,
            pid: 610700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '留坝县',
            children: []
          },
          {
            id: 610730,
            pid: 610700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '佛坪县',
            children: []
          }
        ]
      },
      {
        id: 610800,
        pid: 610000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '榆林市',
        children: [
          {
            id: 610802,
            pid: 610800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '榆阳区',
            children: []
          },
          {
            id: 610803,
            pid: 610800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '横山区',
            children: []
          },
          {
            id: 610822,
            pid: 610800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '府谷县',
            children: []
          },
          {
            id: 610824,
            pid: 610800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '靖边县',
            children: []
          },
          {
            id: 610825,
            pid: 610800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '定边县',
            children: []
          },
          {
            id: 610826,
            pid: 610800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '绥德县',
            children: []
          },
          {
            id: 610827,
            pid: 610800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '米脂县',
            children: []
          },
          {
            id: 610828,
            pid: 610800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '佳县',
            children: []
          },
          {
            id: 610829,
            pid: 610800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '吴堡县',
            children: []
          },
          {
            id: 610830,
            pid: 610800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '清涧县',
            children: []
          },
          {
            id: 610831,
            pid: 610800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '子洲县',
            children: []
          },
          {
            id: 610881,
            pid: 610800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '神木市',
            children: []
          }
        ]
      },
      {
        id: 610900,
        pid: 610000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '安康市',
        children: [
          {
            id: 610902,
            pid: 610900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '汉滨区',
            children: []
          },
          {
            id: 610921,
            pid: 610900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '汉阴县',
            children: []
          },
          {
            id: 610922,
            pid: 610900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '石泉县',
            children: []
          },
          {
            id: 610923,
            pid: 610900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宁陕县',
            children: []
          },
          {
            id: 610924,
            pid: 610900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '紫阳县',
            children: []
          },
          {
            id: 610925,
            pid: 610900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '岚皋县',
            children: []
          },
          {
            id: 610926,
            pid: 610900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '平利县',
            children: []
          },
          {
            id: 610927,
            pid: 610900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '镇坪县',
            children: []
          },
          {
            id: 610928,
            pid: 610900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '旬阳县',
            children: []
          },
          {
            id: 610929,
            pid: 610900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '白河县',
            children: []
          }
        ]
      },
      {
        id: 611000,
        pid: 610000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '商洛市',
        children: [
          {
            id: 611002,
            pid: 611000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '商州区',
            children: []
          },
          {
            id: 611021,
            pid: 611000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '洛南县',
            children: []
          },
          {
            id: 611022,
            pid: 611000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '丹凤县',
            children: []
          },
          {
            id: 611023,
            pid: 611000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '商南县',
            children: []
          },
          {
            id: 611024,
            pid: 611000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '山阳县',
            children: []
          },
          {
            id: 611025,
            pid: 611000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '镇安县',
            children: []
          },
          {
            id: 611026,
            pid: 611000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '柞水县',
            children: []
          }
        ]
      }
    ]
  },
  {
    id: 620000,
    pid: 0,
    level: 1,
    createdAt: null,
    updatedAt: null,
    deletedAt: null,
    isValid: 1,
    label: '甘肃省',
    children: [
      {
        id: 620100,
        pid: 620000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '兰州市',
        children: [
          {
            id: 620102,
            pid: 620100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '城关区',
            children: []
          },
          {
            id: 620103,
            pid: 620100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '七里河区',
            children: []
          },
          {
            id: 620104,
            pid: 620100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '西固区',
            children: []
          },
          {
            id: 620105,
            pid: 620100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '安宁区',
            children: []
          },
          {
            id: 620111,
            pid: 620100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '红古区',
            children: []
          },
          {
            id: 620121,
            pid: 620100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '永登县',
            children: []
          },
          {
            id: 620122,
            pid: 620100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '皋兰县',
            children: []
          },
          {
            id: 620123,
            pid: 620100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '榆中县',
            children: []
          },
          {
            id: 620171,
            pid: 620100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '兰州新区',
            children: []
          }
        ]
      },
      {
        id: 620200,
        pid: 620000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '嘉峪关市',
        children: [
          {
            id: 620201,
            pid: 620200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '市辖区',
            children: []
          }
        ]
      },
      {
        id: 620300,
        pid: 620000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '金昌市',
        children: [
          {
            id: 620302,
            pid: 620300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '金川区',
            children: []
          },
          {
            id: 620321,
            pid: 620300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '永昌县',
            children: []
          }
        ]
      },
      {
        id: 620400,
        pid: 620000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '白银市',
        children: [
          {
            id: 620402,
            pid: 620400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '白银区',
            children: []
          },
          {
            id: 620403,
            pid: 620400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '平川区',
            children: []
          },
          {
            id: 620421,
            pid: 620400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '靖远县',
            children: []
          },
          {
            id: 620422,
            pid: 620400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '会宁县',
            children: []
          },
          {
            id: 620423,
            pid: 620400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '景泰县',
            children: []
          }
        ]
      },
      {
        id: 620500,
        pid: 620000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '天水市',
        children: [
          {
            id: 620502,
            pid: 620500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '秦州区',
            children: []
          },
          {
            id: 620503,
            pid: 620500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '麦积区',
            children: []
          },
          {
            id: 620521,
            pid: 620500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '清水县',
            children: []
          },
          {
            id: 620522,
            pid: 620500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '秦安县',
            children: []
          },
          {
            id: 620523,
            pid: 620500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '甘谷县',
            children: []
          },
          {
            id: 620524,
            pid: 620500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '武山县',
            children: []
          },
          {
            id: 620525,
            pid: 620500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '张家川回族自治县',
            children: []
          }
        ]
      },
      {
        id: 620600,
        pid: 620000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '武威市',
        children: [
          {
            id: 620602,
            pid: 620600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '凉州区',
            children: []
          },
          {
            id: 620621,
            pid: 620600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '民勤县',
            children: []
          },
          {
            id: 620622,
            pid: 620600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '古浪县',
            children: []
          },
          {
            id: 620623,
            pid: 620600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '天祝藏族自治县',
            children: []
          }
        ]
      },
      {
        id: 620700,
        pid: 620000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '张掖市',
        children: [
          {
            id: 620702,
            pid: 620700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '甘州区',
            children: []
          },
          {
            id: 620721,
            pid: 620700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '肃南裕固族自治县',
            children: []
          },
          {
            id: 620722,
            pid: 620700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '民乐县',
            children: []
          },
          {
            id: 620723,
            pid: 620700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '临泽县',
            children: []
          },
          {
            id: 620724,
            pid: 620700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '高台县',
            children: []
          },
          {
            id: 620725,
            pid: 620700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '山丹县',
            children: []
          }
        ]
      },
      {
        id: 620800,
        pid: 620000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '平凉市',
        children: [
          {
            id: 620802,
            pid: 620800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '崆峒区',
            children: []
          },
          {
            id: 620821,
            pid: 620800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '泾川县',
            children: []
          },
          {
            id: 620822,
            pid: 620800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '灵台县',
            children: []
          },
          {
            id: 620823,
            pid: 620800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '崇信县',
            children: []
          },
          {
            id: 620825,
            pid: 620800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '庄浪县',
            children: []
          },
          {
            id: 620826,
            pid: 620800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '静宁县',
            children: []
          },
          {
            id: 620881,
            pid: 620800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '华亭市',
            children: []
          }
        ]
      },
      {
        id: 620900,
        pid: 620000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '酒泉市',
        children: [
          {
            id: 620902,
            pid: 620900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '肃州区',
            children: []
          },
          {
            id: 620921,
            pid: 620900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '金塔县',
            children: []
          },
          {
            id: 620922,
            pid: 620900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '瓜州县',
            children: []
          },
          {
            id: 620923,
            pid: 620900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '肃北蒙古族自治县',
            children: []
          },
          {
            id: 620924,
            pid: 620900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '阿克塞哈萨克族自治县',
            children: []
          },
          {
            id: 620981,
            pid: 620900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '玉门市',
            children: []
          },
          {
            id: 620982,
            pid: 620900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '敦煌市',
            children: []
          }
        ]
      },
      {
        id: 621000,
        pid: 620000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '庆阳市',
        children: [
          {
            id: 621002,
            pid: 621000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '西峰区',
            children: []
          },
          {
            id: 621021,
            pid: 621000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '庆城县',
            children: []
          },
          {
            id: 621022,
            pid: 621000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '环县',
            children: []
          },
          {
            id: 621023,
            pid: 621000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '华池县',
            children: []
          },
          {
            id: 621024,
            pid: 621000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '合水县',
            children: []
          },
          {
            id: 621025,
            pid: 621000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '正宁县',
            children: []
          },
          {
            id: 621026,
            pid: 621000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宁县',
            children: []
          },
          {
            id: 621027,
            pid: 621000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '镇原县',
            children: []
          }
        ]
      },
      {
        id: 621100,
        pid: 620000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '定西市',
        children: [
          {
            id: 621102,
            pid: 621100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '安定区',
            children: []
          },
          {
            id: 621121,
            pid: 621100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '通渭县',
            children: []
          },
          {
            id: 621122,
            pid: 621100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '陇西县',
            children: []
          },
          {
            id: 621123,
            pid: 621100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '渭源县',
            children: []
          },
          {
            id: 621124,
            pid: 621100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '临洮县',
            children: []
          },
          {
            id: 621125,
            pid: 621100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '漳县',
            children: []
          },
          {
            id: 621126,
            pid: 621100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '岷县',
            children: []
          }
        ]
      },
      {
        id: 621200,
        pid: 620000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '陇南市',
        children: [
          {
            id: 621202,
            pid: 621200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '武都区',
            children: []
          },
          {
            id: 621221,
            pid: 621200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '成县',
            children: []
          },
          {
            id: 621222,
            pid: 621200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '文县',
            children: []
          },
          {
            id: 621223,
            pid: 621200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宕昌县',
            children: []
          },
          {
            id: 621224,
            pid: 621200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '康县',
            children: []
          },
          {
            id: 621225,
            pid: 621200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '西和县',
            children: []
          },
          {
            id: 621226,
            pid: 621200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '礼县',
            children: []
          },
          {
            id: 621227,
            pid: 621200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '徽县',
            children: []
          },
          {
            id: 621228,
            pid: 621200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '两当县',
            children: []
          }
        ]
      },
      {
        id: 622900,
        pid: 620000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '临夏回族自治州',
        children: [
          {
            id: 622901,
            pid: 622900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '临夏市',
            children: []
          },
          {
            id: 622921,
            pid: 622900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '临夏县',
            children: []
          },
          {
            id: 622922,
            pid: 622900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '康乐县',
            children: []
          },
          {
            id: 622923,
            pid: 622900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '永靖县',
            children: []
          },
          {
            id: 622924,
            pid: 622900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '广河县',
            children: []
          },
          {
            id: 622925,
            pid: 622900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '和政县',
            children: []
          },
          {
            id: 622926,
            pid: 622900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东乡族自治县',
            children: []
          },
          {
            id: 622927,
            pid: 622900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '积石山保安族东乡族撒拉族自治县',
            children: []
          }
        ]
      },
      {
        id: 623000,
        pid: 620000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '甘南藏族自治州',
        children: [
          {
            id: 623001,
            pid: 623000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '合作市',
            children: []
          },
          {
            id: 623021,
            pid: 623000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '临潭县',
            children: []
          },
          {
            id: 623022,
            pid: 623000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '卓尼县',
            children: []
          },
          {
            id: 623023,
            pid: 623000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '舟曲县',
            children: []
          },
          {
            id: 623024,
            pid: 623000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '迭部县',
            children: []
          },
          {
            id: 623025,
            pid: 623000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '玛曲县',
            children: []
          },
          {
            id: 623026,
            pid: 623000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '碌曲县',
            children: []
          },
          {
            id: 623027,
            pid: 623000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '夏河县',
            children: []
          }
        ]
      }
    ]
  },
  {
    id: 630000,
    pid: 0,
    level: 1,
    createdAt: null,
    updatedAt: null,
    deletedAt: null,
    isValid: 1,
    label: '青海省',
    children: [
      {
        id: 630100,
        pid: 630000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '西宁市',
        children: [
          {
            id: 630102,
            pid: 630100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '城东区',
            children: []
          },
          {
            id: 630103,
            pid: 630100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '城中区',
            children: []
          },
          {
            id: 630104,
            pid: 630100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '城西区',
            children: []
          },
          {
            id: 630105,
            pid: 630100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '城北区',
            children: []
          },
          {
            id: 630106,
            pid: 630100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '湟中区',
            children: []
          },
          {
            id: 630121,
            pid: 630100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大通回族土族自治县',
            children: []
          },
          {
            id: 630123,
            pid: 630100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '湟源县',
            children: []
          }
        ]
      },
      {
        id: 630200,
        pid: 630000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '海东市',
        children: [
          {
            id: 630202,
            pid: 630200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '乐都区',
            children: []
          },
          {
            id: 630203,
            pid: 630200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '平安区',
            children: []
          },
          {
            id: 630222,
            pid: 630200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '民和回族土族自治县',
            children: []
          },
          {
            id: 630223,
            pid: 630200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '互助土族自治县',
            children: []
          },
          {
            id: 630224,
            pid: 630200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '化隆回族自治县',
            children: []
          },
          {
            id: 630225,
            pid: 630200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '循化撒拉族自治县',
            children: []
          }
        ]
      },
      {
        id: 632200,
        pid: 630000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '海北藏族自治州',
        children: [
          {
            id: 632221,
            pid: 632200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '门源回族自治县',
            children: []
          },
          {
            id: 632222,
            pid: 632200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '祁连县',
            children: []
          },
          {
            id: 632223,
            pid: 632200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '海晏县',
            children: []
          },
          {
            id: 632224,
            pid: 632200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '刚察县',
            children: []
          }
        ]
      },
      {
        id: 632300,
        pid: 630000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '黄南藏族自治州',
        children: [
          {
            id: 632321,
            pid: 632300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '同仁县',
            children: []
          },
          {
            id: 632322,
            pid: 632300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '尖扎县',
            children: []
          },
          {
            id: 632323,
            pid: 632300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '泽库县',
            children: []
          },
          {
            id: 632324,
            pid: 632300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '河南蒙古族自治县',
            children: []
          }
        ]
      },
      {
        id: 632500,
        pid: 630000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '海南藏族自治州',
        children: [
          {
            id: 632521,
            pid: 632500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '共和县',
            children: []
          },
          {
            id: 632522,
            pid: 632500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '同德县',
            children: []
          },
          {
            id: 632523,
            pid: 632500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '贵德县',
            children: []
          },
          {
            id: 632524,
            pid: 632500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '兴海县',
            children: []
          },
          {
            id: 632525,
            pid: 632500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '贵南县',
            children: []
          }
        ]
      },
      {
        id: 632600,
        pid: 630000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '果洛藏族自治州',
        children: [
          {
            id: 632621,
            pid: 632600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '玛沁县',
            children: []
          },
          {
            id: 632622,
            pid: 632600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '班玛县',
            children: []
          },
          {
            id: 632623,
            pid: 632600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '甘德县',
            children: []
          },
          {
            id: 632624,
            pid: 632600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '达日县',
            children: []
          },
          {
            id: 632625,
            pid: 632600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '久治县',
            children: []
          },
          {
            id: 632626,
            pid: 632600,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '玛多县',
            children: []
          }
        ]
      },
      {
        id: 632700,
        pid: 630000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '玉树藏族自治州',
        children: [
          {
            id: 632701,
            pid: 632700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '玉树市',
            children: []
          },
          {
            id: 632722,
            pid: 632700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '杂多县',
            children: []
          },
          {
            id: 632723,
            pid: 632700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '称多县',
            children: []
          },
          {
            id: 632724,
            pid: 632700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '治多县',
            children: []
          },
          {
            id: 632725,
            pid: 632700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '囊谦县',
            children: []
          },
          {
            id: 632726,
            pid: 632700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '曲麻莱县',
            children: []
          }
        ]
      },
      {
        id: 632800,
        pid: 630000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '海西蒙古族藏族自治州',
        children: [
          {
            id: 632801,
            pid: 632800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '格尔木市',
            children: []
          },
          {
            id: 632802,
            pid: 632800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '德令哈市',
            children: []
          },
          {
            id: 632803,
            pid: 632800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '茫崖市',
            children: []
          },
          {
            id: 632821,
            pid: 632800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '乌兰县',
            children: []
          },
          {
            id: 632822,
            pid: 632800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '都兰县',
            children: []
          },
          {
            id: 632823,
            pid: 632800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '天峻县',
            children: []
          },
          {
            id: 632857,
            pid: 632800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大柴旦行政委员会',
            children: []
          }
        ]
      }
    ]
  },
  {
    id: 640000,
    pid: 0,
    level: 1,
    createdAt: null,
    updatedAt: null,
    deletedAt: null,
    isValid: 1,
    label: '宁夏回族自治区',
    children: [
      {
        id: 640100,
        pid: 640000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '银川市',
        children: [
          {
            id: 640104,
            pid: 640100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '兴庆区',
            children: []
          },
          {
            id: 640105,
            pid: 640100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '西夏区',
            children: []
          },
          {
            id: 640106,
            pid: 640100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '金凤区',
            children: []
          },
          {
            id: 640121,
            pid: 640100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '永宁县',
            children: []
          },
          {
            id: 640122,
            pid: 640100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '贺兰县',
            children: []
          },
          {
            id: 640181,
            pid: 640100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '灵武市',
            children: []
          }
        ]
      },
      {
        id: 640200,
        pid: 640000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '石嘴山市',
        children: [
          {
            id: 640202,
            pid: 640200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大武口区',
            children: []
          },
          {
            id: 640205,
            pid: 640200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '惠农区',
            children: []
          },
          {
            id: 640221,
            pid: 640200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '平罗县',
            children: []
          }
        ]
      },
      {
        id: 640300,
        pid: 640000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '吴忠市',
        children: [
          {
            id: 640302,
            pid: 640300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '利通区',
            children: []
          },
          {
            id: 640303,
            pid: 640300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '红寺堡区',
            children: []
          },
          {
            id: 640323,
            pid: 640300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '盐池县',
            children: []
          },
          {
            id: 640324,
            pid: 640300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '同心县',
            children: []
          },
          {
            id: 640381,
            pid: 640300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '青铜峡市',
            children: []
          }
        ]
      },
      {
        id: 640400,
        pid: 640000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '固原市',
        children: [
          {
            id: 640402,
            pid: 640400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '原州区',
            children: []
          },
          {
            id: 640422,
            pid: 640400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '西吉县',
            children: []
          },
          {
            id: 640423,
            pid: 640400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '隆德县',
            children: []
          },
          {
            id: 640424,
            pid: 640400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '泾源县',
            children: []
          },
          {
            id: 640425,
            pid: 640400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '彭阳县',
            children: []
          }
        ]
      },
      {
        id: 640500,
        pid: 640000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '中卫市',
        children: [
          {
            id: 640502,
            pid: 640500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '沙坡头区',
            children: []
          },
          {
            id: 640521,
            pid: 640500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '中宁县',
            children: []
          },
          {
            id: 640522,
            pid: 640500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '海原县',
            children: []
          }
        ]
      }
    ]
  },
  {
    id: 650000,
    pid: 0,
    level: 1,
    createdAt: null,
    updatedAt: null,
    deletedAt: null,
    isValid: 1,
    label: '新疆维吾尔自治区',
    children: [
      {
        id: 650100,
        pid: 650000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '乌鲁木齐市',
        children: [
          {
            id: 650102,
            pid: 650100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '天山区',
            children: []
          },
          {
            id: 650103,
            pid: 650100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '沙依巴克区',
            children: []
          },
          {
            id: 650104,
            pid: 650100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新市区',
            children: []
          },
          {
            id: 650105,
            pid: 650100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '水磨沟区',
            children: []
          },
          {
            id: 650106,
            pid: 650100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '头屯河区',
            children: []
          },
          {
            id: 650107,
            pid: 650100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '达坂城区',
            children: []
          },
          {
            id: 650109,
            pid: 650100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '米东区',
            children: []
          },
          {
            id: 650121,
            pid: 650100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '乌鲁木齐县',
            children: []
          }
        ]
      },
      {
        id: 650200,
        pid: 650000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '克拉玛依市',
        children: [
          {
            id: 650202,
            pid: 650200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '独山子区',
            children: []
          },
          {
            id: 650203,
            pid: 650200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '克拉玛依区',
            children: []
          },
          {
            id: 650204,
            pid: 650200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '白碱滩区',
            children: []
          },
          {
            id: 650205,
            pid: 650200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '乌尔禾区',
            children: []
          }
        ]
      },
      {
        id: 650400,
        pid: 650000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '吐鲁番市',
        children: [
          {
            id: 650402,
            pid: 650400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '高昌区',
            children: []
          },
          {
            id: 650421,
            pid: 650400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '鄯善县',
            children: []
          },
          {
            id: 650422,
            pid: 650400,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '托克逊县',
            children: []
          }
        ]
      },
      {
        id: 650500,
        pid: 650000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '哈密市',
        children: [
          {
            id: 650502,
            pid: 650500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '伊州区',
            children: []
          },
          {
            id: 650521,
            pid: 650500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '巴里坤哈萨克自治县',
            children: []
          },
          {
            id: 650522,
            pid: 650500,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '伊吾县',
            children: []
          }
        ]
      },
      {
        id: 652300,
        pid: 650000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '昌吉回族自治州',
        children: [
          {
            id: 652301,
            pid: 652300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '昌吉市',
            children: []
          },
          {
            id: 652302,
            pid: 652300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '阜康市',
            children: []
          },
          {
            id: 652323,
            pid: 652300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '呼图壁县',
            children: []
          },
          {
            id: 652324,
            pid: 652300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '玛纳斯县',
            children: []
          },
          {
            id: 652325,
            pid: 652300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '奇台县',
            children: []
          },
          {
            id: 652327,
            pid: 652300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '吉木萨尔县',
            children: []
          },
          {
            id: 652328,
            pid: 652300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '木垒哈萨克自治县',
            children: []
          }
        ]
      },
      {
        id: 652700,
        pid: 650000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '博尔塔拉蒙古自治州',
        children: [
          {
            id: 652701,
            pid: 652700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '博乐市',
            children: []
          },
          {
            id: 652702,
            pid: 652700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '阿拉山口市',
            children: []
          },
          {
            id: 652722,
            pid: 652700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '精河县',
            children: []
          },
          {
            id: 652723,
            pid: 652700,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '温泉县',
            children: []
          }
        ]
      },
      {
        id: 652800,
        pid: 650000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '巴音郭楞蒙古自治州',
        children: [
          {
            id: 652801,
            pid: 652800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '库尔勒市',
            children: []
          },
          {
            id: 652822,
            pid: 652800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '轮台县',
            children: []
          },
          {
            id: 652823,
            pid: 652800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '尉犁县',
            children: []
          },
          {
            id: 652824,
            pid: 652800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '若羌县',
            children: []
          },
          {
            id: 652825,
            pid: 652800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '且末县',
            children: []
          },
          {
            id: 652826,
            pid: 652800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '焉耆回族自治县',
            children: []
          },
          {
            id: 652827,
            pid: 652800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '和静县',
            children: []
          },
          {
            id: 652828,
            pid: 652800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '和硕县',
            children: []
          },
          {
            id: 652829,
            pid: 652800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '博湖县',
            children: []
          },
          {
            id: 652871,
            pid: 652800,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '库尔勒经济技术开发区',
            children: []
          }
        ]
      },
      {
        id: 652900,
        pid: 650000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '阿克苏地区',
        children: [
          {
            id: 652901,
            pid: 652900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '阿克苏市',
            children: []
          },
          {
            id: 652902,
            pid: 652900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '库车市',
            children: []
          },
          {
            id: 652922,
            pid: 652900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '温宿县',
            children: []
          },
          {
            id: 652924,
            pid: 652900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '沙雅县',
            children: []
          },
          {
            id: 652925,
            pid: 652900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新和县',
            children: []
          },
          {
            id: 652926,
            pid: 652900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '拜城县',
            children: []
          },
          {
            id: 652927,
            pid: 652900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '乌什县',
            children: []
          },
          {
            id: 652928,
            pid: 652900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '阿瓦提县',
            children: []
          },
          {
            id: 652929,
            pid: 652900,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '柯坪县',
            children: []
          }
        ]
      },
      {
        id: 653000,
        pid: 650000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '克孜勒苏柯尔克孜自治州',
        children: [
          {
            id: 653001,
            pid: 653000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '阿图什市',
            children: []
          },
          {
            id: 653022,
            pid: 653000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '阿克陶县',
            children: []
          },
          {
            id: 653023,
            pid: 653000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '阿合奇县',
            children: []
          },
          {
            id: 653024,
            pid: 653000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '乌恰县',
            children: []
          }
        ]
      },
      {
        id: 653100,
        pid: 650000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '喀什地区',
        children: [
          {
            id: 653101,
            pid: 653100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '喀什市',
            children: []
          },
          {
            id: 653121,
            pid: 653100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '疏附县',
            children: []
          },
          {
            id: 653122,
            pid: 653100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '疏勒县',
            children: []
          },
          {
            id: 653123,
            pid: 653100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '英吉沙县',
            children: []
          },
          {
            id: 653124,
            pid: 653100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '泽普县',
            children: []
          },
          {
            id: 653125,
            pid: 653100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '莎车县',
            children: []
          },
          {
            id: 653126,
            pid: 653100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '叶城县',
            children: []
          },
          {
            id: 653127,
            pid: 653100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '麦盖提县',
            children: []
          },
          {
            id: 653128,
            pid: 653100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '岳普湖县',
            children: []
          },
          {
            id: 653129,
            pid: 653100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '伽师县',
            children: []
          },
          {
            id: 653130,
            pid: 653100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '巴楚县',
            children: []
          },
          {
            id: 653131,
            pid: 653100,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '塔什库尔干塔吉克自治县',
            children: []
          }
        ]
      },
      {
        id: 653200,
        pid: 650000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '和田地区',
        children: [
          {
            id: 653201,
            pid: 653200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '和田市',
            children: []
          },
          {
            id: 653221,
            pid: 653200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '和田县',
            children: []
          },
          {
            id: 653222,
            pid: 653200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '墨玉县',
            children: []
          },
          {
            id: 653223,
            pid: 653200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '皮山县',
            children: []
          },
          {
            id: 653224,
            pid: 653200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '洛浦县',
            children: []
          },
          {
            id: 653225,
            pid: 653200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '策勒县',
            children: []
          },
          {
            id: 653226,
            pid: 653200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '于田县',
            children: []
          },
          {
            id: 653227,
            pid: 653200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '民丰县',
            children: []
          }
        ]
      },
      {
        id: 654000,
        pid: 650000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '伊犁哈萨克自治州',
        children: [
          {
            id: 654002,
            pid: 654000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '伊宁市',
            children: []
          },
          {
            id: 654003,
            pid: 654000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '奎屯市',
            children: []
          },
          {
            id: 654004,
            pid: 654000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '霍尔果斯市',
            children: []
          },
          {
            id: 654021,
            pid: 654000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '伊宁县',
            children: []
          },
          {
            id: 654022,
            pid: 654000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '察布查尔锡伯自治县',
            children: []
          },
          {
            id: 654023,
            pid: 654000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '霍城县',
            children: []
          },
          {
            id: 654024,
            pid: 654000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '巩留县',
            children: []
          },
          {
            id: 654025,
            pid: 654000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新源县',
            children: []
          },
          {
            id: 654026,
            pid: 654000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '昭苏县',
            children: []
          },
          {
            id: 654027,
            pid: 654000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '特克斯县',
            children: []
          },
          {
            id: 654028,
            pid: 654000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '尼勒克县',
            children: []
          }
        ]
      },
      {
        id: 654200,
        pid: 650000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '塔城地区',
        children: [
          {
            id: 654201,
            pid: 654200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '塔城市',
            children: []
          },
          {
            id: 654202,
            pid: 654200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '乌苏市',
            children: []
          },
          {
            id: 654221,
            pid: 654200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '额敏县',
            children: []
          },
          {
            id: 654223,
            pid: 654200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '沙湾县',
            children: []
          },
          {
            id: 654224,
            pid: 654200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '托里县',
            children: []
          },
          {
            id: 654225,
            pid: 654200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '裕民县',
            children: []
          },
          {
            id: 654226,
            pid: 654200,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '和布克赛尔蒙古自治县',
            children: []
          }
        ]
      },
      {
        id: 654300,
        pid: 650000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '阿勒泰地区',
        children: [
          {
            id: 654301,
            pid: 654300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '阿勒泰市',
            children: []
          },
          {
            id: 654321,
            pid: 654300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '布尔津县',
            children: []
          },
          {
            id: 654322,
            pid: 654300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '富蕴县',
            children: []
          },
          {
            id: 654323,
            pid: 654300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '福海县',
            children: []
          },
          {
            id: 654324,
            pid: 654300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '哈巴河县',
            children: []
          },
          {
            id: 654325,
            pid: 654300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '青河县',
            children: []
          },
          {
            id: 654326,
            pid: 654300,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '吉木乃县',
            children: []
          }
        ]
      },
      {
        id: 659000,
        pid: 650000,
        level: 2,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '自治区直辖县级行政区划',
        children: [
          {
            id: 659001,
            pid: 659000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '石河子市',
            children: []
          },
          {
            id: 659002,
            pid: 659000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '阿拉尔市',
            children: []
          },
          {
            id: 659003,
            pid: 659000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '图木舒克市',
            children: []
          },
          {
            id: 659004,
            pid: 659000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '五家渠市',
            children: []
          },
          {
            id: 659005,
            pid: 659000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '北屯市',
            children: []
          },
          {
            id: 659006,
            pid: 659000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '铁门关市',
            children: []
          },
          {
            id: 659007,
            pid: 659000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '双河市',
            children: []
          },
          {
            id: 659008,
            pid: 659000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '可克达拉市',
            children: []
          },
          {
            id: 659009,
            pid: 659000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '昆玉市',
            children: []
          },
          {
            id: 659010,
            pid: 659000,
            level: 3,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '胡杨河市',
            children: []
          }
        ]
      }
    ]
  },
  {
    id: 710000,
    pid: 0,
    level: 1,
    createdAt: null,
    updatedAt: null,
    deletedAt: null,
    isValid: 1,
    label: '台湾省',
    children: [
      {
        id: 710100,
        pid: 710000,
        level: null,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '台北市',
        children: [
          {
            id: 710101,
            pid: 710100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '中正区',
            children: []
          },
          {
            id: 710102,
            pid: 710100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大同区',
            children: []
          },
          {
            id: 710103,
            pid: 710100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '中山区',
            children: []
          },
          {
            id: 710104,
            pid: 710100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '松山区',
            children: []
          },
          {
            id: 710105,
            pid: 710100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大安区',
            children: []
          },
          {
            id: 710106,
            pid: 710100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '万华区',
            children: []
          },
          {
            id: 710107,
            pid: 710100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '信义区',
            children: []
          },
          {
            id: 710108,
            pid: 710100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '士林区',
            children: []
          },
          {
            id: 710109,
            pid: 710100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '北投区',
            children: []
          },
          {
            id: 710110,
            pid: 710100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '内湖区',
            children: []
          },
          {
            id: 710111,
            pid: 710100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南港区',
            children: []
          },
          {
            id: 710112,
            pid: 710100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '文山区',
            children: []
          }
        ]
      },
      {
        id: 710200,
        pid: 710000,
        level: null,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '高雄市',
        children: [
          {
            id: 710201,
            pid: 710200,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新兴区',
            children: []
          },
          {
            id: 710202,
            pid: 710200,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '前金区',
            children: []
          },
          {
            id: 710203,
            pid: 710200,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '苓雅区',
            children: []
          },
          {
            id: 710204,
            pid: 710200,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '盐埕区',
            children: []
          },
          {
            id: 710205,
            pid: 710200,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '鼓山区',
            children: []
          },
          {
            id: 710206,
            pid: 710200,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '旗津区',
            children: []
          },
          {
            id: 710207,
            pid: 710200,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '前镇区',
            children: []
          },
          {
            id: 710208,
            pid: 710200,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '三民区',
            children: []
          },
          {
            id: 710209,
            pid: 710200,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '左营区',
            children: []
          },
          {
            id: 710210,
            pid: 710200,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '楠梓区',
            children: []
          },
          {
            id: 710211,
            pid: 710200,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '小港区',
            children: []
          },
          {
            id: 710242,
            pid: 710200,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '仁武区',
            children: []
          },
          {
            id: 710243,
            pid: 710200,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大社区',
            children: []
          },
          {
            id: 710244,
            pid: 710200,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '冈山区',
            children: []
          },
          {
            id: 710245,
            pid: 710200,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '路竹区',
            children: []
          },
          {
            id: 710246,
            pid: 710200,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '阿莲区',
            children: []
          },
          {
            id: 710247,
            pid: 710200,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '田寮区',
            children: []
          },
          {
            id: 710248,
            pid: 710200,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '燕巢区',
            children: []
          },
          {
            id: 710249,
            pid: 710200,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '桥头区',
            children: []
          },
          {
            id: 710250,
            pid: 710200,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '梓官区',
            children: []
          },
          {
            id: 710251,
            pid: 710200,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '弥陀区',
            children: []
          },
          {
            id: 710252,
            pid: 710200,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '永安区',
            children: []
          },
          {
            id: 710253,
            pid: 710200,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '湖内区',
            children: []
          },
          {
            id: 710254,
            pid: 710200,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '凤山区',
            children: []
          },
          {
            id: 710255,
            pid: 710200,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大寮区',
            children: []
          },
          {
            id: 710256,
            pid: 710200,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '林园区',
            children: []
          },
          {
            id: 710257,
            pid: 710200,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '鸟松区',
            children: []
          },
          {
            id: 710258,
            pid: 710200,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大树区',
            children: []
          },
          {
            id: 710259,
            pid: 710200,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '旗山区',
            children: []
          },
          {
            id: 710260,
            pid: 710200,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '美浓区',
            children: []
          },
          {
            id: 710261,
            pid: 710200,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '六龟区',
            children: []
          },
          {
            id: 710262,
            pid: 710200,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '内门区',
            children: []
          },
          {
            id: 710263,
            pid: 710200,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '杉林区',
            children: []
          },
          {
            id: 710264,
            pid: 710200,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '甲仙区',
            children: []
          },
          {
            id: 710265,
            pid: 710200,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '桃源区',
            children: []
          },
          {
            id: 710266,
            pid: 710200,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '那玛夏区',
            children: []
          },
          {
            id: 710267,
            pid: 710200,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '茂林区',
            children: []
          },
          {
            id: 710268,
            pid: 710200,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '茄萣区',
            children: []
          }
        ]
      },
      {
        id: 710300,
        pid: 710000,
        level: null,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '台南市',
        children: [
          {
            id: 710301,
            pid: 710300,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '中西区',
            children: []
          },
          {
            id: 710302,
            pid: 710300,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东区',
            children: []
          },
          {
            id: 710303,
            pid: 710300,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南区',
            children: []
          },
          {
            id: 710304,
            pid: 710300,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '北区',
            children: []
          },
          {
            id: 710305,
            pid: 710300,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '安平区',
            children: []
          },
          {
            id: 710306,
            pid: 710300,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '安南区',
            children: []
          },
          {
            id: 710339,
            pid: 710300,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '永康区',
            children: []
          },
          {
            id: 710340,
            pid: 710300,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '归仁区',
            children: []
          },
          {
            id: 710341,
            pid: 710300,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新化区',
            children: []
          },
          {
            id: 710342,
            pid: 710300,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '左镇区',
            children: []
          },
          {
            id: 710343,
            pid: 710300,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '玉井区',
            children: []
          },
          {
            id: 710344,
            pid: 710300,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '楠西区',
            children: []
          },
          {
            id: 710345,
            pid: 710300,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南化区',
            children: []
          },
          {
            id: 710346,
            pid: 710300,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '仁德区',
            children: []
          },
          {
            id: 710347,
            pid: 710300,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '关庙区',
            children: []
          },
          {
            id: 710348,
            pid: 710300,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '龙崎区',
            children: []
          },
          {
            id: 710349,
            pid: 710300,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '官田区',
            children: []
          },
          {
            id: 710350,
            pid: 710300,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '麻豆区',
            children: []
          },
          {
            id: 710351,
            pid: 710300,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '佳里区',
            children: []
          },
          {
            id: 710352,
            pid: 710300,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '西港区',
            children: []
          },
          {
            id: 710353,
            pid: 710300,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '七股区',
            children: []
          },
          {
            id: 710354,
            pid: 710300,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '将军区',
            children: []
          },
          {
            id: 710355,
            pid: 710300,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '学甲区',
            children: []
          },
          {
            id: 710356,
            pid: 710300,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '北门区',
            children: []
          },
          {
            id: 710357,
            pid: 710300,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新营区',
            children: []
          },
          {
            id: 710358,
            pid: 710300,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '后壁区',
            children: []
          },
          {
            id: 710359,
            pid: 710300,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '白河区',
            children: []
          },
          {
            id: 710360,
            pid: 710300,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东山区',
            children: []
          },
          {
            id: 710361,
            pid: 710300,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '六甲区',
            children: []
          },
          {
            id: 710362,
            pid: 710300,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '下营区',
            children: []
          },
          {
            id: 710363,
            pid: 710300,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '柳营区',
            children: []
          },
          {
            id: 710364,
            pid: 710300,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '盐水区',
            children: []
          },
          {
            id: 710365,
            pid: 710300,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '善化区',
            children: []
          },
          {
            id: 710366,
            pid: 710300,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大内区',
            children: []
          },
          {
            id: 710367,
            pid: 710300,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '山上区',
            children: []
          },
          {
            id: 710368,
            pid: 710300,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新市区',
            children: []
          },
          {
            id: 710369,
            pid: 710300,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '安定区',
            children: []
          }
        ]
      },
      {
        id: 710400,
        pid: 710000,
        level: null,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '台中市',
        children: [
          {
            id: 710401,
            pid: 710400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '中区',
            children: []
          },
          {
            id: 710402,
            pid: 710400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东区',
            children: []
          },
          {
            id: 710403,
            pid: 710400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南区',
            children: []
          },
          {
            id: 710404,
            pid: 710400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '西区',
            children: []
          },
          {
            id: 710405,
            pid: 710400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '北区',
            children: []
          },
          {
            id: 710406,
            pid: 710400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '北屯区',
            children: []
          },
          {
            id: 710407,
            pid: 710400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '西屯区',
            children: []
          },
          {
            id: 710408,
            pid: 710400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南屯区',
            children: []
          },
          {
            id: 710431,
            pid: 710400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '太平区',
            children: []
          },
          {
            id: 710432,
            pid: 710400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大里区',
            children: []
          },
          {
            id: 710433,
            pid: 710400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '雾峰区',
            children: []
          },
          {
            id: 710434,
            pid: 710400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '乌日区',
            children: []
          },
          {
            id: 710435,
            pid: 710400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '丰原区',
            children: []
          },
          {
            id: 710436,
            pid: 710400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '后里区',
            children: []
          },
          {
            id: 710437,
            pid: 710400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '石冈区',
            children: []
          },
          {
            id: 710438,
            pid: 710400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东势区',
            children: []
          },
          {
            id: 710439,
            pid: 710400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '和平区',
            children: []
          },
          {
            id: 710440,
            pid: 710400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新社区',
            children: []
          },
          {
            id: 710441,
            pid: 710400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '潭子区',
            children: []
          },
          {
            id: 710442,
            pid: 710400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大雅区',
            children: []
          },
          {
            id: 710443,
            pid: 710400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '神冈区',
            children: []
          },
          {
            id: 710444,
            pid: 710400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大肚区',
            children: []
          },
          {
            id: 710445,
            pid: 710400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '沙鹿区',
            children: []
          },
          {
            id: 710446,
            pid: 710400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '龙井区',
            children: []
          },
          {
            id: 710447,
            pid: 710400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '梧栖区',
            children: []
          },
          {
            id: 710448,
            pid: 710400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '清水区',
            children: []
          },
          {
            id: 710449,
            pid: 710400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大甲区',
            children: []
          },
          {
            id: 710450,
            pid: 710400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '外埔区',
            children: []
          },
          {
            id: 710451,
            pid: 710400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大安区',
            children: []
          }
        ]
      },
      {
        id: 710600,
        pid: 710000,
        level: null,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '南投县',
        children: [
          {
            id: 710614,
            pid: 710600,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南投市',
            children: []
          },
          {
            id: 710615,
            pid: 710600,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '中寮乡',
            children: []
          },
          {
            id: 710616,
            pid: 710600,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '草屯镇',
            children: []
          },
          {
            id: 710617,
            pid: 710600,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '国姓乡',
            children: []
          },
          {
            id: 710618,
            pid: 710600,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '埔里镇',
            children: []
          },
          {
            id: 710619,
            pid: 710600,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '仁爱乡',
            children: []
          },
          {
            id: 710620,
            pid: 710600,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '名间乡',
            children: []
          },
          {
            id: 710621,
            pid: 710600,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '集集镇',
            children: []
          },
          {
            id: 710622,
            pid: 710600,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '水里乡',
            children: []
          },
          {
            id: 710623,
            pid: 710600,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '鱼池乡',
            children: []
          },
          {
            id: 710624,
            pid: 710600,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '信义乡',
            children: []
          },
          {
            id: 710625,
            pid: 710600,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '竹山镇',
            children: []
          },
          {
            id: 710626,
            pid: 710600,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '鹿谷乡',
            children: []
          }
        ]
      },
      {
        id: 710700,
        pid: 710000,
        level: null,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '基隆市',
        children: [
          {
            id: 710701,
            pid: 710700,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '仁爱区',
            children: []
          },
          {
            id: 710702,
            pid: 710700,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '信义区',
            children: []
          },
          {
            id: 710703,
            pid: 710700,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '中正区',
            children: []
          },
          {
            id: 710704,
            pid: 710700,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '中山区',
            children: []
          },
          {
            id: 710705,
            pid: 710700,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '安乐区',
            children: []
          },
          {
            id: 710706,
            pid: 710700,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '暖暖区',
            children: []
          },
          {
            id: 710707,
            pid: 710700,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '七堵区',
            children: []
          }
        ]
      },
      {
        id: 710800,
        pid: 710000,
        level: null,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '新竹市',
        children: [
          {
            id: 710801,
            pid: 710800,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东区',
            children: []
          },
          {
            id: 710802,
            pid: 710800,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '北区',
            children: []
          },
          {
            id: 710803,
            pid: 710800,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '香山区',
            children: []
          }
        ]
      },
      {
        id: 710900,
        pid: 710000,
        level: null,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '嘉义市',
        children: [
          {
            id: 710901,
            pid: 710900,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东区',
            children: []
          },
          {
            id: 710902,
            pid: 710900,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '西区',
            children: []
          }
        ]
      },
      {
        id: 711100,
        pid: 710000,
        level: null,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '新北市',
        children: [
          {
            id: 711130,
            pid: 711100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '万里区',
            children: []
          },
          {
            id: 711131,
            pid: 711100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '金山区',
            children: []
          },
          {
            id: 711132,
            pid: 711100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '板桥区',
            children: []
          },
          {
            id: 711133,
            pid: 711100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '汐止区',
            children: []
          },
          {
            id: 711134,
            pid: 711100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '深坑区',
            children: []
          },
          {
            id: 711135,
            pid: 711100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '石碇区',
            children: []
          },
          {
            id: 711136,
            pid: 711100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '瑞芳区',
            children: []
          },
          {
            id: 711137,
            pid: 711100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '平溪区',
            children: []
          },
          {
            id: 711138,
            pid: 711100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '双溪区',
            children: []
          },
          {
            id: 711139,
            pid: 711100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '贡寮区',
            children: []
          },
          {
            id: 711140,
            pid: 711100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新店区',
            children: []
          },
          {
            id: 711141,
            pid: 711100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '坪林区',
            children: []
          },
          {
            id: 711142,
            pid: 711100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '乌来区',
            children: []
          },
          {
            id: 711143,
            pid: 711100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '永和区',
            children: []
          },
          {
            id: 711144,
            pid: 711100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '中和区',
            children: []
          },
          {
            id: 711145,
            pid: 711100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '土城区',
            children: []
          },
          {
            id: 711146,
            pid: 711100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '三峡区',
            children: []
          },
          {
            id: 711147,
            pid: 711100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '树林区',
            children: []
          },
          {
            id: 711148,
            pid: 711100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '莺歌区',
            children: []
          },
          {
            id: 711149,
            pid: 711100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '三重区',
            children: []
          },
          {
            id: 711150,
            pid: 711100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新庄区',
            children: []
          },
          {
            id: 711151,
            pid: 711100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '泰山区',
            children: []
          },
          {
            id: 711152,
            pid: 711100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '林口区',
            children: []
          },
          {
            id: 711153,
            pid: 711100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '芦洲区',
            children: []
          },
          {
            id: 711154,
            pid: 711100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '五股区',
            children: []
          },
          {
            id: 711155,
            pid: 711100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '八里区',
            children: []
          },
          {
            id: 711156,
            pid: 711100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '淡水区',
            children: []
          },
          {
            id: 711157,
            pid: 711100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '三芝区',
            children: []
          },
          {
            id: 711158,
            pid: 711100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '石门区',
            children: []
          }
        ]
      },
      {
        id: 711200,
        pid: 710000,
        level: null,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '宜兰县',
        children: [
          {
            id: 711214,
            pid: 711200,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宜兰市',
            children: []
          },
          {
            id: 711215,
            pid: 711200,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '头城镇',
            children: []
          },
          {
            id: 711216,
            pid: 711200,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '礁溪乡',
            children: []
          },
          {
            id: 711217,
            pid: 711200,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '壮围乡',
            children: []
          },
          {
            id: 711218,
            pid: 711200,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '员山乡',
            children: []
          },
          {
            id: 711219,
            pid: 711200,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '罗东镇',
            children: []
          },
          {
            id: 711220,
            pid: 711200,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '三星乡',
            children: []
          },
          {
            id: 711221,
            pid: 711200,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大同乡',
            children: []
          },
          {
            id: 711222,
            pid: 711200,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '五结乡',
            children: []
          },
          {
            id: 711223,
            pid: 711200,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '冬山乡',
            children: []
          },
          {
            id: 711224,
            pid: 711200,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '苏澳镇',
            children: []
          },
          {
            id: 711225,
            pid: 711200,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南澳乡',
            children: []
          }
        ]
      },
      {
        id: 711300,
        pid: 710000,
        level: null,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '新竹县',
        children: [
          {
            id: 711314,
            pid: 711300,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '竹北市',
            children: []
          },
          {
            id: 711315,
            pid: 711300,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '湖口乡',
            children: []
          },
          {
            id: 711316,
            pid: 711300,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新丰乡',
            children: []
          },
          {
            id: 711317,
            pid: 711300,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新埔镇',
            children: []
          },
          {
            id: 711318,
            pid: 711300,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '关西镇',
            children: []
          },
          {
            id: 711319,
            pid: 711300,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '芎林乡',
            children: []
          },
          {
            id: 711320,
            pid: 711300,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '宝山乡',
            children: []
          },
          {
            id: 711321,
            pid: 711300,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '竹东镇',
            children: []
          },
          {
            id: 711322,
            pid: 711300,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '五峰乡',
            children: []
          },
          {
            id: 711323,
            pid: 711300,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '横山乡',
            children: []
          },
          {
            id: 711324,
            pid: 711300,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '尖石乡',
            children: []
          },
          {
            id: 711325,
            pid: 711300,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '北埔乡',
            children: []
          },
          {
            id: 711326,
            pid: 711300,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '峨眉乡',
            children: []
          }
        ]
      },
      {
        id: 711400,
        pid: 710000,
        level: null,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '桃园市',
        children: [
          {
            id: 711414,
            pid: 711400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '中坜区',
            children: []
          },
          {
            id: 711415,
            pid: 711400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '平镇区',
            children: []
          },
          {
            id: 711416,
            pid: 711400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '龙潭区',
            children: []
          },
          {
            id: 711417,
            pid: 711400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '杨梅区',
            children: []
          },
          {
            id: 711418,
            pid: 711400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新屋区',
            children: []
          },
          {
            id: 711419,
            pid: 711400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '观音区',
            children: []
          },
          {
            id: 711420,
            pid: 711400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '桃园区',
            children: []
          },
          {
            id: 711421,
            pid: 711400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '龟山区',
            children: []
          },
          {
            id: 711422,
            pid: 711400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '八德区',
            children: []
          },
          {
            id: 711423,
            pid: 711400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大溪区',
            children: []
          },
          {
            id: 711424,
            pid: 711400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '复兴区',
            children: []
          },
          {
            id: 711425,
            pid: 711400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大园区',
            children: []
          },
          {
            id: 711426,
            pid: 711400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '芦竹区',
            children: []
          }
        ]
      },
      {
        id: 711500,
        pid: 710000,
        level: null,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '苗栗县',
        children: [
          {
            id: 711519,
            pid: 711500,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '竹南镇',
            children: []
          },
          {
            id: 711520,
            pid: 711500,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '头份市',
            children: []
          },
          {
            id: 711521,
            pid: 711500,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '三湾乡',
            children: []
          },
          {
            id: 711522,
            pid: 711500,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南庄乡',
            children: []
          },
          {
            id: 711523,
            pid: 711500,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '狮潭乡',
            children: []
          },
          {
            id: 711524,
            pid: 711500,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '后龙镇',
            children: []
          },
          {
            id: 711525,
            pid: 711500,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '通霄镇',
            children: []
          },
          {
            id: 711526,
            pid: 711500,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '苑里镇',
            children: []
          },
          {
            id: 711527,
            pid: 711500,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '苗栗市',
            children: []
          },
          {
            id: 711528,
            pid: 711500,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '造桥乡',
            children: []
          },
          {
            id: 711529,
            pid: 711500,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '头屋乡',
            children: []
          },
          {
            id: 711530,
            pid: 711500,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '公馆乡',
            children: []
          },
          {
            id: 711531,
            pid: 711500,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大湖乡',
            children: []
          },
          {
            id: 711532,
            pid: 711500,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '泰安乡',
            children: []
          },
          {
            id: 711533,
            pid: 711500,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '铜锣乡',
            children: []
          },
          {
            id: 711534,
            pid: 711500,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '三义乡',
            children: []
          },
          {
            id: 711535,
            pid: 711500,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '西湖乡',
            children: []
          },
          {
            id: 711536,
            pid: 711500,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '卓兰镇',
            children: []
          }
        ]
      },
      {
        id: 711700,
        pid: 710000,
        level: null,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '彰化县',
        children: [
          {
            id: 711727,
            pid: 711700,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '彰化市',
            children: []
          },
          {
            id: 711728,
            pid: 711700,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '芬园乡',
            children: []
          },
          {
            id: 711729,
            pid: 711700,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '花坛乡',
            children: []
          },
          {
            id: 711730,
            pid: 711700,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '秀水乡',
            children: []
          },
          {
            id: 711731,
            pid: 711700,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '鹿港镇',
            children: []
          },
          {
            id: 711732,
            pid: 711700,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '福兴乡',
            children: []
          },
          {
            id: 711733,
            pid: 711700,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '线西乡',
            children: []
          },
          {
            id: 711734,
            pid: 711700,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '和美镇',
            children: []
          },
          {
            id: 711735,
            pid: 711700,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '伸港乡',
            children: []
          },
          {
            id: 711736,
            pid: 711700,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '员林市',
            children: []
          },
          {
            id: 711737,
            pid: 711700,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '社头乡',
            children: []
          },
          {
            id: 711738,
            pid: 711700,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '永靖乡',
            children: []
          },
          {
            id: 711739,
            pid: 711700,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '埔心乡',
            children: []
          },
          {
            id: 711740,
            pid: 711700,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '溪湖镇',
            children: []
          },
          {
            id: 711741,
            pid: 711700,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大村乡',
            children: []
          },
          {
            id: 711742,
            pid: 711700,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '埔盐乡',
            children: []
          },
          {
            id: 711743,
            pid: 711700,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '田中镇',
            children: []
          },
          {
            id: 711744,
            pid: 711700,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '北斗镇',
            children: []
          },
          {
            id: 711745,
            pid: 711700,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '田尾乡',
            children: []
          },
          {
            id: 711746,
            pid: 711700,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '埤头乡',
            children: []
          },
          {
            id: 711747,
            pid: 711700,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '溪州乡',
            children: []
          },
          {
            id: 711748,
            pid: 711700,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '竹塘乡',
            children: []
          },
          {
            id: 711749,
            pid: 711700,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '二林镇',
            children: []
          },
          {
            id: 711750,
            pid: 711700,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大城乡',
            children: []
          },
          {
            id: 711751,
            pid: 711700,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '芳苑乡',
            children: []
          },
          {
            id: 711752,
            pid: 711700,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '二水乡',
            children: []
          }
        ]
      },
      {
        id: 711900,
        pid: 710000,
        level: null,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '嘉义县',
        children: [
          {
            id: 711919,
            pid: 711900,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '番路乡',
            children: []
          },
          {
            id: 711920,
            pid: 711900,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '梅山乡',
            children: []
          },
          {
            id: 711921,
            pid: 711900,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '竹崎乡',
            children: []
          },
          {
            id: 711922,
            pid: 711900,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '阿里山乡',
            children: []
          },
          {
            id: 711923,
            pid: 711900,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '中埔乡',
            children: []
          },
          {
            id: 711924,
            pid: 711900,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大埔乡',
            children: []
          },
          {
            id: 711925,
            pid: 711900,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '水上乡',
            children: []
          },
          {
            id: 711926,
            pid: 711900,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '鹿草乡',
            children: []
          },
          {
            id: 711927,
            pid: 711900,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '太保市',
            children: []
          },
          {
            id: 711928,
            pid: 711900,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '朴子市',
            children: []
          },
          {
            id: 711929,
            pid: 711900,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东石乡',
            children: []
          },
          {
            id: 711930,
            pid: 711900,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '六脚乡',
            children: []
          },
          {
            id: 711931,
            pid: 711900,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新港乡',
            children: []
          },
          {
            id: 711932,
            pid: 711900,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '民雄乡',
            children: []
          },
          {
            id: 711933,
            pid: 711900,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大林镇',
            children: []
          },
          {
            id: 711934,
            pid: 711900,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '溪口乡',
            children: []
          },
          {
            id: 711935,
            pid: 711900,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '义竹乡',
            children: []
          },
          {
            id: 711936,
            pid: 711900,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '布袋镇',
            children: []
          }
        ]
      },
      {
        id: 712100,
        pid: 710000,
        level: null,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '云林县',
        children: [
          {
            id: 712121,
            pid: 712100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '斗南镇',
            children: []
          },
          {
            id: 712122,
            pid: 712100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大埤乡',
            children: []
          },
          {
            id: 712123,
            pid: 712100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '虎尾镇',
            children: []
          },
          {
            id: 712124,
            pid: 712100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '土库镇',
            children: []
          },
          {
            id: 712125,
            pid: 712100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '褒忠乡',
            children: []
          },
          {
            id: 712126,
            pid: 712100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东势乡',
            children: []
          },
          {
            id: 712127,
            pid: 712100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '台西乡',
            children: []
          },
          {
            id: 712128,
            pid: 712100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '仑背乡',
            children: []
          },
          {
            id: 712129,
            pid: 712100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '麦寮乡',
            children: []
          },
          {
            id: 712130,
            pid: 712100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '斗六市',
            children: []
          },
          {
            id: 712131,
            pid: 712100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '林内乡',
            children: []
          },
          {
            id: 712132,
            pid: 712100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '古坑乡',
            children: []
          },
          {
            id: 712133,
            pid: 712100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '莿桐乡',
            children: []
          },
          {
            id: 712134,
            pid: 712100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '西螺镇',
            children: []
          },
          {
            id: 712135,
            pid: 712100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '二仑乡',
            children: []
          },
          {
            id: 712136,
            pid: 712100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '北港镇',
            children: []
          },
          {
            id: 712137,
            pid: 712100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '水林乡',
            children: []
          },
          {
            id: 712138,
            pid: 712100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '口湖乡',
            children: []
          },
          {
            id: 712139,
            pid: 712100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '四湖乡',
            children: []
          },
          {
            id: 712140,
            pid: 712100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '元长乡',
            children: []
          }
        ]
      },
      {
        id: 712400,
        pid: 710000,
        level: null,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '屏东县',
        children: [
          {
            id: 712434,
            pid: 712400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '屏东市',
            children: []
          },
          {
            id: 712435,
            pid: 712400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '三地门乡',
            children: []
          },
          {
            id: 712436,
            pid: 712400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '雾台乡',
            children: []
          },
          {
            id: 712437,
            pid: 712400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '玛家乡',
            children: []
          },
          {
            id: 712438,
            pid: 712400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '九如乡',
            children: []
          },
          {
            id: 712439,
            pid: 712400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '里港乡',
            children: []
          },
          {
            id: 712440,
            pid: 712400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '高树乡',
            children: []
          },
          {
            id: 712441,
            pid: 712400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '盐埔乡',
            children: []
          },
          {
            id: 712442,
            pid: 712400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '长治乡',
            children: []
          },
          {
            id: 712443,
            pid: 712400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '麟洛乡',
            children: []
          },
          {
            id: 712444,
            pid: 712400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '竹田乡',
            children: []
          },
          {
            id: 712445,
            pid: 712400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '内埔乡',
            children: []
          },
          {
            id: 712446,
            pid: 712400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '万丹乡',
            children: []
          },
          {
            id: 712447,
            pid: 712400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '潮州镇',
            children: []
          },
          {
            id: 712448,
            pid: 712400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '泰武乡',
            children: []
          },
          {
            id: 712449,
            pid: 712400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '来义乡',
            children: []
          },
          {
            id: 712450,
            pid: 712400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '万峦乡',
            children: []
          },
          {
            id: 712451,
            pid: 712400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '崁顶乡',
            children: []
          },
          {
            id: 712452,
            pid: 712400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新埤乡',
            children: []
          },
          {
            id: 712453,
            pid: 712400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南州乡',
            children: []
          },
          {
            id: 712454,
            pid: 712400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '林边乡',
            children: []
          },
          {
            id: 712455,
            pid: 712400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东港镇',
            children: []
          },
          {
            id: 712456,
            pid: 712400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '琉球乡',
            children: []
          },
          {
            id: 712457,
            pid: 712400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '佳冬乡',
            children: []
          },
          {
            id: 712458,
            pid: 712400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新园乡',
            children: []
          },
          {
            id: 712459,
            pid: 712400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '枋寮乡',
            children: []
          },
          {
            id: 712460,
            pid: 712400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '枋山乡',
            children: []
          },
          {
            id: 712461,
            pid: 712400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '春日乡',
            children: []
          },
          {
            id: 712462,
            pid: 712400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '狮子乡',
            children: []
          },
          {
            id: 712463,
            pid: 712400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '车城乡',
            children: []
          },
          {
            id: 712464,
            pid: 712400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '牡丹乡',
            children: []
          },
          {
            id: 712465,
            pid: 712400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '恒春镇',
            children: []
          },
          {
            id: 712466,
            pid: 712400,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '满州乡',
            children: []
          }
        ]
      },
      {
        id: 712500,
        pid: 710000,
        level: null,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '台东县',
        children: [
          {
            id: 712517,
            pid: 712500,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '台东市',
            children: []
          },
          {
            id: 712518,
            pid: 712500,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '绿岛乡',
            children: []
          },
          {
            id: 712519,
            pid: 712500,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '兰屿乡',
            children: []
          },
          {
            id: 712520,
            pid: 712500,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '延平乡',
            children: []
          },
          {
            id: 712521,
            pid: 712500,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '卑南乡',
            children: []
          },
          {
            id: 712522,
            pid: 712500,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '鹿野乡',
            children: []
          },
          {
            id: 712523,
            pid: 712500,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '关山镇',
            children: []
          },
          {
            id: 712524,
            pid: 712500,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '海端乡',
            children: []
          },
          {
            id: 712525,
            pid: 712500,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '池上乡',
            children: []
          },
          {
            id: 712526,
            pid: 712500,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东河乡',
            children: []
          },
          {
            id: 712527,
            pid: 712500,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '成功镇',
            children: []
          },
          {
            id: 712528,
            pid: 712500,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '长滨乡',
            children: []
          },
          {
            id: 712529,
            pid: 712500,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '金峰乡',
            children: []
          },
          {
            id: 712530,
            pid: 712500,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大武乡',
            children: []
          },
          {
            id: 712531,
            pid: 712500,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '达仁乡',
            children: []
          },
          {
            id: 712532,
            pid: 712500,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '太麻里乡',
            children: []
          }
        ]
      },
      {
        id: 712600,
        pid: 710000,
        level: null,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '花莲县',
        children: [
          {
            id: 712615,
            pid: 712600,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '花莲市',
            children: []
          },
          {
            id: 712616,
            pid: 712600,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '新城乡',
            children: []
          },
          {
            id: 712618,
            pid: 712600,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '秀林乡',
            children: []
          },
          {
            id: 712619,
            pid: 712600,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '吉安乡',
            children: []
          },
          {
            id: 712620,
            pid: 712600,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '寿丰乡',
            children: []
          },
          {
            id: 712621,
            pid: 712600,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '凤林镇',
            children: []
          },
          {
            id: 712622,
            pid: 712600,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '光复乡',
            children: []
          },
          {
            id: 712623,
            pid: 712600,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '丰滨乡',
            children: []
          },
          {
            id: 712624,
            pid: 712600,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '瑞穗乡',
            children: []
          },
          {
            id: 712625,
            pid: 712600,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '万荣乡',
            children: []
          },
          {
            id: 712626,
            pid: 712600,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '玉里镇',
            children: []
          },
          {
            id: 712627,
            pid: 712600,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '卓溪乡',
            children: []
          },
          {
            id: 712628,
            pid: 712600,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '富里乡',
            children: []
          }
        ]
      },
      {
        id: 712700,
        pid: 710000,
        level: null,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '澎湖县',
        children: [
          {
            id: 712707,
            pid: 712700,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '马公市',
            children: []
          },
          {
            id: 712708,
            pid: 712700,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '西屿乡',
            children: []
          },
          {
            id: 712709,
            pid: 712700,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '望安乡',
            children: []
          },
          {
            id: 712710,
            pid: 712700,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '七美乡',
            children: []
          },
          {
            id: 712711,
            pid: 712700,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '白沙乡',
            children: []
          },
          {
            id: 712712,
            pid: 712700,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '湖西乡',
            children: []
          }
        ]
      }
    ]
  },
  {
    id: 810000,
    pid: 0,
    level: 1,
    createdAt: null,
    updatedAt: null,
    deletedAt: null,
    isValid: 1,
    label: '香港特别行政区',
    children: [
      {
        id: 810100,
        pid: 810000,
        level: null,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '直辖市',
        children: [
          {
            id: 810101,
            pid: 810100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '中西区',
            children: []
          },
          {
            id: 810102,
            pid: 810100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '东区',
            children: []
          },
          {
            id: 810103,
            pid: 810100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '九龙城区',
            children: []
          },
          {
            id: 810104,
            pid: 810100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '观塘区',
            children: []
          },
          {
            id: 810105,
            pid: 810100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '南区',
            children: []
          },
          {
            id: 810106,
            pid: 810100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '深水埗区',
            children: []
          },
          {
            id: 810107,
            pid: 810100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '湾仔区',
            children: []
          },
          {
            id: 810108,
            pid: 810100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '黄大仙区',
            children: []
          },
          {
            id: 810109,
            pid: 810100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '油尖旺区',
            children: []
          },
          {
            id: 810110,
            pid: 810100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '离岛区',
            children: []
          },
          {
            id: 810111,
            pid: 810100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '葵青区',
            children: []
          },
          {
            id: 810112,
            pid: 810100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '北区',
            children: []
          },
          {
            id: 810113,
            pid: 810100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '西贡区',
            children: []
          },
          {
            id: 810114,
            pid: 810100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '沙田区',
            children: []
          },
          {
            id: 810115,
            pid: 810100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '屯门区',
            children: []
          },
          {
            id: 810116,
            pid: 810100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '大埔区',
            children: []
          },
          {
            id: 810117,
            pid: 810100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '荃湾区',
            children: []
          },
          {
            id: 810118,
            pid: 810100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '元朗区',
            children: []
          }
        ]
      }
    ]
  },
  {
    id: 820000,
    pid: 0,
    level: null,
    createdAt: null,
    updatedAt: null,
    deletedAt: null,
    isValid: 1,
    label: '澳门特别行政区',
    children: [
      {
        id: 820100,
        pid: 820000,
        level: null,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        isValid: 1,
        label: '直辖市',
        children: [
          {
            id: 820101,
            pid: 820100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '澳门半岛',
            children: []
          },
          {
            id: 820102,
            pid: 820100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '凼仔',
            children: []
          },
          {
            id: 820103,
            pid: 820100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '路凼城',
            children: []
          },
          {
            id: 820104,
            pid: 820100,
            level: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
            isValid: 1,
            label: '路环',
            children: []
          }
        ]
      }
    ]
  }
]
export { areaDataTree }
