import { ExtractPropTypes } from 'vue'
import type { SelectProps } from 'ant-design-vue/es/select'
// type SelectorTypes = keyof Reference.SelectorTypes

export const vacationSelectProps = {
  apiParams: {
    type: Object,
    default: () => ({}),
  }
}

export type VacationSelectProps = Partial<ExtractPropTypes<typeof vacationSelectProps>> & SelectProps