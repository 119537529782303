import { ExtractPropTypes, PropType } from 'vue'
export const attendanceShiftProps = {
  value: {
    type: Array as PropType<number[]>,
    default: []
  },
  projectId: {
    type: Number
  },
  projectDefaultShift: {
    type: Object as PropType<Recordable>
  }
}

export type AttendanceShiftProps = Partial<ExtractPropTypes<typeof attendanceShiftProps>>