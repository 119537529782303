import { redirectRouter } from '@/hooks/router-redirect'
import EmptyLayout from '@/views/layouts/empty-layout.vue'
import { CustomRouteRecordRaw, RouteLocationRaw } from 'vue-router'
const anttendanceChildrenRoutes = [
  // 企业管理员列表
  {
    path: 'attendance-list',
    name: 'attendanceList',
    component: () => import('@/views/attendance/attendance-list.vue'),
    meta: {
      title: '考勤规则',
      iconfont: 'icon-calendar-check-line',
      requiredPermission: 'ATTENDANCE_LIST',

    }
  },

  {
    path: 'attendance-create',
    name: 'attendanceCreate',
    component: () => import('@/views/attendance/attendance-create.vue'),
    meta: {
      title: '考勤创建',
      hideInMenu: true,
      // actionRouterName: 'attendanceList',
      parentNameForBreadcrumb: 'attendanceList',
      requiredPermission: 'ATTENDANCE_CREATE'
    }
  },

  {
    path: 'attendance-edit/:id',
    name: 'attendanceEdit',
    component: () => import('@/views/attendance/attendance-create.vue'),
    meta: {
      title: '考勤编辑',
      hideInMenu: true,
      // actionRouterName: 'attendanceList',
      parentNameForBreadcrumb: 'attendanceList'
    }
  },
  {
    path: 'clocks-statistic-list',
    name: 'clocksStatisticList',
    component: () => import('@/views/attendance/clocks/clocks-statistic-list.vue'),
    meta: {
      title: '考勤统计',
      iconfont: 'icon-pages-line',
      requiredPermission: 'CLOCKS_STATISTIC_LIST',
    },
  },
  {
    path: 'clocks-record-list',
    name: 'clocksRecordList',
    component: () => import('@/views/attendance/clocks/clocks-record-list.vue'),
    meta: {
      title: '打卡记录',
      iconfont: 'icon-pages-line',
      requiredPermission: 'CLOCKS_RECORD_LIST',
    },
  },
  {
    path: 'clocks-record-detail',
    name: 'clocksRecordDetail',
    component: () => import('@/views/attendance/clocks/clocks-record-detail.vue'),
    meta: {
      title: '打卡详情',
      hideInMenu: true,
      parentNameForBreadcrumb: 'clocksRecordList',
      // actionRouterName: 'clocksRecordList',
    }
  },
  {
    path: 'attendance-shift-list',
    name: 'attendanceShiftList',
    component: () => import('@/views/attendance/attendance-shift-list.vue'),
    meta: {
      title: '班次管理',
      iconfont: 'icon-contacts-book-line',
      requiredPermission: 'ATTENDANCE_SHIFT_LIST',
    }
  },
  {
    path: 'attendance-worker-list',
    name: 'attendanceWorkerList',
    component: () => import('@/views/attendance/attendance-worker-list.vue'),
    meta: {
      title: '排班管理',
      iconfont: 'icon-survey-line',
      requiredPermission: 'ATTENDANCE_WORKER_LIST',
    }
  },

  {
    path: 'clock-import-list',
    name: 'clockImportList',
    component: () => import('@/views/attendance/clock-import-list.vue'),
    meta: {
      title: '考勤表发送记录',
      iconfont: 'icon-calendar-check-line',
      requiredPermission: 'STAFF_USER_LOCK_SHEET_DETAIL_LIST',
    }
  },
  {
    path: 'attendance-feedback',
    name: 'attendanceFeedback',
    component: () => import('@/views/attendance/feedback-list.vue'),
    meta: {
      title: '考勤反馈',
      iconfont: 'icon-calendar-check-line',
      requiredPermission: 'STAFF_USER_LOCK_FACKBOOK',
      showBadge: 'clockFeedbackCount',


    }
  },
  {
    path: 'import-attendance',
    name: 'importAttendance',
    component: () => import('@/views/attendance/import-attendance.vue'),
    meta: {
      title: '导入考勤',
      hideInMenu: true,
      iconfont: 'icon-calendar-check-line',
      requiredPermission: 'STAFF_USER_LOCK_SHEET',
      parentNameForBreadcrumb: 'clockImportList',

    }
  },
  {
    path: 'clock-detail/:id',
    name: 'clockDetail',
    component: () => import('@/views/attendance/clock-detail.vue'),
    meta: {
      title: '考勤表详情',
      hideInMenu: true,
      iconfont: 'icon-calendar-check-line',
      requiredPermission: 'STAFF_USER_LOCK_SHEET_DETAIL',
      parentNameForBreadcrumb: 'clockImportList',
      noQuick: true
    }
  },
  // 把审核这两个单独拎到单据审批菜单
  // {
  //   path: '/oa-list',
  //   name: 'oaList',
  //   component: () => import('@/views/attendance/oa/oa-list.vue'),
  //   meta: {
  //     title: 'OA审核',
  //     iconfont: 'icon-server-line',
  //     requiredPermission: 'OA_APPROVAL',
  //     showBadge: 'pendingApprovalCount',
  //   }
  // },
  // {
  //   path: '/oa-setting',
  //   name: 'oaSetting',
  //   component: () => import('@/views/attendance/oa/oa-setting.vue'),
  //   meta: {
  //     title: 'OA审核流设置',
  //     iconfont: 'icon-file-edit-line',
  //     requiredPermission: 'OA_SETTING_LIST',
  //   },
  // },

  {
    path: 'clocks-statistic-detail',
    name: 'clocksStatisticDetail',
    component: () => import('@/views/attendance/clocks/clocks-statistic-detail.vue'),
    meta: {
      title: '考勤详情',
      hideInMenu: true,
      // actionRouterName: 'clocksStatisticList',
      parentNameForBreadcrumb: 'clocksStatisticList'
    }
  },
  {
    path: 'vacation-rules',
    name: 'vacationRules',
    component: () => import('@/views/attendance/vacation/vacation-rules.vue'),
    meta: {
      title: '假期规则',
      iconfont: 'icon-survey-line',
      requiredPermission: 'VACATION_RULES',
    },
  },
  {
    path: 'staff-vacation-balance',
    name: 'staffVacationBalance',
    component: () => import('@/views/attendance/vacation/staff-vacation-balance.vue'),
    meta: {
      title: '员工假期余额',
      iconfont: 'icon-survey-line',
      requiredPermission: 'STAFF_VACATION_BALANCE',
    },
  },
]
export const attenanceRoutes: CustomRouteRecordRaw = {
  path: 'attenance',
  name: 'attenance',
  component: EmptyLayout,
  redirect: (): RouteLocationRaw => redirectRouter(anttendanceChildrenRoutes),
  meta: {
    title: '考勤管理',
    iconfont: 'icon-emotion-laugh-line',
    desc: '以考勤为维度内容',
    requiredPermission: 'ATTENDANCE',
    needIcon: true,
    noQuick: true
  },
  children: anttendanceChildrenRoutes
}

