
<style lang="less">
.shift-table-container {
  .ant-table-tbody {
    & > tr.ant-table-row-selected {
      & > td:not(.ant-table-cell-row-hover) {
        background: #FFFFFF;
      }
    }

    & > tr {
      & > td {
        padding: 8px;
      }
    }
  }
}

</style>
<template>
  <div class="shift-table-container">
    <div class="flex items-center justify-between mb-2">
      <div>快捷设置班次 {{ defaultShift?.shiftName }} {{ defaultShift?.shiftTime?.map(item => item.shiftTimeFormat).join(', ') }}</div>
      <a-button
        type="link"
        class="mr-[8px]"
        @click="handleShift()"
      >
        更改班次
      </a-button>
    </div>
    <a-form-item-rest>
      <a-table
        v-bind="bindDaysProps"
        class="mb-2"
      >
        <template #bodyCell="{ column, record,index}">
          <template v-if="column.dataIndex === 'action'">
            <a-button
              type="link"
              @click="handleShift(record,index)"
            >
              更改班次
            </a-button>
          </template>
        </template>
      </a-table>
    </a-form-item-rest>
    <basic-modal
      v-model:visible="shiftModalVisible"
      v-bind="bindModalAttr"
    >
      <grid-table
        v-bind="bindShiftTableProps"
        ref="shiftTableRef"
        class="shift-modal-table"
      />
    </basic-modal>
  </div>
</template>
<script lang="ts" setup>
import { attendanceApi } from '@/api/attendance'
import { attendanceShiftProps } from './props'
import { TableProps } from 'ant-design-vue/es/table'
import { onUnmounted } from 'vue'
import { cloneDeep } from 'lodash-es'
import { BasicModalProps } from '../../../../modal/prop'
import { useUserStore } from '../../../../../store/modules/user'
const props = defineProps(attendanceShiftProps)
const emits = defineEmits<{(e: 'update:value', value:number[]):void}>()
const shiftModalVisible = ref(false)
const shiftTableRef = ref()
let currentShiftIdx = null
let currentShift = ref()
const userStore = useUserStore()

let defaultShift = ref(cloneDeep(props.projectDefaultShift))

const restShift = cloneDeep(userStore.user?.relaxAttendanceShift)


const zhou = ['周一', '周二', '周三', '周四', '周五', '周六', '周日']


// watch(() => props.value, async (v:number[]) => {
//   if (v.length) {
//     let res = await attendanceApi.getAttendanceShiftList({ ids: [...new Set(v)] })
//     res = res.filter(item => v.includes(item.id))

//     res = res.reduce((acc, item) => {
//       acc[item.id] = item
//       return acc
//     }, {})

//     v.forEach((item, index) => {
//       dataSource.value.push({
//         day: zhou[index],
//         shift: res[item],
//         idx: index
//       })
//     })
//   }
// }, { immediate: true })

const dataSource = ref<any>([])
const initData = async () => {

  if (props.value && props.value?.length) {
    let res = await attendanceApi.getAttendanceShiftList({ ids: [...new Set(props.value)] })
    res = res.filter(item => props.value.includes(item.id))

    res = res.reduce((acc, item) => {
      acc[item.id] = item
      return acc
    }, {})

    props.value.forEach((item, index) => {
      dataSource.value.push({
        day: zhou[index],
        shift: res[item],
        idx: index
      })
    })
  } else {

    zhou.forEach(item => {
      dataSource.value.push(item === '周六' || item === '周日' ? {
        day: item,
        shift: cloneDeep(restShift)
      } : {
        day: item,
        shift: cloneDeep(unref(defaultShift))
      })
    })

    emits('update:value', dataSource.value.map(item => item.shift?.id))
  }

}
initData()

// const isFixed = (v) => {
//   let is = false
//   v.forEach(item => {
//     item
//   })
// }


const selectedRowKeys = computed(() => dataSource.value.filter(item => item.shift?.id !== restShift.id).map(item => item.day))


const shiftIds = computed(() => unref(dataSource).map(item => item.shift.id))
const onSelectChange = v => {
  unref(dataSource).forEach(item => {
    if (!v.includes(item.day)) {
      item.shift = cloneDeep(restShift)
    } else if (item.shift.id === restShift.id) {
      item.shift = cloneDeep(unref(defaultShift))
    }
  })
  emits('update:value', unref(shiftIds))
}

const bindDaysProps = computed(():TableProps => ({
  dataSource: unref(dataSource),
  rowKey: 'day',
  // rowSelection: true,
  bordered: true,
  rowSelection: {
    selectedRowKeys: unref(selectedRowKeys),
    onChange: onSelectChange
  },
  pagination: false,
  columns: [
    {
      title: '工作日',
      dataIndex: 'day',
      width: 100
    },
    {
      title: '班次时间段',
      customRender: ({ record }) => `${record.shift.shiftName} ${record.shift.shiftTime.map(item => item.shiftTimeFormat).join(', ')}`
      // dataIndex: ['shift', 'shiftTime']
    },
    {
      title: '操作',
      dataIndex: 'action',
      width: 100
    }
  ]
}))

const bindModalAttr:BasicModalProps = {
  title: '快捷设置班次',
  width: 700,
  height: 940,
  okText: '确定',
  cancelText: '取消',
  okButtonProps: {
    type: 'primary'
  },
  onOk: async () => {
    let rows = shiftTableRef.value.getSelectionRows()
    let v = rows.length > 0 ? rows[0] : null
    if (v) {
      if (currentShiftIdx) {
        dataSource.value[currentShiftIdx].shift = v
      } else {
        defaultShift.value = v as any
        dataSource.value.forEach(item => {
          item.shift = item.shift?.id === restShift.id ? restShift : v
        })
        await attendanceApi.updateDefaultShift(v.id, { projectId: props.projectId })
        userStore.getUserInfo()
      }
      currentShift.value = v as any
      shiftModalVisible.value = false
      emits('update:value', unref(shiftIds))
    }
  },
  onCancel: () => {
    shiftModalVisible.value = false
  },
}

const bindShiftTableProps = ref<IGridTable.Props>({
  // title: '班次管理',
  api: {
    getList: attendanceApi.getAttendanceShiftList
  },
  showTools: false,
  rowKey: 'id',
  rowSelection: {
    type: 'radio'
  },
  selectable: true,
  columnSchemas: [
    {
      title: '班次名称',
      dataIndex: 'shiftName',
    },
    {
      title: '考勤时段',
      customRender: ({ record }) => `${record.shiftTime.map(item => item.shiftTimeFormat).join(', ')}`
    },
  ],
  pagination: {
    pageSize: 10,
    showSizeChanger: false
  },
  searchSchemas: [
    {
      label: '班次名称',
      field: 'shiftName',
      span: 12
    }
  ],
  // rowActions: [
  //   {
  //     label: '确定',
  //     click: v => {
  //       if (currentShiftIdx) {
  //         dataSource.value[currentShiftIdx].shift = v
  //       } else {
  //         defaultShift.value = v as any
  //         dataSource.value.forEach(item => {
  //           item.shift = item.shift?.id === restShift.id ? restShift : v
  //         })
  //       }
  //       currentShift.value = v as any
  //       shiftModalVisible.value = false
  //       emits('update:value', unref(shiftIds))
  //     }
  //   }
  // ],
})

const handleShift = (v?: Recordable, index?:number) => {
  currentShiftIdx = null
  if (v) {
    currentShiftIdx = index
  }
  shiftModalVisible.value = true
}

onUnmounted(() => {
  emits('update:value', [])

})

</script>
