import { ExtractPropTypes } from 'vue'
import type { SwitchProps } from 'ant-design-vue/es/switch'

export const CustomSwitchProp = {

  value: {
    type: Number,
    default: 0
  },
  checkedChildren: {
    type: String,
    default: '开启'
  },
  unCheckedChildren: {
    type: String,
    default: '关闭'
  },
  unCheckedValue: {
    type: Number,
    default: 0
  },
  checkedValue: {
    type: Number,
    default: 1
  },
}

export type SwitchProp = Partial<ExtractPropTypes<typeof CustomSwitchProp>> & SwitchProps