import { request } from '@/utils/request'

// 后台管理系统账号
const adminApi = {

  /** 【】获取账号列表 */
  getAdmins: params => request.get('/admin_users/index', params),

  // 审批设置，审批人下拉列表使用
  getAllAdmin: params => request.get('/admin_users/allAdmin', params),

  /** 【】 编辑 新增账号 */
  addAdmin: params => request.post('/admin_users/store', params),

  /** 【】更新账号 */
  updateAdmin: (id, params) => request.post(`/admin_users/update/${id}`, params),

  // 获取部门列表
  getDepartmentLists: params => request.get('/projectCompany/projectCompanyDepartment/getDepartmentLists', params),
  // 根据部门获取用户列表
  getAdminUsers: params => request.get('/projectCompany/projectCompanyDepartment/getAdminUsers', params),
  // 删除部门
  destroyDept: id => request.delete(`/projectCompany/projectCompanyDepartment/destroy/${id}`),
  // 编辑/新增部门
  createAndUpdateDept: params => request.post('/projectCompany/projectCompanyDepartment/store', params),
  // 获取部门详情
  getDepartmentDetail: id => request.get(`/projectCompany/projectCompanyDepartment/show/${id}`),
  // 获取部门人数数量
  getDeptmentUserCount: params => request.get('/projectCompany/projectCompanyDepartment/getAdminUsersCount', params)
}
// 角色管理
const roleApi = {

  /* 获取角色列表 */
  getRoleList: params => request.get('admin_role/index', params),

  /*  添加角色 */
  addRole: params => request.post('admin_role/store', params),

  /* 修改角色 */

  updateRole: (id, params) => {
    params.id = id
    return request.post('admin_role/store', params)
  },

  /* 删除角色 */

  deleteRole: id => request.post('admin_role/delete', { id })

}

const businessInsureApi = {
  // 获取商保列表
  getBusinessInsureList: params => request.get('/social/StaffInsurePlan/lists', params),
  // 新增和更新
  newAndUpdateInsure: params => request.post('/social/StaffInsurePlan/store', params),
  // 批量导入商保增员
  insureAddsImportUsers: params => request.post('/social/StaffInsureAdds/importUsers', params),
  // 删除商保
  delBusinessInsure: id => request.get('/social/StaffInsurePlan/delete', { id }),
  // 批量导入商保检查
  insureAddsImportUsersCheck: params => request.post('/social/StaffInsureAdds/importCheck', params),
}


// 字典配置
const dictionaryApi = {

  /* 获取字典列表 */
  getDictionaryList: params => request.get('dictionary_code/index', params),

  /* 新增字典配置 */
  addDictionaryConfig: params => request.post('dictionary_code/store', params),

  /* 修改字典配置 */
  updateDictionary: (id, params) => request.post(`dictionary_code/update/${id}`, params),

  /* 删除字典 */
  deleteDictionary: id => request.get(`dictionary_code/destroy/${id}`)
}

// 帮助中心Article/HelpArticle/list
const helpApi = {

  /* 文章列表 */
  getHelpArticleList: params => request.post('Article/HelpArticle/lists', params),

  /* 添加文章 */
  addHelpArticleList: params => request.post('Article/HelpArticle/store', params),

  /* 修改文章 */
  updataHelpArticleList: (id, params) => request.post(`Article/HelpArticle/update/${id}`, params),

  /* 删除文章 */
  deleteHelpArticleList: id => request.post('Article/HelpArticle/delete', { id }),

  /* 查看文章 */
  showHelpArticleList: (params, id) => request.post(`Article/HelpArticle/show/${id}`, params)
}

/** 【】租户 */
const clientApi = {

  /** 【】获取租户列表 */
  getClientList: params => request.get('admin_group/index', params),

  addClient: params => request.post('admin_group/store', params),

  updateClient: (id, params) => request.post('admin_group/store', {
    id,
    ...params
  }),

  /** 【】获取切换租户列表 */
  getClientSelectList: () => request.get('admin_group/temp'),

  // 获取当前登录账号绑定的租户列表信息
  getAdminGroupUsersList: params => request.get('admin/adminGroupUsers/index', params),

  /** 【】切换租户列表 */
  changeUserGroup: params => request.post('admin_users/temp_group', params),


}

const menuApi = {

  /** 【】获取菜单列表 */
  getMenuList: params => request.get('admin_routes/index', {
    isTree: 1,
    order: 'desc',
    sort: 'orderby',
    ...params
  }),

  /** 【】获取租户菜单列表 */
  // getClientMenuList: () => request.get('admin_routes/index', {
  //   isTree: 1,
  //   order: 'desc',
  //   sort: 'orderby'
  // }),

  /** 【】获取简单的菜单列表 */
  getMenuSimpleList: params => request.get('admin_routes/routes', params),

  addMenu: params => request.post('admin_routes/store', params),

  removeMenu: params => request.post('admin_routes/delete', params),

  /** 获取全部菜单路由列表 */
  getUserMenuList: params => request.get('admin_routes/user_routes', params),

  /** 【】获取租户路由列表 */
  getClientRouteList: () => request.get('admin_group/list_routes', { isTree: 1 })


}


const permissionApi = {

  /** 【】获取权限列表 */
  getPermissionList: () => request.get('admin_menu/index', { isTree: 1 }),

  /** 【】通过菜单id添加权限列表 */
  addPermissionByMenu: params => request.post('admin_routes/power', params),
}


const customFieldApi = {
  // 自定义字段列表
  getCustomFieldList: params => request.post('staff/staff_group_field/lists', params),

  getCustomFieldUserInfo: params => request.post('projects/projects/userFieldsInfo', params),

  addCustomField: params => request.post('staff/staff_group_field/store', params),

  updateCustomField: (id, params) => request.post(`staff/staff_group_field/update/${id}`, params),

  deleteCustomField: (id, params) => request.post(`staff/staff_group_field/destroy/${id}`, params),
  customFieldSetValid: id => request.post(`staff/staff_group_field/setValid/${id}`)
}

// 消息设置
const messageApi = {
  // 获取消息设置列表
  getMessageEventDetailList: (params = {}) => request.get('/messages/message_event_details/lists', params),
  // 修改消息设置
  modifyMessageSetting: params => request.post('/messages/message_project_send_datetime/store', params),
  // 获取单个消息设置，用于编辑等
  getMessageEventDetail: (params = {}) => request.get('/messages/message_project_send_datetime/show', params),
  // 获取消息列表
  getMessageInboxList: (params = {}) => request.get('/messages/message_inbox_mail/lists', params),
  // 获取最新几条未读消息
  getUnreadMessageList: () => request.get('/unreadMessage'),
  // 单条消息已读
  singleMessageRead: id => request.get(`/readMessage/${id}`),
  // 所有消息已读
  allMessageRead: () => request.get('/messageAllRead'),
  // 批量设置已读
  moreMessageRead: params => request.post('/messageSelectedRead', params)
}

// 小程序登记
const miniProgramApi = {
  // 小程序列表
  getMiniAppList: params => request.get('/client/client_mini_app/lists', params),
  // 小程序新增
  newAddMiniApp: params => request.post('/client/client_mini_app/store', params),
  // 小程序修改
  updateMiniApp: (id, params) => request.post(`/client/client_mini_app/update/${id}`, params),
  // 小程序禁用
  closeMiniApp: params => request.post('/close/miniAppConfig', params),
}

export { adminApi, roleApi, dictionaryApi, helpApi, clientApi, menuApi, permissionApi, customFieldApi, messageApi, miniProgramApi, businessInsureApi }


