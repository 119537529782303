import { ExtractPropTypes, PropType } from 'vue'
import type { SelectProps } from 'ant-design-vue/es/select'

export const projectApiSelectProps = {
  apiParams: {
    type: Object,
    default: () => ({}),
  },
  onApiComplete: {
    type: Function as PropType<(options: Recordable<any>[])=>void>,
    default: () => {}
  }
}

export type ProjectApiSelectProps = Partial<ExtractPropTypes<typeof projectApiSelectProps>> & SelectProps