<template>
  <a-cascader v-bind="bindAttrs" />
</template>
<script lang="ts" setup>
import { socialSecurityApi } from '@/api/social-security'
import { CascaderOptionType } from 'ant-design-vue/es/cascader'
import { pick } from 'lodash-es'
type ProvinceObj = { [key: string]: CascaderOptionType }
type EmitEvents = {
  (e: 'change', ...value): void,
  (e: 'update:value', ...value): void,
  (e: 'options-change', options:Recordable[]):void
}
const emits = defineEmits<EmitEvents>()
const attrs = useAttrs()
const options = ref<Recordable[]>([])

onMounted(() => {
  fetch()
})

async function fetch() {
  let res = await socialSecurityApi.getSocialCityList()
  // 后端返回的数据是省市未合并的需要对其合并处理
  let provinceObj = {} as ProvinceObj
  res.forEach(item => {
    provinceObj[item.provinceId] ? '' : provinceObj[item.provinceId] = {
      label: item.provinceName,
      value: item.provinceId,
      children: []
    }
  })
  res.forEach(item => {
    provinceObj[item.provinceId].children?.push({
      label: item.cityName,
      value: item.cityId,
      children: item.socialPlans.map(plan => ({
        label: plan.title,
        value: plan.id,
        details: plan.details.map(item => pick(item, ['title', 'code', 'minBase', 'companyPayPercent', 'personalPayPercent']))
      }))
    })
  })
  options.value = Object.values(provinceObj)
  emits('options-change', options.value)
  // loading.value = false
}
const bindAttrs = computed(() => ({
  options: unref(options),
  onChange: (...newValue) => emits('change', ...newValue),
  'onUpdate:value': (...newValue) => emits('update:value', ...newValue),
  ...attrs
}))

</script>