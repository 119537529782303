<template>
  <Cascader
    :value="value"
    :options="options"
    placeholder="请选择"
    show-search
    @dropdownVisibleChange="handleFetch"
    @update:value="handleChange"
  />
</template>
<script lang="ts" setup>
import { ref } from 'vue'
import { Cascader } from 'ant-design-vue'
import { insuranceRegionSelectProps } from './props'
import { isArray } from 'lodash-es'
import { globalApi } from '@/api/global'
const options = ref()

const props = defineProps(insuranceRegionSelectProps)

async function initData() {
  const res = await globalApi.getInsuranceProvince()
  options.value = res.province.map(p => ({
    value: props.needChineseValue ? p.name : p.id,
    label: p.name,
    key: p.id,
    children: p.city.map(i => ({
      value: props.needChineseValue ? i.name : i.id,
      label: i.name,
    })),
    isLeaf: false,
  }))
}
initData()


async function handleFetch(res) {
  if (res) {
    await initData()
  }
}

  type EmitEvents = {
    (e: 'update:value', value): void;
  }
const emits = defineEmits<EmitEvents>()

const value = computed(() => {
  if (isArray(props.value)) {
    return props.value?.map(v => (props.needChineseValue ? v : Number(v)))
  } else {
    return []
  }
})

function handleChange(value) {
  emits('update:value', value)
}
</script>
