<style lang="less">
.label::before {
  display: inline-block;
  margin-right: 4px;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  color: #FF4D4F;
  line-height: 1;
  content: "*";
}
</style>
<template>
  <div class="mt-1">
    <div
      v-for="(item, index) in selectValue"
      :key="index"
    >
      <div class="mb-2 mt-2 label">
        分支条件 {{ index+ 1 }}： <plus-circle-outlined
          v-if="!index && !disable"
          class="text-primaryColor-default"
          @click="changeSelect('+', index)"
        />
        <minus-circle-outlined
          v-if="selectValue.length > 1 && index !== 0"
          class="text-primaryColor-default"
          @click="changeSelect('-', index)"
        />
      </div>
      <div class="flex justify-between">
        <a-form-item-rest>
          <a-cascader
            v-model:value="item.cascaderValue"
            v-bind="cascader"
            :disabled="disable"
            :options="casDefaultOptions"
            style=" height: 32px;width: 47%;"
            @change="(value, selectedOptions)=>casChange(value, selectedOptions,index)"
          />
        </a-form-item-rest>
        <a-form-item-rest>
          <div
            v-if="item.fieldType === 'input'"
            class="relative"
            style="width: 47%;"
          >
            <a-input
              v-bind="input"
              v-model:value="item.inputValue"
              class="w-full"
              :disabled="disable"
            />
            <!-- <div
              v-if="!item.inputValue && item.cascaderValue.length"
              class="text-red-500 ml-1absolute -bottom-[20px]"
            >
              请输入
            </div> -->
          </div>
        </a-form-item-rest>

        <a-form-item-rest>
          <div
            v-if="item.fieldType === 'select'"
            class="relative"
            style="width: 47%;"
          >
            <a-select
              v-bind="select"
              v-model:value="item.selectValue"
              :disabled="disable"
              :options="item.selectOptions"
              class="w-full"
            />
            <!-- <div
              v-if="!item.selectValue.length && item.cascaderValue.length"
              class="text-red-500 ml-1 absolute -bottom-[20px]"
            >
              请选择
            </div> -->
          </div>
        </a-form-item-rest>
      </div>
    </div>
  </div>
</template>
<script lang='ts' setup>
import { ref } from 'vue'
import { dynamicCascadeSelectProps } from './props'
import { SelectProps } from 'ant-design-vue/lib/vc-select/Select'
import { cloneDeep, isArray, isUndefined } from 'lodash-es'
import globalModelForm from '@/hooks/global-model-form'
import { jobApi } from '@/api/job'
import { useAppStore } from '@/store/modules/app'
import { projectApi } from '@/api/project'
import { SingleValueType } from 'ant-design-vue/es/vc-cascader/Cascader'
const { getReferenceItems } = useAppStore()
const attrs = useAttrs()
type EmitEvents = {
  (e:'update:value', value):void
}
const disable = ref(true)
const emits = defineEmits<EmitEvents>()
const props = defineProps(dynamicCascadeSelectProps)


const casChange = (value, selectedOptions, index) => {
  const resetCas = () => {
    const selectedCasKey = selectValue.value.map(v => v.cascaderValue[0])
    casDefaultOptions.value.forEach(v => {
      if (selectedCasKey.includes(v.value)) {
        v.disabled = true
      } else {
        v.disabled = false
      }
    })
  }
  if (isUndefined(value) || isUndefined(selectedOptions)) {
    selectValue.value[index].cascaderValue = []
    resetCas()
    return
  }


  const choiceItem = selectValue.value.find(v => v.cascaderValue?.toString() === value.toString())!
  choiceItem.fieldType = selectedOptions[0].type
  choiceItem.selectOptions = projectSelectOptions.value[choiceItem.cascaderValue[0]]
  // choiceItem.selectValue = props.selectProps.mode === 'multiple' ? [] : ''
  choiceItem.selectValue = []
  choiceItem.inputValue = ''
  choiceItem.isCustom = selectedOptions[0].isCustom
  resetCas()

}

enum InputTypeEnum {
  input = 1,
  select
}
type Option = {
    label: string,
    value: string | number,
    disabled?: boolean;
    children?: Option[]
    type?: keyof typeof InputTypeEnum,
    isCustom?: 0 | 1
}
const projectSelectOptions = ref({
  job_id: [] as { label: string, value:number}[],
  staff_type: [] as { label: string, value:number}[]
}) // 所选项目的自定义字段的所有选项
const casDefaultOptions = ref<Option[]>([
  {
    type: 'select',
    label: '岗位',
    value: 'job_id',
    isCustom: 0,
    disabled: false,
    children: [
      {
        label: '等于',
        value: '1'
      },
    ]
  },
  {
    type: 'input',
    label: '部门',
    value: 'department_name',
    isCustom: 0,
    disabled: false,
    children: [
      {
        label: '等于',
        value: '1'
      },
    ]
  },
  {
    type: 'input',
    label: '工作地',
    value: 'company_address',
    isCustom: 0,
    disabled: false,
    children: [
      {
        label: '等于',
        value: '1'
      },
    ]
  },
  {
    type: 'select',
    label: '用工类型',
    value: 'staff_type',
    disabled: false,
    isCustom: 0,
    children: [
      {
        label: '等于',
        value: '1'
      },
    ]
  },
])

const selectValue = ref([
  {
    fieldType: 'input',
    cascaderValue: [] as SingleValueType,
    inputValue: '',
    selectValue: props.selectProps.mode === 'multiple' ? [] : '',
    selectOptions: [] as SelectProps['options'],
    isCustom: 0
  }
])

const changeSelect = (type: '+' | '-', index) => {
  if (type === '+') {
    selectValue.value.push({
      fieldType: 'input',
      cascaderValue: [],
      inputValue: '',
      selectValue: props.selectProps.mode === 'multiple' ? [] : '',
      selectOptions: [],
      isCustom: 0
    })
  } else {
    const delItem = selectValue.value.splice(index, 1)
    casDefaultOptions.value.forEach(v => {
      if (v.value === delItem[0].cascaderValue[0]) {
        v.disabled = false
      }
    })
  }
}

const cascader = computed(() => {
  const cloneCascaderProps = cloneDeep(props.cascaderProps)
  if (!cloneCascaderProps.placeholder) {
    cloneCascaderProps.placeholder = '请选择'
  }
  return cloneCascaderProps
})
const select = computed(() => {
  const cloneSelectProps = cloneDeep(props.selectProps)
  if (!cloneSelectProps.placeholder) {
    cloneSelectProps.placeholder = '请选择'
  }
  return cloneSelectProps
})
const input = computed(() => {
  const cloneInputProps = cloneDeep(props.inputProps)
  if (!cloneInputProps.placeholder) {
    cloneInputProps.placeholder = '请输入'
  }
  return cloneInputProps
})
const getJobList = async () => {
  const jobList = await jobApi.getJobList({})
  // eslint-disable-next-line camelcase
  projectSelectOptions.value.job_id = jobList.map(v => ({
    value: v.id,
    label: v.name
  }))
}


onMounted(() => {
  // eslint-disable-next-line camelcase
  projectSelectOptions.value.staff_type = getReferenceItems('staffTypeEnum').map(v => ({
    label: v.label,
    value: v.value
  }))

  watch(() => globalModelForm.formModel.projectId, async (nv, ov) => {
    if (isUndefined(nv)) {
      disable.value = true
      return
    }

    if (nv !== ov) {

      if (props.isShowCustom) { // 是否展示自定义字段
        const res = await projectApi.getProjectDetail(nv)
        res.exportFieldInfo.forEach(v => {
          projectSelectOptions.value[v.groupFieldInfo.fieldName] = v.groupFieldInfo.fieldValueList.map(v => ({
            label: v.fieldValue,
            value: v.fieldValue
          }))
        casDefaultOptions.value!.push({
          isCustom: 1,
          type: InputTypeEnum[v.groupFieldInfo.fieldType] as keyof typeof InputTypeEnum,
          label: v.groupFieldInfo.fieldName,
          value: v.groupFieldInfo.fieldName,
          disabled: false,
          children:
            [
              {
                label: '等于',
                value: '1'
              },
            ]
        })
        })
      }
      await getJobList()

      if (!isUndefined(props.value)) {
        props.value.forEach((v, i) => {
          selectValue.value[i] = {
            fieldType: v.fieldType,
            cascaderValue: [v.condition, v.operation] as never,
            inputValue: v.value,
            selectValue: v.value,
            selectOptions: projectSelectOptions.value[v.condition],
            isCustom: v.isCustom
          }
        })
      }
      disable.value = false
    }
  }, {
    immediate: true
  })

  // 选中后值,转交给表单
  watch(selectValue.value, (nv, ov) => {
    emits('update:value', nv.map(v => ({
      condition: v.cascaderValue[0],
      operation: v.cascaderValue[1],
      // eslint-disable-next-line no-nested-ternary
      value: v.fieldType === 'input' ? isArray(v.inputValue) ? v.inputValue : [v.inputValue] : v.selectValue,
      isCustom: v.isCustom,
      fieldType: v.fieldType
    })))
  })
})

</script>