import { defineStore } from 'pinia'

interface SystemState {
  isPc: boolean;
  miniScreen: boolean;
}
export const useSystemStore = defineStore('system', {
  state: (): SystemState => ({
    isPc: true,
    miniScreen: false
  }),
  getters: {},
  actions: {
    toggleDevice(isPc: boolean) {
      this.isPc = isPc
    },
    setMiniScrren(v: boolean) {
      this.miniScreen = v
    }
  }
})