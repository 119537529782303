<style lang="less" scoped>
@nav-size-height: 60px;
@layout-max-width: 1100px;

.layout {
  height: 100vh;
  width: 100%;
}

.layout-navbar {
  padding: 0;
  // background-color: #e94d07;
  background-color: #21209C;
}

.layout-sider {
  position: "fixed";
  top: 0;
  bottom: 0;
  left: 0;
  overflow: "auto";
  height: "100vh";

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    // padding: 10px 20px;
    height: 55px;
    background: #FFFFFF;
    box-sizing: border-box;

    .logo-text,
    .logo-collapsed {
      height: 37px;
      width: auto;
    }

    &.collapsed {
      margin: 12px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url("@images/logo.png");
    }
  }
}

</style>
<style lang="less">
.mobile-drawer-menu-container {
  .ant-menu-sub.ant-menu-inline {
    background-color: #FFFFFF;
  }
}
</style>

<template>
  <a-layout
    class="layout"
    style="height: 100vh;"
    theme="light"
  >
    <a-layout-header class="flex shadow z-[1000] layout-navbar">
      <div
        v-if="systemStore?.isPc"
        style="width: 170px;"
        class="flex items-center justify-center"
      >
        <img
          style="height: 37px;"
          src="@images/logo.png"
        >
      </div>

      <MenuOutlined
        v-else
        class="mt-4 ml-4 text-2xl text-white"
        @click="menuVisible = true"
      />
      <nav-bar
        @handleSideVisible="handleSideVisible"
        @change-group="changeGroup"
      />
    </a-layout-header>

    <a-layout>
      <a-layout-sider
        v-if="sideVisible && systemStore.isPc"
        v-model:collapsed="collapsed"
        class="shadow layout-sider"
        collapsible
        :width="245"
        :collapsed-width="48"
        theme="dark"
      >
        <Menu :parent-route="currentParentRoute" />
      </a-layout-sider>
      <a-layout-content class="overflow-auto">
        <Content ref="contentRef" />
      </a-layout-content>

      <a-drawer
        v-model:visible="menuVisible"
        class="mobile-drawer-menu-container"
        v-bind="menuDrawerProps"
      >
        <Menu
          :parent-route="currentParentRoute"
          @clicked="menuVisible=false"
        />
      </a-drawer>
      <!-- <a-layout-footer>
        <Footer />
      </a-layout-footer> -->
    </a-layout>
  </a-layout>
</template>

<script lang="ts" setup>
import NavBar from './components/navbar/index.vue'
import Menu from './components/menu.vue'
import Content from './components/content.vue'
import { RouteRecordNormalized } from 'vue-router'
import { MenuOutlined } from '@ant-design/icons-vue'
import { useSystemStore } from '../../store/modules/system'

const systemStore = useSystemStore()
const contentRef = ref<InstanceType<typeof Content>>()
const collapsed = ref(false)
const currentParentRoute = ref<RouteRecordNormalized>()

const menuVisible = ref(false)
const menuDrawerProps = ref({
  width: 270,
  placement: 'left',
  closable: false
})
const changeGroup = () => {
  if (contentRef.value?.resetHistoryPage) {
    contentRef.value?.resetHistoryPage()
  }
}
const sideVisible = ref(true)
function handleSideVisible(v: boolean, parentRoute:RouteRecordNormalized) {
  currentParentRoute.value = parentRoute
  sideVisible.value = v
}
</script>
