<template>
  <custom-api-select
    :api="dictionaryApi.getDictionaryList"
    :api-params="{ pcode: 'invoice_content',...apiParams }"
    :immediate="true"
    value-field="id"
    label-field="text"
  />
</template>
<script lang="ts" setup>
import { dictionaryApi } from '@/api/system'
import { invoiceContentSelectProps } from './props'
const props = defineProps(invoiceContentSelectProps)
const { apiParams } = props
</script>
