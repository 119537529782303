<template>
  <api-tree-select
    :api="adminApi.getDepartmentLists"
    :api-params="apiParams"
  />
</template>
<script lang="ts" setup>
import { deptTreeSelectProps } from './props'
import { adminApi } from '@/api/system'
const props = defineProps(deptTreeSelectProps)
const { apiParams } = props
</script>
