import { CustomRouteRecordRaw, RouteLocationRaw } from 'vue-router'
import EmptyLayout from '@/views/layouts/empty-layout.vue'
import { redirectRouter } from '@/hooks/router-redirect'
const wageChildrenRoutes: CustomRouteRecordRaw[] = [
  {
    path: 'fund-management',
    name: 'fundManagement',
    component: EmptyLayout,
    meta: {
      title: '资金管理',
      iconfont: 'icon-exchange-cny-fill',
      requiredPermission: 'FUND_MANAGEMENT',
      hideHistoryPage: true,
      noQuick: true
    },
    children: [
      {
        path: 'business-wallet',
        name: 'businessWallet',
        component: () => import('@/views/wage/fund-management/business-wallet.vue'),
        meta: {
          title: '商户钱包',
          hideHistoryPage: false,
          iconfont: 'icon-wallet-3-line',
          requiredPermission: 'BUSINESS_WALLET',
          // parentNameForBreadcrumb: 'workerWage'
        },

      },
      {
        path: 'son-wallet',
        name: 'sonWallet',
        component: () => import('@/views/wage/fund-management/son-wallet.vue'),
        meta: {
          title: '子账户钱包',
          hideHistoryPage: false,
          iconfont: 'icon-wallet-line',
          requiredPermission: 'SON_WALLET',
          // parentNameForBreadcrumb: 'workerWage'
        },

      },
      {
        path: 'balance-detail',
        name: 'balanceDetail',
        component: () => import('@/views/wage/fund-management/balance-detail.vue'),
        meta: {
          title: '余额流水',
          hideHistoryPage: false,
          hideInMenu: true,
          iconfont: 'icon-wallet-line',
          requiredPermission: 'BALANCE_DETAIL',
          parentNameForBreadcrumb: 'sonWallet'

        },
      },
      {
        path: 'balance-detail-detail',
        name: 'balanceDetailDetail',
        component: () => import('@/views/wage/fund-management/balance-detail-detail.vue'),
        meta: {
          title: '余额明细详情',
          hideHistoryPage: false,
          iconfont: 'icon-exchange-cny-fill',
          hideInMenu: true,
          requiredPermission: 'BALANCE_DETAIL_DETAIL',
          parentNameForBreadcrumb: 'sonWallet'

        },

      },
    ]

  },
  {
    path: 'worker-wage',
    name: 'workerWage',
    component: EmptyLayout,
    meta: {
      title: '员工发薪',
      iconfont: 'icon-user-settings-line1',
      requiredPermission: 'WORKER_WAGE',
      hideHistoryPage: true,
      noQuick: true

    },
    children: [
      {
        path: 'wage-bill',
        name: 'wageBill',
        component: () => import('@/views/wage/worker-wage/wage-bill/wage-bill.vue'),
        meta: {
          title: '发薪账单',
          hideHistoryPage: false,
          iconfont: 'icon-file-list-line',
          requiredPermission: 'WAGE_BILL',
        },
      },
      {
        path: 'wage-bill-detail',
        name: 'wageBillDetail',
        component: () => import('@/views/wage/worker-wage/wage-bill/wage-bill-detail.vue'),
        meta: {
          title: '发薪账单详情',
          hideHistoryPage: false,
          iconfont: 'icon-exchange-cny-fill',
          hideInMenu: true,
          requiredPermission: 'WORKER_BILL_DETAIL',
          parentNameForBreadcrumb: 'wageBill',
          noQuick: true
        },
      },
      {
        path: 'create-wage',
        name: 'createWage',
        component: () => import('@/views/wage/worker-wage/wage-bill/create-wage.vue'),
        meta: {
          title: '创建发薪账单',
          hideHistoryPage: false,
          hideInMenu: true,
          iconfont: 'icon-exchange-cny-fill',
          parentNameForBreadcrumb: 'wageBill',
          requiredPermission: 'CREATE_WAGE',
        },
      },
      {
        path: 'wage-record-list',
        name: 'wageRecordList',
        component: () => import('@/views/wage/worker-wage/wage-record-list/wage-record-list.vue'),
        meta: {
          title: '发薪记录',
          hideHistoryPage: false,
          iconfont: 'icon-file-list-line',
          requiredPermission: 'WORKER_RECORD_LIST',
        },
      },
      {
        path: 'new-wage-record-list',
        name: 'newWageRecordList',
        component: () => import('@/views/wage/worker-wage/wage-record-list/wage-record-list2.vue'),
        meta: {
          title: '发薪记录',
          hideHistoryPage: false,
          iconfont: 'icon-bank-card-line',
          requiredPermission: 'NEW_WORKER_RECORD_LIST',
        },
      },
      {
        path: 'wage-record-detail',
        name: 'wageRecordDetail',
        component: () => import('@/views/wage/worker-wage/wage-record-list/wage-record-detail.vue'),
        meta: {
          title: '发薪记录详情',
          hideHistoryPage: false,
          iconfont: 'icon-exchange-cny-fill',
          hideInMenu: true,
          requiredPermission: 'WORKER_RECORD_DETAIL',
          parentNameForBreadcrumb: 'wageRecordList',
          noQuick: true
        },
      },

      {
        path: 'wage-detail-record-list',
        name: 'wageDetailRecordList',
        component: () => import('@/views/wage/worker-wage/wage-detail-record-list.vue'),
        meta: {
          title: '发放明细',
          hideHistoryPage: false,
          iconfont: 'icon-file-list-3-line',
          requiredPermission: 'WAGE_DETAIL_RECORD_LIST', // 之前的和员工详情重复了。。

        },
      },
      {
        path: 'service-charge-bill',
        name: 'serviceChargeBill',
        component: () => import('@/views/wage/worker-wage/service-charge-bill/service-charge-bill.vue'),
        meta: {
          title: '服务费账单',
          hideHistoryPage: false,
          hideInMenu: false,
          iconfont: 'icon-file-list-3-line',
          requiredPermission: 'SERVICE_CHARGE_BILL',
        },
      },
      {
        path: 'service-charge-invoice-record',
        name: 'serviceChargeInvoiceRecord',
        component: () => import('@/views/wage/worker-wage/service-charge-invoice-record/service-charge-invoice-record.vue'),
        meta: {
          title: '开票记录',
          hideHistoryPage: false,
          hideInMenu: false,
          iconfont: 'icon-file-list-3-line',
          requiredPermission: 'SERVICE_CHARGE_INVOICE_RECORD',
        },
      },

      {
        path: 'import-wage',
        name: 'importWage',
        component: () => import('@/views/wage/worker-wage/wage-record-list/import-wage.vue'),
        meta: {
          title: '上传发薪名单',
          hideHistoryPage: false,
          hideInMenu: true,
          iconfont: 'icon-exchange-cny-fill',
          parentNameForBreadcrumb: 'wageRecordList',
          requiredPermission: 'IMPORT_WAGE',
        },
      },
      {
        path: 'new-import-wage',
        name: 'newImportWage',
        component: () => import('@/views/wage/worker-wage/wage-record-list/import-wage2.vue'),
        meta: {
          title: '上传发薪名单',
          hideHistoryPage: false,
          hideInMenu: true,
          iconfont: 'icon-exchange-cny-fill',
          parentNameForBreadcrumb: 'wageRecordList',
          requiredPermission: 'IMPORT_WAGE',
        },
      },

    ]
  },
  {
    path: 'invoice-management',
    name: 'invoiceManagement',
    component: EmptyLayout,
    meta: {
      title: '发票管理',
      iconfont: 'icon-coupon-line',
      requiredPermission: 'INVOICE_MANAGEMENT',
      hideHistoryPage: true,
      noQuick: true
    },
    children: [
      {
        path: 'management-invoice-record',
        name: 'managementInvoiceRecord',
        component: () => import('@/views/wage/invoice-management/management-invoice-record.vue'),
        meta: {
          title: '开票记录',
          hideHistoryPage: false,
          hideInMenu: false,
          iconfont: 'icon-file-list-3-line',
          requiredPermission: 'MANAGEMENT_INVOICE_RECORD',
        },
      },
      {
        path: 'invoice-message',
        name: 'invoiceMessage',
        component: () => import('@/views/wage/invoice-management/invoice-message.vue'),
        meta: {
          title: '开票信息',
          hideHistoryPage: false,
          iconfont: 'icon-coupon-3-line',
          requiredPermission: 'INVOICE_MESSAGE',
        },
      },
      {
        path: 'apply-invoice',
        name: 'applyInvoice',
        component: () => import('@/views/wage/invoice-management/apply-invoice.vue'),
        meta: {
          title: '申请开票',
          hideHistoryPage: false,
          hideInMenu: true,
          iconfont: 'icon-coupon-3-line',
          requiredPermission: 'APPLY_INVOICE',
        },
      },
      {
        path: 'invoice-record',
        name: 'invoiceRecord',
        component: () => import('@/views/wage/invoice-management/invoice-record.vue'),
        meta: {
          title: '开票记录',
          hideHistoryPage: false,
          hideInMenu: true,
          iconfont: 'icon-survey-line',
          requiredPermission: 'INVOICE_RECORD',
        },
      },

      {
        path: 'address-management',
        name: 'addressManagement',
        component: () => import('@/views/wage/invoice-management/address-management.vue'),
        meta: {
          title: '寄送地址管理',
          hideHistoryPage: false,
          iconfont: 'icon-pin-distance-line',
          requiredPermission: 'ADDRESS_MANAGEMENT',
        },
      },
    ]

  },
]
export const wageRoutes: CustomRouteRecordRaw = {
  path: 'wage',
  name: 'wage',
  component: EmptyLayout,
  redirect: (): RouteLocationRaw => redirectRouter(wageChildrenRoutes),
  meta: {
    title: '发薪管理',
    iconfont: 'icon-emotion-laugh-line',
    desc: '以薪酬为维度内容',
    requiredPermission: 'WAGE',
    needIcon: true,
    noQuick: true
  },
  children: wageChildrenRoutes
}