<template>
  <a-input-group class="!flex">
    <CustomApiSelect
      :key="datekey"
      class="flex-1"
      style="width: 90%;"
      v-bind="$attrs"
      :value="value"
      :api="api"
      :immediate="immediate"
      :api-params="apiParams"
      :value-field="valueField"
      :label-field="labelField"
      :result-field="resultField"
      :zero-to-undefined="zeroToUndefined"
      :number-to-string="numberToString"
    />
    <a-button
      type="primary"
      @click="open"
    >
      <template #icon>
        <plus-outlined />
      </template>
    </a-button>
    <basic-modal
      v-bind="bindSocialModelAttr"
      ref="basicFormRef"
    >
      <basic-form
        v-bind="socialPlansAttr"
        ref="basicFormRef"
      />
    </basic-modal>
  </a-input-group>
</template>
<script lang="ts" setup>
// 用工单位名称
import { customApiSelectProps } from './props'
import CustomApiSelect from './custom-api-select.vue'

import { BasicModalProps } from '@/components/modal/prop'
import { useMessage } from '@/hooks/message'
const props = defineProps(customApiSelectProps)
const { value, api, immediate, apiParams, valueField, labelField, resultField, zeroToUndefined, numberToString } = props

import { useAppStore, useUserStore } from '@/store'
import { jobApi } from '@/api/job'
const { getReferenceItems } = useAppStore()
const visible = ref(false)
const basicFormRef = ref()
const datekey = ref()

// 模态框参数
const bindSocialModelAttr = computed((): BasicModalProps => ({
  title: '新增岗位',
  width: 666,
  visible: unref(visible),
  okText: '提交',
  cancelText: '取消',
  okButtonProps: {
    type: 'primary'
  },
  destroyOnClose: true,
  onOk: async () => {
    await basicFormRef.value.validate()
    await jobApi.addJob({
      ...basicFormRef.value.formModel
    })
    datekey.value = Date.now()
    visible.value = false
  },
  onCancel: () => {
    visible.value = false
  },
}))
// 表单参数
const socialPlansAttr = computed((): IForm.Props => ({
  actionable: false,
  labelAlign: 'left',
  layout: 'vertical',
  schemas: [
    // title: '',
    {
      required: true,
      label: '岗位名称',
      field: 'name',
      component: 'Input',
    } as IForm.Schema<'Input'>,
    {
      label: '状态',
      field: 'isValid',
      component: 'RadioGroup',
      componentProps: {
        options: getReferenceItems('isValidEnum'),
      },
      defaultValue: 1,
    },
  ],
}))


function open() {
  if (!useUserStore().hasPermission('JOB_CREATE')) {
    return useMessage.error('没有添加岗位权限')
  }
  visible.value = true
}
</script>
