import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import axios from 'axios'
import { useEnv } from '@/hooks/env'
// import iconv from 'iconv-lite'
// 一个纯粹用javascript解码的模块iconv-lite，可以实现编码转换,解决jszip解压，里面的中文文件名乱码问题

export function download(imageUrls, folderName = '下载') {
  const zip = new JSZip()
  const folder = zip.folder(folderName)
  const downloadTasks = imageUrls.map(imageUrl => fetch(imageUrl).then(imageContent => {
    const name = imageUrl.split('/')[imageUrl.split('/').length - 1]
    // 写入二进制内容文件
    folder?.file(name, imageContent.blob(), { binary: true })
  }))
  Promise.all(downloadTasks).then(() => {
    zip.generateAsync({ type: 'blob' }).then(content => {
      // 写入本地
      saveAs(content, folderName)
    })
  })
}

// imageObj ==> {name:'',url: ''}
export function downloadForObj(imageObjs, folderName = '下载') {
  const zip = new JSZip()
  const folder = zip.folder(folderName)
  const downloadTasks = imageObjs.map(imageObj => fetch(imageObj.url).then(imageContent => {
    const name = imageObj.url.split('.')[imageObj.url.split('.').length - 1]
    // 写入二进制内容文件
    folder?.file(`${imageObj.name}.${name}`, imageContent.blob(), { binary: true })
  }))
  Promise.all(downloadTasks).then(() => {
    zip.generateAsync({ type: 'blob' }).then(content => {
      // 写入本地
      saveAs(content, folderName)
    })
  })
}


export function downloadImg(imageUrl) {
  fetch(imageUrl).then(imageContent => {
    const name = imageUrl.split('/')[imageUrl.split('/').length - 1]
    // 写入二进制内容文件
    saveAs(imageContent, name)
  })
}

function upload(file) {
  let formData = new FormData()
  formData.append('file', file)
  return new Promise((resolve, reject) => {
    axios.post(useEnv.uploadApiUrl, formData).then(res => {
      resolve(res.data.data)
    })
      .catch(err => {
        reject(err)
      })
  })
}
// 解压 .zip 文件,返回一个文件列表
// maxFileSize: 限制文件大小，单位B
export async function unZip(zipFile, maxFileSize = 1000) {
  let fileList: any = []
  // 判断是否是 .zip 文件
  // .rar文件zipFile.type是空
  let isZipFile = zipFile.type === 'application/zip' || zipFile.type === 'application/x-zip-compressed'
  if (!isZipFile) {
    return
  }
  const zip = new JSZip()
  // 解压Zip压缩包，参数默认是二进制
  const zipData = await zip.loadAsync(zipFile, {
    // 尝试解决文件名中文乱码问题，两种方式都报错，尚未解决
    // decodeFileName: function (bytes) {
    //  return iconv.decode(bytes, 'gbk')  // 按中文编码
    // }
    //   decodeFileName: function (bytes) {
    //   return iconv.decode(bytes, 'cp866');
    // }
  })
  console.log(zipData.files)
  let zipLength = Object.keys(zipData.files).length
  // console.log(zipLength)// 拿到压缩包内文件的数量

  return new Promise(async (resolve, reject) => {
    let zipDataFiles: any = []
    let zipDataFilesName: any = []
    // zipData并不是一个数组,没有map方法
    zipData.forEach(async (relativePath, file) => {
      // fix: 过滤 mac osx 系统压缩的副作用
      if (!relativePath.startsWith('__MACOSX')) {
        // 判断是否是目录
        if (!file.dir) {
          // zip.file(file.name)?.async('uint8array')
          //   .then(u8 => {
          //     // 创建 File 对象的
          //     let blob = new File([u8], file.name)
          //     // 将要上传的文件列表
          //     fileList.push({
          //       name: file.name,
          //       file: blob
          //     })
          //     console.log(fileList)
          //     console.log(fileList.length, zipLength)
          //     // 如果得到的文件数量等于压缩包的文件的数量，说明拿到压缩包内所有文件
          //     if (fileList.length === zipLength) {
          //       resolve(fileList)
          //     }
          //     // 直接逐个上传解压后的文件
          //     // upload(blob)
          //   })
          //   .catch(err => {
          //     console.log('解压出错', err)
          //     reject()
          //   })
          zipDataFiles.push(zip.file(file.name)?.async('uint8array'))
          zipDataFilesName.push(file.name)
          // let u8 = await zip.file(file.name)?.async('uint8array')
          // let blob = new File([u8 as any], file.name)
          //   fileList.push({
          //   name: file.name,
          //   file: blob
          // })
        }
      }
    })
    Promise.all(zipDataFiles).then(res => {
      res.forEach((u8, index) => {
        let blob = new File([u8], zipDataFilesName[index])
        if (blob.size > maxFileSize) {
          fileList.push({
            name: zipDataFilesName[index],
            file: blob
          })
        }
      })
      resolve(fileList)
    })
    // console.log("12345:",fileList)//拿到的是空的,即便用await
  })
}