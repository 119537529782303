
import { computed, nextTick, onBeforeUnmount, ref, unref } from 'vue'
import SparkMD5 from 'spark-md5'
import Uploader from 'simple-uploader.js'
interface UploaderDefaults {
    chunkSize?: number;
    accept?:string,
    forceChunkSize?: boolean;
    simultaneousUploads?: number;
    singleFile?: boolean;
    fileParameterName?: string;
    progressCallbacksInterval?: number;
    speedSmoothingFactor?: number;
    query?: Record<string, any>;
    params?: Record<string, any>;
    headers?: Record<string, any>;
    withCredentials?: boolean;
    preprocess?: any;
    method?: string;
    testMethod?: string;
    uploadMethod?: string;
    prioritizeFirstAndLastChunk?: boolean;
    allowDuplicateUploads?: boolean;
    target?: string;
    testChunks?: boolean;
    generateUniqueIdentifier?: any;
    maxChunkRetries?: number;
    chunkRetryInterval?: any;
    permanentErrors?: number[];
    successStatuses?: number[];
    onDropStopPropagation?: boolean;
    checkChunkUploadedByResponse?: any;
}
type Status = '' | 'done' | 'uploading' | 'error'
export type Options = {
    // 禁用
    disabled?: boolean,
    // 支持目录
    directory?: boolean,
    // 多文件
    multiple?: boolean,
    // 分片大小mb
    chunkSize?: number,
    // 添加文件事件
    onAddFile?: (file: any, e: any) => boolean
    // 上传前
    onBeforeUpload?: (files: any[], fileList: any[]) => boolean,
    // 上传进度事件
    onProgress?: (percent: number) => void
    // 上传文件错误
    onError?: (message: string) => void
    // 文件上传成功
    onSuccess?: (result: object, file: any) => void
} & UploaderDefaults
export default function useUploader(options: Options) {
  const percent = ref(0)
  const progressStep = ref('')
  const status = ref<Status>('')
  const uploadFile = ref({})
  const element = ref()
  const config = Object.assign({
    accept: '*',
    chunkSize: options.testChunks ? (options.chunkSize || 1) * 1024 * 1024 : 10240 * 1024 * 1024,
    query: (file: any) => Object.assign({
      file_type: file.fileType,
    }, options.params),
    checkChunkUploadedByResponse: options.testChunks ? function(chunk:any, message:any) {
      let objMessage:any = {}
      try {
        objMessage = JSON.parse(message)
      } catch (e) {
      }
      if ((objMessage.uploaded_chunks || []).indexOf(chunk.offset + 1) >= 0 || objMessage.url) {
        return true
      }
      return false
    } : null
  }, options)
  const uploader = new Uploader(config)
  // 分配触发element
  function assign(element: Element) {
    if (!config.disabled) {
      uploader.assignDrop(element)
      uploader.assignBrowse(element, config.directory, !config.multiple, {
        accept: config.accept
      })
    }
  }
  // 已经开始上传了
  uploader.on('uploadStart', () => {
    status.value = 'uploading'
  })
  // 文件添加触发
  uploader.on('fileAdded', (file: any, e: any) => {
    if (trigger('onAddFile', file, e) === false) {
      uploader.removeFile(file)
      return false
    }
  })
  // 文件已经加入到上传列表中，一般用来开始整个的上传。
  uploader.on('filesSubmitted', (files: any, fileList: any) => {
    if (trigger('onBeforeUpload', files, fileList) === false) {
      uploader.cancel()
    }
    files.forEach((file: any) => {
      file.pause()
      progressStep.value = '校验中'
      file_md5(file.file, {
        progress: percentage => {
          onProgress(percentage)
        },
        success: result => {
          file.uniqueIdentifier = result
          file.resume()
        }
      })
    })
    uploader.upload()
  })
  // 进度触发
  uploader.on('fileProgress', (rootFile: any, file: any, chunk: any) => {
    uploadFile.value = file
    progressStep.value = '上传中'
    onProgress(uploader.progress() * 100)
  })
  // 单个文件上传成功
  uploader.on('fileSuccess', (rootFile: any, file: any, msg: any, chunk: any) => {
    uploader.removeFile(file)
    const result = JSON.parse(msg)
    trigger('onSuccess', result, file)
  })
  // 文件错误触发
  uploader.on('fileError', (rootFile: any, file: any, msg: string) => {
    status.value = 'error'
    uploader.removeFile(file)
    trigger('onError', msg)
  })
  uploader.on('complete', () => {
    status.value = 'done'
  })
  // 更新进度
  function onProgress(num: number) {
    percent.value = num
    trigger('onProgress', percent.value)
  }
  // 触发
  function trigger(name: string, ...args: any[]) {
    if (Object(config)[name]) {
      return Object(config)[name].apply(null, args)
    }
    return null
  }
  // 设置选项
  function setOptions(options: { onSuccess: (response) => void }) {
    Object.assign(config, options)
  }
  nextTick(() => {
    const el = unref(element)
    if (el) {
      assign(el)
    }
  })
  onBeforeUnmount(() => {
    uploader.cancel()
  })
  const isUploading = computed(() => status.value == 'uploading')
  return {
    setOptions,
    uploadFile,
    isUploading,
    progressStep,
    status,
    assign,
    percent,
    element,
    uploader
  }
}
interface FileObserver {
    progress?: (percentage: number) => void,
    success?: (result: string) => void,
}
export function file_md5(file: File, observer: FileObserver = {}) {
  let blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
  let chunkSize = 2097152 // read in chunks of 2MB
  let chunks: number = Math.ceil(file.size / chunkSize)
  let currentChunk = 0
  let spark = new SparkMD5()
  let fileReader = new FileReader()

  fileReader.onload = function(e: any) {
    spark.appendBinary(e.target.result) // append array buffer
    currentChunk += 1
    if (observer.progress) {
      observer.progress(parseInt((currentChunk / chunks * 100) as unknown as string))
    }
    if (currentChunk < chunks) {
      loadNext()
    } else if (observer.success) {
      observer.success(spark.end())
    }
  }
  fileReader.onerror = function() {
    console.log('something went wrong')
  }
  function loadNext() {
    let start = currentChunk * chunkSize
    let end = start + chunkSize >= file.size ? file.size : start + chunkSize

    fileReader.readAsBinaryString(blobSlice.call(file, start, end))
  }
  loadNext()
}