<template>
  <custom-api-select
    :api="clientApi.getClientList"
    :api-params="apiParams"
    :immediate="true"
    value-field="id"
    label-field="text"
  />
</template>
<script lang="ts" setup>
import { clientApi } from '@/api/system'
import { clientApiSelectProps } from './props'
const props = defineProps(clientApiSelectProps)
const { apiParams } = props
</script>
