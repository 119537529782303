<template>
  <div>
    <Cascader
      :value="value"
      :options="options"
      :field-names="fieldConfig"
      placeholder="请选择"
      change-on-select
      @update:value="handleChange"
    />
  </div>
</template>
<script lang="ts" setup name="region-picker">
import { Cascader } from 'ant-design-vue'
import { getAreaDataTree } from './useRegion'
import { regionSelectProps } from './props'
import { isArray } from 'lodash-es'
type EmitEvents = {
  (e:'update:value', value):void
}
const emits = defineEmits<EmitEvents>()
const props = defineProps(regionSelectProps)
const value = computed(() => {
  if (isArray(props.value)) {
    return props.value?.map(v => (props.needChineseValue ? v : Number(v)))
  } else {
    return []
  }
})


const options = computed(() => getAreaDataTree(props.areaLevel))
const fieldConfig = computed(() => ({
  label: 'label',
  value: props.needChineseValue ? 'label' : 'id',
  children: 'children'
}))

function handleChange(value) {
  emits('update:value', value)
}
</script>
