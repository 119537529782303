import { ExtractPropTypes, PropType } from 'vue'
import type { TextAreaProps } from 'ant-design-vue/es/input'

export const inputTextareaUploadProps = {
  uploadable: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  rows: {
    type: Number as PropType<number>,
    default: 5,
  },
  helpText: {
    type: String as PropType<string>,
    default: '请输入内容',
  },
}

export type InputTextareaUploadProps = Partial<ExtractPropTypes<typeof inputTextareaUploadProps>> & TextAreaProps