<style lang="less" scoped>
  .img-delete {
    z-index: 999;
    position: absolute;
    top: -4px;
    right: -4px;
    width: 12px;
    height: 12px;
    line-height: 12px;
    text-align: center;
    border-radius: 6px;
    background-color: crimson;
    color: white;
    font-size: 12px;
  }
</style>

<template>
  <div>
    <div class="relative">
      <div
        v-if="!!uploadable"
        class="absolute"
      >
        <a-form-item-rest>
          <Upload
            accept-type="img"
            :show-upload-list="false"
            multiple
            @change="handleImgChange"
          >
            <div ref="imgUploadRef" />
          </Upload>
        </a-form-item-rest>
        <a-form-item-rest>
          <Upload
            accept-type="file"
            :capture="isMobileAgent()?null:undefined"
            :show-upload-list="false"
            :max-num="20"
            multiple
            @change="handleFileChange"
          >
            <div ref="fileUploadRef" />
          </Upload>
        </a-form-item-rest>
      </div>
      <a-textarea
        v-model:value="text"
        :rows="rows"
        :placeholder="helpText"
        @paste="onPaste"
      />
      <div
        v-if="!!uploadable"
        class="absolute bottom-1 left-1"
      >
        <label
          for="img-upload"
          class="mr-1"
        >
          <a-button @click="imgUploadRef?.click()"> <PictureOutlined /> 图片</a-button>
        </label>
        <label for="file-upload">
          <a-button @click="fileUploadRef?.click()"> <PaperClipOutlined /> 附件</a-button>
        </label>
      </div>
    </div>
    <div
      v-if="imgs.length > 0"
      class="flex justify-start mt-1"
    >
      <div
        v-for="(img, index) in imgs"
        :key="index"
        class="relative mr-1 cursor-pointer border-1"
      >
        <div
          class="img-delete"
          @click="handleDeleteImg(index)"
        >
          x
        </div>
        <div
          class="overflow-hidden"
          style="width: 60px; height: 60px"
        >
          <a-image
            :src="img.response.data.url"
            width="60"
          />
        </div>
      </div>
    </div>
    <div
      v-if="files.length > 0"
      class="mt-1"
    >
      <div
        v-for="(file, index) in files"
        :key="index"
        class="flex justify-between"
      >
        <a
          class="text-gray-700"
          :href="file.response.data.url"
          target="_blank"
        >{{ file.response.data.name }}</a>
        <DeleteOutlined
          class="cursor-pointer"
          style="color: crimson"
          @click="handleDeleteFile(index)"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { PictureOutlined, PaperClipOutlined, DeleteOutlined } from '@ant-design/icons-vue'
import { computed, ref, watch } from 'vue'
import useUploader from '@/hooks/useUploader'
import { useEnv } from '@/hooks/env'
import { useMessage } from '@/hooks/message'
import { isMobileAgent } from '@/utils/is'
import { inputTextareaUploadProps } from './props'
import Upload from './Upload.vue'
const emits = defineEmits(['update:value'])
defineExpose({ reset })
const imgUploadRef = ref<HTMLElement>()
const fileUploadRef = ref<HTMLElement>()
const props = defineProps(inputTextareaUploadProps)
const imgs = ref<any[]>([])
const files = ref<any[]>([])
const text = ref()
const { uploadApiUrl } = useEnv
const uploader = useUploader({
  target: uploadApiUrl,
  testChunks: false,
  params: {
    type: 'img',
  }
})
const fileIds = computed(() => {
  const imgIds = imgs.value.map(i => i.response.data.id)
  const fileIds = files.value.map(i => i.response.data.id)
  return [...imgIds, ...fileIds]
})
watch(
  () => [fileIds.value, text.value],
  val => handleUpdate(),
)
function onPaste(event) {

  const file = event.clipboardData.files[0]
  if (event.clipboardData.types[0] === 'Files' && file) {

    uploader.setOptions({
      onSuccess: response => {
        useMessage.loading()
        imgs.value.push({ response })
      }
    })
    uploader.uploader.addFile(file)
    useMessage.loading()
  }
}
function handleImgChange({ file }) {
  imgs.value.push(file)
  console.log('imgs', imgs.value)
}

function handleFileChange({ file }) {
  files.value.push(file)
  console.log('files', files.value)

}

function handleDeleteImg(index) {
  imgs.value.splice(index, 1)
}

function handleDeleteFile(index) {
  files.value.splice(index, 1)
}

function handleChangeText(val) {
  text.value = val
}

function handleUpdate() {
  emits('update:value', {
    file_ids: fileIds.value,
    text: text.value
  })
}

function reset() {
  text.value = ''
  imgs.value = []
  files.value = []
  handleUpdate()
}
</script>
