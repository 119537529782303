import { ExtractPropTypes, PropType } from 'vue'

export const regionSelectProps = {
  // 双向数据绑定的该组件的值
  value: {
    type: Array as PropType<string[]>,
    default: undefined,
  },
  // 是否直接要名字
  needChineseValue: {
    type: Boolean,
    default: false
  },
  // 省市区级别
  areaLevel: {
    type: Number,
    // 1是直接省级，2是市级，3区级
    // 兼容之前的，默认2级
    default: 2
  }
}

export type RegionSelectProps = ExtractPropTypes<typeof regionSelectProps>


