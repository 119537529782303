<style lang="less" scoped>
.calendar * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.calendar {
  display: flex;
  overflow: hidden;
  margin: 0;
  border: 2px solid #4E6EF2;
  border-radius: 16px;
  height: 465px;
  box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
  box-sizing: border-box;

  .container {
    padding-top: 14px;
    padding-left: 14px;

    .bar {
      position: relative;
      display: flex;
      margin-bottom: 24px;
      padding: 0 10px;
      height: 30px;

      div {
        position: relative;
        flex: 1;
      }

      div.button {
        position: absolute;
        right: 0;
        border-radius: 6px;
        height: 30px;
        width: 68px;
        font-size: 13px;
        text-align: center;
        color: #333333;
        background: #F5F5F6;
        line-height: 30px;
        cursor: pointer;
      }

      input,
      select {
        position: relative;
        margin-right: 6px;
        border: 1px solid #D7D9E0;
        border-radius: 6px;
        padding: 7px;
        width: 80px;
        text-align: center;
        background: #FFFFFF;
        box-sizing: border-box;
        line-height: 1;
        cursor: pointer;
      }

      select {
        appearance: none;
      }
    }

    ul,
    ol {
      width: 448px;
      list-style: none;
    }

    ul.head {
      li {
        float: left;
        height: 36px;
        width: 64px;
        font-size: 13px;
      }
    }

    ul.body {
      ol {
        li {
          position: relative;
          float: left;
          padding: 2px;
          height: 60px;
          width: 64px;
          cursor: pointer;

          div.inner {
            border: 2px solid transparent;
            border-radius: 6px;
            padding: 4px;

            b {
              display: block;
              height: 22px;
              font-size: 18px;
              font-weight: normal;
              color: #000000;
            }

            i {
              display: block;
              font-size: 12px;
              color: #333333;
              font-style: normal;
            }

            u {
              position: absolute;
              top: 7px;
              left: 7px;
              font-size: 12px;
              text-decoration: none;
              color: #626675;
              line-height: 12px;
            }
          }
        }

        li.other {
          opacity: 0.4;
          filter: alpha(opacity=40);
        }

        li:hover {
          div.inner {
            border: 2px solid #BDBFC8;
          }
        }

        li.selected {
          div.inner {
            border: 2px solid #BDBFC8;
          }
        }

        li.holiday {
          div.inner {
            background: #F5F5F6;
          }
        }

        li.holiday.rest {
          div.inner {
            background: #FDE3E4;
          }
        }

        li.rest {
          div.inner {
            b {
              color: #F73131;
            }

            u {
              color: #F73131;
            }
          }
        }

        li.today {
          div.inner {
            border: 2px solid #4E6EF2 !important;
          }
        }
      }
    }
  }

  .side {
    width: 50%;
    color: #FFFFFF;
    background: #4E6EF2;

    .ymd {
      padding-left: 14px;
      line-height: 45px;
      font-size: 13px;
    }

    .day {
      position: relative;
      margin: 0 auto;
      border-radius: 12px;
      height: 80px;
      width: 80px;
      font-size: 52px;
      text-align: center;
      background: rgb(255 255 255 / 50%);
      line-height: 80px;
    }

    .lunar {
      margin-top: 6px;
      padding: 0 14px;

      div {
        font-size: 13px;
        line-height: 21px;
      }
    }

    .festival {
      position: relative;
      margin-top: 13px;
      padding-right: 14px;
      padding-left: 22px;
      font-size: 12px;
      text-align: justify;
      color: #FFFFFF;
      line-height: 16px;
    }

    .festival::before {
      position: absolute;
      top: 6px;
      left: 16px;
      border-radius: 50%;
      height: 3px;
      width: 3px;
      background: #FFFFFF;
      content: "";
    }

    .yiji {
      position: relative;
      margin-top: 12px;
      padding-top: 12px;
      height: 80%;
      text-align: center;
      background: rgb(255 255 255 / 15%);

      .yi {
        float: left;
        width: 50%;

        div {
          font-size: 12px;
          line-height: 20px;
        }
      }

      .ji {
        float: right;
        width: 50%;

        div {
          font-size: 12px;
          line-height: 20px;
        }
      }

      b {
        display: block;
        margin: 0 auto;
        height: 30px;
        width: 30px;
        font-size: 24px;
        text-align: center;
        color: #FFFFFF;
        line-height: 30px;
        font-style: normal;
      }
    }
  }
}
</style>

<template>
  <div class="calendar">
    <div class="container">
      <div class="bar">
        <div>
          <input
            v-model=" state.year "
            type="number"
          >年
        </div>
        <div>
          <select v-model=" state.month ">
            <option
              v-for=" i in 12 "
              :key=" i "
              :value=" i "
            >
              {{ i }}月
            </option>
          </select>
        </div>
        <div>
          <select v-model=" state.holidayMonth ">
            <option value=" 0">
              假期安排
            </option>
            <option
              v-for=" h, i in state.holidays "
              :key=" i "
              :value=" h.month "
            >
              {{ h.name }}
            </option>
          </select>
        </div>
        <div>
          <div
            class="button"
            @click=" onBack "
          >
            返回今天
          </div>
        </div>
      </div>
      <ul class="head">
        <li
          v-for=" head, i in state.data.heads "
          :key=" i "
        >
          {{ head }}
        </li>
      </ul>
      <ul class="body">
        <li
          v-for=" week, i in state.data.weeks "
          :key=" i "
        >
          <ol>
            <li
              v-for=" day, i in week.days "
              :key=" i "
              :class=" { today: day.isToday, selected: day.isSelected, other: day.month != state.month, rest: day.isRest, holiday: day.isHoliday } "
              @click="onSelect(day)"
            >
              <div class="inner">
                <b class="text-center">{{ day.day }}</b>
                <i>{{ day.desc }}</i>
                <u v-if=" day.isHoliday ">{{ day.isRest ? '休' : '班' }}</u>
              </div>
            </li>
          </ol>
        </li>
      </ul>
    </div>
    <div class="side">
      <div class="ymd">
        {{ state.selected.ymd }}
      </div>
      <div class="day">
        {{ state.selected.day }}
      </div>
      <div class="lunar">
        <div>{{ state.selected.lunarMonth }}月 {{ state.selected.lunarDay }}</div>
        <div>{{ state.selected.yearGanZhi }}年 {{ state.selected.yearShengXiao }}</div>
        <div>{{ state.selected.monthGanZhi }}月 {{ state.selected.dayGanZhi }}日</div>
      </div>
      <div
        v-for=" f, i in state.selected.festivals "
        :key=" i "
        class="festival"
      >
        {{ f }}
      </div>
      <div
        v-if="showYiji"
        class="yiji"
      >
        <div class="yi">
          <b>宜</b>
          <div class="grid grid-cols-2">
            <div
              v-for=" f, i in state.selected.yi "
              :key=" i "
            >
              {{ f }}
            </div>
          </div>
        </div>
        <div class="ji">
          <b>忌</b>
          <div class="grid grid-cols-2">
            <div
              v-for=" f, i in state.selected.ji "
              :key=" i "
            >
              {{ f }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive, watch } from 'vue'
import { Solar, SolarMonth, SolarWeek, HolidayUtil } from 'lunar-typescript'
import { useMessage } from '@/hooks/message'

const now = Solar.fromDate(new Date())

class Day {
  public month = 0

  public day = 0

  public lunarDay = ''

  public lunarMonth = ''

  public yearGanZhi = ''

  public yearShengXiao = ''

  public monthGanZhi = ''

  public dayGanZhi = ''

  public ymd = ''

  public desc = ''

  public isToday = false

  public isSelected = false

  public isRest = false

  public isHoliday = false

  public festivals: string[] = []

  public yi: string[] = []

  public ji: string[] = []
}

class Week {
  public days: Day[] = []
}

class Month {
  public heads: string[] = []

  public weeks: Week[] = []
}

class Holiday {
  public name = ''

  public month = 0
}

const state = reactive({
  year: now.getYear(),
  month: now.getMonth(),
  weekStart: 1,
  selected: new Day(),
  data: new Month(),
  holidays: new Array<Holiday>(),
  holidayMonth: 0
})

function buildDay(d: Solar) {
  const ymd = d.toYmd()
  const lunar = d.getLunar()
  const day = new Day()
  day.month = d.getMonth()
  day.day = d.getDay()
  day.lunarMonth = lunar.getMonthInChinese()
  day.lunarDay = lunar.getDayInChinese()
  day.yearGanZhi = lunar.getYearInGanZhi()
  day.yearShengXiao = lunar.getYearShengXiao()
  day.monthGanZhi = lunar.getMonthInGanZhi()
  day.dayGanZhi = lunar.getDayInGanZhi()
  day.ymd = ymd
  day.isToday = ymd == now.toYmd()
  day.isSelected = ymd == state.selected.ymd
  if (day.isToday && state.selected.day === 0) {
    state.selected = day
  }
  const solarFestivals = d.getFestivals()
  solarFestivals.forEach(f => {
    day.festivals.push(f)
  })
  d.getOtherFestivals().forEach(f => {
    day.festivals.push(f)
  })
  lunar.getFestivals().forEach(f => {
    day.festivals.push(f)
  })
  lunar.getOtherFestivals().forEach(f => {
    day.festivals.push(f)
  })
  let rest = false
  if (d.getWeek() === 6 || d.getWeek() === 0) {
    rest = true
  }
  const holiday = HolidayUtil.getHoliday(ymd)
  if (holiday) {
    rest = !holiday.isWork()
  }
  day.isHoliday = !!holiday
  day.isRest = rest
  day.yi = lunar.getDayYi()
  day.ji = lunar.getDayJi()
  let desc = lunar.getDayInChinese()
  const jq = lunar.getJieQi()
  if (jq) {
    desc = jq
  } else if (lunar.getDay() === 1) {
    desc = `${lunar.getMonthInChinese()}月`
  } else if (solarFestivals.length > 0) {
    const f = solarFestivals[0]
    if (f.length < 4) {
      desc = f
    }
  }
  day.desc = desc
  return day
}

function render() {
  const month = new Month()
  const weeks: SolarWeek[] = []
  const solarWeeks = SolarMonth.fromYm(parseInt(`${state.year}`, 10), parseInt(`${state.month}`, 10)).getWeeks(state.weekStart)
  solarWeeks.forEach(w => {
    weeks.push(w)
  })
  while (weeks.length < 6) {
    weeks.push(weeks[weeks.length - 1].next(1, false))
  }
  weeks.forEach(w => {
    const week = new Week()
    const heads: string[] = []
    w.getDays().forEach(d => {
      heads.push(d.getWeekInChinese())
      week.days.push(buildDay(d))
    })
    month.heads = heads
    month.weeks.push(week)
  })
  state.data = month
  const holidays: Holiday[] = []
  HolidayUtil.getHolidays(state.year).forEach(h => {
    const holiday = new Holiday()
    holiday.name = h.getName()
    holiday.month = parseInt(h.getTarget().substring(5, 7), 10)
    const exists = holidays.some(a => a.name == holiday.name)
    if (!exists) {
      holidays.push(holiday)
    }
  })
  state.holidays = holidays
}

function onSelect(day: Day) {
  state.selected = day
}

function onBack() {
  state.holidayMonth = 0
  state.year = now.getYear()
  state.month = now.getMonth()
  state.selected = buildDay(now)
}

render()

watch(() => state.year, (nv, ov) => {
  if (!nv) {
    return
  }
  if (Number(nv) > 9999) {
    state.year = ov
    useMessage.info('年份最大输入四位数')
    return
  }
  render()

})

watch(() => state.month, () => {
  render()
})

watch(() => state.selected, () => {
  render()
})

watch(() => state.holidayMonth, newVal => {
  const month = parseInt(`${newVal}`, 10)
  if (month > 0) {
    state.month = month
    render()
  }
})

</script>
