import { redirectRouter } from '@/hooks/router-redirect'
import EmptyLayout from '@/views/layouts/empty-layout.vue'
import { CustomRouteRecordRaw, RouteLocationRaw } from 'vue-router'
const insuranceChildrenRoutes = [
  // 社保菜单
  {
    path: 'projects',
    name: 'InsuranceProjectList',
    component: () => import('@/views/insurance/project/index.vue'),
    meta: {
      title: '项目管理',
      iconfont: 'icon-calendar-check-line',
    },
  },
  {
    path: 'personnels-list',
    name: 'insurancePersonnels',
    component: () => import('@/views/insurance/personnels/personnels-list.vue'),
    meta: {
      title: '参保人员名单',
      iconfont: 'icon-calendar-check-line',
      requiredPermission: 'INSURANCE_PERSONNELS',
    },
  },

  {
    path: 'project/detail/:id',
    name: 'InsuranceProjectDetail',
    component: () => import('@/views/insurance/project/detail.vue'),
    meta: {
      title: '项目明细',
      hideInMenu: true,
      parentNameForBreadcrumb: 'InsuranceProjectList',
    },
  },

  {
    path: 'insurance-personnel-change',
    name: 'insurancePersonnelChange',
    component: () => import('@/views/insurance/personnelChange/index.vue'),
    meta: {
      title: '参保变更确认',
      iconfont: 'icon-contacts-book-line',
      requiredPermission: 'INSURANCE_PERSONNEL_CHANGE',
    }
  },
  {
    path: 'insurance-charges',
    name: 'insuranceCharges',
    component: () => import('@/views/insurance/charges/index.vue'),
    meta: {
      title: '购买扣费确认',
      iconfont: 'icon-survey-line',
      requiredPermission: 'INSURANCE_CHARGES',
    }
  },
  {
    path: 'insurance-policy',
    name: 'insurancePolicy',
    component: () => import('@/views/insurance/policy/index.vue'),
    meta: {
      title: '社保/公积金政策',
      iconfont: 'icon-pages-line',
      requiredPermission: 'INSURANCE_POLICY',
    },
  },
  {
    path: 'insurance-calculator',
    name: 'insuranceCalculator',
    targetHref: 'https://erptest.xinchouyi.com/admin/public/insurance/calculator',
    meta: {
      title: '社保计算器',
      iconfont: 'icon-pages-line',
      requiredPermission: 'INSURANCE_CALCULATOR',
    },
  },

]
export const insuranceRoutes: CustomRouteRecordRaw = {
  path: 'insurance',
  name: 'insurance',
  component: EmptyLayout,
  redirect: (): RouteLocationRaw => redirectRouter(insuranceChildrenRoutes),
  meta: {
    title: '社保公积金管理',
    iconfont: 'icon-emotion-laugh-line',
    desc: '以社保为维度内容',
    requiredPermission: 'INSURANCE',
    needIcon: true,
    noQuick: true
  },
  children: insuranceChildrenRoutes
}

