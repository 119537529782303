<template>
  <a-select
    v-bind="bindAttrs"
    :loading="loading"
    @dropdownVisibleChange="handleFetch"
  >
    <!-- <template
      v-if="loading"
      #suffixIcon
    >
      <loading-outlined spin />
    </template>
    <template
      v-if="loading"
      #notFoundContent
    >
      <span>
        <loading-outlined
          spin
          class="mr-1"
        />
        暂无数据
      </span>
    </template> -->
  </a-select>
</template>
<script lang="ts" setup>
import { computed, unref, useAttrs } from 'vue'
import { get, isFunction, isArray } from 'lodash-es'
import { getCacheApi } from './api-cache'
import { customApiSelectProps } from './props'

const props = defineProps(customApiSelectProps)
type EmitEvents = {
  (e:'change', ...value):void
  (e:'update:value', ...value):void
  (e: 'options-change', options: Recordable[]): void
  (e: 'option-select', ...value):void
}
const emits = defineEmits<EmitEvents>()
const attrs = useAttrs()

const loading = ref(false)
const isFetched = ref(false)
const options = ref<Recordable[]>([])
const value = computed(() => (props.value === 0 && props.zeroToUndefined ? undefined : props.value))

onMounted(() => {
  props.immediate && fetch()
})

watch(() => props.apiParams, (value, oldValue) => {
  if (JSON.stringify(value) !== JSON.stringify(oldValue)) {
    fetch()
  }
})

async function handleFetch() {
  if (!isFetched.value) {
    await fetch()
  }
}
async function fetch() {
  const { api, apiParams, resultField, labelField, valueField, numberToString } = props
  if (!api || !isFunction(api)) {
    return
  }

  loading.value = true
  const res = await getCacheApi(api, apiParams)
  let optionData = isArray(res) ? res : get(res, resultField)
  options.value = optionData?.map((item:any, index:number) => {
    const optionValue = item[valueField]
    return {
      label: isFunction(labelField) ? labelField(item) : item[labelField as string],
      value: numberToString ? `${optionValue}` : optionValue,
      key: `${optionValue}${index}`,
      origin: item
    }
  })
  isFetched.value = true
  emits('options-change', options.value)
  loading.value = false
}
const getValue = (value: any) => {
  let tmp = unref(value)
  if (!loading.value) {
    return tmp
  }
  if ((isArray(tmp) && tmp?.length !== 0) || (!isArray(tmp) && tmp)) {
    return '加载中'
  } else {
    return unref(value)
  }
}

watch(() => props.value, v => {
  v && optionSelect(v)
})

function optionSelect(v) {
  if (Array.isArray(v)) {
    emits(
      'option-select',
      options.value.filter(o => v.indexOf(o.value) > -1),
    )
  } else {
    emits(
      'option-select',
      options.value.find(o => o.value === v),
    )
  }
}
const bindAttrs = computed(() => ({
  dropdownStyle: { maxHeight: '280px' },
  optionFilterProp: 'label',
  showSearch: true,
  value: getValue(value),
  options: unref(options),
  onChange: (...newValue) => emits('change', ...newValue),
  'onUpdate:value': (...newValue) => emits('update:value', ...newValue),
  ...attrs,
}))
</script>
