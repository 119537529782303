import { redirectRouter } from '@/hooks/router-redirect'
import EmptyLayout from '@/views/layouts/empty-layout.vue'
import { CustomRouteRecordRaw, RouteLocationRaw } from 'vue-router'
const commercialInsuranceChildrenRoutes = [
  // 社保菜单
  {
    path: 'project',
    name: 'CommercialInsuranceProjectList',
    component: () => import('@/views/commercialInsurance/project/index.vue'),
    meta: {
      title: '项目列表',
      iconfont: 'icon-calendar-check-line',
      requiredPermission: 'COMMERCIAL_INSURANCE_PROJECT_LIST'
    },
  },
  // {
  //   path: 'personnels-list',
  //   name: 'insurancePersonnels',
  //   component: () => import('@/views/insurance/personnels/personnels-list.vue'),
  //   meta: {
  //     title: '参保人员名单',
  //     iconfont: 'icon-calendar-check-line',
  //     requiredPermission: 'INSURANCE_PERSONNELS',
  //   },
  // },

  {
    path: 'project/detail/:id',
    name: 'commercialInsuranceProjectDetail',
    component: () => import('@/views/commercialInsurance/project/detail.vue'),
    meta: {
      title: '项目明细',
      hideInMenu: true,
      parentNameForBreadcrumb: 'CommercialInsuranceProjectList',
      requiredPermission: 'COMMERCIAL_INSURANCE_PROJECT_LIST_DETAIL'
    },
  },

  // {
  //   path: 'insurance-personnel-change',
  //   name: 'insurancePersonnelChange',
  //   component: () => import('@/views/insurance/personnelChange/index.vue'),
  //   meta: {
  //     title: '参保变更确认',
  //     iconfont: 'icon-contacts-book-line',
  //     requiredPermission: 'INSURANCE_PERSONNEL_CHANGE',
  //   }
  // },
  // {
  //   path: 'insurance-charges',
  //   name: 'insuranceCharges',
  //   component: () => import('@/views/insurance/charges/index.vue'),
  //   meta: {
  //     title: '购买扣费确认',
  //     iconfont: 'icon-survey-line',
  //     requiredPermission: 'INSURANCE_CHARGES',
  //   }
  // },
  // {
  //   path: 'insurance-policy',
  //   name: 'insurancePolicy',
  //   component: () => import('@/views/insurance/policy.vue'),
  //   meta: {
  //     title: '社保/公积金政策',
  //     iconfont: 'icon-pages-line',
  //     requiredPermission: 'INSURANCE_POLICY',
  //   },
  // },
  // {
  //   path: 'insurance-calculator',
  //   name: 'insuranceCalculator',
  //   component: () => import('@/views/insurance/calculator.vue'),
  //   meta: {
  //     title: '社保计算器',
  //     iconfont: 'icon-pages-line',
  //     requiredPermission: 'INSURANCE_CALCULATOR',
  //   },
  // },

]
export const commercialInsuranceRoutes: CustomRouteRecordRaw = {
  path: 'commercialInsurance',
  name: 'commercialInsurance',
  component: EmptyLayout,
  redirect: (): RouteLocationRaw => redirectRouter(commercialInsuranceChildrenRoutes),
  meta: {
    title: '商保',
    iconfont: 'icon-emotion-laugh-line',
    desc: '以商保为维度内容',
    requiredPermission: 'COMMERCIAL_INSURANCE',
    needIcon: true,
    noQuick: true
  },
  children: commercialInsuranceChildrenRoutes
}

