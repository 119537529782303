import { defineStore } from 'pinia'
import { router } from '@/router/index'
import { RouteRecordNormalized, RouteRecordRaw } from 'vue-router'
import { useUserStore } from './user'

interface MenuTreeState {
    menuTree: RouteRecordRaw[]
}
export const useMenuTreeStore = defineStore('nav-menu-tree', {
  state: (): MenuTreeState => ({
    menuTree: []
  }),
  getters: {},
  actions: {
    initMenuTree() {
      const appRoute = computed(() => router.getRoutes().find(el => el.name === 'root') as RouteRecordNormalized)
      const userStore = useUserStore()
      const getTree = () => {
        const copyRouter = JSON.parse(JSON.stringify(appRoute.value.children))
        copyRouter.sort((a: RouteRecordNormalized, b: RouteRecordNormalized) => (a.meta.order || 0) - (b.meta.order || 0)) // 菜单优先级排序

        return copyRouter.filter(routeConfig => {

          // 整个模块的路由都无权限
          if (!userStore.hasPermission(routeConfig.meta?.requiredPermission)) {
            return null
          }

          // 无子路由，则证明当前是具体路由的菜单，直接返回
          if (!routeConfig.children) {
            return routeConfig
          }

          /* todo:优化代码使用递归简化 */
          // 有子路由，证明该 config 为折叠菜单，而非具体路由菜单，需要寻找其子有效路由
          const subRouteConfigs = routeConfig.children.filter(subRouteConfig => {

            if (!userStore.hasPermission(subRouteConfig.meta?.requiredPermission)) {
              return null
            }
            if (subRouteConfig.meta.hideInMenu === true) {
              return null
            }
            // 无子路由，则证明当前是具体路由的菜单，直接返回
            if (!subRouteConfig.children) {
              return subRouteConfig
            }
            // 有子路由，证明该 config 为折叠菜单，而非具体路由菜单，需要寻找其子有效路由

            const insubRouteConfigs = subRouteConfig.children.filter(insubRouteConfig => insubRouteConfig.meta?.hideInMenu !== true && userStore.hasPermission(insubRouteConfig.meta?.requiredPermission))
            if (insubRouteConfigs.length) {
              subRouteConfig.children = insubRouteConfigs
              return subRouteConfig
            }
            return null
          })
          if (subRouteConfigs.length) {
            routeConfig.children = subRouteConfigs
            return routeConfig
          }

          return null
        }) // 去除假值简写方式，等价于 a.filter(function (x) { return Boolean(x); });

      }
      // 获得菜单项配置
      this.menuTree = getTree()
    }

  }
})