<template>
  <custom-api-select
    :api="dictionaryApi.getDictionaryList"
    :api-params="{ pid: jobTypeList[0].id }"
    :immediate="true"
    value-field="id"
    label-field="text"
  />
</template>
<script lang="ts" setup>
import { dictionaryApi } from '@/api/system'
import { ref } from 'vue'
let jobTypeList = ref<any>([])
jobTypeList.value = await dictionaryApi.getDictionaryList({ code: 'job_type' })
// 没有用，可删
</script>