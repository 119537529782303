/* ************************************************
【】 校验是否选择了错误表格是在单sheet导入的时候是通过表头的名字来进行判断的只需要对应好表头就可以了
【】 在多sheet表格导入时，时通过表格底部的sheet来进行判断的，需要配置  sheetNames
  ************************************************
*/
import { TextKeyMap } from '@/utils/xlsx'
import { ExtractPropTypes, PropType } from 'vue'
export type DateFormat = 'YYYY-MM-DD HH:mm' |
  'YYYY-MM-DD' | 'YYYY-MM' | 'NOW' | 'YYYY/MM/DD'
type ValidateType = 'jobUserName' | 'jobUserBirthday' | 'jobUserSex' | 'jobUserMobile' | 'jobUserEducation' | 'resumeName' | 'not'
export type ValidateKeyAndType = {
  [key: string]: {
    required: boolean, // 校验是否必填
    type: ValidateType, // 校验类型 not 是不校验
    requiredAssociationKey?: string, // 校验关联的另一个字段key
    associationValidateString?: string // 校验的字段填什么会影响到另一个字段

  }
}
type TextKeyMapArray = {
  // 需要去 % 的key
  needRemovePercentKey?: string[],
  // 菜单映射
  textKeyMap: TextKeyMap,
  // 是否过滤数据
  isFilter?: boolean,
  // 过滤数据依赖的key和指定的字符 key===String 过滤掉整条数据
  filterDependedKeyAndString?: FilterDependedKeyAndString
}
export type FilterDependedKeyAndString = {
  key: string,
  dependedString: string
}
export const jobResumeImportProps = {
  // 是否过滤数据
  isFilter: {
    type: Boolean,
    default: false
  },
  // 过滤数据依赖的key和指定的字符,  key===String 过滤掉整条数据
  filterDependedKeyAndString: {
    type: Object as PropType<FilterDependedKeyAndString>,
    default: {}
  },
  // 多sheet导入时，不需要解析的sheet
  notResolvedSheetIndex: {
    type: Array<number>,
    default: []
  },
  // 多sheet导入时，用来判断是否导错模板
  sheetNames: {
    type: Array<string>,
    default: [],
  },
  // 多sheet导入菜单映射
  textKeyMapArray: {
    type: Array<TextKeyMapArray>,
    default: []
  },
  // 菜单映射
  textKeyMap: {
    type: Object as PropType<any>,
    default: {}
  },
  // // 最大excel导入数据条数
  maxImportNum: {
    type: Number,
    default: 500
  },
  // 文件最大大小
  maxSize: {
    type: Number,
    default: 2,
  },

  // 模板下载链接
  downLoadUrl: {
    type: String,
    default: ''
  },
  // 下载模板表名
  excelName: {
    type: String,
    default: '默认表格'
  },
  // 想要转换的格式
  format: {
    type: String as PropType<DateFormat>,
    default: 'YYYY-MM-DD'
  },
  // 需要转换日期格式的key
  needFormatDataKey: {
    type: Array<string>,
    default: []
  },
  // 提示文字
  warningTexts: {
    type: Array<string>,
    default: [
      '1、请按照模板表格填写人员信息与提供简历文件，需将表格与简历文件打包成一个ZIP的压缩包上传；',
      '2、简历建议使用PDF文件，DOC或DOCX格式也可以，大小不超过20M；'
    ]
  },
  // 需要去 % 的key（会转成小数）
  needRemovePercentKey: {
    type: Array<string>,
    default: []
  },
  // 需要校验的key和校验规则
  validateKeyAndType: {
    type: Object as PropType<ValidateKeyAndType>,
    default: {}
  },

  // 二次格式数据
  transFormData: {
    type: Function as PropType<Fn>,
    default: () => { }
  }

}
export type JobResumeImportProps = Partial<ExtractPropTypes<typeof jobResumeImportProps>>