<template>
  <a-config-provider
    :locale="zhCN"
    :transform-cell-text="({ text }) => text ? text : '--'"
  >
    <div>
      <router-view />
      <modal-form
        v-if="globalModelForm._isRender"
        v-bind="(globalModelForm.modelFormAttr as Readonly<ExtractPropTypes<typeof modalFormProps>>)"
        :ref="el => globalModelForm.modelFromRef = el"
        :visible="globalModelForm.visible"
        @ok="() => globalModelForm.visible = false"
        @cancel="() => globalModelForm.visible = false"
      />
    </div>
  </a-config-provider>
</template>
<script lang="ts" setup>
import type { ExtractPropTypes } from 'vue'
import { modalFormProps } from '@/components/form/props'
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import ModalForm from './components/form/modal-form.vue'
import globalModelForm from '@/hooks/global-model-form'
import { useWindowSizeFn } from './hooks/window-size-fn'
import { useSystemStore } from './store/modules/system'
const systemStore = useSystemStore()

const setDevider = () => {
  const { body } = document
  const rect = body.getBoundingClientRect()
  const isMobile = rect.width - 1 < 992 && /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  const miniScreen = rect.width - 1 < 1280
  systemStore.toggleDevice(!isMobile)
  systemStore.setMiniScrren(miniScreen)
}

useWindowSizeFn(setDevider)
setDevider()

</script>