import { ExtractPropTypes, PropType } from 'vue'

export const insuranceRegionSelectProps = {
  // 双向数据绑定的该组件的值
  value: {
    type: Array as PropType<string[]>,
    default: undefined,
  },
  // 是否直接要名字
  needChineseValue: {
    type: Boolean,
    default: false
  },
  type: {
    type: String,
    default: 'insuranceProvince',
  }
}

export type InsuranceRegionSelectProps = ExtractPropTypes<typeof insuranceRegionSelectProps>


