import { VNode, PropType, ExtractPropTypes } from 'vue'
type RenderFn = (data: any) => (VNode | HTMLElement | string)
type VueTsxData = {
  record:any
}
export const vueTsxProps = {
  render: {
    type: Function as PropType<RenderFn>,
    default: () => '',
    required: true,
  },
  data: {
    type: Object as PropType<VueTsxData>,
    default: () => ({})
  }
}

export type VueTsxProps = Partial<ExtractPropTypes<typeof vueTsxProps>>