import { useSiteConfig } from '@/hooks/site-config'
import { computed, unref, ComputedRef, Ref } from 'vue'
import { useSystemStore } from '@/store'

const { table } = useSiteConfig

export function useScroll(
  props: ComputedRef<IGridTable.Props>,
  tableElRef: Ref<IGridTable.TableBodyExpose | undefined>,
  columnsRef: ComputedRef<IGridTable.ColumnProps[]>,
  scrollRef: Ref<{ x?: string | number | true, y?: string | number }>,
) {
  const isFullscreen = ref(false)
  const systemStore = useSystemStore()
  const scrollY = ref('calc(100vh - 600px)')
  function setFullscreen(newValue: boolean) {
    isFullscreen.value = newValue
  }

  function setScollY() {
    if (!systemStore.isPc) {
      scrollY.value = undefined
      return
    }
    nextTick(() => {
      const el = document.querySelector('tbody')
      // 解决点击搜索展开时候出现表格浮动
      scrollY.value = `calc(100vh - ${offsetTop(el) + 80}px)`
      setTimeout(() => {
        let scrollHeight = offsetTop(el) + 80
        scrollY.value = `calc(100vh - ${scrollHeight}px)`
      })
    })
  }

  // setScollY()

  function offsetTop(el: HTMLElement, filterClass: Array<string> = []): any {
    for (let key in filterClass) {
      if (el.className.indexOf(filterClass[key]) > -1) {
        return 0
      }
    }
    if (el.offsetParent) {
      return offsetTop(el.offsetParent as HTMLElement, filterClass) + el.offsetTop
    }

    return el.offsetTop
  }

  const getScrollX = computed(() => {
    let x = 0
    const { selectable, rowSelection, indexable, rowActions, cardable } = unref(props)
    const SELECT_BOX_WIDTH = 30 // 选择框宽度
    const INDEX_BOX_WIDTH = 50 // 索引列宽度
    const ACTION_BOX_WIDTH = 180 // 操作栏宽度
    if (selectable || rowSelection) {
      x += SELECT_BOX_WIDTH
    }
    if (indexable) {
      x += INDEX_BOX_WIDTH
    }
    if (rowActions && rowActions.length > 0) {
      x += ACTION_BOX_WIDTH
    }
    unref(columnsRef)
      .filter(column => !column.defaultHidden)
      .forEach(column => {
        x += column.width ? Number.parseInt(column.width as string) : table.defaultColumnWidth
      })
    const tableWidth = unref(tableElRef)?.tableBodyRef?.value?.$el.offsetWidth ?? 0
    return (!systemStore.isPc && cardable) || tableWidth > x ? '100%' : x
  })


  // const scrollRef = computed(() => {
  //   const { scroll } = unref(props)

  //   const tableScroll = {
  //     scrollToFirstRowOnChange: true,
  //     y: unref(scrollY),
  //     x: unref(getScrollX),
  //     ...scroll,
  //   }
  //   if (isFullscreen.value) {
  //     tableScroll.y = 'calc(100vh - 90px)'
  //   }
  //   return tableScroll
  // })

  return {
    setFullscreen,
    setScollY
  }
}
