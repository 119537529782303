import { PropType, ExtractPropTypes } from 'vue'
import { CascaderProps } from 'ant-design-vue/es/cascader'
import { SelectProps } from 'ant-design-vue/es/select'
import { InputProps } from 'ant-design-vue/es/input'

export const dynamicCascadeSelectProps = {
  // 双向数据绑定的该组件的值
  value: {
    // type: [Array] as PropType< (number | string)[]>,
    type: Array<any>,
    default: undefined,
  },
  storeAble: {
    type: Boolean,
    default: true,
  },
  cascaderProps: {
    type: Object as PropType<CascaderProps & {projectSelectOptions:SelectProps}>, // 所选项目的自定义字段的所有选项
    default: {}
  },
  selectProps: {
    type: Object as PropType<SelectProps>,
    default: {

    }
  },
  inputProps: {
    type: Object as PropType<InputProps>,
    default: {}
  },

  /* 是否展示自定义字段 */
  isShowCustom: {
    type: Boolean,
    default: false
  }
}

export type DynamicCascadeSelectProps = Partial<ExtractPropTypes<typeof dynamicCascadeSelectProps>> & SelectProps