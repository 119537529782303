<template>
  <custom-api-select
    :api="attendanceApi.getVacationRuleList"
    :api-params="apiParams"
    :immediate="true"
    value-field="id"
    label-field="vacationName"
  />
</template>
<script lang="ts" setup>
import { attendanceApi } from '@/api/attendance'
import { vacationSelectProps } from './props'
const props = defineProps(vacationSelectProps)
const { apiParams } = props
</script>
