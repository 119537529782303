<!-- 用于在vue模板中使用tsx的功能 -->
<script lang="tsx">
import { defineComponent } from 'vue'
import { vueTsxProps } from './props'
export default defineComponent({
  props: vueTsxProps,
  setup(props) {
    return () => (
      <>
        {props.render(props.data)}
      </>
    )
  }
})
</script>