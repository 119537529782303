import { ExtractPropTypes, PropType } from 'vue'

export const exportTableSelectProps = {
  value: {
    type: Object as PropType<object>,
  },
  demoUrl: {
    type: [String, Function],
    default: ''
    // default:'https://new-xinchouyi-1301695016.cos.ap-guangzhou.myqcloud.com/file/%E5%B7%A5%E8%B5%84%E5%8D%95%E6%A8%A1%E6%9D%BF.xlsx'
  },
  helpTextPrefix: {
    type: String,
    default: '请按'
  },
  helpText: {
    type: String,
    default: '模板'
  },
  requireField: {
    type: Array as PropType<string[]>,
    // eslint-disable-next-line vue/require-valid-default-prop
    default: ['realname', 'mobile', 'idcard', 'bank_account', 'should_money', 'money', 'shebao_money', 'gjj_money', 'tax_money', 'entry_at'],
  },
  scroll: {
    type: Object,
    // eslint-disable-next-line vue/require-valid-default-prop
    default: {
      x: 2000,
      y: 500
    }
  },
  fieldMap: {
    type: Object as PropType<object>,
    // eslint-disable-next-line vue/require-valid-default-prop
    default: {
      realname: '姓名',
      mobile: '电话',
      idcard: '身份证',
      bank_account: '银行卡',
      should_money: '应发金额',
      money: '实发金额',
      fee_money: '服务费',
      shebao_money: '社保',
      gjj_money: '公积金',
      tax_money: '个税',
      entry_at: '入职日期',
      bank_name: '支行',
      bank_sn: '联行号',
      remark: '备注',
    },
  },
}


export type ExportTableSelectProps = Partial<ExtractPropTypes<typeof exportTableSelectProps>>