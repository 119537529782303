import { request } from '@/utils/request'

// 城市社保
const socialSecurityApi = {
  //  【】获取城市社保方案
  getSocialSecurity: cityId => request.get(`/social/socials/detail/${cityId}`),
  // 【】 获取城市社保列表
  getSocialCityList: () => request.get('/social/socials/index'),
  // 【】 项目统一设置员工社保
  setProjectSocialSecurity: params => request.post('/staff/staff_social_plans/set', params),
  // 【】 导入员工社保设置
  importSocialSecurity: params => request.post('/staff/staff_social_plans/import', params)
}
export { socialSecurityApi }