<template>
  <a-switch
    :checked="props.value"
    :checked-children="props.checkedChildren"
    :un-checked-children="props.unCheckedChildren"
    :un-checked-value="props.unCheckedValue"
    :checked-value="props.checkedValue"
    @change="handleChange"
  />
</template>

<script lang="ts" setup name="Switch">

import { CustomSwitchProp } from './props'
const props = defineProps(CustomSwitchProp)

type EmitEvents = {
  (e:'update:value', value:string):void
}
const emits = defineEmits<EmitEvents>()

function handleChange(value) {
  emits('update:value', value)
}
</script>
