import 'polyfill-object.fromentries' // 浏览器版本问题导致Object.fromEntries is not a function的问题
import App from './app.vue'
import { createApp } from 'vue'
import { setupRouter } from './router'
import { setupStore } from './store'
import { setFunDebug } from '../config/fundebug.config.js'
import Tmap from '@map-component/vue-tmap'
import './styles/app.less'
import './styles/reset.less'
import { fixArray } from './utils/fixArray'
import '@/utils/checkUpdate' // 因版本更新迭代的速度很快，就会引起用户在应用当中点击无反应
const app = createApp(App)
fixArray() // 修复低版本浏览器不能使用数组的一些新方法
setupStore(app)
setupRouter(app)
setFunDebug(app)
app.use(Tmap)
app.mount('#app')
