<template>
  <a-tree-select
    :loading="loading"
    v-bind="bindAttrs"
  />
</template>
<script lang="ts" setup>
import { apiTreeSelectProps } from './props'
import { TreeSelectProps } from 'ant-design-vue'
import { isFunction, isArray } from 'lodash-es'
const props = defineProps(apiTreeSelectProps)
const attrs = useAttrs()
const { fieldNames } = attrs
const treeData = ref<TreeSelectProps['treeData']>([])
type EmitEvents = {
  (e: 'change', ...value): void,
  (e: 'update:value', ...value): void,
  (e: 'treeDataLoaded', ...value): void
}
const loading = ref(false)
const value = computed(() => (props.value === 0 && props.zeroToUndefined ? undefined : props.value))
const emits = defineEmits<EmitEvents>()
async function fetch() {
  const { api, apiParams, valueToString } = props
  if (!api && !isFunction(api)) {
    return
  }
  loading.value = true
  let result = await api(apiParams)
  treeData.value = result
  emits('treeDataLoaded', treeData.value)
  loading.value = false
}
onMounted(() => {
  fetch()
})
const getValue = (value: any) => {
  let tmp = unref(value)
  if (!loading.value) {
    return tmp
  }
  if ((isArray(tmp) && tmp?.length !== 0) || (!isArray(tmp) && tmp)) {
    return '加载中'
  } else {
    return unref(value)
  }
}
const bindAttrs = computed((): TreeSelectProps => ({
  treeData: treeData.value,
  showSearch: true,
  value: getValue(value),
  dropdownStyle: {
    maxHeight: '400px',
    overflow: 'auto'
  },
  fieldNames: fieldNames ?? {
    label: 'name',
    value: 'id'
  },
  allowClear: true,
  onChange: (v: any) => {
    emits('change', v)
    emits('update:value', v)
  },
  ...attrs,
}))
</script>
